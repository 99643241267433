import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import './account-type-badge.scss';

class AccountTypeBadge extends Component {
  constructor(props) {
    super(props);
    this.getType = this.getType.bind(this);
  }

  getType(typeId) {
    switch (typeId) {
      case 1: return 'account-form.types.billing';
      case 2: return 'account-form.types.saving';
      case 3: return 'account-form.types.wallet';
      case 4: return 'account-form.types.credit-card';
    }
  }


  getVariant(typeId) {
    switch (typeId) {
      case 1: return 'badge-primary';
      case 2: return 'badge-success';
      case 3: return 'badge-secondary';
      case 4: return 'badge-danger';
    }
  }

  render() {
    return (
      <div className="account-type-badge-c ">
        <div className={'badge badge-outlined badge-pill ' + this.getVariant(this.props.typeId)}>
          <FormattedMessage id={this.getType(this.props.typeId)}></FormattedMessage>
        </div>
      </div>

    );
  }

}

export default AccountTypeBadge;