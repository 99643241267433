import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button from '../common/button';
import Panel from '../common/panel';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import './progress.scss';

const Progress = ({ striped, success, info, warning, danger, value, min, max }) => {
  min = min || 0;
  max = max || 100;
  value = value || 0;

  let valueP = value / max * 100;
  if (valueP> 100) valueP = 100;

  return (
    <div className="progress-c">
      <div className="progress">
        <div className={classNames("progress-bar", {
          "progress-bar-striped": striped,
          "bg-success": success,
          "bg-danger": danger,
          "bg-warning": warning,
          "bg-info": info,
        })} role="progressbar" style={{ "width": valueP + "%" }} aria-valuenow={value} aria-valuemin={min} aria-valuemax={max}></div>
      </div>
    </div>
  );
}


Progress.propTypes = {

}


export default Progress;