import predef from '../../predef';
import Store from './store';

class AccountsStore {
    constructor() {
        this.accountsStore = new Store(predef.endpoints.account.index,
            [
                predef.events.account.accountAdded,
                predef.events.account.accountUpdated,
                predef.events.account.accountDeleted,
                predef.events.account.accountRestored,
                predef.events.account.accountsReordered,
                predef.events.userSettings.userSettingsUpdated,
                predef.events.transaction.reimbursablesChanged,
                predef.events.transaction.transactionsChanged,
            ], predef.events.account.accountsStoreUpdated);

        this.deltededAccountsStore = new Store(predef.endpoints.account.deleted,
            [predef.events.account.accountDeleted, predef.events.account.accountRestored]);
    }

    get accounts() {
        return this.accountsStore.asObservable();
    }

    get deletedAccounts() {
        return this.deltededAccountsStore.asObservable();
    }

    byId(id){
        return new Promise((resolve, _)=> {
            this.accounts.subscribe(entities => {
                if (entities == null) return;

                resolve(entities.single(x=>x.id == id));
            });
        });
    }
}

const instance = new AccountsStore();
export default instance;
