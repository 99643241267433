import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { deepCopy, guid } from '../../util';
import Col from './col';
import './controlled-form.scss';
import './form.scss';
import FormControlService from '../services/form-control-service';
import Panel from './panel';

class ControlledForm extends Component {

  constructor(props) {
    super(props);
    this.formId = guid();
    this.onChangeCallbacks = {};
    this.onSubmit = this.onSubmit.bind(this);
  }

  render() {
    let groups = this.props.definition.groups || [{ name: 'default', fields: this.props.definition.fields }];

    return (
      <div className={'controlled-form-c  ' + (this.props.className || '')}>
        <form onSubmit={this.onSubmit}>
          <div className="fields ">
            {this.renderGroups(groups)}
            {
              this.props.definition.postFieldsContent != null ? this.props.definition.postFieldsContent : null
            }
          </div>
          {
            this.props.children ? <div className={classNames("actions", this.props.definition?.actionsLayout)}>
              {this.props.children}
            </div> : null
          }
        </form>
      </div>
    );
  }

  onSubmit() {
    this.props.onSubmit?.();
  }

  renderGroups(groups) {
    let content = [];

    for (let group of groups) {
      if (group.hidden) continue;
      content.push(<React.Fragment key={group.name} >
        {group.header != null ? <h3><FormattedMessage id={group.header}></FormattedMessage></h3> : null}
        {this.props.groupsInPanels ? <Panel>{this.renderGroup(group)}</Panel> : this.renderGroup(group)}
      </React.Fragment>)
    }

    return <div className="gorups">{content}</div>
  }

  renderGroup(group) {
    return <div className={classNames('row group', group.name + '-group')}>
      {this.renderFields(group.fields)}
    </div>
  }

  renderFields(fields) {
    let controls = [];
    for (let i = 0; i < fields.length; i++) {
      let field = fields[i];
      if (!field.hidden) {
        if (field.type == 'line-break') {
          controls.push(<div key={guid()} className={classNames('w-100', field.layout)}></div>);
        }
        else {
          controls.push(<Col key={field.name} layout={field.layout}>{this.renderField(field)}</Col>);
        }
      }
    }

    return controls;
  }

  getOnChange(name) {
    if (!this.onChangeCallbacks.hasOwnProperty(name)) {
      this.onChangeCallbacks[name] = (value) => {
        let values = this.props.values == null ? {} : this.props.values // deepCopy(this.props.values)
        values[name] = value;

        this.props.onChange(values)
      }
    }
    return this.onChangeCallbacks[name];

  }

  renderField(field) {
    let hasError = this.props.errors != null && this.props.errors[field.name] != null;
    let errorMessage = field.errorMessage;

    if (hasError && errorMessage == null) {
      errorMessage = this.props.errors[field.name];
    }

    let props = deepCopy(field);
    props.hasError = hasError;
    props.errorMessage = errorMessage;
    props.onChange = this.getOnChange(field.name);
    props.onSubmit = this.onSubmit;
    props.value = this.getValue(field);
    props.key = field.name;

    return FormControlService.getControlFromField(field, props);
  }

  getValue(field) {
    if (this.props.values == null) return FormControlService.getValueOrDefault(field.type, null);

    return FormControlService.getValueOrDefault(field.type, this.props.values[field.name]);
  }
}

ControlledForm.propTypes = {
  definition: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  values: PropTypes.object,
  groupsInPanels: PropTypes.bool,
  className: PropTypes.string,
  errors: PropTypes.object,

}

export default ControlledForm;