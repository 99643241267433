import Service from './service';
import predef from '../../predef';

class IncomeStreamsService extends Service {
    update(incomeStreams) {
        return this.post(predef.endpoints.incomeStreams.index, incomeStreams, predef.events.incomeStreams.incomeStreamsChanged,incomeStreams,'income-streams-service.udpate-success', 'income-streams-service.udpate-error')
    }

    
}


let instance = new IncomeStreamsService();
export default instance;
