import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button, { Cancel, Remove, Save, Edit } from '../common/button';
import Panel from '../common/panel';
import PropTypes from 'prop-types';
import Page from '../common/page';
import classNames from 'classnames/bind';
import { H3 } from '../common/headers';

import './responsive-lens.scss';

class ResponsiveLens extends Component {

  constructor(props) {
    super(props);
    this.state = { windowWidth: window.innerWidth, scope: this.getScope(window.innerWidth) };
  }

  render() {
    if (window.location.href.indexOf("localhost") == -1) return null;

    return <div className="responsive-lens-c">
      {this.state.windowWidth} {this.state.scope}
    </div>
  }



  componentDidMount() {
    window.onresize = () => {
      this.setSizeAndScope();
    };
  }

  setSizeAndScope(){
    this.setState({ windowWidth: window.innerWidth, scope: this.getScope(window.innerWidth) })
  }

  getScope(w) {
    if ($('body').hasClass('pinned-sidebar-open')) {
      if (w >= 1680) return "cs-w"
      if (w >= 1480) return "cs-xl";
      if (w >= 1304) return "cs-lgp";
      if (w >= 1272) return "cs-lg";
      if (w >= 1160) return "cs-mdp";
      if (w >= 1048) return "cs-md";
      if (w >= 952) return "cs-smp";
      if (w >= 856) return "cs-sm";
      return "cs-xs";
    }
    else {
      if (w >= 1400) return "c-w"
      if (w >= 1200) return "c-xl";
      if (w >= 1024) return "c-lgp";
      if (w >= 992) return "c-lg";
      if (w >= 880) return "c-mdp";
      if (w >= 768) return "c-md";
      if (w >= 672) return "c-smp";
      if (w >= 576) return "c-sm";
      return "c-xs";
    }
  }

  componentWillUnmount() {
  }

}


ResponsiveLens.propTypes = {

}


export default ResponsiveLens;