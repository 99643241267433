import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import AreaChart from '../common/charts/area-chart';
import ChartColor from '../common/charts/chart-color';
import CurrencyTabs from '../common/currency-tabs';
import LoadingScreen from '../common/loading-screen';
import {NoDataMessage} from '../common/message'
import Report from '../services/report-service';
import './net-value-history-report.scss';


class NetValueHistoryReport extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  render() {
    return (
      <div className="net-value-history-report-c">
        <h2><FormattedMessage id="net-value-history-report.header"></FormattedMessage></h2>
        {this.state.loading ? <LoadingScreen></LoadingScreen> : this.renderReports()}
      </div>
    );
  }

  renderReports() {
    if (this.state.data.currencyItems.length == 0) return <NoDataMessage></NoDataMessage>;


    return <CurrencyTabs currencies={this.state.data.currencyItems} renderTab={(currency, isBaseCurrency) => {
      let activeCurrencyItem = this.state.data.currencyItems.single(x => x.currency == currency && x.isBaseCurrency == isBaseCurrency);
      let items = activeCurrencyItem.items;
      let chartDefinition = {
        timeSeries: true,
        x: items.select(x => x.date),
        series: [
          {
            y: items.select(y => y.realEstates),
            itemStyle: (v) => ChartColor.violet.solid,
            color: ChartColor.violet.standard,
            name: 'common.real-estates'
          },
          {
            y: items.select(y => y.assets),
            itemStyle: (v) => ChartColor.blue.solid,
            color: ChartColor.blue.standard,
            name: 'common.assets'
          },
          {
            y: items.select(y => y.investments),
            itemStyle: (v) => ChartColor.yellow.solid,
            color: ChartColor.yellow.standard,
            name: 'common.investments'
          },
          {
            y: items.select(y => y.reimbursable),
            itemStyle: (v) => ChartColor.orange.solid,
            color: ChartColor.orange.standard,
            name: 'common.reimbursables'
          },
          {
            y: items.select(y => y.accountsBalance),
            itemStyle: (v) => ChartColor.green.solid,
            color: ChartColor.green.standard,
            name: 'common.accounts-balance'
          },
        ],
        height: 600,
        color: ChartColor.blue.gradient.leftRight,
      }

      return <AreaChart key={(isBaseCurrency ? "~" : "") + currency} definition={chartDefinition}></AreaChart>
    }}></CurrencyTabs>
  }

  // accountsBalance: 123993.18
  // assets: 0
  // date: "2017-05-23T00:00:00"
  // funds: 0
  // investments: 0
  // netValue: 139706.98
  // realEstates: 0
  // reimbursable: 15713.8

  reloadReport() {
    this.setState({ loading: true }, () => {
      Report.netValueHistory().then((res) => {
        this.setState({
          data: { currencyItems: res }
          , loading: false
          , activeCurrency: this.state.activeCurrency || (res.length > 0 ? res[0].currency : null)
        })
      });
    });
  }

  componentDidMount() {
    this.reloadReport();
    this.busSub = Bus.subscribe(this);
  }

  componentWillUnmount() {
    this.busSub.unsubscribe();
  }

  _onLanguageChanged() {
    this.reloadReport();
  }

}

NetValueHistoryReport.propTypes = {

}

export default NetValueHistoryReport;