import React from 'react';
import './json.scss';


const Json = (props) => {
  return window.location.href.indexOf("localhost") > -1 || props.showOnProd ? <div className="json-c">
    <pre>{JSON.stringify(props.data,null,4)}</pre>
  </div> : null;
}


Json.propTypes = {

}


export default Json;