import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import { routeParamId } from '../../util';
import predef from '../../predef';
import Button, { Cancel, Remove, Save, Edit } from '../common/button';
import Panel from '../common/panel';
import PropTypes from 'prop-types';
import Page from '../common/page';
import classNames from 'classnames/bind';
import { H3 } from '../common/headers';

import './income-stream-recurring-todos.scss';
import RecurringTodos from '../common/todos/recurring-todos';
import Todos from '../common/todos/todos';

class IncomeStreamRecurringTodos extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <div className="income-stream-recurring-todos-c">
      <RecurringTodos entityRef={predef.todosRefs.incomeStream} refId={this.props.match.params.id}> </RecurringTodos>
    </div>
  }
}


IncomeStreamRecurringTodos.propTypes = {

}


export default IncomeStreamRecurringTodos;