import React, { Component } from 'react';
import * as yup from 'yup';
import predef from '../../predef';
import Form from '../common/form';
import Page from '../common/page';
import RealEstatesService from '../services/real-estates-service';
import RealEstatesStore from '../stores/real-estates-store';
import './real-estate-form.scss';
import { routeParamId } from '../../util';
import Json from '../common/json'
import LoadingScreen from '../common/loading-screen';
import Panel from '../common/panel';
import { createHashHistory } from 'history';
const history = createHashHistory();



export const realEstateValidationSchema = yup.object().shape({
  name: yup.string().required(),
  purchaseCurrency: yup.string().required()
});


class RealEstateForm extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <div className="real-estate-form-c">
      <Form
        validationSchema={realEstateValidationSchema}
        onSubmit={vals => this.save(vals)}
        definition={this.getFormDefinition()}
        initialValues={this.props.realEstate}
        groupsInPanels
      >

      </Form>
    </div>
  }

  save(re) {
    if (this.props.realEstate != null) {
      return RealEstatesService.updateRealEstate(re);
    }
    else {
      return RealEstatesService.addRealEstate(re).then(() => history.push(predef.routes.realEstates.index));
    }
  }


  getFormDefinition() {
    return{
      groups: [
        {
          name: 'real-estate-info',
          header: 'real-estate-form.common-info',
          fields: [
            {
              type: 'text',
              name: 'name',
              label: 'common.name',
              layout: 'c c-md-12 c-lg-8 c-w-600',
              errorMessage: 'common.field-is-required'
            },
            {
              type: 'text',
              name: 'address',
              label: 'real-estate-form.address',
              layout: 'c c-md-12 c-lg-16 c-w-600'
            },
            {
              type: 'text',
              name: 'city',
              label: 'real-estate-form.city',
              layout: 'c c-smp-12 c-lg-6 c-w-200'
            },
            {
              type: 'text',
              name: 'kwNumber',
              label: 'real-estate-form.kw-number',
              layout: 'c c-smp-12  c-lg-6 c-w-200'
            },
            {
              type: 'number',
              name: 'area',
              label: 'real-estate-form.area',
              layout: 'c c-smp-8  c-lg-6 c-w-200'
            },
            {
              type: 'number',
              name: 'marketValue',
              label: 'real-estate-form.market-value',
              layout: 'c c-smp-8  c-lg-6 c-w-200'
            },
            {
              type: 'number',
              name: 'mortgage',
              label: 'real-estate-form.mortgage',
              layout: 'c c-smp-8  c-lg-6 c-w-200'
            },
            {
              type: 'number',
              name: 'unaccountedPurchaseCost',
              label: 'real-estate-form.unaccounted-purchase-cost',
              layout: 'c c-smp-16  c-lg-12 c-xl-8 '
            },
            {
              type: 'rich',
              name: 'notes',
              label: 'common.notes',
              layout: 'c c-w-1200'
            }
          ]
        },
        {
          name: 'buy-info',
          header: 'real-estate-form.buy-info',
          fields: [
            {
              type: 'number',
              name: 'purchasePrice',
              label: 'real-estate-form.purchase-price',
              layout: 'c c-sm-8 c-lg-4 c-w-200'
            },
            {
              type: 'currency',
              name: 'purchaseCurrency',
              label: 'common.currency',
              layout: 'c c-sm-8 c-lg-4 c-w-200',
              errorMessage: 'common.field-is-required',
              hidden: this.props.realEstate != null
            },
            {
              type: 'read-only-info',
              name: 'purchaseCurrency',
              label: 'common.currency',
              layout: 'c c-sm-8 c-lg-4 c-w-200',
              hidden: this.props.realEstate == null
            },
            {
              type: 'date',
              name: 'purchaseDate',
              label: 'real-estate-form.purchase-date',
              layout: ' c c-sm-8 c-lg-4 c-w-200'
            }
          ]
        },
        {
          name: 'rent',
          header: 'real-estate-form.rent',
          fields: [
            {
              type: 'checkbox',
              name: 'isRented',
              label: 'real-estate-form.is-rented',
              layout: 'c '
            },
            {
              type: 'date',
              name: 'firstRent',
              label: 'real-estate-form.first-rent',
              layout: 'c c-sm-12 c-mdp-8 c-lg-6 c-w-180'
            },
            {
              type: 'checkbox',
              name: 'isTenancyManaged',
              label: 'real-estate-form.is-tenancy-managed',
              layout: 'c  '
            },
            {
              type: 'text',
              name: 'managingCompany',
              label: 'real-estate-form.managing-company',
              layout: 'c c-sm-12 c-mdp-8 c-lg-6 c-w-400'
            },
            {
              type: 'checkbox',
              name: 'treatExpensesAsIncomeCost',
              label: 'real-estate-form.treat-expenses-as-income-cost',
              layout: 'c '
            },
            {
              type: 'checkbox',
              name: 'treatIncomeAsPassive',
              label: 'real-estate-form.treat-income-as-passive',
              layout: 'c '
            }
          ]
        }
      ]
    }
  }
}


RealEstateForm.propTypes = {

}


export default RealEstateForm;


export class EditRealEstate extends Component {

  constructor(props) {
    super(props);
    this.state = { isLoading: true, realEstate: null };
  }

  render() {
    return this.state.isLoading ? <LoadingScreen></LoadingScreen> : this.renderBody();
  }

  renderBody() {
    return <RealEstateForm realEstate={this.state.realEstate}></RealEstateForm>
  }

  componentDidMount() {
    let id = routeParamId(this.props);
    RealEstatesStore.byId(id).then((re) => {
      this.setState({ realEstate: re, isLoading: re == null })
    });
  }

}

export class AddRealEstate extends Component {

  constructor(props) {
    super(props);
    this.state = { isLoading: true, realEstate: null };
  }
  render() {
    return <Page
      header={"real-estate-form.add-real-estate"}
      data={true}
      isLoading={false}
      renderBody={() => this.renderBody()}
    ></Page >
  }

  renderBody() {
    return <RealEstateForm></RealEstateForm>;
  }
}
