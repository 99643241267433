import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Api from './api';

export function PrivateRoute({ component: Component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          Api.isUserAuthenticated() ? (
            <Component {...props} />
          ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location }
                }}
              />
            )
        }
      />
    );
  }