import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button from '../common/button';
import Panel from '../common/panel';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { H3 } from '../common/headers';
import LoadingScreen from '../common/loading-screen';

import './loading-placeholder.scss';

const LoadingPlaceholder = ({ height }) => {
  height = height || 16;
  height = height + 'rem';
  let style = {
    height: height,
    minHeight: height
  }
  return <div className="loading-placeholder-c"  style={style}>
     <span className="loader fas fa-cog fa-spin"></span>
  </div>
}


LoadingPlaceholder.propTypes = {

}


export default LoadingPlaceholder;