import React, { Component } from 'react';
import predef from '../../predef';
import PagedGrid from '../common/grid/paged-grid';
import { H3 } from '../common/headers';
import Json from '../common/json';
import Number from '../common/number';
import Panel from '../common/panel';
import TransactionService from '../services/transactions-service';
import './reimbursables.scss';
import Date from '../common/date';
import ReimbursablesFilter from './reimbursables-filter';
import Button from '../common/button';
import TransactionsModal from '../transactions/transactions-modal';
import Modal from '../common/modal';
import Transaction from '../transactions/transaction';


class Reimbursables extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showReimbursements: false,
      showAddReimbursement: false
    };
  }

  createDefinition() {
    return [
      {
        show: true,
        headerClasses: "len-45",
        header: "common.index-column-header",
        renderCell: (_, i) => i + 1,
        align: 'center'

      },
      {
        show: true,
        headerClasses: "len-160",
        header: "common.amount",
        name: 'amount',
        renderCell: (o) => <Number value={o.amount} currency={o.currency}></Number>,
        align: 'right',
        sortable: true
      },
      {
        show: true,
        headerClasses: "len-160",
        name: 'reimbursed',
        header: "common.reimbursed",
        renderCell: (o) => <Number value={o.reimbursed} currency={o.currency}></Number>,
        align: 'right',
        sortable: true
      },
      {
        show: true,
        name: 'description',
        headerClasses: "len-320",
        header: "common.description",
        renderCell: (o) => o.description,
        sortable: true
      },
      {
        show: true,
        name: 'date',
        headerClasses: "len-120",
        header: "common.date",
        renderCell: (o) => <Date date={o.date}></Date>,
        sortable: true
      },
      {
        header: "common.account",
        name: 'account',
        show: true,
        headerClasses: "len-200",
        header: "common.account",
        renderCell: (o) => o.account,
        sortable: true
      },
      {
        name: 'actions',
        headerClasses: 'len-410',
        headerDisplayName: 'common.actions',
        renderCell: (o) => <React.Fragment>
          <Button link fad="eye" label="reimbursables.show-reimbursement" onClick={() => this.setState({ showReimbursements: true, reimbursementFor: o.operationId })}></Button>
          <Button link fad="edit" label="reimbursables.edit-transaction" path={predef.routes.transaction.edit(o.transactionId, predef.routes.reimbursables.index)}></Button>
          <Button link fad="plus" label="reimbursables.add-reimbursement" onClick={() => this.setState({ showAddReimbursement: true, addReimbursementFor: o.operationId, addReimbursementCurrency: o.currency })}></Button>
        </React.Fragment>,
        show: true
      }
    ];
  }

  render() {
    let reimbursableDefinition = this.createDefinition();
    let notReimbursedDefinition = this.createDefinition();
    return <div className="reimbursables-c">
      <Modal
        component={Transaction}
        componentProps={{
          transaction: {currency: this.state.addReimbursementCurrency, operations: [{ expense: false, isReimbursement: true, reimbursementFor: this.state.addReimbursementFor }] } }}
        onClose={() => this.setState({ showAddReimbursement: false })}
        show={this.state.showAddReimbursement}
        title={'transactions-modal.add-reimbursement'}
      >

      </Modal>
      <TransactionsModal show={this.state.showReimbursements} onClose={() => this.setState({ showReimbursements: false })} filter={{ reimbursementFor: this.state.reimbursementFor }}></TransactionsModal>
      <H3 header="reimbursables.not-reimbursed-header"></H3>
      <Panel>
        <ReimbursablesFilter onFilterChange={(f) => this.setState({ notReimbursedFilter: f })}></ReimbursablesFilter>
      </Panel>
      <Panel>
        <PagedGrid  sorting={{column: 'date', order: 'desc'}} fetchDataOn={predef.events.transaction.reimbursablesChanged} filter={this.state.notReimbursedFilter} id="not-reimbursed-operations" definition={notReimbursedDefinition} endpoint={predef.endpoints.transaction.notReimbursed}></PagedGrid>
      </Panel>

      <H3 header="reimbursables.reibursed-header"></H3>
      <Panel>
        <ReimbursablesFilter onFilterChange={(f) => this.setState({ reimbursedFilter: f })}></ReimbursablesFilter>
      </Panel>
      <Panel>
        <PagedGrid sorting={{column: 'date', order: 'desc'}} fetchDataOn={predef.events.transaction.reimbursablesChanged} filter={this.state.reimbursedFilter} id="reimbursed-operations" definition={reimbursableDefinition} endpoint={predef.endpoints.transaction.reimbursed}></PagedGrid>
      </Panel >
    </div>
  }


}


Reimbursables.propTypes = {

}


export default Reimbursables;