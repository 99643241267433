import PropTypes from "prop-types";
import React, { Component } from "react";
import predef from "../../predef";
import Badge from "../common/badge";
import Button from "../common/button";
import Date from "../common/date";
import Loader from "../common/loader";
import Message from "../common/message";
import Number from "../common/number";
import Pagger from "../common/pagger";
import Panel from "../common/panel";
import { TColumn } from "../common/table";
import "./controlled-transaction-list.scss";
import Flags from "./flags";
import TransactionFilter from "./transaction-filter";
import TransactionListColumnConfig from "./transaction-list-column-config";
import "./transaction-list.scss";
import TransactionsTable from "./transactions-table";
import TransferBadge from "./transfer-badge";
import Tags from "../common/tags";
import DateInput from "../common/date-input";
import classNames from "classnames";

class DateCell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      originalDate: props.transaction.date,
      date: props.transaction.date,
      id: "tran-date-" + props.transaction.id,
      editMode: false,
    };
    this.updateDate = this.updateDate.bind(this);
  }

  updateDate() {
    if (this.state.date === this.props.transaction.date) {
      this.setState({ editMode: false });
      return;
    }

    this.props.actions.updateTransactionDate(
      this.props.transaction.id,
      this.state.date
    );
    this.setState({ editMode: false });
  }

  render() {
    if (!this.state.editMode)
      return (
        <td>
          <div className="date-col">
            <Date date={this.props.transaction.date}></Date>
            <span
              onClick={() => this.setState({ editMode: true })}
              className="fa fa-edit b"
            ></span>
          </div>
        </td>
      );

    return (
      <td>
        <div className="date-col-edit">
          <DateInput
            showCalendarButton={false}
            name="transaction-date"
            value={this.state.date}
            onChange={(v) => this.setState({ date: v })}
          ></DateInput>
          <span onClick={this.updateDate} className="fa fa-save b"></span>
        </div>
      </td>
    );
  }
}

class ControlledTransactionList extends Component {
  constructor(props) {
    super(props);
    let config = ControlledTransactionList.getGridConfig(props);
    this.state = {
      gridConfig: config,
      showColumnsConfig: false,
    };
  }

  onFilterChanged(filter) {
    this.props.onViewStateChanged({ filter: filter });
  }

  onPageChanged(p) {
    this.props.onViewStateChanged({
      pagging: {
        page: p,
        pageSize: this.props.viewState.pagging.pageSize,
      },
    });
  }

  onPageSizeChanged(ps) {
    this.props.onViewStateChanged({
      pagging: {
        pageSize: ps,
        page: this.props.viewState.pagging.page,
      },
    });
  }

  onColumnsConfigChagnes(cc) {
    this.props.onViewStateChanged({
      columnsConfig: cc,
    });
  }

  render() {
    return (
      <div className="controlled-transaction-list-c">
        {this.props.hideFilters ? null : (
          <TransactionFilter
            initialFilter={this.props.initialFilter}
            disabled={this.props.viewState.disabledFilters}
            onFilterChanged={(f) => this.onFilterChanged(f)}
          ></TransactionFilter>
        )}
        <Panel className="tarnsaction-list">
          <div className="header">
            {this.props.viewState.isLoading ? (
              <Loader show={this.props.viewState.isLoading}></Loader>
            ) : (
              <div className="loading-placeholder">&nbsp;</div>
            )}
            {this.props.viewState.totalRows > 0 ? (
              <React.Fragment>
                <Pagger
                  pageSize={this.props.viewState.pagging.pageSize}
                  selectedPage={this.props.viewState.pagging.page}
                  totalRows={this.props.viewState.totalRows}
                  onPageChanged={(p) => this.onPageChanged(p)}
                  onPageSizeChanged={(ps) => this.onPageSizeChanged(ps)}
                ></Pagger>

                <Button
                  className="show-config btn-sm"
                  outline={!this.state.showColumnsConfig}
                  fa="cogs"
                  onClick={() =>
                    this.setState((state, props) => {
                      return { showColumnsConfig: !state.showColumnsConfig };
                    })
                  }
                ></Button>
              </React.Fragment>
            ) : null}
          </div>

          {this.state.showColumnsConfig ? (
            <TransactionListColumnConfig
              columns={
                this.props.viewState.columnsConfig ||
                this.state.gridConfig
                  .where((x) => x.allowEdit !== false)
                  .select((x) => {
                    return {
                      name: x.name,
                      header: x.header,
                      headerDisplayName: x.headerDisplayName,
                    };
                  })
              }
              onColumnsConfigChagnes={(cc) => this.onColumnsConfigChagnes(cc)}
            ></TransactionListColumnConfig>
          ) : null}
          {this.props.viewState.totalRows == 0 &&
          !this.props.viewState.isLoading ? (
            <Message message="transaction-list.no-data"></Message>
          ) : (
            <TransactionsTable
              onTransactionDelete={this.props.onTransactionDelete}
              onUpdateTransactionDate={this.props.onUpdateTransactionDate}
              config={this.state.gridConfig}
              transactions={this.props.viewState.transactions}
              isDeletingTransactions={
                this.props.viewState.isDeletingTransactions
              }
            ></TransactionsTable>
          )}
        </Panel>
      </div>
    );
  }

  static getDerivedStateFromProps(props, state) {
    return { gridConfig: ControlledTransactionList.getGridConfig(props) };
  }

  static getGridConfig(props) {
    return [
      {
        name: "expense-income-indicator",
        header: "transaction-list.row-number",
        renderHeader: () => (
          <React.Fragment>
            <TColumn className=" expense-income-header"></TColumn>
          </React.Fragment>
        ),
        renderCells: (tran, op, i) => {

          if (tran != null )
            return (
              <td
                className={classNames({
                  expense: tran.amount < 0,
                  income: tran.amount > 0,
                })}
              ></td>
            );

          if (tran == null)
            return (
              <td
                className={classNames({
                  expense: op.amount < 0,
                  income: op.amount > 0,
                })}
              ></td>
            );

            return <td></td>
        },
        show: true,
      },
      {
        name: "row-number",
        header: "transaction-list.row-number",
        renderHeader: () => (
          <React.Fragment>
            <TColumn
              className="len-25"
              header="transaction-list.row-number"
            ></TColumn>
            <TColumn className="len-25"></TColumn>
          </React.Fragment>
        ),
        renderCells: (tran, op, i) => (
          <React.Fragment>
            <td>{tran != null ? i + 1 : null}</td>
            <td>{tran == null ? i + 1 : null}</td>
          </React.Fragment>
        ),
        show: true,
      },
      {
        name: "date",
        header: "transaction-list.date",
        renderHeader: () => (
          <TColumn className="len-135" header="transaction-list.date"></TColumn>
        ),
        show: true,
        renderCells: (tran, op, i, actions) => {
          if (tran === null) return <td></td>;

          return <DateCell transaction={tran} actions={actions}></DateCell>;
        },
      },
      {
        name: "flags",
        header: "transaction-list.flags",
        renderHeader: () => <TColumn className="len-25"></TColumn>,
        show: true,
        renderCells: (tran, op, i) => (
          <td>
            <Flags operation={op} transaction={tran}></Flags>
          </td>
        ),
      },

      {
        name: "account",
        header: "transaction-list.account",
        renderHeader: () => (
          <TColumn
            className="len-200"
            header="transaction-list.account"
          ></TColumn>
        ),
        show: props.showAccountColumn,
        allowEdit: props.showAccountColumn,
        renderCells: (tran, op, i) => (
          <td>{tran != null ? tran.account.name : null}</td>
        ),
      },
      {
        name: "amount",
        header: "transaction-list.amount",
        renderHeader: () => (
          <TColumn
            className="len-120"
            header="transaction-list.amount"
          ></TColumn>
        ),
        show: true,
        renderCells: (tran, op, i) => {
          let amount = tran == null ? op.amount : tran.amount;
          let currency = props.showCurrency
            ? tran == null
              ? op.currency
              : tran.currency
            : null;
          return (
            <td className="t-right">
              <Number value={amount} currency={currency}></Number>
            </td>
          );
        },
      },
      {
        name: "category",
        header: "transaction-list.category",
        renderHeader: () => (
          <TColumn
            className="len-180"
            header="transaction-list.category"
          ></TColumn>
        ),
        show: true,
        renderCells: (tran, op, i) => {
          var hasCategory = op != null && op.category != null;
          var hasTags = op != null && op.tags != null && op.tags.length > 0;

          var res = [];
          if (hasCategory) {
            res.push(<React.Fragment key="cat">{op.category}</React.Fragment>);
          }
          if (hasCategory && hasTags) res.push(<br key="br"></br>);

          if (hasTags) {
            res.push(
              <Tags
                key="tags"
                tags={op.tags.select((x) => {
                  return { text: x, primary: true };
                })}
              ></Tags>
            );
          }
          return <td>{res}</td>;
        },
      },
      {
        name: "budget",
        header: "transaction-list.budget",
        renderHeader: () => (
          <TColumn
            className="len-180"
            header="transaction-list.budget"
          ></TColumn>
        ),
        show: !props.hideBudgetColumn,
        renderCells: (tran, op, i) => (
          <td>{op == null || op.budget == null ? null : op.budget.name}</td>
        ),
      },
      // {
      //   name: 'income-stream',
      //   header: 'common.income-stream',
      //   renderHeader: () => <TColumn className="len-180" header="common.income-stream"></TColumn>,
      //   show: true,
      //   renderCells: (tran, op, i) => <td>{op == null || op.incomeStream == null ? null : op.incomeStream.name}</td>
      // },
      {
        name: "description",
        header: "transaction-list.description",
        renderHeader: () => (
          <TColumn
            header="transaction-list.description"
            className="desc-col"
          ></TColumn>
        ),
        show: true,
        renderCells: (tran, op, i) => {
          let badges = [];
          if (tran != null && tran.isPartOfTransfer) {
            badges.push(
              <TransferBadge key="transfer" transaction={tran}></TransferBadge>
            );
          }

          if (
            op != null &&
            op.realEstate != null &&
            (props.showRealEstateBadges == null || props.showRealEstateBadges)
          ) {
            badges.push(
              <Badge
                key={"re " + op.realEstateId}
                fa="home"
                text="transaction-list.real-estate"
                secondary
                values={{ name: op.realEstate.name }}
              ></Badge>
            );
          }

          if (
            op != null &&
            op.incomeStream != null &&
            (props.showIncomeStreamBadges == null ||
              props.showIncomeStreamBadges)
          ) {
            badges.push(
              <Badge
                key={"is " + op.incomeStreamId}
                fa="chart-line"
                text="transaction-list.income-stream"
                secondary
                values={{ name: op.incomeStream.name }}
              ></Badge>
            );
          }
          return (
            <td>
              {badges}
              {badges.length > 0 ? <span>&nbsp;</span> : null}
              {op == null ? null : op.description}
            </td>
          );
        },
      },

      {
        name: "actions",
        header: "transaction-list.actions",
        renderHeader: () => <TColumn className="len-130"></TColumn>,
        show: true,
        renderCells: (tran, op, i, actions) => {
          if (tran == null) return null;
          return (
            <td>
              {tran.isInvestment ? (
                <Button
                  link
                  className="btn-sm action"
                  path={predef.routes.investments.dashboard(tran.investmentId)}
                  label="controlled-transaction-list.edit-investment"
                ></Button>
              ) : (
                <React.Fragment>
                  <Button
                    className="btn-sm action"
                    link
                    path={
                      tran.isPartOfTransfer
                        ? predef.routes.transaction.editTransfer(tran.id)
                        : predef.routes.transaction.edit(tran.id)
                    }
                    outline
                    label="common.edit"
                  ></Button>
                  <Button
                    className="btn-sm action"
                    link
                    danger
                    onClick={() => {
                      actions.deleteTransaction(tran);
                    }}
                    outline
                    label="common.delete"
                  ></Button>
                </React.Fragment>
              )}
            </td>
          );
        },
      },
    ];
  }
}

ControlledTransactionList.propTypes = {
  viewState: PropTypes.shape({
    filter: PropTypes.object,
    pagging: PropTypes.shape({
      page: PropTypes.number.isRequired,
      pageSize: PropTypes.number.isRequired,
    }),
    totalRows: PropTypes.number.isRequired,
    disabledFilters: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    transactions: PropTypes.array.isRequired,
  }),
  showCurrency: PropTypes.bool.isRequired,
  showAccountColumn: PropTypes.bool.isRequired,
  onViewStateChanged: PropTypes.func.isRequired,
  onTransactionDelete: PropTypes.func.isRequired,
  onUpdateTransactionDate: PropTypes.func.isRequired,
};

export default ControlledTransactionList;
