import * as echarts from 'echarts/dist/echarts.js';
import { writer } from 'repl';


let colors = {
    primary10: "hsl(204, 96%, 27%)",
    primary9: "hsl(203, 87%, 34%)",
    primary8: "hsl(202, 84%, 41%)",
    primary7: "hsl(201, 79%, 46%)",
    primary6: "hsl(199, 84%, 55%)",
    primary5: "hsl(197, 92%, 61%)",
    primary4: "hsl(196, 94%, 67%)",
    primary3: "hsl(195, 97%, 75%)",
    primary2: "hsl(195, 100%, 85%)",
    primary1: "hsl(195, 100%, 95%)",
    grey10: "hsl(210, 24%, 16%)",
    grey9: "hsl(209, 20%, 25%)",
    grey8: "hsl(209, 18%, 30%)",
    grey7: "hsl(209, 14%, 37%)",
    grey6: "hsl(211, 12%, 43%)",
    grey5: "hsl(211, 10%, 53%)",
    grey4: "hsl(211, 13%, 65%)",
    grey3: "hsl(210, 16%, 82%)",
    grey2: "hsl(214, 15%, 91%)",
    grey1: "hsl(216, 33%, 97%)",
    blue10: "hsl(204, 97%, 27%)",
    blue9: "hsl(203, 87%, 34%)",
    blue8: "hsl(202, 83%, 41%)",
    blue7: "hsl(201, 79%, 46%)",
    blue6: "hsl(199, 84%, 55%)",
    blue5: "hsl(197, 92%, 61%)",
    blue4: "hsl(196, 94%, 67%)",
    blue3: "hsl(195, 97%, 75%)",
    blue2: "hsl(195, 100%, 85%)",
    blue1: "hsl(195, 100%, 95%)",
    red10: "hsl(348, 94%, 20%)",
    red9: "hsl(350, 94%, 28%)",
    red8: "hsl(352, 90%, 35%)",
    red7: "hsl(354, 85%, 44%)",
    red6: "hsl(356, 75%, 53%)",
    red5: "hsl(360, 83%, 62%)",
    red4: "hsl(360, 91%, 69%)",
    red3: "hsl(360, 100%, 80%)",
    red2: "hsl(360, 100%, 87%)",
    red1: "hsl(360, 100%, 95%)",
    yellow10: "hsl(15, 86%, 30%)",
    yellow9: "hsl(22, 82%, 39%)",
    yellow8: "hsl(29, 80%, 44%)",
    yellow7: "hsl(36, 77%, 49%)",
    yellow6: "hsl(42, 87%, 55%)",
    yellow5: "hsl(44, 92%, 63%)",
    yellow4: "hsl(48, 94%, 68%)",
    yellow3: "hsl(48, 95%, 76%)",
    yellow2: "hsl(48, 100%, 88%)",
    yellow1: "hsl(49, 100%, 96%)",
    green10: "hsl(170, 97%, 15%)",
    green9: "hsl(168, 80%, 23%)",
    green8: "hsl(166, 72%, 28%)",
    green7: "hsl(164, 71%, 34%)",
    green6: "hsl(162, 63%, 41%)",
    green5: "hsl(160, 51%, 49%)",
    green4: "hsl(158, 58%, 62%)",
    green3: "hsl(156, 73%, 74%)",
    green2: "hsl(154, 75%, 87%)",
    green1: "hsl(152, 68%, 96%)",
}

let chartColors = {
     navyBlue : '#34495e',
     violet : '#9b59b6',
     blue : '#3498db',
     green : '#62cb31',
     yellow : '#ffb606',
     orange : '#e67e22',
     red : '#e74c3c',
     redDeep : '#c0392b',
     writergrey : '#6a6c6f',
     grey: 'hsl(211, 12%, 43%)'
}

export default class ChartColor {
    constructor(dark, standard, light) {
        this.dark = dark;
        this.standard = standard;
        this.light = light;
    }

    static get blue() {
        return new ChartColor(null, chartColors.blue, null)
    }
    static get red() {
        return new ChartColor(null, chartColors.red, null)
    }
    static get yellow() {
        return new ChartColor(null, chartColors.yellow, null)
    }
    static get orange() {
        return new ChartColor(null, chartColors.orange, null)
    }

    static get violet() {
        return new ChartColor(null, chartColors.violet,null)
    }

    static get green() {
        return new ChartColor(null, chartColors.green)
    }

    static get grey() {
        return new ChartColor(null, chartColors.grey)
    }

    get gradient() {
        return new Gradient(this.dark, this.standard, this.light);
    }

    get solid() {
        return { color: this.standard };
    }
 
}




export class Gradient {
    constructor(dark, standard, light) {
        this.dark = dark;
        this.standard = standard;
        this.light = light;
    }

    get topBottom() {
        return this.gradient(0, 0, 0, 1)
    }

    get bottomTop() {
        return this.gradient(0, 1, 0, 0)

    }

    get rightLeft() {
        return this.gradient(1, 0, 0, 0)
    }

    get leftRight() {
        return this.gradient(0, 0, 1, 0)
    }

    gradient(x, y, x2, y2) {
        return {
            normal: {
                color: new echarts.graphic.LinearGradient(
                    x, y, x2, y2,
                    [
                        { offset: 0, color: this.dark },
                        { offset: 0.25, color: this.standard },
                        { offset: 1, color: this.light }
                    ]
                )
            },
            emphasis: this.emphasis
        };
    }

    get emphasis() {
        return {
            shadowColor: 'rgba(0, 0, 0, 0.5)',
            shadowBlur: 10,
            shadowOffsetY: 3,
            shadowOffsetX: 3
        }
    };
}