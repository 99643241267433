import React, { Component } from 'react';
import Highlighter from 'react-highlight-words';
import { FormattedMessage } from 'react-intl';
import ReactSelect from 'react-select';
import Localization from '../services/localization-service';
import classNames from 'classnames/bind';

import './select.scss';
import Loader from './loader';

function formatOptionLabel({ label }, { inputValue }) {
  return (
    <Highlighter
      searchWords={[inputValue]}
      textToHighlight={label}
      highlightClassName="highlighted"
    />
  );
}

const selectStyles = {
  menuPortal: (base, state) => ({
    ...base,
    zIndex: "100030"
  }),
  control: (base) => ({
    ...base,
    height: '34px',
    'min-height': '34px',
  })
};


const translateLabels = (options, hasGroups) => {
  if (hasGroups) {
    for (let group of options) {
      if (group.translate) {
        group.label = Localization.formatMessage(group.label);
      }

      for (let opt of group.options) {
        if (opt.translate) {
          opt.label = Localization.formatMessage(opt.label);
        }
      }
    }
  }
  else {
    for (let opt of options) {
      if (opt?.translate) {
        opt.label = Localization.formatMessage(opt.label);
      }
    }
  }

  return options;
}

const getSelected = (value, options, hasGroups) => {
  // console.log('getSelected value: ',value)
  // console.log('getSelected options: ',options)

  if (value == null) return null;

  var rawOptions = getOptions(options, hasGroups);
  if (rawOptions == null) return null;
  var option = rawOptions.find(x => x.value === value);

  return option;
}

const getOptions = (options, hasGroups) => {
  if (options == null) return null;
  if (!hasGroups) {
    return options;
  }
  return options.selectMany(x => x.options);
}

export default class Select extends Component {
  constructor(props) {
    super(props);
    this.state = { options: null, isLoading: true, isMenuOpen: false };
    this.onChange = this.onChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, state) {
    // console.log('getDerivedState props: ', nextProps);
    // console.log('getDerivedState state: ', state);

    let options = Select.filterOptions(state.originalOptions, nextProps.filter, nextProps.formatGroupLabel != null);
    return {
      options: options
    }
  }

  onChange(selected, event) {
    this.setState({ value: selected == null ? null : selected.value });
    this.props.onChange(selected == null ? null : selected.value);
  }

  render() {
    return (
      <div
        className={classNames(
          "fc select-fc",
          `${this.props.name}-field`,
          "form-group",
          {
            'no-label': this.props.label == null,
            'has-error': this.props.hasError
          })} name={this.props.name}>
        {this.props.label != null ? <label ><FormattedMessage id={this.props.label}></FormattedMessage></label> : null}
        {this.props.missingProps ? this.renderMissingProps() : this.renderSelect()}
      </div>
    )
  }

  renderMissingProps() {
    return <div className={classNames("form-group")}>
      <input type="text" disabled className={classNames("form-control", {"is-invalid": this.props.hasError})} value={Localization.formatMessage(this.props.missingPropsMessage)}></input>
      {this.renderError()}
    </div>
  }

  componentDidUpdate() {
    if (this.state.isLoading) return;
    let selected = getSelected(this.props.value, this.state.options, this.props.formatGroupLabel != null);
    // console.log("did update options", this.state.options)
    // console.log("did update value", this.props.value)
    // console.log("did update selected", selected)
    if (this.props.value != null && selected == null)
      this.onChange(null);
  }

  renderSelect() {
    let options = this.state.isLoading ? [] : this.state.options;
    let selected = this.state.isLoading ? null : getSelected(this.props.value, options, this.props.formatGroupLabel != null);
    // console.log('render isLoading:', this.state.isLoading);
    // console.log('render options:', this.state.options);
    // console.log('render value:', this.props.value);
    // console.log('render selected:', selected);
    // + (hasError ? ' has-error' : '')
    return <React.Fragment>
      <Loader show={this.state.isLoading}></Loader>
      <ReactSelect
        styles={selectStyles}
        isDisabled={this.state.isLoading || this.props.disabled}
        classNamePrefix='react-select'
        className='react-select-container'
        placeholder={this.props.placeholder ? Localization.formatMessage(this.props.placeholder) : null}
        onChange={this.onChange}
        value={selected}
        options={options}
        menuPortalTarget={document.body}
        formatGroupLabel={this.props.formatGroupLabel}
        isClearable={this.props.isClearable}
        isSearchable={true}
        noOptionsMessage={() => Localization.formatMessage('select.no-options')}
        formatOptionLabel={this.props.formatOptionLabel || formatOptionLabel}
        onKeyDown={(e) => {
          if (e.keyCode === 13 && this.props.value != null && this.props.value != '' && this.props.onSubmit != null) {
            this.props.onSubmit();
          }
        }}
      // menuIsOpen={true}
      />
      {this.renderError()}
    </React.Fragment>
  }
  renderError() {
     return this.props.hasError
        ? <div className="error-message invalid-feedback">
          <FormattedMessage id={this.props.errorMessage}></FormattedMessage>
        </div>
        : null
  }

  static filterOptions(opts, filter, hasGroups) {
    if (opts == null || opts == [] || filter == null) return opts;

    if (!hasGroups) {
      return opts.filter(filter);
    }
    let groups = [];
    for (let group of opts) {
      var ng = { label: group.label, translate: group.translate, options: group.options.filter(filter) }
      if (ng.options.length > 0) {
        groups.push(ng);
      }
    }

    return groups;
  }

  componentDidMount() {
    // console.log('did mount value: ', this.props.value)
    this.optionsSub = this.props.options.subscribe(opts => {
      if (opts == null) return;

      opts = JSON.parse(JSON.stringify(opts));
      if (opts == null || opts === []) {
        this.setState({ originalOptions: opts, options: opts }, () => this.setState({ isLoading: opts == null }));
        return;
      }
      let options = translateLabels(opts, this.props.formatGroupLabel != null);
      this.setState({ originalOptions: options, options: Select.filterOptions(options, this.props.filter, this.props.formatGroupLabel != null) }, () => this.setState({ isLoading: opts == null }));
    });
  }

  componentWillUnmount() {
    this.optionsSub.unsubscribe();
  }


}
