import classNames from 'classnames/bind';
import moment from 'moment';
import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage } from 'react-intl';
import { guid } from '../../util';
import Button from '../common/button';
import Calendar from '../services/calendar-service';
import Localization from '../services/localization-service';
import DateInput from './date-input';
import './date-range-input.scss';
import InlineMonthInput from './inline-month-input';
import InlineYearInput from './inline-year-input';
import RadioSwitchInput from './radio-switch-input';
import Popper from 'popper.js';
import { clear } from 'toastr';

class DateRangeInput extends Component {

  constructor(props) {
    super(props);
    this.state = { mode: 'month', value: null };
    this.id = 'dri' + guid();
    this.closeDropDownWhenClickOutside = this.closeDropDownWhenClickOutside.bind(this);
  }

  getMode(value) {
    if (value == null) return 'month';
    if (value.dateFrom == null || value.dateTo == null) return 'dates';

    let from = Calendar.momentFromIsoDate(value.dateFrom);
    let to = Calendar.momentFromIsoDate(value.dateTo);
    let startOfMonth = moment(from);
    startOfMonth.startOf('month').startOf('day');
    let endOfMonth = moment(from);
    endOfMonth.endOf('month').startOf('day');
    let startOfYear = moment(from);
    startOfYear.startOf('year').startOf('day');
    let endOfYear = moment(from);
    endOfYear.endOf('year').startOf('day');

    if (from.isSame(startOfYear) && to.isSame(endOfYear)) return 'year';
    if (from.isSame(startOfMonth) && to.isSame(endOfMonth)) return 'month';

    return 'dates';

  }

  componentDidUpdate(prevProps, prevState) {
    let props = this.props;
    let state = this.state;


    if (props.value != null && state.value == null) return this.setState({ value: props.value })
    if (props.value != null && state.value != null) {
      if (props.value.dateFrom != state.value.dateFrom || props.value.dateTo != state.value.dateTo) {
        return this.setState({ value: props.value });
      }
    }

    return null;
  }

  showValue() {
    let value = this.state.value;
    if (value == null || value.dateFrom == null || value.dateTo == null) return <FormattedMessage id="date-range-input.select-value"></FormattedMessage>

    switch (this.getMode(value)) {
      case 'year':
        return Calendar.getYearFromIso(value.dateFrom);
      case 'month':
        return Localization.formatDateMMMMYYYY(value.dateFrom);
      case 'dates':
        return `${Localization.formatDate(value.dateFrom)} - ${Localization.formatDate(value.dateTo)}`;
    }

    return null;
  }
  render() {
    return <div className="date-range-input-c">
      <div className={classNames("form-group",
        {
          'has-error': this.props.hasError,
          'no-label': this.props.label == null
        })}>
        {this.props.label ? <label ><FormattedMessage id={this.props.label}></FormattedMessage></label> : null}
        {this.renderControl()}
        {
          this.props.hasError && this.props.errorMessage
            ? <div className="error-message invalid-feedback">
              <FormattedMessage id={this.props.errorMessage}></FormattedMessage>
            </div>
            : null
        }
      </div>

    </div>
  }

  renderControl() {
    return <div className={classNames(this.state.mode)} id={this.id}>
      <div className="h" onClick={() => this.toggleDropDown()}>
        {this.showValue()} <span className="fa fa-caret-down"></span>
      </div>
      {createPortal(<div id={'drop-down-' + this.id} className="date-range-input-drop-down drop-down "  >
        <div className="body">
          {this.renderPreselect()}
          {this.renderSelectors()}
        </div>
      </div>, $('body')[0])}
    </div>
  }

  onChange() {
    console.log('on-change',this.state)
    if (this.state.value != null && (this.state.value.dateFrom == null || this.state.value.dateTo == null))
      return;

    this.props.onChange?.(this.state.value);
  }

  setDates(dateFrom, dateTo, mode) {
    this.setState({ value: { dateFrom: Calendar.momentToIsoDate(dateFrom), dateTo: Calendar.momentToIsoDate(dateTo) }, mode: mode }
      , () => this.onChange());
    this.closeDropDown();
  }

  onDateFromChanged(v) {
    console.log('from: ',v)
    if (this.state.value?.dateTo != null) {
      let from = Calendar.momentFromIsoDate(v);
      let to = Calendar.momentFromIsoDate(this.state.value.dateTo);

      if (to.isBefore(from)) {
        let state = { value: { dateFrom: Calendar.momentToIsoDate(to), dateTo: Calendar.momentToIsoDate(from) } };
        this.setState(state, () => this.onChange());
        return;
      }
    }
    let state = { value: { dateFrom: v, dateTo: this.state.value?.dateTo } };
clear
    this.setState(state, () => this.onChange());
  }

  onDateToChanged(v) {
    console.log('to: ',v)
    if (this.state.value?.dateFrom != null) {
      let from = Calendar.momentFromIsoDate(this.state.value.dateFrom);
      let to = Calendar.momentFromIsoDate(v);

      if (to.isBefore(from)) {
        this.setState({ value: { dateFrom: Calendar.momentToIsoDate(to), dateTo: Calendar.momentToIsoDate(from) } }, () => this.onChange());
        return;
      }
    }

    this.setState({ value: { dateFrom: this.state.value?.dateFrom, dateTo: v } }, () => this.onChange());
  }




  onMonthChanged(v) {
    if (v == null) {
      this.setState({ value: null }, () => this.onChange());
      return;
    }

    let date = moment([v.year, v.month - 1, 1]);

    this.setState({ value: { dateFrom: Calendar.momentToIsoDate(date), dateTo: Calendar.momentToIsoDate(date.endOf('month')) } }, () => this.onChange());
    this.closeDropDown();
  }

  onYearChanged(v) {
    if (v == null) {
      this.setState({ value: null });
      return;
    }

    let date = moment([v, 0, 1]);

    this.setState({ value: { dateFrom: Calendar.momentToIsoDate(date), dateTo: Calendar.momentToIsoDate(date.endOf('year')) } }, () => this.onChange());
    this.closeDropDown();

  }

  getValueForYearSelector() {
    if (this.state.value == null) return null;
    if (this.getMode(this.state.value) == 'year')
      return Calendar.getYearFromIso(this.state.value.dateFrom);
    return null;
  }

  getValueForMonthSelector() {
    if (this.state.value == null) return null;
    let date = Calendar.momentFromIsoDate(this.state.value.dateFrom);
    if (this.getMode(this.state.value) == 'month')
      return { year: date.year(), month: date.month() + 1 };

    return null;
  }

  componentDidMount() {
    if (this.props.value != null) {
      this.setState({ value: this.props.value, mode: this.getMode(this.props.value) });
    }
    this.$dropDown.hide();
    $(document).bind('click', this.closeDropDownWhenClickOutside);
  }

  componentWillUnmount() {
    $(document).unbind('click', this.closeDropDownWhenClickOutside);
  }


  closeDropDownWhenClickOutside(e) {
    if ($.contains($('#' + this.id)[0], e.target) || $.contains(this.$dropDown[0], e.target)) {
      return;
    }
    this.closeDropDown();
  }
  closeDropDown() {
    if (this.$dropDown.is(':visible')) {
      this.$dropDown.hide();
      this.popper?.destroy();
    }
  }

  toggleDropDown() {
    if (this.$dropDown.is(':visible')) {
      this.$dropDown.hide();
      this.popper?.destroy();
    }
    else {
      this.$dropDown.show();
      this.popper = new Popper(this.$toggle[0], this.$dropDown[0], {
        placement: 'bottom', modifiers: {
          preventOverflow: { enabled: true, boundariesElement: $('body')[0] }
        }
      });
    }
  }

  get $toggle() {
    return $('#' + this.id + ' .h');
  }

  get $dropDown() {
    return $('#' + 'drop-down-' + this.id);
  }


  renderModeSelector() {
    let switchItems = [{
      label: 'common.year',
      activeClass: 'primary',
      inactiveClass: 'outline-primary',
      value: 'year'
    }, {
      label: 'common.month',
      activeClass: 'primary',
      inactiveClass: 'outline-primary',
      value: 'month'
    }, {
      label: 'date-range-input.dates',
      activeClass: 'primary',
      inactiveClass: 'outline-primary',
      value: 'dates'
    }];
    return <div className="mode-selector">
      <RadioSwitchInput
        value={this.state.mode}
        items={switchItems}
        onChange={(v) => this.setState({ mode: v })} ></RadioSwitchInput>
    </div>
  }

  renderSelectors() {
    let dateMissing = this.state.value?.dateFrom == null || this.state.value?.dateTo == null;
    console.log('render', this.state)
    return <div className="selectors">
      {this.renderModeSelector()}
      <div className="cal">
        {this.state.mode == 'year' ? <InlineYearInput onChange={(v) => this.onYearChanged(v)} value={this.getValueForYearSelector()}></InlineYearInput> : null}
        {this.state.mode == 'month' ? <InlineMonthInput onChange={(v) => this.onMonthChanged(v)} value={this.getValueForMonthSelector()}></InlineMonthInput> : null}
        {this.state.mode == 'dates' ? <div>
          <DateInput name="dateFrom" label="common.date-from" value={this.state.value?.dateFrom} onChange={(v) => this.onDateFromChanged(v)}></DateInput>
          <DateInput name="dateTo" label="common.date-to" value={this.state.value?.dateTo} onChange={(v) => this.onDateToChanged(v)}></DateInput>
          <Button className="select-btn" label="date-range-input.select" outline primary={!dateMissing} secondary={dateMissing} onClick={() => this.closeDropDown()} disabled={dateMissing}></Button>
        </div> : null}
      </div>
      <div className="actions">
        <Button link label="common.cancel" onClick={() => this.closeDropDown()}></Button>
        <Button link danger label="common.clear" fa="times" onClick={() => {
          this.setState({ value: null });
          this.closeDropDown();
        }}></Button>
      </div>
    </div>
  }

  renderPreselect() {
    return <div className="preselect">
      <div className="group">
        <Button link label="date-range-input.last-7-days" onClick={() => {
          this.setDates(moment().subtract(6, 'days'), moment(), 'dates');
        }}></Button>
        <Button link label="date-range-input.current-week" onClick={() => {
          this.setDates(moment().startOf('week'), moment(), 'dates');
        }}></Button>
        <Button link label="date-range-input.previous-week" onClick={() => {
          let endOfLastWeek = moment().startOf('week').subtract(1, 'day');
          let startOfLastWeek = moment(endOfLastWeek).startOf('week');
          this.setDates(startOfLastWeek, endOfLastWeek, 'dates');
        }}></Button>
      </div>
      <div className="group">
        <Button link label="date-range-input.last-30-days" onClick={() => {
          this.setDates(moment().subtract(29, 'days'), moment(), 'dates');
        }}></Button>
        <Button link label="date-range-input.current-month" onClick={() => {
          let start = moment().startOf('month');
          let end = moment().endOf('month');
          this.setDates(start, end, 'month');
        }}></Button>
        <Button link label="date-range-input.previous-month" onClick={() => {
          let start = moment().subtract(1, 'month').startOf('month');
          let end = moment(start).endOf('month');
          this.setDates(start, end, 'month');
        }}></Button>
      </div>
      <div className="group">
        <Button link label="date-range-input.last-90-days" onClick={() => {
          this.setDates(moment().subtract(89, 'days'), moment(), 'dates');

        }}></Button>
        <Button link label="date-range-input.current-quarter" onClick={() => {
          let start = moment().startOf('quarter');
          let end = moment(start).endOf('quarter');
          this.setDates(start, end, 'dates');

        }}></Button>
        <Button link label="date-range-input.previous-quarter" onClick={() => {
          var quarter = moment().quarter();

          let start = moment().startOf('year').quarter(quarter - 1);
          let end = moment(start).endOf('quarter');
          this.setDates(start, end, 'dates');
        }}></Button>

      </div>
      <div className="group">
        <Button link label="date-range-input.last-year" onClick={() => {
          this.setDates(moment().subtract(364, 'days'), moment(), 'dates');

        }}></Button>
        <Button link label="date-range-input.current-year" onClick={() => {
          let start = moment().startOf('year');
          let end = moment().endOf('year');
          this.setDates(start, end, 'year');
        }}></Button>
        <Button link label="date-range-input.previous-year" onClick={() => {
          let start = moment().subtract(1, 'year').startOf('year');
          let end = moment(start).endOf('year');
          this.setDates(start, end, 'year');
        }}></Button>
      </div>

    </div>
  }

}


DateRangeInput.propTypes = {

}


export default DateRangeInput;