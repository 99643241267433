import React, { Component, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button from './button';
import Panel from './panel';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { H3 } from './headers';
import ResidentService from '../services/residents-service';
import { BehaviorSubject } from 'rxjs';
import Select from './select';
import Badge from './badge';


import './resident-select.scss';
const formatOptionLabel = ({ label, status }, { inputValue }) => {
  return <span>{label} {status != null ? <Badge {...status}></Badge> : null}</span>
}
const getStatusOrder = (status) => {
  if (status == null || status.text == null) return 3;
  if (status.text == 'resident-service.past-resident') return 2;
  return 1;
}



class ResidentSelect extends Component {

  constructor(props) {
    super(props);
    this.state = { realEstateId: null, };
    this.residents = new BehaviorSubject();
  }

  render() {
    return <Select formatOptionLabel={formatOptionLabel} className="resident-select-c" {...this.props} options={this.residents.asObservable()}></Select>
  }

  static getDerivedStateFromProps(props, state) {
    if (props.realEstateId != state.realEstateId) return { realEstateId: props.realEstateId };
    return null;
  }

  fetchResidents() {
    if (!this.props.fetchAll && this.state.realEstateId != null) {
      ResidentService.getRealEstateResidents(this.state.realEstateId).then(rs => {
        this.residents.next(rs?.select(x => { return { label: x.name, value: x.id, status: ResidentService.getStatus(x) } }).orderBy(x => getStatusOrder(x.status)));
      });
    }

    if (this.props.fetchAll) {
      ResidentService.getAllResidents().then(rs => {
        this.residents.next(rs?.select(x => { return { label: x.name, value: x.id, status: ResidentService.getStatus(x) } }).orderBy(x => getStatusOrder(x.status)));
      });
    }
  }

  _onResidentsChanged() {
    this.fetchResidents();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.realEstateId != prevState.realEstateId) {
      this.setState({ realEstateId: this.props.realEstateId });
      this.fetchResidents();
    }
  }

  componentDidMount() {
    this.setState({ realEstateId: this.props.realEstateId }, () => this.fetchResidents());
    this.busSub = Bus.subscribe(this);
  }

  componentWillUnmount() {
    this.busSub.unsubscribe();
  }

}


ResidentSelect.propTypes = {

}


export default ResidentSelect;