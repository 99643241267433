import Bus from '../../bus';
import Api from '../../api';
import { BehaviorSubject } from 'rxjs';

export class GenericStore {
    constructor(getEntitiesFunc, invalidateEvents, updatedEvent, defaultValue) {
        this._subject = new BehaviorSubject(defaultValue);
        this.initSucessfull = false;
        this.isLoading = false;
        this.getEntitiesFunc = getEntitiesFunc;
        this.updatedEvent = updatedEvent;
        if (invalidateEvents != null) {
            Bus.subscribeTo(invalidateEvents, () => this.invalidate())
        }
    }

    invalidate() {
        this.initSucessfull = false;
        this.init();
    }

    asObservable() {
        this.init();
        return this._subject.asObservable();

    }
    init() {
        if (this.initSucessfull || this.isLoading) return;

        this.isLoading = true;

        this.getEntitiesFunc().then((data) => {
            this.initSucessfull = true;
            this.isLoading = false;
            this._subject.next(data == null ? [] : data);
            if (this.updatedEvent != null) {
                Bus.publish(this.updatedEvent);
            }
        }).catch(() => {
            this.initSucessfull = false;
            this.isLoading = false;
        });
    }
}

export default class Store extends GenericStore {
    constructor(apiRoute, invalidateEvents, updatedEvent, defaultValue) {
        super(() => Api.get(apiRoute), invalidateEvents, updatedEvent, defaultValue)
    }
}


