import React, { Component } from 'react';


import './error.scss';
import { FormattedHTMLMessage } from 'react-intl';

class Error extends Component {
  render() {
    return (
      <div className="error-c">
        {this.props.text == null ? null : <FormattedHTMLMessage id={this.props.text}></FormattedHTMLMessage>}
        {this.props.raw == null ? null : <span>{this.props.raw.toString()}</span>}
        {this.props.children}
      </div>
    );
  }
}

export default Error;