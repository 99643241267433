import { BehaviorSubject } from 'rxjs';

class SecondaryNavStateServiceService {
    constructor() {
        this._navigation = new BehaviorSubject(null);
    }

    get navigation() {
        return this._navigation.asObservable();
    }

    set(nav) {
        this._navigation.next(nav);
    }

    clear() {
        this._navigation.next(null);
    }
}


let instance = new SecondaryNavStateServiceService();
export default instance;
