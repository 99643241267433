import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button from '../common/button';
import Panel from '../common/panel';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { H3 } from '../common/headers';

import './read-only-info.scss';

const ReadOnlyInfo = (props) => {
  return <div className={classNames(
    "fc read-only-info-c",
    `${props.name}-field`,
    "form-group",
    {
      'no-label': props.label == null
    })}>
    {props.label != null ? <label><FormattedMessage id={props.label}></FormattedMessage></label> : null}
    <label className="value">{props.value}</label>
  </div>
}


ReadOnlyInfo.propTypes = {

}


export default ReadOnlyInfo;