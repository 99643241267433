import predef from '../../predef';
import Store from './store';

class FutureExpensesStore {
    constructor() {
        this.futureExpensesStore = new Store(predef.endpoints.futureExpense.index,
            [predef.events.futureExpense.futureExpenseChanged]);

    }

    get futureExpenses() {
        return this.futureExpensesStore.asObservable();
    }

    byId(id){
        return new Promise((resolve, _)=> {
            this.futureExpenses.subscribe(entities => {
                if (entities == null) return;

                resolve(entities.single(x=>x.id == id));
            });
        });
    }
}

const instance = new  FutureExpensesStore();
export default instance;
