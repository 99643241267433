import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button from '../common/button';
import Panel from '../common/panel';
import PropTypes from 'prop-types';
import Page from '../common/page';
import './recurring-budgets.scss';
import PlanningService from '../services/planning-service';
import BudgetsForm, { budgetSchema } from './budgets-form';
import { validateAll } from '../../util';

class RecurringBudgets extends Component {

  constructor(props) {
    super(props);
    this.state = {
      budgets: [],
      budgetsLoaded: false,
    };
  }

  render() {
    return (
      <Page className="recurring-budgets-c" loading={!this.state.budgetsLoaded} renderBody={() => this.renderBody()} header="recurring-budgets.header" data={true}></Page>
    );
  }

  renderBody() {
    return <BudgetsForm budgets={this.state.budgets} onSubmit={(bs) => this.saveBudgets(bs)}></BudgetsForm>
  }

  componentDidMount() {
    this.loadBudgets();
  }

  saveBudgets(budgets) {
    return new Promise((resolve, reject) => {
      PlanningService.updateRecurringBudgets(budgets).then(() => {
        resolve();
        this.loadBudgets()
      }).catch(() => {
        reject();
      });
    })
  }

  loadBudgets() {
    PlanningService.getRecurringBudgets()
      .then(bs => this.setState({ budgets: bs, budgetsLoaded: true }));
  }
}


RecurringBudgets.propTypes = {

}


export default RecurringBudgets;