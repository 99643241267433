import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import TransactionListColumnConfig from '../../transactions/transaction-list-column-config';
import Button from '../button';
import Pagger from '../pagger';
import { Table, TBody, TColumn, THead } from '../table';
import './controlled-grid.scss';
import Loader from '../../common/loader';
import Json from '../../common/json'
import { deepCopy } from '../../../util';

class ControlledGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfig: false
    };
    this.toggleConfig = this.toggleConfig.bind(this);
    this.onPageChanged = this.onPageChanged.bind(this);
    this.onPageSizeChanged = this.onPageSizeChanged.bind(this);
    this.changeSorting = this.changeSorting.bind(this);
  }

  toggleConfig() {
    this.setState({ showConfig: !this.state.showConfig })
  }

  onPageChanged(page) {
    this.props.onPagingChange?.({ page: page, pageSize: this.props.paging.pageSize });
  }

  onPageSizeChanged(size) {
    this.props.onPagingChange?.({ page: 1, pageSize: size });
  }

  changeSorting(column) {
    if (this.props.onSortingChange == null) return;
    if (column.name === this.state.sorting.column) {
      if (this.state.sorting.order === 'asc') {
        this.props.onSortingChange?.({ column: column.name, order: 'desc', value: column.value });
      }
      else {
        this.props.onSortingChange?.({ column: column.name, order: 'asc', value: column.value });
      }
      return;
    }
    this.props.onSortingChange?.({ column: column.name, order: 'asc', value: column.value });
  }

  render() {
    return <div className="controlled-grid-c">
      {this.props.definition == null ? null :
        <React.Fragment>
          <div className="header">
            <Loader show={this.props.loading}></Loader>
            {this.state.showPaging ? <Pagger pageSize={this.props.paging.pageSize} selectedPage={this.props.paging.page} totalRows={this.props.totalRows} onPageChanged={this.onPageChanged} onPageSizeChanged={this.onPageSizeChanged}></Pagger> : <div className="pager-placeholder"></div>}
            {/* {this.props.allowColumnConfigChange == null || this.props.allowColumnConfigChange ? <Button className="show-config btn-sm" outline={!this.state.showConfig} fa="cogs" onClick={this.toggleConfig}></Button> : null} */}
          </div>

          {/* {this.state.showConfig ? <TransactionListColumnConfig onColumnsConfigChagnes={v => this.props.onColumnsConfigChagnes?.(v)} columns={this.state.definition}></TransactionListColumnConfig> : null} */}
          <Table autoWidth={this.props.autoWidth} tableStriped={this.props.tableStriped} >
            <THead>
              {
                this.props.definition.where(x => x.show).map(x => {
                  return <TColumn onClick={() => {
                    if (x.sortable) {
                      this.changeSorting(x);
                    }
                  }} key={x.name} className={classNames(x.headerClasses, { "sortable": x.sortable, 'acr': x.align == 'right', 'acc': x.align == 'center' })}>
                    {x.sortable && this.props.sorting?.column == x.name && this.props.sorting?.order == 'asc' ? <span className="sort-icon fad fa-sort-amount-up-alt"> </span> : null}
                    {x.sortable && this.props.sorting?.column == x.name && this.props.sorting?.order == 'desc' ? <span className="sort-icon fad fa-sort-amount-down"></span> : null}
                    {x.header == null ? null : <FormattedMessage id={x.header}></FormattedMessage>}
                  </TColumn>
                })
              }
            </THead>
            <TBody>
              {this.state.data.map((row, rowNumber) => {
                let id = this.props.idSelector == null ? (row.id || "row_" + rowNumber) : this.props.idSelector(row);
                return <tr key={id} >
                  {this.props.definition.where(x => x.show).map((colDef, i) => {
                    return <td className={classNames(typeof colDef.cellClassNames === "function" ? colDef.cellClassNames(row, rowNumber) : colDef.cellClassNames, { 'acr': colDef.align == 'right', 'acc': colDef.align == 'center' })} key={id + i}>{colDef.renderCell(row, rowNumber)}</td>
                  })}
                </tr>
              })}
            </TBody>
            {this.props.renderTotalRow?.()}
          </Table>
        </React.Fragment>}

    </div>
  }

  static getDerivedStateFromProps(props, state) {
    for (let col of props.definition) {
      if (col.name == null) col.name = col.header;
    }
    return { data: props.data || [], definition: props.definition, sorting: props.sorting || {}, showPaging: props.paging != null };

  }
}


ControlledGrid.propTypes = {
  definition: PropTypes.arrayOf(PropTypes.shape({
    show: PropTypes.bool,
    renderCell: PropTypes.func.isRequired,

    name: PropTypes.string,
    header: PropTypes.string,
    headerClasses: PropTypes.string,
    headerDisplayName: PropTypes.string
  })).isRequired,
  id: PropTypes.string.isRequired,
  data: PropTypes.array,

  sort: PropTypes.shape({
    order: PropTypes.oneOf(['asc', 'desc']),
    columnName: PropTypes.string
  }),
  paging: PropTypes.shape({
    pageSize: PropTypes.number,
    page: PropTypes.number
  }),
  onDefinitionChange: PropTypes.func,
  onSortOrderChange: PropTypes.func,
  onPagingChange: PropTypes.func
}


export default ControlledGrid;