import "./import-jquery";
import React from 'react';
import ReactDOM from 'react-dom';
import App from "./app"
import { HashRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import bootstrap from 'bootstrap';
import extendArray from './array';
import 'bootstrap-datepicker';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker3.standalone.css'
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.pl.js'
import 'react-bootstrap-typeahead/css/Typeahead.css'


extendArray();

ReactDOM.render(

  <HashRouter>
    <App></App>
  </HashRouter>
  ,
  document.getElementById('root')
);