import React, {useState} from 'react';
import Button from '../common/button';
import TransactionsModal from '../transactions/transactions-modal';
import './view-transactions.scss';


const ViewTransactions = (props) => {
  let [show, setShow] = useState(false);
  return <div className="view-transactions-c">
    <TransactionsModal show={show} onClose={() => setShow(false)} filter={props.filter}></TransactionsModal>
    <Button link  fad="eye" onClick={() => setShow(true)}></Button>
  </div>
}


ViewTransactions.propTypes = {

}


export default ViewTransactions;