import Service from './service';
import predef from '../../predef';

class FutureIncomesService extends Service {
    update(fis) {
        return this.post(
            predef.endpoints.futureIncome.index
            , fis
            , predef.events.futureIncome.futureIncomesChanged
            , fis
            , 'future-incomes-service.udpate-success'
            , 'future-incomes-service.udpate-error');
    }
}

let instance = new FutureIncomesService();
export default instance;
