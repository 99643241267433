import React from 'react';
import predef from '../../predef';
import { InvestmentContext } from '../contexts';
import Notes from '../notes/notes';
import './investment-notes.scss';


const InvestmentNotes = (props) => {
  return <div className="investment-notes-c">
    <InvestmentContext.Consumer>
      {(investment) => <div>
        <Notes noteRef={predef.noteRefs.investment} refId={investment.id}></Notes>
      </div>}
    </InvestmentContext.Consumer>
  </div>
}


InvestmentNotes.propTypes = {

}


export default InvestmentNotes;