import React from 'react';
import { FormattedMessage } from 'react-intl';
import './switch.scss';
import classNames from 'classnames/bind';



const Switch = (props) => <div className={classNames("fc switch-fc", `${props.name}-field`, "form-group")}>
<button type="button"  disabled={props.disabled} className={'btn ' + (props.disabled ? 'btn-outline-secondary' : (props.value ? 'btn-primary' : 'btn-outline-primary'))} onClick={()=>props.onChange(!props.value)}>{props.icon ? <span className={props.icon}></span> : null} {props.text ? <FormattedMessage id={props.text}></FormattedMessage> : null}</button>
</div>

export default Switch;