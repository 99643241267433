import React, { Component } from 'react';
import { getIndicatorDetails, guid } from '../../util';
import LoadingPlaceholder from '../common/loading-placeholder';
import AccountsBalancesReport from '../reports/accounts-balances-report';
import BudgetsReport from '../reports/budgets-report';
import IncomeStreamsReport from '../reports/income-streams-report';
import Indicator from '../reports/indicator';
import Calendar from '../services/calendar-service';
import IndicatorsStore from '../stores/indicators-store';
import AccountStore from '../stores/accounts-store';
import Message from '../common/message';

import './dashboard.scss';
import { timestamp } from 'rxjs/operators';
import { NoDataMessage } from '../common/message';
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      indicators: null,
      isLoading: true,
      month: Calendar.currentMonth,
      year: Calendar.currentYear
    }
    this.id = "dashboard-" + guid();
  }

  render() {
    if (this.state.hasAccounts == null) return <div>
      <LoadingPlaceholder></LoadingPlaceholder>
    </div>
    if (this.state.hasAccounts) return this.renderDashboard();
    return <Message message="create accounts message"></Message>
  }

  renderDashboard() {
    return <div className="dashboard-c">
      <div className="row indicators">
        <div className="col">
          {this.state.isLoading ? <LoadingPlaceholder height="5.2"></LoadingPlaceholder>
            : <div className="dashboard" id={this.id}>
              {this.state.indicators.map((ind, i) => {
                ind = getIndicatorDetails(ind);
                return <Indicator key={i} data={ind}></Indicator>
              })}
            </div>}
        </div>
      </div>
      <div className="reports" >
        <div className="ds">
          <BudgetsReport year={this.state.year} month={this.state.month}></BudgetsReport>
          <IncomeStreamsReport year={this.state.year} month={this.state.month}></IncomeStreamsReport>
        </div>
        <div className="ds"><AccountsBalancesReport></AccountsBalancesReport></div>
      </div>
    </div >
  }


  componentDidMount() {
    this.accountsSub = AccountStore.accounts.subscribe(accs =>{
      this.setState({ hasAccounts: accs == null ? null : accs.length > 0 });
    });
    this.indicatorsSub = IndicatorsStore.indicators.subscribe(ind => {
      this.setState({ indicators: ind, isLoading: ind == null });
    });

  }

  componentWillUnmount() {
    this.indicatorsSub.unsubscribe();
  }
}

export default Dashboard;