import Service from './service';
import predef from '../../predef';

class RealEstatesService extends Service {
    addRealEstate(re) {
        return this.post(predef.endpoints.realEstate.index
            , re
            , predef.events.realEstates.realEstatesChanged
            , re
            , 'real-estates-service.real-estate-added'
            , 'real-estates-service.real-estate-add-error')
    }

    deleteRealEstate(reId) {
        return this.delete(
            predef.endpoints.realEstate.indexId(reId)
            , predef.events.realEstates.realEstatesChanged
            , reId
            , 'real-estates-service.real-estate-deleted'
            , 'real-estates-service.real-estate-delete-error')
    }

    updateRealEstate(re) {
        return this.put(
            predef.endpoints.realEstate.index
            , re
            , predef.events.realEstates.realEstatesChanged
            , re
            , 'real-estates-service.real-estate-updated'
            , 'real-estates-service.real-estate-update-error'
        )
    }

    dashboardReport(id, year) {
        return this.get(predef.endpoints.realEstate.dashbaordReport(id, year));
    }

    addNote(note, realEstateId, year, month) {
        return this.put(
            predef.endpoints.realEstate.addNote(realEstateId, month, year),
            {text: note},
            null,
            null,
            'real-estates-service.note-added',
            'real-estates-service.note-adding-error'
        )
    }

    updateNote(note, id) {
        return this.post(
            predef.endpoints.realEstate.updateNote(id),
            {text : note},
            null,
            null,
            'real-estates-service.note-updated',
            'real-estates-service.note-updating-error'
        )
    }
}


let instance = new RealEstatesService();
export default instance;
