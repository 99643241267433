import React, { Component } from 'react';
import { deepCopy } from '../../util';
import Calendar from '../services/calendar-service';
import Localization from '../services/localization-service';
import Panel from './panel';
import Textbox from './textbox';
import TimelineItem from './timeline-item';
import './timeline.scss';

class Timeline extends Component {

  constructor(props) {
    super(props);
    this.state = { items: [], search: '', searchMode: '' };
  }

  render() {
    return <div className="timeline-c">
      {this.renderFilter()}
      {this.state.items != null ? this.renderYears() : null}
    </div>
  }

  renderFilter() {
    return <div className="filter">
      <Panel>
        <Textbox value={this.state.search} placeholder="common.search" onChange={(v) => this.onFlterChange(v)}></Textbox>
      </Panel>
    </div>
  }

  onFlterChange(search) {
    this.setState({ search: search }, () => {
      this.search();
    })
  }

  search() {
    let items = deepCopy(this.state.items);

    for (let yearItems of items) {
      for (let monthItems of yearItems.items)
        for (let item of monthItems.items) {
          item.highlight = false;

          if (item.title?.toLowerCase().indexOf(this.state.search.toLowerCase()) >= 0
            || item.body.indexOf(this.state.search) >= 0
            || item.tags?.any(x => x.text.indexOf(this.state.search) >= 0)

          ) {
            item.highlight = true;
          }
        }
    }

    this.setState({ items: items });
  }

  renderYears() {
    return this.state.items.map(x => {
      return <React.Fragment key={x.year}>
        <div className="timeline-item header" date-is={x.year}>
        </div>
        {this.renderMonths(x.items)}
      </React.Fragment>
    })
  }

  renderMonths(items) {
    return items.map(x => {
      return <React.Fragment key={x.month}>
        <div className="timeline-item header" date-is={Localization.formatMonthName(x.month)}>
        </div>
        {this.renderMonth(x.items)}
      </React.Fragment>
    })
  }

  renderMonth(items) {
    return items.map(x => {
      return <TimelineItem showRemove={this.props.showRemove} onRemove={(n) => this.props.onRemove(n)} highlight={x.highlight} showEditNote={this.props.showEditNote} key={x.id} item={x}>

      </TimelineItem>
    })
  }



  componentDidMount() {
    let yearGroups = this.props.items.groupBy(x => Calendar.getYearFromIso(x.date)).orderBy(x => x.key, 'desc');
    let items = [];
    for (let yearGroup of yearGroups) {
      items.push({
        year: yearGroup.key,
        items: yearGroup.values
          .groupBy(x => Calendar.getMonthFromIso(x.date)).orderBy(x => x.key, 'desc').select(x => {
            return {
              month: x.key,
              items: x.values.orderBy(x => x.date, 'desc')
            }
          })
      });
    }

    this.setState({ items: items });
  }

  componentWillUnmount() {
  }

}


Timeline.propTypes = {

}


export default Timeline;