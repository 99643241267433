import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button from '../common/button';
import Panel from '../common/panel';
import PropTypes from 'prop-types';

import './transaction-list-column-config.scss';

class TransactionListColumnConfig extends Component {

  constructor(props) {
    super(props);
    this.state = {
      columns: []
    };

    this.onChange = this.onChange.bind(this);
  }

  render() {
    return (
      <div className="transaction-list-column-config-c">
        {this.renderColumns()}
      </div>
    );
  }

  onChange(i) {
    this.setState((state, props) => {
      state.columns[i].show = !state.columns[i].show;
      this.props.onColumnsConfigChagnes(state.columns);
      return state;
    });
  }

  changeOrder(i, delta) {
    this.setState((state, props) => {
      let cols = JSON.parse(JSON.stringify(state.columns));
      cols.moveBy(i, delta);

      this.props.onColumnsConfigChagnes(cols);
      return { columns: cols };
    });
  }

  renderColumns() {
    let res = [];
    let ii = 0;
    for (let col of this.state.columns) {
      let i = ii;
      res.push(<div key={col.name} className="config">
        <div onClick={() => this.onChange(i)} className="ncheckbox">
          <span>{col.header != null ? <FormattedMessage id={col.header}></FormattedMessage> : (col.headerDisplayName == null ? null : <FormattedMessage id={col.headerDisplayName}></FormattedMessage>)}</span>
          <input type="checkbox" onChange={() => this.onChange(i)} checked={this.state.columns[i].show} ></input>
          <span className="tick"></span>
        </div>
        {i > 0 ? <span className="fa fa-arrow-left" onClick={() => this.changeOrder(i, - 1)}></span> : null}
        {i < this.state.columns.length - 1 ? <span className="fa fa-arrow-right" onClick={() => this.changeOrder(i, 1)}></span> : null}
      </div>)

      ii++;
    }

    return res;
  }

  componentDidMount() {
    this.setState({ columns: this.props.columns })
  }

  componentWillUnmount() {
  }

}
TransactionListColumnConfig.propTypes = {
  onColumnsConfigChagnes: PropTypes.func.isRequired
}

export default TransactionListColumnConfig;