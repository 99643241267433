import predef from '../../predef';
import Store from './store';

class CategoriesStore {
    constructor() {
        this.categoriesStore = new Store(predef.endpoints.category.index,
            [
                predef.events.transaction.transactionsChanged,
                predef.events.category.categoriesChanged
            ], predef.events.transaction.categoriesStoreUpdated);

    }

    get categories() {
        return this.categoriesStore.asObservable();
    }

}

const instance = new CategoriesStore();
export default instance;
