import Bus from './bus';

export default class Cache {
    constructor(invalidateEvents) {
        if (invalidateEvents != null) {
            Bus.subscribeTo(invalidateEvents, () => this.cache = {});
        }

        this.cache = {};
    }

    add(key, value) {
        this.cache[key] = value;
    }

    get(key) {
        return this.cache[key];
    }

    has(key) {
        return this.cache[key] != null;
    }

    tryGet(key, valFunc) {
        if (this.has(key)) return this.get(key);
        this.add(key, valFunc());
        return this.get(key);
    }

    invalidate(key) {
        delete this.cache[key];
    }

}