import React, { Component, useState } from 'react';
import Bus from '../../bus';
import predef from '../../predef';
import AccountTypeBadge from '../common/account-type-badge';
import Button, { Edit, Remove } from '../common/button';
import Page from '../common/page';
import { ChangeOrderButtons, Table, TBody, TColumn, THead } from '../common/table';
import AccountsStore from '../stores/accounts-store';
import './accounts.scss';
import { Copy } from '../common/copy';
import Number from '../common/number';
import AccountsService from '../services/accounts-service';
import Grid from '../common/grid/grid';
import Checkbox from '../common/checkbox';
import Loader from '../common/loader';

const ShowHideAccountOnSidebar = (props) => {
  const [isSetting, setIsSetting] = useState(false);
  if (isSetting)
    return <Loader show></Loader>

  return <Checkbox value={props.account.showOnSidebar} onChange={(show) => {
    setIsSetting(true);
    AccountsService.showHideOnSidebar(props.account.id, show).then(x => setIsSetting(false)).catch(x => setIsSetting(false));
  }}></Checkbox>
}

class Accounts extends Component {

  constructor(props) {
    super(props);
    this.state = { accounts: null, loading: true };

  }

  render() {
    return <Page
      className="accounts-c"
      header="accounts.header"
      loading={this.state.loading}
      data={this.state.accounts}
      noDataMessage="accounts.no-data"
      renderBody={() => this.renderBody()}
      actions={
        <React.Fragment>
          <Button outline label="accounts.add-account" fa="plus" path={predef.routes.accounts.add()}></Button>
          <Button link label="accounts.change-accounts-order" path={predef.routes.accounts.order}></Button>
          <Button link label="accounts.restore-account" path={predef.routes.accounts.restore}></Button>
        </React.Fragment>
      }
    >
    </Page>
  }

  renderBody() {



    return this.state.accounts.map(acs => {
      return <div key={acs.key}>
        <h3>{acs.key}</h3>

        <Grid id="accounts" data={acs.values} definition={
          [
            {
              show: true,
              headerClasses: "len-45",
              header: "common.index-column-header",
              renderCell: (acc, i) => i + 1,
              sortable: false
            },
            {
              show: true,
              headerClasses: "",
              header: "common.name",
              renderCell: (acc) => acc.name,
              sortable: true,
              srotValue: (acc) => acc.name
            },
            {
              show: true,
              headerClasses: "len-120",
              header: "accounts.balance",
              renderCell: (acc) => <Number value={acc.balance} currency={acc.currency}></Number>,
              sortable: true,
              align: 'right',
              sortValue: (acc) => acc.balance
            },
            {
              show: true,
              headerClasses: "len-180",
              header: "accounts.type",
              renderCell: (acc) => <AccountTypeBadge typeId={acc.type}></AccountTypeBadge>,
            },
            {
              show: true,
              headerClasses: "len-280",
              header: "accounts.number",
              renderCell: (acc) => acc.number,
              sortable: true,
              srotValue: (acc) => acc.number
            },
            {
              show: true,
              headerClasses: "len-180",
              header: "accounts.show-on-sidebar",
              renderCell: (acc) => {
                return <ShowHideAccountOnSidebar account={acc}></ShowHideAccountOnSidebar>
              },
              sortable: false
            },
            {
              show: true,
              headerClasses: "len-180",
              name: 'actions',
              headerDisplayName: 'common.actions',
              renderCell: (acc) => {
                return <React.Fragment>
                  <Edit path={predef.routes.accounts.edit(acc.id)}></Edit>
                  <Remove onClick={() => this.deleteAccount(acc.id)}></Remove>
                </React.Fragment>
              }
            },
          ]
        } ></Grid>

      </div>
    })
  }



  deleteAccount(id) {
    AccountsService.deleteAccount(id);
  }

  componentDidMount() {
    this.accountsSub = AccountsStore.accounts.subscribe(acs => {
      this.setState({ accounts: acs == null ? [] : acs.groupBy(x => x.currency), loading: acs == null })
    });
  }

  componentWillUnmount() {
    this.accountsSub.unsubscribe();
  }

}

export default Accounts;