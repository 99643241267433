import Service from './service';
import predef from '../../predef';

class AssetsService extends Service {
    getAssets() {
        return this.get(predef.endpoints.asset.index)
    }

    updateAssets(assets) {
        return this.post(
            predef.endpoints.asset.index,
            assets,
            predef.events.asset.assetsChanged,
            assets,
            'assets-service.update-success',
            'assets-service.update-error'
        );
    }
}


let instance = new AssetsService();
export default instance;
