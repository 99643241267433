import Service from './service';
import predef from '../../predef';

class FutureExpensesService extends Service {
    update(fes) {
        return this.post(
            predef.endpoints.futureExpense.index
            , fes
            , predef.events.futureExpense.futureExpenseChanged
            , fes
            , 'future-espenses-service.udpate-success'
            , 'future-espenses-service.udpate-error');
    }
}


let instance = new FutureExpensesService();
export default instance;
