import React, { useState, useEffect } from "react";
import Button from "../common/button";
import predef from "../../predef";
import FileDropZone from "../common/file-drop-zone";
import Form from "../common/form";
import Date from "../common/date";
import "./import.scss";
import Badge from "../common/badge";
import Transaction from "../transactions/transaction";
import Transfer from "../transactions/transfer";
import TransactionsService from "../services/transactions-service";
import Decimal from "decimal.js";
import Toastr from "../services/toastr-srevice";
import { guid } from "../../util";
import AccountsStore from "../stores/accounts-store";
import Localization from "../services/localization-service";
import Message from "../common/message";
import { FormattedMessage } from "react-intl";
import { TBody, THead, Table, TColumn } from "../common/table";
import classNames from "classnames";

function Info({ children }) {
  return <div className="info">{children}</div>;
}

function Detail({ label, value }) {
  return (
    <div className="detail">
      <div className="label">{Localization.formatMessage(label)}</div>
      <div className="value ">{value}</div>
    </div>
  );
}

function TransferInfo({ transaction, account }) {
  if (!transaction.isTransfer) return null;
  if (transaction.transferTo) {
    return (
      <Info>
        <Detail label="import.transfer-to" value={account?.name}></Detail>
      </Info>
    );
  }
  if (transaction.transferFrom) {
    return (
      <Info>
        <Detail label="import.transfer-from" value={account?.name}></Detail>
      </Info>
    );
  }

  return null;
}

function TransactionsList({ transactions }) {
  if (!transactions || transactions.length === 0) {
    return <Message message="import.no-transactions-for-that-date"></Message>;
  }

  return (
    <div className="transactions-list">
      <Table>
        <THead>
          <TColumn>
            <FormattedMessage id="common.amount"></FormattedMessage>
          </TColumn>
          <TColumn>
            <FormattedMessage id="common.category"></FormattedMessage>
          </TColumn>
          <TColumn>
            <FormattedMessage id="common.description"></FormattedMessage>
          </TColumn>
        </THead>
        <TBody>
          {transactions.map((tran) => {
            return (
              <tr>
                <td>{tran.amount}</td>
                <td>{tran.operations.select((x) => x.category).join(", ")}</td>
                <td>
                  {tran.operations.select((x) => x.description).join(", ")}
                </td>
              </tr>
            );
          })}
        </TBody>
      </Table>
    </div>
  );
}

function Indicators({ transaction, isSaved }) {
  return (
    <div className="indicators">
      <div className="small-badges">
        <Badge fa="database" secondary>
          {transaction.existingTransactionsForSameDay}
        </Badge>
        <Badge fa="arrow-down" primary>
          {transaction.transactionsInImportForSameDay}
        </Badge>
        <Badge
          fa={
            transaction.isTransfer
              ? "exchange"
              : transaction.amount < 0
              ? "minus"
              : "plus"
          }
          primary={transaction.isTransfer}
          danger={!transaction.isTransfer && transaction.amount < 0}
          success={!transaction.isTransfer && transaction.amount > 0}
        ></Badge>
        {transaction.importedTransaction != null ? (
          <div className="previously-saved fa fa-save"></div>
        ) : null}
        {isSaved ? <div className="saved fa fa-save"></div> : null}
        {transaction.obsolete ? (
          <div className="obsolete fas fa-hourglass-end"></div>
        ) : null}
        {transaction.existingTransactionsForSameDay > 0 &&
        transaction.transactionsInImportForSameDay !=
          transaction.existingTransactionsForSameDay ? (
          <div className="warning fas fa-exclamation-triangle"></div>
        ) : null}
      </div>
    </div>
  );
}

function TransactionImport({
  transaction,
  account,
  accounts,
  existingTransactions,
}) {
  const [isSaved, setIsSaved] = useState(false);
  const [newTransaction, setNewTransaction] = useState(transaction);
  const [showTransactionForm, setShowTransactionForm] = useState(false);
  const [showTransferForm, setShowTransferForm] = useState(false);
  const [showTransactions, setShowTransactions] = useState(false);
  const [accountFrom, setAccountFrom] = useState(null);
  const [accountTo, setAccountTo] = useState(null);
  useEffect(() => {
    if (transaction.transferTo)
      AccountsStore.byId(transaction.transferTo).then(setAccountTo);
    if (transaction.transferFrom)
      AccountsStore.byId(transaction.transferFrom).then(setAccountFrom);
  }, [transaction]);

  return (
    <div className="transaction-import">
      <div className={classNames('imported-transaction', { 'show-divider': showTransactionForm || showTransferForm || showTransactions })}>
        <Indicators isSaved={isSaved} transaction={transaction}></Indicators>
        <div className="transaction-information">
          <div className="info">
            <Info>
              <Detail label="common.amount" value={transaction.amount}></Detail>
              <Detail
                label="common.date"
                value={
                  <Date
                    date={transaction.date}
                    format="dddd, D MMMM yyyy"
                  ></Date>
                }
              ></Detail>
              <Detail
                label="common.description"
                value={transaction.originalDescription}
              ></Detail>
              <Detail
                label="import.sender"
                value={transaction.senderName}
              ></Detail>
              <Detail
                label="import.receiver"
                value={transaction.receiverName}
              ></Detail>
            </Info>
            <TransferInfo
              account={transaction.transferTo ? accountTo : accountFrom}
              transaction={transaction}
            ></TransferInfo>
          </div>

          <div className="forms">
            {!transaction.isTransfer ? (
              <Form
                hideSubmitButton
                onSubmit={() => {}}
                initialValues={transaction}
                definition={{
                  fields: [
                    {
                      type: "category",
                      name: "category",
                      label: "operation-form.category",
                      income: transaction.amount > 0,
                      expense: transaction.amount < 0,
                      layout: "c-sm-12 c-lg-6 c-w-250",
                      allowNew: true,
                    },
                    {
                      type: "text",
                      name: "description",
                      label: "operation-form.description",
                      layout: "c-sm-12 c-lg-9 c-w-500",
                    },
                  ],
                }}
                onChange={(v) => setNewTransaction(v)}
              ></Form>
            ) : (
              <Form
                hideSubmitButton
                onSubmit={(d) => {}}
                initialValues={transaction}
                definition={{
                  fields: [
                    {
                      type: "text",
                      name: "description",
                      label: "operation-form.description",
                      layout: "c-sm-12 c-lg-9 c-w-500",
                    },
                  ],
                }}
                onChange={(v) => setNewTransaction(v)}
              ></Form>
            )}
            
          </div>
          <div className="form-actions">
              <Button
                fa={transaction.isTransfer ? "exchange" : "plus"}
                onClick={() => {
                  if (!transaction.isTransfer) {
                    TransactionsService.add({
                      accountId: account.id,
                      currency: account.currency,
                      date: transaction.date,
                      externalId: transaction.externalId,
                      operations: [
                        {
                          currency: account.currency,
                          amount: transaction.amount,
                          category: newTransaction.category,
                          description: newTransaction.description,
                          date: transaction.date,
                        },
                      ],
                    }).then(() => {
                      setIsSaved(true);
                    });
                  } else {
                    if (transaction.transferTo?.toLowerCase() === account.id.toLowerCase()) {
                      TransactionsService.makeTransfer({
                        externalId: transaction.externalId,
                        accountFromId: transaction.transferFrom,
                        accountToId: account.id,
                        amount: new Decimal(transaction.amount)
                          .abs()
                          .toNumber(),
                        date: transaction.date,
                        description: newTransaction.description,
                      }).then(() => {
                        setIsSaved(true);
                      });
                    } else if (transaction.transferFrom?.toLowerCase() === account.id.toLowerCase()) {
                    console.log("good case", transaction)
                      TransactionsService.makeTransfer({
                        externalId: transaction.externalId,
                        accountFromId: account.id,
                        accountToId: transaction.transferTo,
                        amount: new Decimal(transaction.amount)
                          .abs()
                          .toNumber(),
                        date: transaction.date,
                        description: newTransaction.description,
                      }).then(() => {
                        setIsSaved(true);
                      });
                    } else {
                      Toastr.error(
                        "Could not make transfer invalid import data."
                      );
                    }
                  }
                }}
                label={
                  transaction.isTransfer
                    ? "import.make-transfer"
                    : "import.add-transaction"
                }
              ></Button>
              <Button
                fa="file-invoice"
                light
                secondary
                onClick={() => {
                  setShowTransferForm(false);
                  setShowTransactionForm(!showTransactionForm);
                }}
                label="import.prepare-transaction"
              ></Button>
              <Button
                fa="exchange"
                light
                secondary
                onClick={() => {
                  setShowTransactionForm(false);
                  setShowTransferForm(!showTransferForm);
                }}
                label="import.prepare-transfer"
              ></Button>
              <Button
                fa="list"
                light
                secondary
                onClick={() => {
                  setShowTransactions(!showTransactions);
                }}
                label="import.show-transactions"
              ></Button>
            </div>
            
        </div>
      
        
      </div>
      <div className="helper-tools">
        {showTransactionForm ? (
            <Transaction
              onSave={(promise) => {
                promise.then(() => {
                  setIsSaved(true);
                  setShowTransactionForm(false);
                });
              }}
              transaction={{
                date: transaction.date,
                accountId: transaction.accountId,
                operations: [
                  {
                    amount: transaction.amount,
                    category: newTransaction.category,
                    description: newTransaction.description,
                  },
                ],
              }}
              currency={account.currency}
              accountId={account.id}
            ></Transaction>
          ) : null}
          {showTransferForm ? (
            <Transfer
              date={transaction.date}
              amount={new Decimal(transaction.amount).abs().toNumber()}
              description={newTransaction.description}
              accountFrom={
                accountTo && !accountFrom
                  ? account
                  : transaction.amount < 0
                  ? account
                  : accountFrom
              }
              accountTo={
                accountFrom && !accountTo
                  ? account
                  : transaction.amount > 0
                  ? account
                  : accountTo
              }
            ></Transfer>
          ) : null}
          {showTransactions ? (
            <TransactionsList
              transactions={existingTransactions[transaction.date]}
            ></TransactionsList>
          ) : null}</div>
    </div>
  );
}

function ImportForm({ result, account, accounts }) {
  if (!result) return null;
  return (
    <div>
      {result.transactions.map((tran, i) => {
        return (
          <TransactionImport
            key={tran.externalId + i}
            transaction={tran}
            account={account}
            existingTransactions={result.existingTransactions}
            accounts={accounts}
          ></TransactionImport>
        );
      })}
    </div>
  );
}

export default function Import({ account }) {
  const [result, setResult] = useState();
  const [id, setId] = useState(guid());
  const [accounts, setAccounts] = useState();
  useEffect(() => {
    AccountsStore.accounts.subscribe(setAccounts);
  }, []);
  const onUploadFinished = (data) => {
    setId(guid());
    setResult(data.data);
  };

  return (
    <div className="import-c">
      <FileDropZone
        endpoint={predef.endpoints.import.transactions.alior}
        formData={{
          request: {
            accountId: account.id,
          },
        }}
        onUploadFinished={onUploadFinished}
      ></FileDropZone>

      <div key={id}>
        <ImportForm
          accounts={accounts}
          result={result}
          account={account}
        ></ImportForm>
      </div>
    </div>
  );
}
