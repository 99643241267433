import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button, { Cancel, Remove, Save, Edit } from '../common/button';
import Panel from '../common/panel';
import Page from '../common/page';
import { H3 } from '../common/headers';
import Json from '../common/json';
import './edit-transaction-template.scss';
import TransactionTemplates from '../stores/transaction-templates-store'
import { routeParamId } from '../../util';
import TransactionTemplateForm from './transaction-template-form';

class EditTransactionTemplate extends Component {

  constructor(props) {
    super(props);
    this.state = { isLoading: true, transactionTemplate: null };
    this.renderBody = this.renderBody.bind(this);
  }

  render() {
    return <Page
      className="edit-transaction-template-c"
      header="edit-transaction-template.header"
      loading={this.state.isLoading}
      data={true}
      renderBody={this.renderBody}
      noPanel
    >
    </Page>
  }

  renderBody() {

    return <React.Fragment>
      <TransactionTemplateForm transactionTemplate={this.state.transactionTemplate}></TransactionTemplateForm>
    </React.Fragment>
  }


  componentDidMount() {
    let id = routeParamId(this.props);
    TransactionTemplates.byId(id).then(template => {
      this.setState({ transactionTemplate: template, isLoading: false })
    });
  }

  componentWillUnmount() {
  }

}

export default EditTransactionTemplate;