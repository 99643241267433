import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button from '../common/button';
import Panel from '../common/panel';
import PropTypes from 'prop-types';
import Page from '../common/page';
import classNames from 'classnames/bind';
import Number from '../common/number';
import ControlledForm from '../common/controlled-form';
import './transfer-form.scss';

class TransferForm extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    let isNotExchange = this.props.currencyFrom == null || this.props.currencyTo == null || this.props.currencyFrom == this.props.currencyTo;
    let form = {
      fields: [
        {
          type: 'account',
          name: 'accountFromId',
          label: 'transfer.account-from',
          errorMessage: 'common.field-is-required',
          layout: 'c',
          hidden: this.props.accountFrom != null
        },
        {
          type: 'raw',
          hidden: this.props.accountFrom == null,
          name: 'account-from-info',
          layout: 'c',
          render: () => {
            return <div className="account-info">
              <label><FormattedMessage id="transfer.account-from"></FormattedMessage></label>
              <label>{this.props.accountFrom.name} <Number value={this.props.accountFrom.balance} currency={this.props.accountFrom.currency}></Number></label>
            </div>
          }
        },
        {
          type: 'account',
          name: 'accountToId',
          label: 'transfer.account-to',
          errorMessage: 'common.field-is-required',
          excludeAccount: this.props.values.accountFromId,
          currencyFilter: this.props.currency,
          disabled: this.accountTo == null && this.props.values.accountFromId == null,
          layout: 'c',
          hidden: this.props.accountTo != null
        },
        {
          type: 'raw',
          hidden: this.props.accountTo == null,
          name: 'account-to-info',
          layout: 'c',
          render: () => {
            return <div className="account-info">
            <label><FormattedMessage id="transfer.account-to"></FormattedMessage></label>
            <label>{this.props.accountTo.name} <Number value={this.props.accountTo.balance} currency={this.props.accountTo.currency}></Number></label>
          </div>
          }
        },
        {
          type: 'number',
          name: 'amount',
          label: 'transfer.amount',
          errorMessage: 'common.field-is-required',
          layout: 'c'

        },
        {
          type: 'radio-switch',
          name: 'exchangeType',
          items: [{
            label: 'transfer-form.i-know-total-amount',
            activeClass: 'primary',
            inactiveClass: 'outline-secondary',
            value: 'total-amount',
          }, {
            label: 'transfer-form.i-know-exchange-rate',
            activeClass: 'primary',
            inactiveClass: 'outline-secondary',
            value: 'exchange'
          }],
          hidden: isNotExchange,
          layout: 'c'
        },
        {
          type: 'number',
          name: 'exchangeRate',
          label: 'transfer.exchange-rate',
          errorMessage: 'common.field-is-required',
          hidden: isNotExchange || this.props.values.exchangeType != 'exchange',
          layout: 'c'
        },
        {
          type: 'number',
          name: 'totalAmount',
          label: 'transfer.total-amount',
          errorMessage: 'common.field-is-required',
          hidden: isNotExchange || this.props.values.exchangeType != 'total-amount',
          layout: 'c'
        },
        {
          type: 'date',
          name: 'date',
          label: 'transfer.date',
          errorMessage: 'common.field-is-required',
          layout: 'c',
          showCalendarButton: false, 
          showDateAdjustmentButtons: true
        },
        {
          type: 'text',
          name: 'description',
          label: 'transfer.description',
          layout: 'c'
        },
      ]
    }

    if (this.props.values.accountFromId == null) {
      this.props.values.accountToId = null;
    }

    return <div className="transfer-form-c">
      <div className="form">
        <ControlledForm errors={this.props.errors} values={this.props.values} onChange={(values) => this.onChange(values)} definition={form}></ControlledForm>
      </div>
    </div>
  }

  onChange(values) {
    if (values.accountFromId == null || values.accountToId == null) {
      values.totalAmount = null;
      values.exchangeRate = null;
    }
    else if (values.exchangeType == 'exchange') {
      values.totalAmount = null;
    }
    else {
      values.exchangeRate = null;
    }
    this.props.onChange(values)
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

}


TransferForm.propTypes = {

}


export default TransferForm;