import PropTypes from "prop-types";
import predef from '../../predef'
import React, { Component, useState } from "react";
import Bus from "../../bus";
import { guid } from "../../util";
import Button, { Edit } from "../common/button";
import Date from "../common/date";
import DocumentCategorySelect from "../common/document-category-select";
import FileDropZone from "../common/file-drop-zone";
import FuzyDateInput from "../common/fuzy-date-input";
import Loader from "../common/loader";
import LoadingPlaceholder from "../common/loading-placeholder";
import Modal from "../common/modal";
import Pagger from "../common/pagger";
import Panel from "../common/panel";
import SplitLink from "../common/split-link";
import { Table, TBody, TColumn, THead } from "../common/table";
import Textbox from "../common/textbox";
import DocumentsService from "../services/documents-service";
import Localization from "../services/localization-service";
import AttachDocument from "./attach-document";
import Documents from "./documents";
import DocumentsFilter from "./documents-filter";
import "./documents-grid.scss";
import Toastr from "../services/toastr-srevice";

function FileName({ name }) {
  if (!name) return name;

  let newName = name;

  return (
    <div style={{ textOverflow: "ellipsis" }} title={name}>
      {newName}
    </div>
  );
}

const DocumnetDate = ({ link, ...rest }) => {
  if (link == null) return null;
  if (link.date != null) return <Date date={link.date}></Date>;
  if (link.year != null && link.month != null)
    return Localization.formatDateMMMMYYYY(`${link.year}-${link.month}-01`);
  return link.year || null;
};

const ReadRow = ({ index, link, onEdit, ...rest }) => {
  const [isDetaching, setIsDetaching] = useState(false);
  return (
    <tr>
      <td>{index}</td>
      <td className="name-cell">
        <Button link documentName={link.name} documentId={link.documentId}>
          <FileName name={link.name}></FileName>
        </Button>
      </td>
      <td>{link.description}</td>
      <td>{link.category}</td>
      <td>
        <DocumnetDate link={link}></DocumnetDate>
      </td>
      <td className="actions-cell">
        <Edit onClick={() => onEdit(link)}></Edit>
        {isDetaching ? (
          <Loader show={true}></Loader>
        ) : (
          <Button
            onClick={() => {
              setIsDetaching(true);
              DocumentsService.detachDocument(link)
                .then(() => setIsDetaching(false))
                .catch(() => setIsDetaching(false));
            }}
            link
            danger
            label="documents-grid.detach"
          ></Button>
        )}
      </td>
    </tr>
  );
};

const WriteRow = ({ link, onSave, onCancel, index, docRef, ...rest }) => {
  const [name, setName] = useState(link.document?.name);
  const [description, setDescription] = useState(link.description);
  const [category, setCategory] = useState(link.category);
  const [date, setDate] = useState(link);

  return (
    <tr>
      <td>{index}</td>
      <td>
        <Textbox
          hasError={name == null || name == ""}
          errorMessage="common.field-is-required"
          value={name || ""}
          onChange={(v) => setName(v)}
        ></Textbox>
      </td>
      <td>
        <Textbox
          value={description || ""}
          onChange={(v) => setDescription(v)}
        ></Textbox>
      </td>
      <td>
        <DocumentCategorySelect
          docRef={docRef}
          value={category || ""}
          onChange={(v) => setCategory(v)}
        ></DocumentCategorySelect>
      </td>
      <td>
        <FuzyDateInput link={date} onChange={(v) => setDate(v)}></FuzyDateInput>
      </td>
      <td className="actions-cell">
        <Button
          link
          label="common.save"
          onClick={() => {
            if (name == "" || name == null) return;
            onSave({
              name: name,
              description: description,
              category: category,
              year: date?.year,
              month: date?.month,
              date: date?.date,
              id: link.id,
            });
          }}
        ></Button>{" "}
        <Button
          link
          danger
          label="common.cancel"
          onClick={() => onCancel()}
        ></Button>
      </td>
    </tr>
  );
};

const Row = ({ link, index, docRef, ...rest }) => {
  const [mode, setMode] = useState("read");
  if (mode == "read")
    return (
      <ReadRow
        index={index}
        link={link}
        onEdit={() => setMode("edit")}
      ></ReadRow>
    );
  return (
    <WriteRow
      docRef={docRef}
      index={index}
      link={link}
      onSave={(link) => {
        DocumentsService.updateLink(link).then((x) => setMode("read"));
      }}
      onCancel={() => setMode("read")}
    ></WriteRow>
  );
};

class DocumentsGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      documents: null,
      isSaving: false,
      pageSize: 20,
      page: 1,
      totalRows: 0,
      filterKey: guid(),
      showDeleteDocuments: false,
    };
    this.renderBody = this.renderBody.bind(this);
  }

  render() {
    let body = this.state.isLoading ? (
      <LoadingPlaceholder></LoadingPlaceholder>
    ) : (
      this.renderBody()
    );
    if (this.props.noPanel)
      return <div className="documents-grid-c">{body}</div>;
    return <div className="documents-grid-c">{body}</div>;
  }

  onUploadFinished() {
    Toastr.success("file-drop-zone.upload-success");
    Bus.publish(predef.events.document.documentsChanged, this.props.docRef);
    if (this.unmounted) return;
    this.setState({ filterKey: guid(), filter: null }, () =>
      this.loadDocuments(true)
    );
  }

  download(close, orderBy, thenBy) {
    DocumentsService.download(
      this.getDownloadModel(orderBy, thenBy),
      Localization.formatMessage("documents-grid.download-all-file-name", {
        fileNamePrefix: this.props.fileNamePrefix,
      })
    );
    close?.();
  }

  renderBody() {
    return (
      <React.Fragment>
        <Modal
          component={AttachDocument}
          componentProps={{
            link: {
              ref: this.props.docRef,
              refId: this.props.refId,
            },
          }}
          onClose={() => this.setState({ showAttach: false })}
          show={this.state.showAttach || false}
          title={"documents-grid.attach-document"}
        ></Modal>

        <Modal
          component={Documents}
          componentProps={{
            renderActions: (doc) => {
              return (
                <Button
                  danger
                  key="remove"
                  link
                  label="common.remove"
                  onClick={() => DocumentsService.deleteDocument(doc)}
                ></Button>
              );
            },
            actionsHeaderClasses: "len-100",
          }}
          onClose={() => this.setState({ showDeleteDocuments: false })}
          show={this.state.showDeleteDocuments || false}
          title={"documents-grid.deleting-documents"}
        ></Modal>
        <Panel>
          <DocumentsFilter
            docRef={this.props.docRef}
            key={this.state.filterKey}
            initialValues={this.state.filter}
            onChange={(f) =>
              this.setState({ filter: f, page: 1 }, () =>
                this.loadDocuments(true)
              )
            }
          ></DocumentsFilter>
        </Panel>
        <Panel>
          <div className="upload-link-form">
            <DocumentCategorySelect
              docRef={this.props.docRef}
              label="documents-grid.upload-category"
              value={this.state.linkCategory}
              onChange={(v) => this.setState({ linkCategory: v })}
            >
              {" "}
            </DocumentCategorySelect>
            <FuzyDateInput
              label="documents-grid.upload-date"
              value={this.state.linkDate}
              onChange={(v) => this.setState({ linkDate: v })}
            ></FuzyDateInput>
          </div>
          <div className="grid-actions">
            <Button
              link
              fal="link"
              className="action"
              label="documents-grid.attach-document"
              onClick={() => this.setState({ showAttach: true })}
            ></Button>
            {this.state.documents.length > 0 ? (
              <React.Fragment>
                <Button
                  link
                  className="action"
                  fal="download"
                  label="documents-grid.download-selected"
                ></Button>
                <SplitLink
                  onClick={(close) => this.download(close)}
                  className="action"
                  label="common.download-all"
                  items={[
                    {
                      label: "documents-grid.group-by-date",
                      onClick: (close) => this.download(close, "date"),
                    },
                    {
                      label: "documents-grid.group-by-category",
                      onClick: (close) => this.download(close, "category"),
                    },
                    {
                      label: "documents-grid.group-by-date-category",
                      onClick: (close) =>
                        this.download(close, "date", "category"),
                    },
                    {
                      label: "documents-grid.group-by-category-date",
                      onClick: (close) =>
                        this.download(close, "category", "date"),
                    },
                  ]}
                  button={{ fa: "download" }}
                ></SplitLink>
              </React.Fragment>
            ) : null}
            <Button
              link
              label="documents-grid.deleting-documents"
              onClick={() => this.setState({ showDeleteDocuments: true })}
            ></Button>
          </div>
          {this.state.documents.length > 0 ? this.renderFileDropZone() : null}
          <div className="pagger">
            <div className="silent-loader">
              <Loader show={this.state.silentLoad}></Loader>
            </div>
            <Pagger
              pageSize={this.state.pageSize}
              selectedPage={this.state.page}
              totalRows={this.state.totalRows}
              onPageChanged={(p) => {
                this.setState({ page: p });
                this.loadDocuments(true);
              }}
              onPageSizeChanged={(ps) => {
                this.setState({ pageSize: ps });
                this.loadDocuments(true);
              }}
            ></Pagger>
          </div>
          {this.state.documents != null && this.state.documents.length > 0 ? (
            <Table>
              <THead>
                <TColumn className="n-col len-40">#</TColumn>
                <TColumn className="name-col" header="common.name"></TColumn>
                <TColumn
                  className="desc-col "
                  header="common.description"
                ></TColumn>
                <TColumn
                  className="cat-col len-220"
                  header="common.category"
                ></TColumn>
                <TColumn
                  className="month-col len-165"
                  header="common.date"
                ></TColumn>
                <TColumn className="actions-col len-160"></TColumn>
              </THead>

              <TBody>
                {this.state.documents.map((docLink, i) => {
                  return (
                    <Row
                      docRef={this.props.docRef}
                      key={docLink.id}
                      index={i + 1}
                      link={docLink}
                    ></Row>
                  );
                })}
              </TBody>
            </Table>
          ) : null}
          {this.renderFileDropZone()}
        </Panel>
      </React.Fragment>
    );
  }

  renderFileDropZone() {
    return (
      <FileDropZone
        onUploadFinished={() => this.onUploadFinished()}
        endpoint={predef.endpoints.document.index}
        formData={{
          link: {
            ref: this.props.docRef,
            refId: this.props.refId,
            category: this.state.linkCategory,
            date: this.state.linkDate?.date,
            month: this.state.linkDate?.month,
            year: this.state.linkDate?.year,
          },
        }}
      ></FileDropZone>
    );
  }

  downloadAll() {
    DocumentsService.download(this.getQuery(), this.props.downloadAllFilename);
  }

  detachDocuemnt(docLink) {
    return DocumentsService.detachDocument(docLink);
  }

  loadDocuments(silent) {
    const loadDocs = () =>
      DocumentsService.getPagedDocuments(this.getPagedQuery()).then((res) => {
        this.setState({
          isLoading: res == null,
          silentLoad: false,
          documents: res.items,
          totalRows: res.totalItems,
        });
      });
    this.setState({ isLoading: !silent, silentLoad: silent }, () => {
      loadDocs();
    });
  }

  componentDidMount() {
    this.loadDocuments(false);
    this.busSub = Bus.subscribe(this);
  }

  componentWillUnmount() {
    this.busSub.unsubscribe();
  }

  _onDocumentsChanged() {
    this.loadDocuments(true);
  }

  getPagedQuery() {
    return {
      filter: this.getFilter(),
      paging: {
        page: this.state.page,
        pageSize: this.state.pageSize,
      },
    };
  }

  getFilter() {
    return {
      ref: this.props.docRef,
      refId: this.props.refId,
      name: this.state.filter?.name,
      description: this.state.filter?.description,
      category: this.state.filter?.category,
      dateFrom: this.state.filter?.dateFrom,
      dateTo: this.state.filter?.dateTo,
    };
  }

  getDownloadModel(groupBy, thenBy) {
    let filter = this.getFilter();
    filter.groupBy = groupBy;
    filter.thenGroupBy = thenBy;

    return filter;
  }
}

DocumentsGrid.propTypes = {
  docRef: PropTypes.string.isRequired,
  refId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default DocumentsGrid;
