import React, { Component, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import './top-navigation.scss';
import predef from '../../predef';
import classNames from 'classnames/bind';
import { withRouter } from 'react-router-dom';
import { hoverAndTouchDropDown } from '../../util';
import FlagsService from '../services/flags-service';
import Api from '../../api';

const formUrl = (match, url) => {
  let m = trimSlash(match.url);
  let u = trimSlash(url);
  return m == '' ? '/' + u : `/${m}/${u}`;
}

const trimSlash = (str) => {
  return str.replace(new RegExp("[/]+$"), "").replace(new RegExp("^[/]+"), "");
}

const nav = [
  { url: 'dashboard', label: 'top-navigation.dashboard' },
  {
    label: 'top-navigation.reports', dropDownSize: 'lg', key: 'reports', children: [
      { label: 'top-navigation.expenses-by-category', url: predef.routes.reports.expensesByCategory },
      { label: 'top-navigation.expenses-income-structure', url: predef.routes.reports.expensesIncomeStructure },
      { label: 'common.cash-flow', url: predef.routes.reports.cashFlow },
      { label: 'top-navigation.saving-factor', url: predef.routes.reports.savingFactor },
      { label: 'top-navigation.net-value', url: 'netvalue' },
    ]
  },
  {
    label: 'top-navigation.planning', key: 'planning', children: [
      { label: 'top-navigation.plans', url: 'planning' },
      { label: 'top-navigation.recurring-incomes', url: 'recurringincomes' },
      { label: 'top-navigation.recurring-budgets', url: 'recurringbudgets' },
      { label: 'top-navigation.future-balance', url: 'futurebalance' },

    ]
  },
  {
    label: 'top-navigation.more', key: 'more', children: [
      { label: 'top-navigation.accounts', url: 'accounts' },
      { label: 'top-navigation.reimbursables', url: 'reimbursables' },
      { label: 'top-navigation.income-streams', url: 'incomestreams' },
      { label: 'top-navigation.real-estates', url: 'realestates' },
      { label: 'top-navigation.investments', url: 'investments'},
      { label: 'common.assets', url: 'assets'},
      { label: 'top-navigation.category-editor', url: 'categoryeditor' },
      { label: 'top-navigation.transaction-templates', url: 'transactionTemplates' },
      { label: 'top-navigation.recurring-transactions', url: 'recurringTransactions' }
    ]
  },
];

const renderParentItem = (item, match, pathname) => {
  pathname = pathname.toLowerCase();
  return <div className={classNames("n-nav-item dropdown")} key={item.key}>
    <a  data-toggle="dropdown" className={classNames("n-dropdown-toggle", {
      "active": item.children.any(x => formUrl(match, x.url).toLowerCase() == pathname.toLowerCase())
    })}>
      <FormattedMessage id={item.label}></FormattedMessage> <span className="fa fa-chevron-down"></span>
    </a>
    <div className={classNames("dropdown-menu dropdown-menu-left", item.dropDownSize)}>
      {item.children.map(i => {
        if (i.flag != null && !FlagsService.hasFlag(i.flag)) return null;
        return <NavLink className="box" key={i.url} activeClassName="active" to={formUrl(match, i.url)}>
          <div className="link">
            <FormattedMessage id={i.label}></FormattedMessage>
          </div>
          <div className="link-desc">
            {i.description != null ? <FormattedMessage id={i.description}></FormattedMessage> : null}
          </div>
        </NavLink>
      })}
    </div>
  </div>
}

const TopNavigation = ({ match, ...rest }) => {
  useEffect(() => {
    hoverAndTouchDropDown('.top-navigation-c');
  });
  return <div className="top-navigation-c">
    <div className="n-nav">
      {nav.map(item => {
        if (item.url) {
          let url = formUrl(match, item.url);
          return <div className="n-nav-item" key={item.url}>
            <NavLink activeClassName="active" to={url}>
              <FormattedMessage id={item.label}></FormattedMessage>
            </NavLink>
          </div>
        }

        if (item.children) {
          return renderParentItem(item, match, rest.location.pathname)
        }
      })}
    </div>

  </div>

}


TopNavigation.propTypes = {

}


export default withRouter(TopNavigation);
