import React from 'react';
import { H3 } from '../common/headers';
import './add-transaction-template.scss';
import TransactionTemplateForm from './transaction-template-form';


const AddTransactionTemplate = (props) => {
  return <div className="add-transaction-template-c">
    <H3 header="add-transaction-template.header"></H3>
    <TransactionTemplateForm></TransactionTemplateForm>
  </div>
}


AddTransactionTemplate.propTypes = {

}


export default AddTransactionTemplate;