import predef from '../../predef';
import Store from './store';

class OperationTagsStore {
    constructor() {
        this.operationTagsStore = new Store(predef.endpoints.operationTag.index,
            [predef.events.transaction.transactionsChanged], predef.events.transaction.operationTagsStoreUpdated);

    }

    get operationTags() {
        return this.operationTagsStore.asObservable();
    }

}

const instance = new  OperationTagsStore();
export default instance;


