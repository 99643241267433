import React from 'react';
import predef from '../../predef';
import { InvestmentContext } from '../contexts';
import DocumentsGrid from '../documents/documents-grid';
import './investment-documents.scss';


const InvestmentDocuments = () => {
  return <div className="investment-documents-c">
    <InvestmentContext.Consumer>
      {(inv) => {
        return <DocumentsGrid id="investment" docRef={predef.documentRefs.investment} refId={inv.id} fileNamePrefix={inv.name}></DocumentsGrid>
      }}
    </InvestmentContext.Consumer>
  </div>
}

InvestmentDocuments.propTypes = {

}


export default InvestmentDocuments;