import axios from "axios";
import { guid } from "./util";
import moment from "moment";
import predef from "./predef";
import Toastr from "./app/services/toastr-srevice";
import { saveAs } from "file-saver";
import Localization from "./app/services/localization-service";
import Bus from "./bus";
let user = null;
let tenantId = null;
let token = null;
let validTill = null;
let flags = [];

let userKey = "user";
let tenantIdKey = "tenantId";
let tokenKey = "token";
let validTillKey = "validTill";
let flagsKey = "flags";

class Api {
  constructor() {}

  ensureTokens() {
    token = window.localStorage.getItem(tokenKey);
    tenantId = window.localStorage.getItem(tenantIdKey);
    user = JSON.parse(window.localStorage.getItem(userKey));
    validTill = window.localStorage.getItem(validTillKey);
    flags = JSON.parse(window.localStorage.getItem(flagsKey));
  }

  isUserAuthenticated() {
    if (token == null) {
      this.ensureTokens();
    }
    let tokenValid = this.isTokenValid(validTill);
    return token != null && validTill != null && tokenValid;
  }

  switchTenant(id) {
    window.localStorage.setItem(tenantIdKey, id);
    window.location.reload();
  }

  currentTenant() {
    return tenantId;
  }

  setUser(userData, rememberme) {
    token = userData.access_token;
    tenantId = userData.user.tenantId;
    user = userData.user;
    validTill = userData.expires_at;
    flags = userData.flags;
    if (rememberme) {
      window.localStorage.setItem(tokenKey, token);
      window.localStorage.setItem(tenantIdKey, tenantId);
      window.localStorage.setItem(userKey, JSON.stringify(user));
      window.localStorage.setItem(validTillKey, validTill);
      window.localStorage.setItem(flagsKey, JSON.stringify(flags));
    } else {
      this.clearUserData();
    }
  }

  clearUserData() {
    window.localStorage.removeItem(tokenKey);
    window.localStorage.removeItem(tenantIdKey);
    window.localStorage.removeItem(userKey);
    window.localStorage.removeItem(validTillKey);
    window.localStorage.removeItem(flagsKey);
  }

  logout() {
    this.clearUserData();
    window.location.reload();
  }

  getUser() {
    return user;
  }
  setUserLogin(login) {
    user.login = login;
    Bus.publish(predef.events.user.userLoginChanged, login);
  }
  getApiUrl(endpoint) {
    if (endpoint.startsWith("/") || endpoint.startsWith("\\"))
      endpoint = endpoint.substr(1);

    if (
      location.hostname === "localhost" ||
      location.hostname === "127.0.0.1"
    ) {
      return `${location.protocol}//localhost:5000/${endpoint}`;
    }

    return `${location.protocol}//${location.hostname}${
      location.port ? ":" + location.port : ""
    }/${endpoint}`;
  }

  get(endpoint, successMessage, errorMessage, headers) {
    return this.handle(
      predef.methods.get,
      endpoint,
      null,
      successMessage,
      errorMessage,
      headers
    );
  }

  post(endpoint, body, successMessage, errorMessage, headers) {
    return this.handle(
      predef.methods.post,
      endpoint,
      body,
      successMessage,
      errorMessage,
      headers
    );
  }

  put(endpoint, body, successMessage, errorMessage, headers) {
    return this.handle(
      predef.methods.put,
      endpoint,
      body,
      successMessage,
      errorMessage,
      headers
    );
  }

  delete(endpoint, successMessage, errorMessage, headers) {
    return this.handle(
      predef.methods.delete,
      endpoint,
      null,
      successMessage,
      errorMessage,
      headers
    );
  }

  postFormUrlEncoded(endpoint, body, successMessage, errorMessage) {
    return this.handle(
      predef.methods.post,
      endpoint,
      body,
      successMessage,
      errorMessage,
      {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    );
  }

  downloadFile(id, name) {
    let uri = this.getApiUrl(predef.endpoints.document.indexId(id));
    let options = this.getOptions();
    options.responseType = "blob";
    name = name.replace(/[<>\\/:"|\?\*]/g, "_");
    axios.get(uri, options).then((data) => {
      saveAs(data.data, name);
    });
  }

  downloadDocuments(query, name) {
    let uri = this.getApiUrl(
      predef.endpoints.document.downloadDocuments(Localization.lang)
    );
    let options = this.getOptions();
    options.responseType = "blob";
    name = name.replace(/[<>\\/:"|\?\*]/g, "_");
    axios.post(uri, query, options).then((data) => {
      saveAs(data.data, name);
    });
  }

  uploadFiles(endpoint, files, formData, progress) {
    let uri = this.getApiUrl(endpoint);
    let options = this.getOptions();
    options.onUploadProgress = (progressEvent) => {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      progress(percentCompleted, progressEvent.loaded, progressEvent.total);
    };
    let data = new FormData();
    for (let file of files) {
      data.append("file", file);
    }
    for (let key in formData) {
      data.append(key, JSON.stringify(formData[key]));
    }

    return axios.put(uri, data, options);
  }

  getOptions(headers) {
    if (token == null) {
      this.ensureTokens();
    }
    let options = {
      headers: {
        Authorization: `Bearer ${token}`,
        tenant: tenantId,
      },
    };

    if (headers != null) {
      for (let headerKey in headers) {
        options.headers[headerKey] = headers[headerKey];
      }
    }

    return options;
  }

  handle(method, endpoint, body, successMessage, errorMessage, headers) {
    let id = guid();
    let uri = this.getApiUrl(endpoint);
    let options = this.getOptions(headers);
    let promise = null;

    // this.logRequest(id, method, uri, body, options, successMessage, errorMessage);

    switch (method) {
      case "GET":
        promise = axios.get(uri, options);
        break;
      case "POST":
        promise = axios.post(uri, body, options);
        break;
      case "PUT":
        promise = axios.put(uri, body, options);
        break;
      case "DELETE":
        promise = axios.delete(uri, options);
        break;
    }

    let wrapper = new Promise((resolve, reject) => {
      promise
        .then((data) => {
          // this.logResponse(id, method, uri, data)
          resolve(data.data);
          if (successMessage != null) {
            Toastr.success(successMessage);
          }
        })
        .catch((e) => {
          if (errorMessage != null) {
            Toastr.error(errorMessage);
          }
          this.logError(id, method, uri, e);
          var result = { originalError: e };
          var error = e?.response?.data?.error;
          if (error != null) result.errors = [error];
          else result.errors = e?.response?.data?.errors;
          reject(result);
          if (e.response != null && e.response.status === 401) {
            window.location = `#${predef.routes.login}`;
          }
          if (e.message === "Network Error") {
            window.location = `#${predef.routes.apiUnavailable}`;
          }
        });
    });

    return wrapper;
  }

  // logRequest(id, method, uri, body, options, successMessage, errorMessage) {
  //     return;
  //     if (location.hostname !== "localhost" ||  window.apiLogs !== true) return;

  //     let msg = `API REQUEST: ${id.substr(0, 5)} ${method} ${uri}`;
  //     let data =
  //     {
  //         body: body,
  //         options: options,
  //         successMessage: successMessage,
  //         errorMessage: errorMessage
  //     };

  //     return console.info(msg, data);
  // }

  // logResponse(id, method, uri, data) {
  //     return;
  //     if (location.hostname !== "localhost" || window.apiLogs !== true) return;

  //     let msg = `API RESPONSE: ${id.substr(0, 5)} ${method} ${uri}`;
  //     console.info(msg, data);
  // }

  logError(id, method, uri, error) {
    if (location.hostname !== "localhost") return;
    if (error == "Error: Request failed with status code 401") return;

    let msg = `API RESPONSE: ${id.substr(0, 5)} ${method} ${uri}`;
    console.error(msg, error);
  }

  isTokenValid(expiresEpoch) {
    let now = moment().utc().valueOf();
    let ei = (expiresEpoch - now) / 1000;
    return expiresEpoch > now;
  }

  getFlags() {
    return flags || [];
  }
}

const instance = new Api();
export default instance;
