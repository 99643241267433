var intl;
import Calendar from './calendar-service';
import moment from 'moment';

class LocalizationService {
    formatMessage(id, values) {
        return intl.formatMessage({ id: id }, values);
    }

    formatNumber(value, padDecimal) {
        if (value == null || value === '') return '';
        let parts = value.toFixed(2).split('.');

        let whole = parts[0].split('').reverse().join('').replace(/(\d{3})/gm, "$1" + this.formatting.thousandsSepeartor).split('').reverse().join('');
        if (whole[0] == this.formatting.thousandsSepeartor) whole = whole.substring(1);
        if (!padDecimal && (parts.length == 1 || parts[1] == "00")) return whole;
        if (!padDecimal) {
            return `${whole}${this.formatting.decimalSeparator}${parts[1]}`;
        }

        if (parts.length == 1) return `${whole}${this.formatting.decimalSeparator}00`;
        var decimal = parts[1];

        if (decimal.length == 0) decimal += "00";
        if (decimal.length == 1) decimal += "0";

        return `${whole}${this.formatting.decimalSeparator}${decimal}`;
    }

    formatDate(date, format) {
        if (date == null) return '';
        var md = Calendar.momentFromIsoDate(date);
        if (!md.isValid()) return '';

        return md.format(format ?? this.formatting.dateFormat);
    }

    formatDateMMMYY(date) {
        if (date == null) return '';
        var md = Calendar.momentFromIsoDate(date);
        if (!md.isValid()) return '';

        return md.format('MMM \'YY');
    }

    formatDateMMMMYYYY(date) {
        if (date == null) return '';
        var md = Calendar.momentFromIsoDate(date);
        if (!md.isValid()) return '';

        return md.format('MMMM YYYY');
    }

    formatDateTime(date) {
        if (date == null) return '';
        var md = moment(date,"YYYY-MM-DDTHH:mm:ss:SSSSSS");
        if (!md.isValid()) return '';
        let format = this.formatting.dateFormat + " HH:mm:ss(SSS)";
        return md.format(format);
    }

    formatMonthName(month) {
        return moment('2000-' + month + '-01', 'YYYY-MM-DD').format('MMMM');
    }

    get intl() {
        return intl;
    }

    set intl(i) {
        intl = i.intl;
    }

    get lang() {
        return intl.locale;
    }

    get formatting() {
        if (this.lang == 'pl') {
            return {
                decimalSeparator: ',',
                dateFormat: 'DD.MM.YYYY',
                datePicker: {
                    monthAndYearFormat: 'MM yyyy'
                },
                thousandsSepeartor: ' '
            }
        }
        else {
            return {
                decimalSeparator: '.',
                dateFormat: 'DD/MM/YYYY',
                datePicker: {
                    monthAndYearFormat: 'MM yyyy'
                },
                thousandsSepeartor: ','
            }
        }
    }

}

const instance = new LocalizationService();
export default instance;
