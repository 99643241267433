import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage } from 'react-intl';
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { guid } from '../../util';
import Button from '../common/button';
import './modal.scss';

class Modal extends Component {

  constructor(props) {
    super(props);
    this.id = 'modal' + guid();
    $('body').append(`<div id="${this.id}"></div>`);
  }

  render() {
    const BodyComponent = this.props.component;
    let bodyComponentProps = this.props.componentProps || {};
    var body = <div className={classNames('modal-c', this.props.className)}>
      <TransitionGroup component={null}>
        {this.props.show && (
          <CSSTransition classNames="dialog" timeout={300}>
            <div className="n-modal">
              <div className="n-modal-main">
                <div className="n-modal-header">
                  <div>
                    {this.props.title != null ? <FormattedMessage id={this.props.title} data={this.props.titleData}></FormattedMessage> : null}
                  </div>
                  <div>
                    <Button link fal="times-circle" onClick={() => this.props.onClose('cancel')}></Button>
                  </div>
                </div>
                <div className="n-modal-body">
                  <BodyComponent onChange={(v) => this.setState({ value: v })} {...bodyComponentProps}></BodyComponent>
                </div>
                <div className="n-modal-footer">
                  {this.props.closeButton != null ? <Button {...this.props.closeButton} className="close-btn"
                    onClick={() => this.props.onClose('save')} loading={this.props.isSaving}></Button> :
                    <Button
                      fal="times"
                      label="modal.close"
                      className="close-btn"
                      onClick={() => this.props.onClose('save')}></Button>
                  }
                </div>
              </div>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>;
    return createPortal(body, $('#' + this.id)[0]);
  }
  componentWillUnmount() {
    var modal = document.getElementById(this.id);
    modal.parentNode.removeChild(modal);
  }

}

Modal.propTypes = {
  component: PropTypes.func.isRequired,
  componentProps: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  closeButtonLabel: PropTypes.string,
  isSaving: PropTypes.bool
}

export default Modal;