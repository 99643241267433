import React, { Component } from 'react';
import * as yup from 'yup';
import { guid } from '../../util';
import EditableGrid from '../common/grid/editable-grid';
import LoadingPlaceholder from '../common/loading-placeholder';
import Panel from '../common/panel';
import FutureIncomesStore from '../stores/future-incomes-store';
import FutureIncomesService from '../services/future-incomes-service';


import './future-incomes.scss';
const validationSchema = yup.object().shape({
  description: yup.string().min(1, 'common.field-is-required').required(),
  amount: yup.number().required('common.field-is-required'),
  date: yup.string().required().min(10),
  currency: yup.string().required()
});
class FutureIncomes extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      incomes: null,
      refreshToken: guid()
    };
  }

  render() {
    return <Panel
      className="future-incomes-c"
    >
      {this.state.isLoading ? <LoadingPlaceholder></LoadingPlaceholder> : this.renderBody()}
    </Panel>
  }

  submitFutureIncomes(fis) {
    return new Promise((resolve, reject) => {
      FutureIncomesService.update(fis)
        .then(() => resolve())
        .catch(() => reject())
    })

  }

  renderBody() {
    return <React.Fragment>
      <EditableGrid
        width={90}
        id="future-incomes"
        onSubmit={(fis) => this.submitFutureIncomes(fis)}
        data={this.state.incomes}
        validationSchema={validationSchema}
        key={this.state.refreshToken}
        autoWidth={true}
        definition={[
          {
            show: true,
            header: 'common.description',
            type: 'text',
            name: 'description',
            headerClasses: "len-300"
          },
          {
            show: true,
            header: 'common.amount',
            type: 'number',
            name: 'amount',
            headerClasses: "len-160"
          },
          {
            show: true,
            header: 'common.currency',
            type: 'currency',
            name: 'currency',
            headerClasses: "len-160",
            controlProps: {
              onlyUsed: true,
              isClearable: false
            }
          },
          {
            show: true,
            header: 'common.date',
            type: 'date',
            name: 'date',
            headerClasses: "len-160"
          }
        ]} >
      </EditableGrid>
    </React.Fragment>
  }

  componentDidMount() {
    this.sub = FutureIncomesStore.futureIncomes.subscribe(x => {
      this.setState({ incomes: x, isLoading: x == null, refreshToken: guid() });
    });
  }

  componentWillUnmount() {
    this.sub.unsubscribe();
  }

}


FutureIncomes.propTypes = {

}


export default FutureIncomes;