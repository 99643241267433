import React from 'react';
import './transfer-badge.scss';
import Badge from '../common/badge';

const TransferBadge = ({ transaction }) => {
  if (transaction == null || !transaction.isPartOfTransfer) return null;

  if (transaction.amount < 0 && transaction.accountTo != null) {
    return <Badge key="transfer" text="transaction-list.transfer-to" secondary values={{ name: transaction.accountTo.name }}></Badge>
  }
  else if (transaction.accountFrom != null) {
    return <Badge key="transfer" text="transaction-list.transfer-from" secondary values={{ name: transaction.accountFrom.name }}></Badge>
  }
}


TransferBadge.propTypes = {

}


export default TransferBadge;