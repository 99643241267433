import classNames from 'classnames/bind';
import Popper from 'popper.js';
import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage } from 'react-intl';
import predef from '../../predef';
import { guid } from '../../util';
import './text-suggest-input.scss';
import Localization from '../services/localization-service';

const getOptions = (suggestions, value) => {

  if (suggestions == null) return [];
  let options = [];
  suggestions = suggestions.distinct();
  if (value == null || value == '') return suggestions.select(x => {
    return {
      value: x,
      index: 0
    }
  });
  for (let option of suggestions) {
    if (option == null) continue;
    let index = option.toLowerCase().indexOf(value.toLowerCase());
    if (index > -1) {
      options.push({ index: index, value: option });
    }
  }

  return options.orderBy(x => x.index);
}




class TextSuggestInput extends Component {

  constructor(props) {
    super(props);
    this.state = { selected: -1, options: null };
    this.id = "tsi" + guid();
    this.closeDropDownWhenClickOutside = this.closeDropDownWhenClickOutside.bind(this);
  }

  onChange(value, resetSelected) {
    this.props.onChange(value);
    if (resetSelected) {
      this.setState({ selected: 0 }, () => this.scrollToSelected());
    }
  }

  static getDerivedStateFromProps(props, state) {
    return { options: getOptions(props.options, props.value) };
  }

  onFocus() {
    if (this.props.value != null && this.props.value.length > 0 && this.state.options != null && this.state.options.length > 0) {
      this.openDropDown();
    }
  }

  onKeyDown(e) {
    if (e.keyCode == predef.keyCodes.tab) {
      this.closeDropDown();
    }
  }

  render() {
    return <div className="text-suggest-input-c">
      <div className={classNames(
                `${this.props.name}-field`,
                "form-group",
                {
                    'has-error': this.props.hasError
                })}>
        {this.props.label != null ? <label><FormattedMessage id={this.props.label}></FormattedMessage></label> : null}
        <input placeholder={this.props.placeholder != null ? Localization.formatMessage(this.props.placeholder) : null} onKeyDown={(e) => this.onKeyDown(e)} onFocus={() => this.onFocus()} onKeyUp={(e) => this.onKeyUp(e)} id={this.id} value={this.props.value || ''} onChange={(event) => this.onChange(event.target.value, true)} type="text" 
        className={classNames('form-control',{'is-invalid': this.props.hasError})}></input>
        {
                this.props.hasError && this.props.errorMessage
                    ? <div className="error-message invalid-feedback">
                        <FormattedMessage id={this.props.errorMessage}></FormattedMessage>
                    </div>
                    : null
            }
        {createPortal(<div id={'drop-down-' + this.id} className="text-suggest-input-c drop-down "  >
          <div className="body" >
            {this.state.options != null ? this.state.options.map((x, i) =>
              <div onClick={() => this.select(x.value)} className={classNames("item", { 'selected': i == this.state.selected })} key={i}>
                {this.getOption(this.props.value, x.value)}
              </div>
            ) : null}
          </div>
        </div>, $('body')[0])}
      </div>
    </div>
  }


  getOption(value, opt) {
    if (opt == null || value == null) return opt;
    let index = opt.toLowerCase().indexOf(value.toLowerCase());
    if (index > -1) {
      let searchEnd = index + value.length;
      let prefix = opt.substring(0, index);
      let found = opt.substring(index, index + value.length);
      let sufix = opt.substring(searchEnd, opt.length);

      return <React.Fragment>{prefix}<span className="b">{found}</span>{sufix}</React.Fragment>
    }
  }

  select(value) {
    this.onChange(value);
    this.closeDropDown();
    if (this.props.submitOnSelect) {
      this.props?.onSubmit(value);
    }
  }

  closeDropDown() {
    if (!this.isDropDownOpen) return;
    this.$dropDown.hide();
    this.popper?.destroy();
  }

  openDropDown() {
    if (this.isDropDownOpen) return;
    this.$dropDown.show();
    this.popper = new Popper(this.$input[0], this.$dropDown[0], {
      modifiers: {
        placement: 'bottom-end',
        preventOverflow: { enabled: true, boundariesElement: $('body')[0] },
        setPopperWidth: {
          enabled: true,
          fn: ({ instance: { reference, popper } }) => {
            popper.style.width = `${reference.offsetWidth}px`;
          },
          order: 1,
        }
      },
      onCreate: () => {
        this.popper.scheduleUpdate()
      }
    });
    this.setState({ selected: 0 })
  }

  moveSelectedDown() {
    if (this.state.selected == this.state.options.length - 1) return;
    this.setState((state, props) => { return { selected: state.selected + 1 } }, () => {
      this.scrollToSelected();
    });

  }

  moveSelectedUp() {
    if (this.state.selected <= 0) return;
    this.setState((state, props) => { return { selected: state.selected - 1 } }, () => {
      this.scrollToSelected();
    });
  }

  scrollToSelected() {
    if (this.$dropDown.children().first().children().length <= this.state.selected) return;
    let item = this.$dropDown.children().first().children().eq(this.state.selected);
    item[0].scrollIntoView(true);
  }


  onKeyUp(e) {
    this.props.onKeyUp?.(e);

    if (this.state.options == null) return;

    if (e.keyCode == predef.keyCodes.enter) {
      if (this.isDropDownOpen && this.state.selected < this.state.options.length && this.state.selected > -1) {
        let op = this.state.options[this.state.selected];
        this.onChange(op.value);
        this.closeDropDown();
        this.setState({ selected: -1 });
        if (this.props.submitOnSelect) {
          this.props.onSubmit?.(op.value);
        }
        return;
      }
      else {
        this.props.onSubmit?.(this.props.value);
        return;
      };
    }

    if (e.keyCode != predef.keyCodes.enter
      && this.props.value != null
      && this.props.value.length > 0
      && this.state.options != null
      && this.state.options.length > 0) {
      this.openDropDown();
    }
    else {
      this.closeDropDown();
    }

    if (e.keyCode == predef.keyCodes.arrowDown) {
      this.arrowDown = false;
      this.moveSelectedDown()
      return;
    }
    if (e.keyCode == predef.keyCodes.arrowUp) {
      this.arrowUp = false;
      this.moveSelectedUp();
      return;
    }


  }

  get isDropDownOpen() {
    return this.$dropDown.is(':visible');
  }


  get $input() {
    return $('#' + this.id);
  }

  get $dropDown() {
    return $('#' + 'drop-down-' + this.id);
  }

  closeDropDownWhenClickOutside(e) {
    if (this.$input[0] == e.target || $.contains(this.$input[0], e.target) || $.contains(this.$dropDown[0], e.target)) {
      return;
    }
    this.closeDropDown();
  }

  closeDropDown() {
    if (this.$dropDown.is(':visible')) {
      this.$dropDown.hide();
      this.popper?.destroy();
    }
  }

  componentDidMount() {
    this.$dropDown.hide();
    $(document).bind('click', this.closeDropDownWhenClickOutside);
  }

  componentWillUnmount() {
    $(document).unbind('click', this.closeDropDownWhenClickOutside);
  }

}


TextSuggestInput.propTypes = {

}


export default TextSuggestInput;