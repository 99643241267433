import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '../common/button';
import LoadingPlaceholder from '../common/loading-placeholder';
import Message from '../common/message';
import Number from '../common/number';
import Panel from '../common/panel';
import Progress from '../common/progress';
import { Table, TBody, TColumn, THead } from '../common/table';
import ReportService from '../services/report-service';
import './budgets-report.scss';
import TransactionsModal from '../transactions/transactions-modal';
import { IsoCalendar } from '../services/calendar-service';

class BudgetsReport extends Component {

  constructor(props) {
    super(props);
    this.state = { budgets: null, isLoading: true, showTransactions: false, transactionFilter: null };
  }

  render() {
    return <div className="budgets-report-c">
      {this.state.isLoading ? <LoadingPlaceholder></LoadingPlaceholder> :
        <React.Fragment>
          <TransactionsModal show={this.state.showTransactions} onClose={() => this.setState({ showTransactions: false })} filter={this.state.transactionFilter}></TransactionsModal>
          <h3><FormattedMessage id="budgets-report.header"></FormattedMessage></h3>
          <Panel>
            {this.state.budgets != null && this.state.budgets.budgets.length === 0 ? <Message message="budgets-report.no-data"></Message> :
              <Table tableStriped={false}  >
                <THead>
                  <TColumn className="len-200"></TColumn>
                  <TColumn className="len-100 number-cell" header="budgets-report.used" ></TColumn>
                  <TColumn className="len-100  number-cell" header="budgets-report.limit"></TColumn>
                  <TColumn className="progress-col"></TColumn>
                  <TColumn className="view-col"></TColumn>
                </THead>
                <TBody>{this.state.budgets.budgets.map(ci => {
                    return <React.Fragment key={ci.currency}>
                      <tr>
                        <td colSpan="5" className=" currency-col">{ci.currency}</td>
                      </tr>
                      {ci.items.map(b => <tr key={b.id}>
                        <td>{b.name}</td>
                        <td className="number-cell"><Number value={b.used}></Number></td>
                        <td className="number-cell"><Number value={b.limit}></Number></td>
                        <td><Progress min={0} max={b.limit} value={b.used} danger={b.used > b.limit} success={b.used < b.limit} warning={b.used == b.limit} ></Progress></td>
                        <td>
                          <Button link fad="eye" onClick={() => {
                            this.setState({
                              showTransactions: true, transactionFilter: {
                                budgetId: b.id
                              }
                            })
                          }}></Button>
                        </td>
                      </tr>)}
                      {ci.notBudgeted > 0 ? <tr className="unbudgeted">
                        <td><FormattedMessage id="budgets-report.unbudgeted"></FormattedMessage></td>
                        <td className="number-cell">{ci.notBudgeted}</td>
                        <td></td>
                        <td></td>
                        <td> <Button link fad="eye" onClick={() => {

                          this.setState({
                            showTransactions: true, transactionFilter: {
                              hasNoBudget: true,
                              isInvestment: false,
                              isExpense: true,
                              isReimbursable: false,
                              isIncomeCost: false,
                              currency: ci.currency,
                              date: {
                                dateFrom: IsoCalendar.startOfCurrentMonth,
                                dateTo: IsoCalendar.endOfCurrentMonth
                              }
                            }
                          })
                        }}></Button></td>
                      </tr> : null}
                    </React.Fragment>
                  }
                  )}</TBody></Table>}
          </Panel>
        </React.Fragment>
      }
    </div>
  }

  componentDidMount() {
    this.updateBudgets();
  }

  updateBudgets() {
    ReportService.budgetsInMonth(this.props.year, this.props.month).then(budgets => {
      this.setState({ budgets: budgets, isLoading: budgets == null });
    });
  }



}


BudgetsReport.propTypes = {

}


export default BudgetsReport;