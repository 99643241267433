import * as echarts from 'echarts/dist/echarts.js';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { guid } from '../../../util';
import Localization from '../../services/localization-service';
import { Decimal } from 'decimal.js';

import './area-chart.scss';

class AreaChart extends Component {

  constructor(props) {
    super(props);
    this.id = 'hbc-' + guid();
    this.resize = this.resize.bind(this);
    this.state = {};
  }

  resize() {
    if (this.chart != null) {
      this.chart.resize();
    }
  }

  render() {
    return (
      <div className="area-chart-c">
        <div style={{ width: "100%", height: this.props.definition.height + "px" }} className="chart" id={this.id}></div>
      </div>
    );
  }


  formatXForTooltip(val) {
    if (this.props.definition.formatXForTooltip != null) return xFormatterForTooltip(val);
    if (this.props.definition.timeSeries) return Localization.formatDateMMMMYYYY(val);
    return val;
  }

  formatY(val) {
    if (this.props.definition.formatY != null) return this.props.definition.formatY(val);
    return Localization.formatNumber(val);
  }

  getSum(series, dataIndex) {
    if (series.length == 1) return '';

    return ' : '+Localization.formatNumber(series.sum(x=>x.data.value))
  }

  componentDidMount() {
    this.chart = echarts.init(document.getElementById(this.id));


    var option = {
      title: {
      },
      legend: {
        show: this.props.definition.showLegend,
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(50,50,50,0.9)',
        formatter: this.props.definition.tooltipFormatter || ((args) => {
          let series = this.props.definition.series || [this.props.definition.serie];
          let tooltip = `<p>${this.formatXForTooltip(args[0].axisValue)}${this.getSum(args)}</p> `;

          args.reverse().forEach(({ marker, dataIndex, seriesIndex }) => {
            let serie = series[seriesIndex];
            tooltip += `<p>${marker} ${(serie.name ? Localization.formatMessage(serie.name) + ':' : '')} ${this.formatY(serie.y[dataIndex])}</p>`;
          });
          return tooltip;
        }),
      },
      dataZoom: [{
        type: 'slider',
        start: 0,
        end: 100
      }, {
        start: 0,
        end: 100,
        handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
        handleSize: '80%',
        handleStyle: {
          color: '#fff',
          shadowBlur: 3,
          shadowColor: 'rgba(0, 0, 0, 0.6)',
          shadowOffsetX: 2,
          shadowOffsetY: 2
        }
      }],
      xAxis: {
        data: this.props.definition.x,
        axisTick: {
          alignWithLabel: true,
        },
        axisLabel: {
          show: true,
          formatter: this.props.definition.formatXLabel || (this.props.definition.timeSeries ? (d) => Localization.formatDateMMMYY(d) : null)
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '50',
        containLabel: true,
      },
      yAxis: {},
      series: this.getSeries()
    };
    if (this.props.definition.verticalBars) {
      let xa = option.xAxis;
      option.xAxis = option.yAxis;
      option.yAxis = xa;
    }
    if (this.props.definition.override) {
      option.grid = { ...option.grid, ...(this.props.definition.override.grid || {}) }
      option.legend = { ...option.legend, ...(this.props.definition.override.legend || {}) }
    }


    this.chart.setOption(option);
    window.addEventListener("resize", this.resize);
  }


  getSeries() {
    if (this.props.definition.serie == null) {
      return this.props.definition.series.select(x => this.getSerie(x));
    }

    return [this.getSerie(this.props.definition.serie)];
  }

  getSerie(serie) {
    return {
      name: serie.name ? Localization.formatMessage(serie.name) : '',
      type: 'line',
      lineStyle: {
        color: serie.color
      },
      areaStyle: {
        normal: { color: serie.color }
      },
      itemStyle: {
        opacity: 0
      },
      stack: 'one',
      data: serie.y.select(x => { return { value: x, itemStyle: serie.itemStyle ? serie.itemStyle(x) : null }; }),

    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }


}


AreaChart.propTypes = {

}


export default AreaChart;