import Service from './service';
import predef from '../../predef';
import api from '../../api';


class DocumentsService extends Service {

  getPagedDocuments(query) {
    return this.post(predef.endpoints.document.linkQuery, query);
  }

  detachDocument(link) {
    return this.delete(predef.endpoints.document.linkId(link.id)
    , predef.events.document.documentsChanged
    , link.ref
    , "documents-service.detach-documnet-success"
    , 'documents-service.detach-documnet-error')
  }

  attachDocument(link) {
    return this.put(predef.endpoints.document.link
      , link
      , predef.events.document.documentsChanged
      , link.ref
      , 'documents-service.attach-success'
      , 'documents-service.attach-error')
  }

  download(query, fileName) {
    return api.downloadDocuments(query, fileName)
  }

  deleteDocument(doc){
    return this.delete(predef.endpoints.document.indexId(doc.id)
    ,predef.events.document.documentsChanged
    ,null
    ,'documents-service.delete-document-success'
    ,'documents-service.delete-document-error')
  }

  updateLink(link) {
    return this.post(
      predef.endpoints.document.link
      , link
      , predef.events.document.documentsChanged
      , link.ref
      , 'documents-service.update-link-success'
      , 'documents-service.update-link-error'
    );
  }

  getCategories(ref){
    return this.get(predef.endpoints.document.categories(ref));
  }

}



let instance = new DocumentsService();
export default instance;
