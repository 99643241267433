import React, { Component } from 'react';
import Bus from '../../bus';
import predef from '../../predef';
import Button from '../common/button';
import Localization from '../services/localization-service';
import './language-selector.scss';

class LanguageSelector extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="language-selector-c">
        <div className="langs">
          <Button link emphasis={Localization.lang == 'en'} label="shell.en" onClick={()=>this.changeLang('en')} ></Button>
          <Button link emphasis={Localization.lang == 'pl'} label="shell.pl" onClick={()=>this.changeLang('pl')}></Button>
        </div>
      </div>
    );
  }

  changeLang(lang){
    Bus.publish(predef.events.localization.languageSelected, lang);
  }
}

export default LanguageSelector;
