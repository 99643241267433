import React, { Component } from 'react';
import predef from '../../predef';
import Badge from '../common/badge';
import Grid from '../common/grid/grid';
import Loader from '../common/loader';
import Localization from '../services/localization-service';
import DocumentsStore from '../stores/documents-store';
import './documents.scss';
class Documents extends Component {

  constructor(props) {
    super(props);
    this.state = { isLoading: true, documents: null };
  }

  render() {
    return <div className="documents-c">
      {this.state.isLoading ? <Loader show={true}></Loader> : <Grid id="documents" pageSize={20} data={this.state.documents}
        definition={[{
          show: true,
          headerClasses: "len-300",
          header: "common.name",
          renderCell: (doc) => doc.name,
          sortable: true
        },
        {
          show: true,
          headerClasses: "len-300",
          header: "documents.attached-to",
          renderCell: (doc) => this.formatLinks(doc),
          sortable: true
        },
        {
          show: true,
          name: 'actions',
          cellClassNames: 'actions-cell',
          headerClasses: this.props.actionsHeaderClasses || "len-200",
          renderCell: (doc) => this.props.renderActions?.(doc),
          sortable: true
        }
        ]}
      ></Grid>}
    </div>
  }


  formatDate(doc) {
    if (doc.date == null) return null;
    return Localization.formatDate(doc.date);
  }

  formatLinks(doc) {
    if (doc.links == null || doc.links.length == 0) return null;
    var res = [];
    for (let link of doc.links) {
      switch (link.ref) {
        case predef.documentRefs.realEstate:
          res.push(<Badge key={link.id} fa="home" text="documents.real-estate" secondary values={{ name: link.realEstateName, date: Localization.formatDateMMMMYYYY(`${link.year}-${link.month}-01`) }}></Badge>)
          break;
        case predef.documentRefs.incomeStream:
          res.push(<Badge key={link.id} fa="chart-line" text="documents.income-stream" secondary values={{ name: link.incomeStreamName, date: Localization.formatDateMMMMYYYY(`${link.year}-${link.month}-01`) }}></Badge>)
          break;
        case predef.documentRefs.resident:
          res.push(<Badge key={link.id} fa="user" text="documents.resient" secondary values={{ name: link.residentName, date: Localization.formatDateMMMMYYYY(`${link.year}-${link.month}-01`) }}></Badge>)
      }
    }

    return res;
  }

  componentDidMount() {
    this.dsSub = DocumentsStore.documents.subscribe(docs => {
      this.setState({ documents: docs, isLoading: docs == null });
    });
  }

  componentWillUnmount() {
    this.dsSub.unsubscribe();
  }

}

export default Documents;