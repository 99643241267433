import React, { Component } from 'react';
import { Table, TBody, TColumn, THead } from '../common/table';
import './transactions-table.scss';
import classNames from 'classnames/bind';
import { FormattedMessage } from 'react-intl';
import Number from '../common/number';
import Badge from '../common/badge';

class TransactionsTable extends Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.actions = {
      deleteTransaction: (tran) => this.deleteTransaction(tran),
      updateTransactionDate: (id,date) => this.updateTransactionDate(id,date),
    }
  }

  deleteTransaction(tran) {
    this.props.onTransactionDelete(tran);
  }

  updateTransactionDate(id,date) {
    this.props.onUpdateTransactionDate(id,date)
  }

  render() {
    return (
      <div className="transactions-table-c">
        {this.renderTable()}
      </div>
    );
  }

  renderTable() {
    return (<Table tableStriped={false}>
      <THead>
        {this.renderHeaders()}
      </THead>
      <TBody>
        {this.renderRows()}
      </TBody>
      <tfoot>
        {this.renderFooter()}
      </tfoot>
    </Table>)
  }

  renderRows() {
    if (this.props.transactions == null) return null;

    var renderCells = (tran, op, i) => {
      var cells = [];

      for (let col of this.props.config) {
        if (col.show) {
          cells.push(<React.Fragment key={col.name + '' + (tran == null ? 'null' : tran.id) + '-' + (op == null ? 'null' : op.id + "-" + i)}>{col.renderCells(tran, op, i, this.actions)}</React.Fragment>)
        }
      }

      return cells
    }

    var res = this.props.transactions.map((tran, i) => {

      if (tran.operations.length == 1) {
        let op = tran.operations[0];

        return (<tr className={classNames({ 'even': i % 2 === 0, 'expense': tran.amount < 0, 'income': tran.amount > 0, 'deleting': this.props.isDeletingTransactions.any(x => x === tran.id) })} key={tran.id} id={tran.id} >
          {renderCells(tran, op, i)}
        </tr>)
      }
      else {
        return (<React.Fragment key={tran.id}>
          <tr key={tran.id} className={classNames({ 'even': i % 2 === 0, 'expense': tran.amount < 0, 'income': tran.amount > 0, 'deleting': this.props.isDeletingTransactions.any(x => x === tran.id) })} >
            {renderCells(tran, null, i)}
          </tr>
          {tran.operations.map((op, j) => {
            return (<tr key={op.id} className={classNames({ 'even': i % 2 === 0, 'deleting': this.props.isDeletingTransactions.any(x => x === tran.id), 'matched': op.isMatched, 'not-matched': !op.isMatched })}>
              {renderCells(null, op, j)}
              <td></td>
            </tr>)
          })}
        </React.Fragment>)
      }
    });

    return res;
  }

  renderHeaders() {
    let headers = [];
    for (let col of this.props.config) {
      if (col.show) {
        headers.push(<React.Fragment key={col.name}>{col.renderHeader()}</React.Fragment>)
      }
    }

    return headers;
  }

  renderFooter() {
    if (this.props.config == null) return null;
    return <tr>
      {this.props.config.where(x => x.show).map(x => {
        if (x.name == 'row-number') return <React.Fragment key="row-number"><td key="row-number-1"></td><td key="row-number-2"></td></React.Fragment>
        if (x.name != 'amount') return <td key={x.name}></td>
        return <td key={x.name} className="amount">
          {this.renderSums()}
        </td>
      })}
    </tr>
  }

  renderSums() {
    if (this.props.transactions == null) return null;
    var transGroup = this.props.transactions.groupByCurrency();
    return transGroup.map(g => {
      let ops = g.values.selectMany(x => x.operations).where(x => x.isMatched);
      let badges = [<Badge key={g.key}><Number value={ops.sum(x => x.amount)} currency={g.key}></Number></Badge>];
      if (g.values.any(x => x.isPartOfTransfer)) {
        let transfers = g.values.where(x => x.isPartOfTransfer);
        badges.push(<Badge key={"transfers" + g.key}><span className="fa fa-exchange"></span> <Number value={transfers.sum(x => x.amount)} currency={g.key}></Number></Badge>)
      }
      if (g.values.any(x => !x.isPartOfTransfer && x.operations.length == 0)) {
        let adjustments = g.values.where(x => !x.isPartOfTransfer && x.operations.length);
        badges.push(<Badge key={"adjustments" + g.key}><span className="fa fa-wrench"></span> <Number value={adjustments.sum(x => x.amount)} currency={g.key}></Number></Badge>)
      }
      return badges;
    })
  }

}

export default TransactionsTable;