import React, { Component } from 'react';
import ControlledForm from '../common/controlled-form';
import Loader from '../common/loading-screen';
import Panel from '../common/panel';
import UserSettings from '../stores/user-settings-store';
import TransactionInput from '../transactions/transaction-input';
import './transaction-template-form.scss';
import Button, { Save, Cancel } from '../common/button';
import { validate, deepCopy } from '../../util';
import predef from '../../predef';
import * as yup from 'yup';
import TransactionTemplatesService from '../services/transaction-templates-service'
import { createHashHistory } from 'history';
import Json from '../common/json';
const history = createHashHistory();

const templateValiationSchema = yup.object().shape({
  name: yup.string().required().min(1)
});

class TransactionTemplateForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      templateFormValues: {
        name: null,
        category: null,
        currency: null
      },
      saving: false,
      isLoading: true,
      submitted: false,
      template: null,
      templateId: null
    }
  }

  onTemplateHeaderChange(v) {
    this.setState({ templateFormValues: v, errors: this.state.submitted ? validate(templateValiationSchema, v) : null });
  }

  onTemplateChanged(template) {
    this.setState({ template: template });
  }

  save() {
    let errors = validate(templateValiationSchema, this.state.templateFormValues);
    this.setState({ submitted: true, errors: errors }, () => {
      if (!this.state.errors.hasErrors) {
        let templateDesc = deepCopy(this.state.templateFormValues);
        templateDesc.template = JSON.stringify(this.state.template || null);
        this.setState({ saving: true }, () => {
          if (this.state.templateId != null) {
            templateDesc.id = this.state.templateId;
            TransactionTemplatesService.updateTemplate(templateDesc).then(x => history.push(predef.routes.transactionTemplate.index)).catch(e => this.setState({ saving: false }))
          }
          else {
            TransactionTemplatesService.addTemplate(templateDesc).then(x => history.push(predef.routes.transactionTemplate.index)).catch(e => this.setState({ saving: false }))
          }
        })

      }
    });
  }

  render() {
    return <div className="transaction-template-form-c">
      {this.state.isLoading ? <Loader></Loader> : <Panel><ControlledForm
        errors={this.state.errors}
        values={this.state.templateFormValues}
        onChange={(v) => this.onTemplateHeaderChange(v)}
        definition={{
          fields: [
            {
              type: 'text',
              name: 'name',
              label: 'transaction-template-form.name',
              errorMessage: 'common.field-is-required',
              layout: 'c c-sm-12  c-md-6 c-w-250'
            },
            {
              type: 'currency',
              name: 'currency',
              label: 'common.currency',
              isClearable: false,
              layout: 'c c-sm-12 c-md-4 c-w-120'
            },
            {
              type: 'text',
              name: 'category',
              label: 'transaction-template-form.category',
              errorMessage: 'common.field-is-required',
              layout: 'c c-sm-12 c-md-6 c-w-250'
            }
          ]
        }} ></ControlledForm>
        <TransactionInput transaction={this.state.template} templateMode onChange={template => this.onTemplateChanged(template)} ></TransactionInput>
        <div className="actions">
          <Cancel path={predef.routes.transactionTemplate.index}></Cancel>
          <Save width={14} loading={this.state.saving} className="save-btn" label="transaction-template-form.save-template" onClick={() => this.save()}></Save>
        </div>
      </Panel>}
    </div >
  }

  componentDidMount() {
    this.tsSub = UserSettings.defaultCurrency.subscribe(dc => {

      if (this.props.transactionTemplate != null) {
        this.setState({
          templateFormValues: {
            name: this.props.transactionTemplate.name,
            category: this.props.transactionTemplate.category,
            currency: this.props.transactionTemplate.currency
          },
          template: JSON.parse(this.props.transactionTemplate.template),
          templateId: this.props.transactionTemplate.id,
          isLoading: false
        })
      }
      else {
        let form = this.state.templateFormValues;
        form.currency = dc;
        this.setState({ defaultCurrency: dc, isLoading: false, templateFormValues: form })
      }
    });
  }

  componentWillUnmount() {
  }

}


TransactionTemplateForm.propTypes = {

}


export default TransactionTemplateForm;