import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button from '../common/button';
import Panel from '../common/panel';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { H3 } from '../common/headers';
import Badge from './badge';
import './tags.scss';
import { guid } from '../../util';

const Tags = (props) => {
  return <div className="tags-c">
    {props.tags?.map(({ text, ...rest }) => <Badge {...rest} key={rest.id || guid()}>{text}</Badge>)}
  </div>
}

Tags.propTypes = {

}


export default Tags;