import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button, { Cancel, Remove, Save, Edit } from '../common/button';
import Panel from '../common/panel';
import PropTypes from 'prop-types';
import Page from '../common/page';
import classNames from 'classnames/bind';
import { H3 } from '../common/headers';

import './note.scss';
import NoteForm from './note-form';
import Date from '../common/date';
import { timingSafeEqual } from 'crypto';
import Badge from '../common/badge';

class Note extends Component {

  constructor(props) {
    super(props);
    this.state = { mode: 'view' };
  }

  render() {
    return <Panel className="note-c">

      {this.state.mode == 'edit' ? <NoteForm noteRef={this.props.note.ref}
        refId={this.props.note.refId}
        note={this.props.note}
        onCancel={() => this.setState({ mode: 'view' })}
        onSave={() => this.setState({ mode: 'view' })}
      ></NoteForm> : this.renderView()}
    </Panel>
  }


  renderView() {
    return <div className="note">
      <div className="header">
        <div className="date">
          <Date date={this.props.note.date}></Date>
        </div>
        <div className="tags">
          {this.renderTags()}
        </div>
        <div className="edit">
          {this.state.mode == 'view' ? <Button link fa="edit" className="edit-btn" label="common.edit" onClick={() => this.setState({ mode: 'edit' })}></Button> : null}
        </div>
      </div>
      <div className="title">
        {this.props.note.title}
      </div>

      <div className="body" dangerouslySetInnerHTML={{ __html: this.props.note.body }}>
      </div>

    </div>
  }

  renderTags() {
    if (this.props.note.tags == null || this.props.note.tags == '') return null;

    let tags = this.props.note.tags.split(';');

    return tags.map(t => <Badge>{t}</Badge>)
  }
  componentDidMount() {
  }

  componentWillUnmount() {
  }

}


Note.propTypes = {
  note: PropTypes.object.isRequired
}


export default Note;