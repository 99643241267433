import classNames from 'classnames/bind';
import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import predef from '../../predef';
import Button from '../common/button';
import LoadingScreen from "../common/loading-screen";
import SideBarStateService from '../state-services/side-bar-state-service';
import AccountsStore from '../stores/accounts-store';
import IncomeStreamsStore from '../stores/income-streams-store';
import InvestmentsStore from '../stores/investments-store';
import RealEstatesStore from '../stores/real-estates-store';
import './side-bar.scss';
class SideBar extends Component {

  constructor(props) {
    super(props);
    this.state = { accounts: [], loading: true, showAccountType: null, ref: null };
    this.closeSideBar = this.closeSideBar.bind(this);
  }

  toggleFilter(ref, filter) {
    let newState = { ref: ref };
    switch (ref) {
      case 'accounts':
        newState.showAccountType = filter
    }
    this.setState(newState);
  }

  render() {
    return <div className="side-bar-c">
      {this.state.loading ? <LoadingScreen></LoadingScreen> : this.renderSideBar()}
    </div>
  }

  renderSideBar() {
    return <div>

      {this.state.accounts != null && this.state.accounts.length > 0 ? <div className="filter">
        <div className="left">
          <span onClick={() => this.toggleFilter('accounts', 0)} className={this.getClassNames("fa-infinity", this.state.ref == 'accounts' && this.state.showAccountType == 0)}></span>
          <span onClick={() => this.toggleFilter('accounts', 1)} className={this.getClassNames("fa-credit-card-blank", this.state.ref == 'accounts' && this.state.showAccountType == 1)}></span>
          <span onClick={() => this.toggleFilter('accounts', 3)} className={this.getClassNames("fa-wallet", this.state.ref == 'accounts' && this.state.showAccountType == 3)}></span>
          <span onClick={() => this.toggleFilter('accounts', 2)} className={this.getClassNames("fa-piggy-bank", this.state.ref == 'accounts' && this.state.showAccountType == 2)}></span>
          <span onClick={() => this.toggleFilter('accounts', 4)} className={this.getClassNames("fa-credit-card", this.state.ref == 'accounts' && this.state.showAccountType == 4)}></span>
        </div>
        <div className="right">
          <span onClick={() => this.toggleFilter('incomeStreams')} className={this.getClassNames("fa-chart-line", this.state.ref == 'incomeStreams')}></span>
          <span onClick={() => this.toggleFilter('realEstates')} className={this.getClassNames("fa-home", this.state.ref == 'realEstates')}></span>

        </div>

      </div> : null}
      {this.renderSideBarBody()}
    </div>
  }

  getClassNames(icon, isActive) {
    return classNames('far', icon, { "active": isActive });
  }

  renderSideBarBody() {
    switch (this.state.ref) {
      case 'accounts':
        return <div>

          {this.renderAccountsCurrencyGroups()}
          {/* <Message>
            <FormattedMessage id="side-bar.not-all-accounts-are-visible"></FormattedMessage>
            <Button link label="side-bar.change-account-settings"></Button>
          </Message> */}
          <Button outline className="add-account-button" label="shell.add-account" fa="plus" path={predef.routes.accounts.add(this.state.showAccountType)}></Button>
        </div>
      case 'incomeStreams':
        return this.renderIncomeStreams();
      case 'realEstates':
        return this.renderRealEstates();
      case 'investments':
        return this.renderInvestments();
    }
  }

  renderIncomeStreams() {
    return <div className="body">
      {this.state.incomeStreams == null ? <LoadingScreen></LoadingScreen> : this.state.incomeStreams.map(is => {
        return <div className="side-bar-item" key={is.id}>
          <NavLink activeClassName="active" to={predef.routes.incomeStreams.dashboard(is.id)}>{is.name}</NavLink></div>
      })}
      {/* <Button className="manage-button" path={predef.routes.incomeStreams.index} fal="cog" outline label="side-bar.manage-income-stream"></Button> */}
    </div>
  }


  renderRealEstates() {
    return <div className="body">{this.state.realEstates == null ? <LoadingScreen></LoadingScreen> :
      this.state.realEstates.map(re => {
        return <div className="side-bar-item" key={re.id}>
          <NavLink activeClassName="active" to={predef.routes.realEstates.dashboard(re.id)}>{re.name}</NavLink></div>
      })}</div>
  }

  renderAccounts(accounts) {
    return accounts.map(a => {
      return (<div className="side-bar-item" key={a.id}>
        <NavLink activeClassName="active" to={predef.routes.accounts.view(a.id)}>{a.name}</NavLink></div>)
    });
  }

  renderInvestments() {
    return <div>
      <div className="body">{this.state.investments == null ? <LoadingScreen></LoadingScreen> :
        this.state.investments.map(inv => {
          return <div className="side-bar-item" key={inv.id}>
            <NavLink activeClassName="active" to={predef.routes.investments.dashboard(inv.id)}>{inv.name}</NavLink></div>
        })}</div>
      <Button outline className="add-account-button" label="shell.add-investment" fa="plus" path={predef.routes.investments.add}></Button>
    </div>

  }

  renderAccountsCurrencyGroups() {
    let acs = this.state.accounts.where(x => (x.type == this.state.showAccountType || this.state.showAccountType == 0) && x.showOnSidebar).groupByCurrency().map(acs => {
      return (
        <div className="currency-group" key={acs.key}>
          <div className="currency-header">{acs.key}</div>
          <div className="accounts">
            {this.renderAccounts(acs.values)}
          </div>
        </div>
      );
    });

    return <div className="accounts">{acs}</div>;
  }

  ensureFilter() {
    if (this.state.ref != null) return;

    this.setFilter();
  }

  setFilter() {
    var path = this.props.location.pathname;
    if (path.startsWith('/account/')) {
      if (this.state.accounts == null || this.state.accounts.length === 0) return;
      let id = path.substring(9);
      let account = this.state.accounts.single(x => x.id == id);

      if (this.state.ref == 'accounts' && this.state.showAccountType === account.type.toString()) return;
      if (this.state.ref == 'accounts' && this.state.showAccountType === 0) return;

      this.setState({ showAccountType: account.type.toString(), ref: 'accounts' });
      return;
    }


    if (path.startsWith('/incomestream/')) {
      this.setState({ ref: 'incomeStreams' });
      return;
    }

    if (path.startsWith('/realestate/')) {
      this.setState({ ref: 'realEstates' });
      return;
    }

    if (path.startsWith('/investment/')) {
      this.setState({ ref: 'investments' });
      return;
    }

    if (this.state.accounts != null) {
      this.setState({ ref: 'accounts', showAccountType: 0 });
      return;
    }

  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname != this.props.location.pathname) {
      this.setFilter();
    }
  }

  componentDidMount() {
    $(document).bind('click', (e) => {
      if (e.target != null && $('.side').length > 0) {
        if ($.contains($('.side')[0], e.target)) {
          return;
        }
        SideBarStateService.closeUnpinned();
      }
    });

    this.accountsSub = AccountsStore.accounts.subscribe(acs => {
      this.setState({ accounts: acs == null ? [] : acs, loading: acs == null }, () => this.ensureFilter());
    });

    this.incomeStreamsSub = IncomeStreamsStore.incomeStreams.subscribe(x => {
      this.setState({ incomeStreams: x }, () => this.ensureFilter());
    });

    this.investmentsSub = InvestmentsStore.investments.subscribe(invs => this.setState({
      investments: invs
    }, () => this.ensureFilter()));

    this.realEstatesSub = RealEstatesStore.realEstates.subscribe(x => {
      this.setState({ realEstates: x }, () => this.ensureFilter());
    });
  }

  closeSideBar(e) {
    SideBarStateService.closeUnpinned()
  }

  componentWillUnmount() {
    this.accountsSub.unsubscribe();
    this.incomeStreamsSub.unsubscribe();
    this.realEstatesSub.unsubscribe();
  }

}

export default withRouter(SideBar);