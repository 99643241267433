import Service from './service';
import predef from '../../predef';

class TransactionTemplatesService extends Service {
    addTemplate(template) {
       return this.put(predef.endpoints.transactionTemplate.index
            , template
            , predef.events.transactionTemplate.transactionTemplatesChanged
            , template
            , 'transaction-templates-service.add-success'
            , 'transaction-templates-service.add-error'
        );
    }

    updateTemplate(template) {
        return this.post(predef.endpoints.transactionTemplate.index
            , template
            , predef.events.transactionTemplate.transactionTemplatesChanged
            , template
            , 'transaction-templates-service.update-success'
            , 'transaction-templates-service.update-error'
        );
    }

    deleteTemplate(id) {
        return this.delete(predef.endpoints.transactionTemplate.indexId(id)
            , predef.events.transactionTemplate.transactionTemplatesChanged
            , id
            , 'transaction-templates-service.delete-success'
            , 'transaction-templates-service.delete-error');
    }
}


let instance = new TransactionTemplatesService();
export default instance;
