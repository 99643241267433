import React, { Component } from 'react';
import './unavailable.scss';
import { H1 } from '../common/headers';
import Button from '../common/button';

class Unavailable extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="unavailable-c">
        <H1 header="unavailable.header"></H1>
        <div className="try-again">
          <Button path="/" label="unavailable.try-again"></Button>
        </div>
      </div>
    );
  }

}

export default Unavailable;