import React, { Component } from 'react';
import TagsInput from '../common/tags-input';
import './categories-select.scss';
import CategoryStore from '../stores/categories-store'

class CategoriesSelect extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let {placeholder, ...rest} = this.props;
    return <div className="categories-select-c">
      <TagsInput  {...rest} placeholder={placeholder || 'categories-select.add-category'}  options={this.state.options} ></TagsInput>
    </div>
  }


  componentDidMount() {
    this.sub = CategoryStore.categories.subscribe(cs => {
      if (cs == null) return;
      this.setState({ categories: cs, options: cs.where(x => this.props.expense == true ? x.type == 1 : x.type == 2).select(x=>x.path) })
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.expense != this.props.expense && this.state.options != null){
      this.setState({options: this.state.categories.where(x => this.props.expense == true ? x.type == 1 : x.type == 2).select(x=>x.path) });
    }
  }

  componentWillUnmount() {
    this.sub.unsubscribe();
  }
}


CategoriesSelect.propTypes = {

}


export default CategoriesSelect;