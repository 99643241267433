import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { createHashHistory } from 'history';
const history = createHashHistory();
import Bus from '../../bus';
import classNames from 'classnames/bind';
import './button.scss';
import PropTypes from 'prop-types';
import api from '../../api';

class Button extends Component {

  constructor(props) {
    super(props);
    this.classes = this.classes.bind(this);
    this.onClick = this.onClick.bind(this);
    this.state = { isSubmitting: false, loading: false };
  }

  onClick() {
    if (this.state.isSubmitting || this.props.loading) return;

    this.setState({ isSubmitting: true }, () => {
      if (this.props.command != null) {
        this.props.command.command()
          .then(() => {
            this.setState({ isSubmitting: false });
            if (this.props.command.successEvent != null) {
              Bus.publish(this.props.command.successEvent)
            }
          })
          .catch(() => this.setState({ isSubmitting: false }));
      }

      if (this.props.onClick != null && typeof (this.props.onClick) === 'function') {
        let res = this.props.onClick();
        Promise.resolve(res).then(() => {
          if (!this.isUnmounted)
            this.setState({ isSubmitting: false });
        });
        return;
      }

      if (this.props.path != null) {
        this.setState({ isSubmitting: false });
        history.push(this.props.path);
      }

      if (this.props.documentId != null) {
        api.downloadFile(this.props.documentId, this.props.documentName || this.props.documentId);
      }

      if (this.props.fileUrl != null) {
        api.downloadFile(this.props.fileUrl, this.props.fileName);
      }

    });
  }

  classes() {
    let cs = this.props.link ? 'button-link-c ' : 'btn button-c ';
    let variant = 'primary';

    if (this.props.success) variant = 'success';
    if (this.props.secondary) variant = 'secondary';
    if (this.props.danger) variant = 'danger';
    if (this.props.warning) variant = 'warning';
    if (this.props.info) variant = 'info';
    if (this.props.light) variant = 'light';
    if (this.props.dark) variant = 'dark';

    if (!this.props.link) {
      if (!this.props.outline) {
        cs += "btn-" + variant;
      } else {
        cs += "btn-outline-" + variant;
      }
    }
    else {
      cs += 'btn-link-' + variant;
    }

    if (this.props.emphasis) {
      cs += ' em';
    }

    if (this.props.className == null) {
      return cs;
    }

    return cs + " " + this.props.className;
  }

  render() {
    return !this.props.link ? this.renderButton() : this.renderLink();
  }

  renderLink() {
    return (
      <a onClick={this.onClick} className={this.classes()}>
        <span>
          {
            (this.props.fa == null && this.props.fad == null && this.props.fal == null && this.props.far == null && this.props.fas == null && this.props.fab == null) || this.props.loading ? null : <span className={this.getIconClasses()}></span>
          }
          {
            this.props.label == null || this.props.loading ? this.props.children : <FormattedMessage id={this.props.label} values={this.props.labelValues}></FormattedMessage>
          }
          {
            this.props.loading == true ? <span className="fas fa-cog fa-spin"></span> : null
          }
        </span>
      </a>
    );
  }

  renderButton() {
    let style = this.props.style || {};
    if (this.props.width != null) {
      style.minWidth = this.props.width + "rem";
    }
    return (
      <button disabled={this.props.disabled} style={style} type={this.props.submit === true ? 'submit' : 'button'} className={this.classes()} onClick={this.onClick}>
        {
          (this.props.fa == null && this.props.fal == null && this.props.far && this.props.fas && this.props.fab == null) || this.props.loading ? null : <span className={this.getIconClasses()}></span>
        }
        {
          !this.props.loading ?
            this.props.label == null ? this.props.children : <FormattedMessage id={this.props.label} values={this.props.labelValues}></FormattedMessage>
            : null
        }
        {
          this.props.loading == true ? <span className="fas fa-cog fa-spin"></span> : null
        }
      </button>
    );
  }

  getIconClasses() {
    let classes = "ico";
    if (this.props.label == null && this.props.children == null) classes += " solo";
    if (this.props.fa != null) classes += " fa fa-" + this.props.fa;
    if (this.props.fal != null) classes += " fal fa-" + this.props.fal;
    if (this.props.fas != null) classes += " fas fa-" + this.props.fas;
    if (this.props.far != null) classes += " far fa-" + this.props.far;
    if (this.props.fab != null) classes += " fab fa-" + this.props.fab;
    if (this.props.fad != null) classes += " fad fa-" + this.props.fad;
    return classes;
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

}

Button.propTypes = {
  commoand: PropTypes.shape({
    command: PropTypes.func,
    successEvent: PropTypes.string
  })
}

export default Button;


export const Cancel = ({ label, ...rest }) => <Button link className="cancel-btn" label={label || "common.cancel"} fa="arrow-left" {...rest}></Button>

export const Remove = (props) => <Button danger link label="common.remove" {...props}></Button>

export const Edit = (props) => <Button link label="common.edit" {...props}></Button>

export const Save = (props) => <Button fal="save" width={8.5} label={props.label || 'common.save'} {...props}></Button>

export const Add = (props) => <Button outline fa="plus" label={props.label || 'common.add'} {...props}></Button>


export const ToggleLink = ({ turnOnLabel, turnOffLabel, onToggle, isOn, ...rest }) => {
  return (isOn ?
    <Button link label={turnOnLabel} onClick={() => onToggle(false)} {...rest}></Button> :
    <Button link label={turnOffLabel} onClick={() => onToggle(true)}  {...rest}></Button>)
}