import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import BarChart from '../common/charts/bar-chart';
import ChartColor from '../common/charts/chart-color';
import Json from '../common/json';
import Loader from '../common/loader';
import Panel from '../common/panel';
import Localization from '../services/localization-service';
import Report from '../services/report-service';
import './saving-factor-report.scss';
import ChartFuncs from '../common/charts/chart-funcs';
import CurrencyTabs from '../common/currency-tabs';
import LoadingScreen from '../common/loading-screen';
import MonthsRangeInput from '../common/months-range-input';
import Calendar from '../services/calendar-service';
import { PanelMessage } from '../common/message'

class SavingFactorReport extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      dateRange: Calendar.last12MonthsRange
    };
  }

  render() {
    return (
      <div className="saving-factor-report-c">
        <h2><FormattedMessage id="saving-factor-report.header"></FormattedMessage></h2>
        <Panel>
          <MonthsRangeInput value={this.state.dateRange} onChange={(v) => this.setState({ dateRange: v }, () => this.reloadReport())}></MonthsRangeInput>
        </Panel>
        {this.state.loading ? <LoadingScreen ></LoadingScreen> : <div>
          {this.renderReports()}</div>}
      </div>
    );
  }

  renderReports() {
    if (this.state.data.currencyItems.length == 0) return <PanelMessage message="common.no-data"></PanelMessage>;


    return <CurrencyTabs currencies={this.state.data.currencyItems} renderTab={(currency, isBaseCurrency) => {
      let activeCurrencyItem = this.state.data.currencyItems.single(x => x.currency == currency && x.isBaseCurrency == isBaseCurrency);
      let items = activeCurrencyItem.items;
      let chartDefinition = {
        x: items.select(x => x.date),
        timeSeries: true,
        formatY: (v) => v + "%",
        serie: {
          y: items.select(y => y.value),
          barLabelFormatter: (v) => v.value + "%",
          itemStyle: ChartFuncs.valueBasedColors,
        },
        height: 600,
        color: ChartColor.blue.gradient.leftRight,
        downloadFileName: "saving-factor-report.download-file-name"
      }

      return <BarChart showLabels={true} definition={chartDefinition} key={(isBaseCurrency ? "~" : "") + currency}></BarChart>
    }}></CurrencyTabs>
  }

  reloadReport() {
    this.setState({ loading: true }, () => {
      Report.savingFactor(this.state.dateRange).then((res) => {
        this.setState({
          data: res
          , loading: false
          , activeCurrency: this.state.activeCurrency || (res.currencyItems.length > 0 ? res.currencyItems[0].currency : null)
        })
      });
    });
  }

  componentDidMount() {
    this.reloadReport();
    this.busSub = Bus.subscribe(this);
  }

  componentWillUnmount() {
    this.busSub.unsubscribe();
  }

  _onLanguageChanged() {
    this.reloadReport();
  }

}


SavingFactorReport.propTypes = {

}


export default SavingFactorReport;