import React, { Component } from 'react';
import { BehaviorSubject } from 'rxjs';
import api from '../../../api';
import Bus from '../../../bus';
import { deepEqual } from '../../../util';
import LoadingPlaceholder from '../loading-placeholder';
import ControlledGrid from './controlled-grid';
import './paged-grid.scss';

export class PagedDataProvider {
  constructor(filter, paging, sorting, columnsConfig, endpoint) {
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onPagingChange = this.onPagingChange.bind(this);
    this.onSortingChange = this.onSortingChange.bind(this);
    
    this.currentState = {
      filter: filter || {},
      paging: paging || { page: 1, pageSize: 50 },
      totalRows: 0,
      columnsConfig: columnsConfig,
      currentPage: [],
      isLoading: true,
      firstLoad: true,
      sorting: sorting || {}
    }
    this.endpoint = endpoint;
    this.stateSubject = new BehaviorSubject(this.currentState);

    this.update()
  }

  get state() {
    return this.stateSubject.asObservable();
  }

  onPagingChange(paging) {
    this.updateState({ paging: paging });
  }

  onSortingChange(sorting) {
    this.updateState({ sorting: sorting });
  }

  onFilterChange(filter) {
    if (!deepEqual(filter, this.currentState.filter)) {
      this.updateState({ filter: filter });
    }
  }

  update(force) {
    let query = {
      filter: this.currentState.filter || {},
      paging: this.currentState.paging,
      sorting: this.currentState.sorting
    };
    if (deepEqual(query, this.lastQuery) && !force) return;
    this.lastQuery = query;
    this.updateState({ isLoading: true });
    api.post(this.endpoint, query).then((data) => {
      this.updateState({ isLoading: false, firstLoad: false, currentPage: data.items, totalRows: data.totalItems })
    });
  }

  updateState(state) {
    for (let key in state) {
      this.currentState[key] = state[key];
    }
    this.stateSubject.next(this.currentState);
    if (state.filter != null || state.paging != null || state.sorting != null) {
      this.update();
    }
  }
}

class PagedGrid extends Component {

  constructor(props) {
    super(props);
    this.state = { filter: null };
  }

  componentDidUpdate(prevProps, prevState) {
    this.state.dataProvider.onFilterChange(this.props.filter);
  }

  render() {
    return <div className="paged-grid-c">
      {this.state.dataProvider != null ? this.renderGrid() : null}
    </div>
  }

  renderGrid() {
    return this.state.state?.isLoading && this.state.state?.firstLoad ? <LoadingPlaceholder></LoadingPlaceholder> : <ControlledGrid
      id={this.props.id}
      definition={this.props.definition}

      data={this.state.state?.currentPage}
      totalRows={this.state.state?.totalRows}
      loading={this.state.state?.isLoading}

      paging={this.state.state?.paging}
      sorting={this.state.state?.sorting}

      onSortingChange={(sorting) => this.state.dataProvider?.onSortingChange(sorting)}
      onPagingChange={this.state.dataProvider?.onPagingChange}

      allowColumnConfigChange={this.props.allowColumnConfigChange}
      tableStriped={this.props.tableStriped}
      renderTotalRow={this.props.renderTotalRow}
      autoWidth={this.props.autoWidth}

    ></ControlledGrid>
  }

  componentDidMount() {

    this.setState({
      dataProvider: new PagedDataProvider(null, null, this.props.sorting, null, this.props.endpoint), filter: this.props.filter
    }, () => {
      if (this.props.fetchDataOn != null) {
        this.busSub = Bus.subscribeTo(this.props.fetchDataOn, () => this.state.dataProvider.update(true));
      }
      this.state.dataProvider.state.subscribe(s => this.setState({ state: s }))
    });
  }

}



export default PagedGrid;