import Service from './service';
import predef from '../../predef';
import Cache from '../../cache';
import Bus from '../../bus';

class KvService extends Service {
    constructor() {
        super();
        Bus.subscribeTo([predef.events.cashFlowMatrixReportConfig.cashFlowMatrixReportConfigChanged], (key) => this.cache.invalidate(key));
        this.cache = new Cache([]);
    }

    getValue(key) {
        let val = this.cache.tryGet(key, () => this.get(predef.endpoints.kv.index(key)));
        return new Promise((resolve, reject) => {
            val.then(v => resolve(v == null ? null : JSON.parse(v.value))).catch(e => reject(e));
        });
    }

    save(key, value, event, successMessage, errorMessage) {
        return this.put(predef.endpoints.kv.index(key)
            , {value: JSON.stringify(value)}
            , event
            , key
            , successMessage
            , errorMessage);
    }
}


let instance = new KvService();
export default instance;
