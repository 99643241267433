import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Bus from '../../bus';
import { guid } from '../../util';
import Calendar, { IsoCalendar } from '../services/calendar-service';
import Localization from '../services/localization-service';
import './inline-year-input.scss';

class InlineYearInput extends Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.id = 'imi-' + guid();
  }

  render() {
    return <div className="inline-year-input-c">
      <div id={this.id}></div>
    </div>
  }

  get $() {
    return $('#' + this.id);
  }

  componentDidMount() {
    this.busSub = Bus.subscribe(this);
    let date = this.props.value != null ? `${this.props.value}-01-01` : null;
    this.createDatePicker(date, true)
  }

  removeOnChange() {
    this.$.datepicker().off('changeDate');
  }

  addOnChange() {
    this.$.datepicker().on('changeDate', () => this.onChange())
  }
  createDatePicker(date, noOnChange) {
    this.$.datepicker({
      language: Localization.lang,
      format: Localization.formatting.datePicker.monthAndYearFormat,
      minViewMode: 2,
    });
    this.addOnChange();
    this.setDate(date, noOnChange);
  }


  componentWillUnmount() {
    this.busSub.unsubscribe();

  }

  _onLanguageChanged(lang) {
    let date = this.getDate();
    this.$.datepicker('destroy');
    this.createDatePicker(date, true);
  }

  getDate() {
    return this.$.datepicker('getDate');
  }


  onChange() {
    let selectedDate = this.getDate();
    if (selectedDate == null) {
      if (this.props.value == null) {
        return;
      }
      else {
        this.props.onChange(null)
        return;
      }
    }

    let date = Calendar.toIsoDate(this.getDate(), null);
    let year = IsoCalendar.getYear(date)
    if (this.props.value == year) return;

    this.props.onChange(year);
  }

  setDate(date, noOnChange) {
    if (typeof date === 'string') {
      date = Calendar.fromIsoDate(date);
    }

    if (noOnChange) {
      this.removeOnChange();
    }
    this.$.datepicker('setDate', date);
    if (noOnChange) {
      this.addOnChange();
    }
  }

}


InlineYearInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func
}


export default InlineYearInput;