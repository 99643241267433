import predef from '../../predef';
import Store from './store';

class DocumentsStore {
    constructor() {
        this.documentsStore = new Store(predef.endpoints.document.index,
            [predef.events.document.documentsChanged], predef.events.document.documentsStoreUpdated);

    }

    get documents() {
        return this.documentsStore.asObservable();
    }

    byId(id){
        return new Promise((resolve, _)=> {
            this.documents.subscribe(entities => {
                if (entities == null) return;

                resolve(entities.single(x=>x.id == id));
            });
        })
    }


}

const instance = new  DocumentsStore();
export default instance;
