import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import TransactionsStateService from '../state-services/transactions-state-service';
import ControlledTransactionList from './controlled-transaction-list';
import './transactions.scss';

class Transactions extends Component {

  constructor(props) {
    super(props);
    this.state = { viewState: null }
  }

  render() {
    return (
      <div className="transactions-c">
        <h2><FormattedMessage id="transactions.header"></FormattedMessage></h2>
        {this.state.viewState != null ?
          <ControlledTransactionList
            onViewStateChanged={(vs) => TransactionsStateService.updateState(vs)}
            viewState={this.state.viewState}
            showCurrency={true}
            showAccountColumn={true}
            onTransactionDelete={(tran)=>TransactionsStateService.viewStateProvider.onTransactionDelete(tran)}
            onUpdateTransactionDate={(id,date)=>TransactionsStateService.viewStateProvider.onUpdateTransactionDate(id,date)}
          ></ControlledTransactionList> : null}
      </div>
    );
  }

  componentDidMount() {
    this.sub = TransactionsStateService.viewState.subscribe(vs => {
      this.setState({ viewState: vs })
    });
  }

  componentWillUnmount() {
    this.sub.unsubscribe();
    TransactionsStateService.pauseUpdates();
  }


}



export default Transactions;