import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { routeParamId } from '../../util';
import BarChart from '../common/charts/bar-chart';
import ChartColor from '../common/charts/chart-color';
import ChartFuncs from '../common/charts/chart-funcs';
import CurrencyTabs from '../common/currency-tabs';
import { H3 } from '../common/headers';
import LoadingPlaceholder from '../common/loading-placeholder';
import { NoDataMessage } from '../common/message';
import MonthsRangeInput from '../common/months-range-input';
import Panel from '../common/panel';
import Calendar from '../services/calendar-service';
import ReportService from '../services/report-service';
import IncomeStreamsStore from '../stores/income-streams-store';
import './income-stream-indicators-over-time-report.scss';

class IncomeStreamIndicatorsOverTimeReport extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cashflowReportLoading: true
      , structureReportLoading: true
      , structureReport: null
      , cashFlowReport: null
      , year: Calendar.currentYear
      , cashflowReportDatesRange: Calendar.last12MonthsRange
      , structureReportDatesRange: Calendar.last12MonthsRange
    };
  }

  render() {
    return <div className="income-stream-indicators-over-time-report-c">{this.renderBody()}</div>
  }

  renderBody() {

    if (this.state.structureReport == null || this.state.structureReport.length == 0) return <NoDataMessage></NoDataMessage>
    return <div>
      <div className="report">
        <H3 header="income-stream-indicators-over-time-report.income-expenses-structure"></H3>
        <Panel>
          <MonthsRangeInput value={this.state.structureReportDatesRange} onChange={(v) => this.setState({ structureReportDatesRange: v }, () => this.reloadStructureReport())}></MonthsRangeInput>
        </Panel>
        {this.state.structureReportLoading ? <LoadingPlaceholder></LoadingPlaceholder> : <CurrencyTabs currencies={this.state.structureReport} renderTab={(currency, isBaseCurrency) => this.renderStructureReport(currency, isBaseCurrency)}></CurrencyTabs>}
      </div>
      <div className="report">
        <H3 header="common.cash-flow"></H3>
        <Panel>
          <MonthsRangeInput value={this.state.cashflowReportDatesRange} onChange={(v) => this.setState({ cashflowReportDatesRange: v }, () => this.reloadCashFlowReport())}></MonthsRangeInput>
        </Panel>
        {this.state.cashflowReportLoading ? <LoadingPlaceholder></LoadingPlaceholder> : <CurrencyTabs currencies={this.state.cashFlowReport} renderTab={(currency, isBaseCurrency) => this.renderCashFlowReport(currency, isBaseCurrency)}></CurrencyTabs>}
      </div>
    </div>
  }

  renderStructureReport(currency, isBaseCurrency) {
    var items = this.state.structureReport.single(x => x.currency == currency && x.isBaseCurrency == isBaseCurrency).items;
    let structureSeries = [
      {
        y: items.select(y => y.value.income),
        itemStyle: (v) => ChartColor.green.solid,
        name: 'common.income',
        color: ChartColor.green.standard
      },

      {
        y: items.select(y => y.value.reimbursements),
        itemStyle: (v) => ChartColor.blue.solid,
        name: 'common.reimbursements',
        color: ChartColor.blue.standard
      },
      {
        y: items.select(y => y.value.incomeCosts),
        itemStyle: (v) => ChartColor.violet.solid,
        name: 'common.income-cost',
        color: ChartColor.violet.standard
      },
      {
        y: items.select(y => y.value.reimbursables),
        itemStyle: (v) => ChartColor.yellow.solid,
        name: 'common.reimbursables',
        color: ChartColor.yellow.standard
      },
      {
        y: items.select(y => y.value.expenses),
        itemStyle: (v) => ChartColor.red.solid,
        name: 'common.expenses',
        color: ChartColor.red.standard
      },
    ];

    let chartDefinition = {
      showIndicators: true,
      x: items.select(x => x.date),
      timeSeries: true,
      height: 450,
      color: ChartColor.blue.gradient.leftRight,
      series: structureSeries,
      showLegend: true,
      override: {
        legend: {
          top: 35,
          left: 25
        },
        grid: {
          top: 100,
        }
      },
      downloadFileName: "income-stream-indicators-over-time-report.income-expenses-structure-download-file-name",
      downloadFileNameValues: { name: this.state.incomeStream.name }
    }
    return <div key={(isBaseCurrency ? "~" : "") + currency}>

      <BarChart definition={chartDefinition} ></BarChart>

    </div>
  }

  renderCashFlowReport(currency, isBaseCurrency) {
    var items = this.state.cashFlowReport.single(x => x.currency == currency && x.isBaseCurrency == isBaseCurrency).items;

    let chartDefinition = {
      showIndicators: true,
      x: items.select(x => x.date),
      timeSeries: true,
      height: 450,
      color: ChartColor.blue.gradient.leftRight,
      series: [
        {
          y: items.select(y => y.value.cashFlow),
          itemStyle: (v) => ChartColor.green.solid,
          name: 'common.cash-flow',
          itemStyle: ChartFuncs.valueBasedColors
        }
      ],
      downloadFileName: "income-stream-indicators-over-time-report.cashflow-download-file-name",
      downloadFileNameValues: { name: this.state.incomeStream.name }
    }

    return <div key={(isBaseCurrency ? "~" : "") + currency}>
      <BarChart definition={chartDefinition} ></BarChart>
    </div>
  }

  componentDidMount() {
    let id = routeParamId(this.props);
    IncomeStreamsStore.byId(id).then(is => {
      this.setState({ incomeStream: is }, () => {
        ReportService.incomeStreamIndicatorsOverTime(this.state.structureReportDatesRange, this.props.incomeStreamId)
          .then(x => this.setState({ structureReportLoading: false, cashflowReportLoading: false, cashFlowReport: x, structureReport: x }))
          .catch(x => this.setState({ cashflowReportLoading: false, structureReportLoading: false, }))
      })
    });
  }

  reloadCashFlowReport() {
    this.setState({ cashflowReportLoading: true }, () => {
      ReportService.incomeStreamIndicatorsOverTime(this.state.cashflowReportDatesRange, this.props.incomeStreamId)
        .then(x => this.setState({ cashflowReportLoading: false, cashFlowReport: x }))
        .catch(x => this.setState({ cashflowReportLoading: false }))
    });
  }

  reloadStructureReport() {
    this.setState({ structureReportLoading: true }, () => {
      ReportService.incomeStreamIndicatorsOverTime(this.state.structureReportDatesRange, this.props.incomeStreamId)
        .then(x => this.setState({ structureReportLoading: false, structureReport: x }))
        .catch(x => this.setState({ structureReportLoading: false }))
    })
  }

}

export default withRouter(IncomeStreamIndicatorsOverTimeReport);