import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Bus from '../../bus';
import { guid } from '../../util';
import Calendar, { IsoCalendar } from '../services/calendar-service';
import Localization from '../services/localization-service';

import './inline-date-input.scss';



class InlineDateInput extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.id = 'imi-' + guid();
    }

    render() {
        return <div className="inline-date-input-c">
            <div id={this.id}></div>
        </div>
    }

    get $() {
        return $('#' + this.id);
    }

    componentDidMount() {
        this.busSub = Bus.subscribe(this);
        this.createDatePicker(this.props.value)
    }

    removeOnChange() {
        this.$.datepicker().off('changeDate');
    }

    addOnChange() {
        this.$.datepicker().on('changeDate', () => this.onChange())
    }
    createDatePicker(date, noOnChange) {
        this.$.datepicker({
            language: Localization.lang,
            format: Localization.formatting.datePicker.monthAndYearFormat,
            minViewMode: 0,
        });
        this.addOnChange();
        this.setDate(date, noOnChange);
    }


    componentWillUnmount() {
        this.busSub.unsubscribe();

    }

    _onLanguageChanged(lang) {
        let date = this.getDate();
        this.$.datepicker('destroy');
        this.createDatePicker(date, true);
    }

    getDate() {
        return this.$.datepicker('getDate');
    }


    onChange() {
        let date = Calendar.toIsoDate(this.getDate(), null);
        if (this.props.value === date) return;

        this.props.onChange(date);
    }

    setDate(date, noOnChange) {
        if (typeof date === 'string') {
            date = Calendar.fromIsoDate(date);
        }

        if (noOnChange) {
            this.removeOnChange();
        }
        this.$.datepicker('setDate', date);
        if (noOnChange) {
            this.addOnChange();
        }
    }

}


InlineDateInput.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func
}


export default InlineDateInput;