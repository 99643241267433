import Service from './service';
import predef from '../../predef';

class NotesService extends Service {
    addNote(note) {
        return this.put(
            predef.endpoints.note.index
            , note
            , predef.events.note.notesChanged
            , { ref: note.ref, refId: note.refId }
            , 'notes-service.note-adding-success'
            , 'notes-service.note-adding-error')
    }

    deleteNote(note) {
        return this.delete(
            predef.endpoints.note.indexId(note.id)
            , predef.events.note.notesChanged
            , { ref: note.ref, refId: note.refId }
            , 'notes-service.note-deleting-success'
            , 'notes-service.note-deleting-error'
        )
    }

    updateNote(note) {
        return this.post(
            predef.endpoints.note.index
            , note
            , predef.events.note.notesChanged
            , { ref: note.ref, refId: note.refId }
            , 'notes-service.note-update-success'
            , 'notes-service.note-update-error')
    }

    getNotes(ref, refId) {
        return this.post(predef.endpoints.note.getNotes(ref, refId));
    }
}


let instance = new NotesService();
export default instance;
