import Localization from './localization-service';
import toastr from 'toastr'

class ToastrService {
    error(messageId,values) {
        toastr.error(Localization.formatMessage(messageId,values));
    }

    success(messageId,values) {
        toastr.success(Localization.formatMessage(messageId,values));
    }
    warning(messageId,values) {
        toastr.warning(Localization.formatMessage(messageId,values));
    }
}
const instance = new ToastrService();
export default instance;
