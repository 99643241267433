import React, { Component } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import * as yup from 'yup';
import './register.scss';
import ControlledForm from '../common/controlled-form';
import Button from '../common/button';
import LanguageSelector from '../common/language-selector';
import ReCAPTCHA from "react-google-recaptcha";
import Bus from '../../bus';
import predef from '../../predef';
import Error from '../common/error';
import { deepEqual, validate } from '../../util';
import Json from '../common/json';
import Authentication from '../services/authentication-service';

export const registerValidationSchema = yup.object().shape({
  login: yup.string()
    .min(6)
    .required('common.field-is-required'),
  password: yup.string()
    .required('common.field-is-required'),
  aggreedToTermsAndConditions: yup.bool().test('aggreeToTaC', 'register.no-agreement-error', value => value === true)
})


class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tacUrls: {
        toc: '#/toc',
        pp: '#/pp'
      },
      reCaptchaError: false,
      reCaptchaToken: null,
      loading: false,
      hasRegistered: false,
      error: false,
      errorText: null,
      submitted: false,
      errors: {}
    };
  }

  submitForm() {
    this.setState({ submitted: true }, () => {
      this.validateReCaptcha();
      var result = this.valiateForm();
      if (!deepEqual(result, {}) || this.state.reCaptchaError) return;

      this.state.values.reCaptchaToken = this.state.reCaptchaToken;

      this.setState({loading: true},()=> {
        Authentication.register(this.state.values).then(() => {
          this.setState({ hasRegistered: true, loading: false })
        }).catch(() => {
          this.setState({ error: true, errorText: 'register.error', loading: false });
        });
      })

    });
  }

  validateReCaptcha() {
    this.setState({ reCaptchaError: this.state.reCaptchaToken == null || this.state.reCaptchaToken == '' });
  }

  onChange(token) {
    this.setState({ reCaptchaToken: token }, () => {
      this.validateReCaptcha();
    });
  }

  valiateForm() {
    let result = {};
    if (!this.state.submitted) {
      this.setState({ errors: result });
      return result;
    };

    result = validate(registerValidationSchema, this.state.values);
    this.setState({ errors: result });

    return result;
  }

  render() {
    let form = {
      fields: [
        {
          type: 'text',
          name: 'login',
          label: 'register.login',
          errorMessage: 'register.invalid-login-error',
        },
        {
          type: 'password',
          name: 'password',
          label: 'register.password',
          errorMessage: 'common.field-is-required',
        },
        {
          type: 'checkbox',
          name: 'aggreedToTermsAndConditions',
          label: 'register.agree-to-terms-and-conditions',
          labelData: this.state.tacUrls,
          errorMessage: 'register.no-agreement-error',
        }
      ]
    }



    return (
      <div className="register-c">
        <Json data={this.state.errors}></Json>
        <LanguageSelector></LanguageSelector>
        <div className="panel">
          <div className="header">
            <FormattedMessage id={"register.header"}></FormattedMessage>
          </div>
          <div className="body">
            {this.state.error == true ? <Error text={this.state.errorText}></Error> : <span></span>}
            {this.state.hasRegistered ? this.renderRegisteredMessage() : <div>
              <ControlledForm errors={this.state.errors} values={this.state.values} onChange={(vs) => this.setState({ values: vs })} definition={form} >
              </ControlledForm>
              <ReCAPTCHA
                sitekey="6LeStA0UAAAAAIcDhPHUejBGYcM0vsCl_rYm74jt"
                onChange={(token) => this.onChange(token)}
              />
              {
                this.state.reCaptchaError ? <div className="error-message invalid-feedback">
                  <FormattedHTMLMessage id="register.recaptcha-error" values={this.state.tacUrls}></FormattedHTMLMessage>
                </div> : null
              }
              <Button label="register.register" onClick={() => this.submitForm()} loading={this.state.loading}  ></Button>
            </div>}
          </div>
        </div>
      </div>
    );


  }

  renderRegisteredMessage() {
    return (<Message message="register.you-have-registered"></Message>);
  }


  componentDidMount() {
  }

  componentWillUnmount() {
  }

}

export default Register;