import React from 'react';
import { FormattedMessage } from 'react-intl';
import './textbox.scss';
import Localization from '../services/localization-service';
import classNames from 'classnames/bind';
import { onEnterKey } from '../../util';

const Textbox = (props) => {
    return (
        <div
            className={classNames(
                "fc textbox-fc",
                `${props.name}-field`,
                "form-group",
                {
                    'has-error': props.hasError,
                    'no-label': props.label == null
                })}>

            {props.label ? <label ><FormattedMessage id={props.label}></FormattedMessage></label> : null}
            {!props.disabled ?
                <input key="enabled" type="text"
                    autoComplete={props.autoComplete}
                    className={"form-control" + (props.hasError ? ' is-invalid' : '')}
                    name={props.name} placeholder={props.placeholder ? Localization.formatMessage(props.placeholder) : null}
                    onChange={(e) => props.onChange(e.target.value)}
                    value={props.value}
                    disabled={props.disabled}
                    onKeyPress={(e) => {
                        onEnterKey(e, props.onSubmit);
                    }}
                /> :
                <input key="disabled" type="text" title={Localization.formatMessage(props.disabledMessage)} defaultValue={Localization.formatMessage(props.disabledMessage)} disabled className="form-control" ></input>
            }
            {
                props.hasError && props.errorMessage
                    ? <div className="error-message invalid-feedback">
                        <FormattedMessage id={props.errorMessage}></FormattedMessage>
                    </div>
                    : null
            }
        </div>
    );
}

export default Textbox