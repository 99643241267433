import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Highlighter from 'react-highlight-words';
import { map } from 'rxjs/operators';
import Select from '../common/select';
import Localization from '../services/localization-service';
import ReimbursablesStore from '../stores/reimbursables-store';
import './reimbursable-select.scss';
import { BehaviorSubject } from 'rxjs';
import TransactionService from '../services/transactions-service';

const formatOptionLabel = (data, { inputValue }) => {
  return <React.Fragment>
    <span className="reimbursable-select-option-item"><Highlighter
      searchWords={[inputValue]}
      textToHighlight={data.label}
      highlightClassName="highlighted"
    /></span>
  </React.Fragment>
}


class ReimbursableSelect extends Component {
  constructor(props) {
    super(props);
    this.subject = new BehaviorSubject(null);
    this.state = { additionalOptions: [], handledAdditional: false }
  }

  render() {
    return (
      <Select className="reimbursable-select-fc"
        isClearable
        formatOptionLabel={formatOptionLabel}
        missingProps={this.props.currency == null}
        missingPropsMessage="reimbursable-select.select-account"
        options={this.subject.asObservable()}
        {...this.props}
      ></Select>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.curreny == null && this.props.currency != null) || (prevProps.currency != this.props.currency)) {
      this.updateOptions();
    }
  }

  updateOptions() {
    if (this.unmouted) return;
    if (this.state.reimbursables == null) return;
    if (!this.state.handledAdditional && !this.state.reimbursables.items.any(x => x.id == this.props.value)) {
      TransactionService.getReimbursableById(this.props.value).then(x => {
        if (x != null) {
          this.setState((state, props) => {
            if (state.handledAdditional) return;
            state.additionalOptions.push({
              label: this.formatLabel(x),
              value: x.operationId
            })
            return {
              handledAdditional: true,
              additionalOptions: state.additionalOptions
            }
          }, () => this.updateSubject())

          return;
        }
        this.updateSubject();
      })

      return;
    }


    this.updateSubject();
  }

  updateSubject() {
    let options = this.state.reimbursables.items
      .where(x => x.currency == this.props.currency)
      .select(x => {
        return {
          label: this.formatLabel(x),
          value: x.operationId,
        }
      }).concat(this.state.additionalOptions);
    this.subject.next(options);
  }

  formatLabel(r) {
    return `${Localization.formatNumber(Math.abs(r.amount))} ${r.currency} ${(r.description != null ? " - " + r.description : '')}`
  }

  componentDidMount() {
    ReimbursablesStore.reimbursables.subscribe(rs => {
      if (this.unmouted) return;
      this.setState({ reimbursables: rs }, () => { this.updateOptions() });
    });

  }

  componentWillUnmount() {
    this.unmouted = true;
  }

}


ReimbursableSelect.propTypes = {
  currency: PropTypes.string
}


export default ReimbursableSelect;