import predef from '../../predef';
import Store from './store';

class ReimbursablesStore {
    constructor() {
        this.store = new Store(predef.endpoints.transaction.notReimbursed,
            [predef.events.transaction.reimbursablesChanged], predef.events.transaction.reimbursablesStoreUpdated);
    }

    get reimbursables() {
        return this.store.asObservable();
    }

}

const instance = new ReimbursablesStore();
export default instance;
