import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage } from 'react-intl';
import { guid } from '../../util';
import Localization from '../services/localization-service';
import './fuzy-date-input.scss';
import InlineDateInput from './inline-date-input';
import InlineMonthInput from './inline-month-input';
import InlineYearInput from './inline-year-input';
import RadioSwitchInput from './radio-switch-input';
import classNames from 'classnames/bind';
import Button from './button';
import Popper from 'popper.js';

class FuzyDateInput extends Component {

  constructor(props) {
    super(props);
    this.state = { mode: 'month', value: null };
    this.id = "fdi" + guid();
    this.closeDropDownWhenClickOutside = this.closeDropDownWhenClickOutside.bind(this);
  }

  showValue() {
    let selectVal = <FormattedMessage id="fuzy-date-input.select-value"></FormattedMessage>
    if (this.state.value == null) return selectVal;
    if (typeof this.state.value === 'object') return Localization.formatDateMMMMYYYY(`${this.state.value.year}-${this.state.value.month}-01`)
    if (typeof this.state.value === 'string') return Localization.formatDate(this.state.value)
    if (typeof this.state.value === 'number') return this.state.value

    return null;
  }

  render() {
    let switchItems = [{
      label: 'common.year',
      activeClass: 'primary',
      inactiveClass: 'outline-primary',
      value: 'year'
    }, {
      label: 'common.month',
      activeClass: 'primary',
      inactiveClass: 'outline-primary',
      value: 'month'
    }, {
      label: 'common.date',
      activeClass: 'primary',
      inactiveClass: 'outline-primary',
      value: 'date'
    }];


    let date = this.props.link?.date;
    let month = this.props.link;
    let year = this.props.link?.year;

    if (date != null) {
      month = null;
      year = null;
    }
    else if (this.props.link?.month != null) {
      date = null;
      year = null;
    }
    else if (this.props.link?.year != null) {
      date = null;
      month = null;
      year = this.props.link?.year;
    }
    else {
      date = null;
      month = null;
      year = null;
    }

    return <div className="fuzy-date-input-c">
      <div className={classNames(this.state.mode, 'form-group')} id={this.id}>
        {this.props.label != null ? <label className="form-label"><FormattedMessage id={this.props.label}></FormattedMessage></label> : null}
        <button className="btn btn-outline-primary trigger form-control" type="button" onClick={() => this.toggleDropDown()} >
          {this.showValue()}<span className="fa fa-caret-down"></span>
        </button>
        {createPortal(<div id={'fdi-dropdown-' + this.id} className="fuzy-date-input-drop-down"  >
          <div className="mode-selector">
            <RadioSwitchInput
              value={this.state.mode}
              items={switchItems}
              onChange={(v) => this.setState({ mode: v })} ></RadioSwitchInput>
          </div>
          <div className="cal">
            {this.state.mode == 'year' ? <InlineYearInput value={year} onChange={(v) => this.yearChanged(v)}></InlineYearInput> : null}
            {this.state.mode == 'month' ? <InlineMonthInput value={month} onChange={(v) => this.monthChanged(v)}></InlineMonthInput> : null}
            {this.state.mode == 'date' ? <InlineDateInput value={date} onChange={(v) => this.dateChanged(v)}></InlineDateInput> : null}
          </div>
          <div className="actions">
            <Button link label="common.cancel" onClick={() => this.closeDropDown()}></Button>
            <Button link danger label="common.clear" fa="times" onClick={() => this.clear()}></Button>
          </div>
        </div>, $('body')[0])}
      </div>
    </div>
  }


  closeDropDown() {
    if (this.$dropDown.is(':visible')) {
      this.$dropDown.hide();
      this.popper?.destroy();
    }
  }

  toggleDropDown() {
    if (this.$dropDown.is(':visible')) {
      this.$dropDown.hide();
      this.popper?.destroy();
    }
    else {
      this.$dropDown.show();
      this.popper = new Popper(this.$toggle[0], this.$dropDown[0], {
        placement: 'bottom', modifiers: {
          preventOverflow: { enabled: true, boundariesElement: $('body')[0] }
        }
      });
    }
  }
  closeDropDownWhenClickOutside(e) {
    if ($.contains($('#' + this.id)[0], e.target) || $.contains(this.$dropDown[0], e.target)) {
      return;
    }
    this.closeDropDown();
  }
  get $toggle() {
    return $('#' + this.id + ' .trigger');
  }

  get $dropDown() {
    return $('#' + 'fdi-dropdown-' + this.id);
  }

  clear() {
    this.closeDropDown();
    this.setState({ value: null, mode: 'month' });
    this.props.onChange?.(null);
  }

  monthChanged(m) {
    this.closeDropDown();
    this.setState({ value: m, mode: 'month' });

    let val = m == null ? null : { month: m.month, year: m.year, date: null };
    this.props.onChange?.(val);
  }

  yearChanged(y) {
    this.closeDropDown();
    this.setState({ value: y, mode: 'year' })
    let val = { month: null, year: y, date: null };
    this.props.onChange?.(val);
  }

  dateChanged(d) {
    this.closeDropDown();
    this.setState({ value: d, mode: 'date' })
    let val = { month: null, year: null, date: d };
    this.props.onChange?.(val);
  }

  componentDidMount() {
    if (this.props.link?.date != null) {
      this.setState({ value: this.props.link.date, mode: 'date' });
    }
    else if (this.props.link?.month != null) {
      this.setState({ value: { year: this.props.link.year, month: this.props.link.month }, mode: 'month' });
    }
    else if (this.props.link?.year != null) {
      this.setState({ value: this.props.link.year, mode: 'year' });
    }
    else {
      this.setState({ value: null, mode: 'month' });
    }
    this.$dropDown.hide();
    $(document).bind('click', this.closeDropDownWhenClickOutside);
  }

  componentWillUnmount() {
    $(document).unbind('click', this.closeDropDownWhenClickOutside);
  }

}


FuzyDateInput.propTypes = {
  link: PropTypes.object
}


export default FuzyDateInput;