import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import './message.scss';
import Panel from './panel';

class Message extends Component {
  render() {
    return (
      <div className="message-c alert alert-primary">
        {this.props.message ? <FormattedMessage id={this.props.message}></FormattedMessage> : null}
        {this.props.children}
      </div>
    );
  }

}

export default Message;

export const NoDataMessage = (props) => <Message message="common.no-data"></Message>

export const PanelMessage = (props) => <Panel><Message {...props}></Message></Panel>