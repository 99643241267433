import React from 'react';
import './number.scss';
import { Copy } from './copy';
import Localization from '../services/localization-service';
import classNames from 'classnames/bind';

const renderValue = (noCopy, valueText, currency) => {
    if (noCopy) {
        return <span>{valueText} {currency}</span>;
    }

    return <span><Copy value={valueText}></Copy> {currency}</span>;
}
const Number = ({ em, color, value, currency, noCopy, blanksFor, padDecimal }) => {
    if (value === null || value === undefined || value === '') {
        return null
    };

    let valueText = Localization.formatNumber(value, padDecimal == null ? true : padDecimal);
    return <div className={classNames("number-c", {
        "green": color && value > 0,
        "yellow": color && value == 0,
        "red": color && value < 0,
        "em": em
    })}>
        {blanksFor != null && value === blanksFor ? null : renderValue(noCopy, valueText, currency)}
    </div>
}

export default Number;