import React, { Component } from 'react';
import predef from '../../predef';
import AccountTypeBadge from '../common/account-type-badge';
import Button from '../common/button';
import Page from '../common/page';
import { Table, TBody, TColumn, THead } from '../common/table';
import AccountsStore from '../stores/accounts-store';
import './restore-account.scss';
import { Copy } from './../common/copy';
import AccountService from '../services/accounts-service';
class RestoreAccount extends Component {
  constructor(props) {
    super(props);
    this.state = { accounts: [], loading: true };
    this.renderBody = this.renderBody.bind(this);
  }

  render() {
    return <Page
      className="restore-account-c"
      header="restore-account.header"
      loading={this.state.loading} data={this.state.accounts}
      noDataMessage="restore-account.no-data"
      renderBody={this.renderBody}
      actions={<Button link label="restore-account.accounts" fa="arrow-left" path={predef.routes.accounts.index}></Button>}
    >

    </Page>
  }

  renderBody() {
    return (<React.Fragment>
      <Table>
        <THead>
          <TColumn className="len-300" header="restore-account.name"></TColumn>
          <TColumn className="len-140 t-right" header="restore-account.balance"></TColumn>
          <TColumn className="len-150" header="restore-account.type"></TColumn>
          <TColumn className="len-290" header="restore-account.number"></TColumn>
          <TColumn className="len-150"></TColumn>
        </THead>
        <TBody>
          {this.renderRows()}
        </TBody>
      </Table>
    </React.Fragment>
    );
  }

  renderRows() {
    return this.state.accounts.map(a => {
      return (<tr key={a.id}>
        <td>{a.name}</td>
        <td className="t-right">{a.balance} {a.currency}</td>
        <td><AccountTypeBadge typeId={a.type}></AccountTypeBadge></td>
        <td><Copy value={a.number}></Copy></td>
        <td>
          <Button label="restore-account.restore" onClick={()=>this.restore(a.id)} path={predef.routes.accounts.index} fa="undo" outline ></Button>
        </td>
      </tr>)
    })
  }

  restore(id){
    AccountService.restoreAccount(id);
  }

  componentDidMount() {
    this.accountsSub = AccountsStore.deletedAccounts.subscribe(acs => this.setState({ accounts: acs == null ? [] : acs, loading: acs == null }));
  }

  componentWillUnmount() {
    this.accountsSub.unsubscribe();
  }

}

export default RestoreAccount;