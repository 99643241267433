import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { deepCopy, guid } from '../../util';
import Button from '../common/button';
import Form from '../common/form';
import './reimbursables-filter.scss';


class ReimbursablesFilter extends Component {

  constructor(props) {
    super(props);
    this.state = { filterKey: guid(), filter: null };
  }

  onFilterChange(filter) {
    filter = deepCopy(filter)
    if (filter?.date != null) {
      filter.dateFrom = filter.date.dateFrom;
      filter.dateTo = filter.date.dateTo;
      delete filter.date;
    }
    this.props.onFilterChange?.(filter);
  }

  render() {
    return (
      <div className={"reimbursables-filter-c"}>
        <Form
          onChange={(f) => this.setState({ filter: f })}
          keepValuesAfterSubmit={true}
          key={this.state.filterKey}
          submitButton={{ label: 'common.filter', fa: 'filter', outline: true }}
          onSubmit={(f) => this.onFilterChange(f)} definition={this.getFilterForm()}>
          <Button outline secondary label="common.clear" onClick={() => this.setState({ filterKey: guid() })} fa="times"></Button>
        </Form>
      </div>
    );
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  getFilterForm() {
    return {
      fields: [{
        type: 'currency',
        name: 'currency',
        label: 'transaction-filter.currency',
        onlyUsed: true,
        layout: "c c-sm-12 c-md-8 c-lg-6 c-w-160"
      },
      {
        type: 'account',
        name: 'accountId',
        label: 'transaction-filter.account',
        currencyFilter: this.state.filter?.currency,
        layout: "c c-sm-12 c-md-8 c-w-350"
      },
      {
        type: 'date-range',
        name: 'date',
        label: 'transaction-filter.date',
        layout: 'c c-sm-12 c-md-8 c-w-250'
      },
      {
        type: 'category',
        name: 'category',
        label: 'transaction-filter.category',
        layout: 'c c-sm-12 c-md-8 c-w-250'
      },
      {
        type: 'number',
        name: 'amountFrom',
        label: 'transaction-filter.amount-from',
        layout: 'c c-sm-12 c-md-8 c-w-125'
      },
      {
        type: 'number',
        name: 'amountTo',
        label: 'transaction-filter.amount-to',
        layout: 'c c-sm-12 c-md-8 c-w-125'
      },
      {
        type: 'text',
        name: 'description',
        label: 'transaction-filter.description',
        layout: 'c c-sm-12 c-md-8 c-w-250'
      }]
    };
  }

}

ReimbursablesFilter.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
}


export default ReimbursablesFilter;