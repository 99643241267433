import React, { Component } from 'react';
import predef from '../../predef';
import Button, { Edit, Remove } from '../common/button';
import Date from '../common/date';
import Grid from '../common/grid/grid';
import LoadingPlaceholder from '../common/loading-placeholder';
import Number from '../common/number';
import Panel from '../common/panel';
import TransactionService from '../services/transactions-service';
import './investment-transactions.scss';

class InvestmentTransactionsList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <Panel className="investment-transactions-list-c">
      {this.state.transactions == null ? <LoadingPlaceholder></LoadingPlaceholder> : <React.Fragment>
        {this.props.investmentCapital ? <Button path={predef.routes.investments.addTransaction(this.props.investmentId, 'capital')} outline label="investment-transactions-list.add-capital-transaction" fa="plus"></Button> : null}
        {this.props.investmentReturn ? <Button path={predef.routes.investments.addTransaction(this.props.investmentId, 'return')} outline label="investment-transactions-list.add-return-transaction" fa="plus"></Button> : null}
        <Grid definition={[
        {
        show: true,
        headerClasses: "len-45 ",
        header: "common.index-column-header",
        renderCell: (t, i) => i + 1,
        sortable: false,
        cellClassNames: (t,i)=> (t.amount < 0 && this.props.investmentCapital) || (t.amount > 0 && this.props.investmentReturn) ? 'green' : 'red'
      }, {
        show: true,
        headerClasses: "len-160",
        header: "common.date",
        renderCell: (t, i) => <Date date={t.date}></Date>,
        sortable: false,
      },
      {
        show: true,
        headerClasses: "len-160",
        header: "common.amount",
        renderCell: (t, i) => <Number value={t.amount * this.getSign()}></Number>,
        sortable: false,
      },
      {
        show: true,
        headerClasses: "",
        header: "common.description",
        renderCell: (t, i) => t.operations.length==1 ? t.operations[0].description : t.description,
        sortable: false,
      },
      {
        show: true,
        headerClasses: "len-120",
        name: 'actions',
        renderCell: (t, i) => <React.Fragment>
          <Remove onClick={()=> {
            TransactionService.deleteTransaction(t).then(x=> this.loadTransactions())
          }}></Remove>
          <Edit path={predef.routes.transaction.edit(t.id,predef.routes.investments.dashboard(this.props.investmentId))}>

          </Edit>

        </React.Fragment>,
        sortable: false,
      }]}
        id="investment-transactions"
        data={this.state.transactions?.items}
      >
      </Grid>
        
        </React.Fragment>}
    </Panel>
  }

  getSign() {
    if (this.props.investmentReturn) return 1;
    return -1;
  }

  loadTransactions() {
    TransactionService.query({
      filter: {
        investmentCapital: this.props.investmentCapital,
        investmentReturn: this.props.investmentReturn,
        investmentId: this.props.investmentId
      },
      sorting: {
        column: "Date",
        order: "Asc"
      }
    }).then((res) => {
      this.setState({ transactions: res });
    });
  }
  componentDidMount() {
    this.loadTransactions();
  }

  componentWillUnmount() {
  }
}

export default InvestmentTransactionsList;