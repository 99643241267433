import PropTypes from 'prop-types';
import React, { Component } from 'react';
import * as yup from 'yup';
import { deepCopy, guid } from '../../../util';
import Calendar from '../../services/calendar-service';
import TodosService from '../../services/todos-service';
import Button from '../button';
import EditableGrid from '../grid/editable-grid';
import LoadingPlaceholder from '../loading-placeholder';
import MonthInput from '../month-input';
import Panel from '../panel';
import './todos.scss';
const validationSchema = yup.object().shape({
  description: yup.string().required('common.field-is-required'),
});


class Todos extends Component {

  constructor(props) {
    super(props);
    this.state = { isLoading: true, todos: null, month: Calendar.todayIso };
  }

  createFromRecurring() {
    if (this.state.isLoading) return;
    this.setState({ isLoading: true }, () => {
      var promise = TodosService.createFromRecurringTasks(this.props.entityRef, this.props.refId, this.state.month);
      promise.then(() => this.getTodos());
    })
  }
  render() {
    return <Panel className="todos-c">
      <div className="actions"><Button onClick={() => this.createFromRecurring()} link label="todos.copy-recurring-todos"></Button></div>
      <MonthInput value={this.state.month} onChange={(v) => this.onMonthChange(v)}></MonthInput>
      {this.state.isLoading ? <LoadingPlaceholder></LoadingPlaceholder> : this.renderPanelBody()}
    </Panel>
  }

  submit(todos) {
    return new Promise((resolve, reject) => {
      todos = deepCopy(todos);
      for (let todo of todos) {
        let year = Calendar.getYearFromIso(this.state.month);
        let month = Calendar.getMonthFromIso(this.state.month);
        let day = todo.day || Calendar.getLastDayOfMonthFromIso(this.state.month);
        todo.deadline = `${year}-${month}-${day}`
        delete todo.day;
      }
      TodosService.updateTodos(this.props.entityRef, this.props.refId, todos)
        .then(() => {
          resolve();
          this.getTodos();
        })
        .catch(() => {
          reject();
        });
    });
  }

  onMonthChange(v) {
    this.setState({ month: v }, () => {
      this.getTodos();
    });
  }

  renderPanelBody() {
    return <div>

      <EditableGrid
        key={this.state.month}
        width={88}
        id="todos"
        onSubmit={(todos) => this.submit(todos)}
        data={this.state.todos}
        validationSchema={validationSchema}
        refreshToken={this.state.refreshToken}
        autoWidth={true}
        definition={[
          {
            show: true,
            header: 'todos.is-done',
            type: 'checkbox',
            name: 'isDone',
            headerClasses: "len-120",
            align: 'center'
          },
          {
            show: true,
            header: 'common.description',
            type: 'text',
            name: 'description',
            headerClasses: "len-500"
          },
          {
            show: true,
            header: 'todos.deadline',
            type: 'days',
            month: this.state.month,
            name: 'day',
            headerClasses: 'len-160',
            fullDateLabel: true
          }
        ]} >
      </EditableGrid>
    </div>
  }

  getTodos() {
    this.setState({ isLoading: true }, () => {
      TodosService.getTodos(this.props.entityRef, this.props.refId, this.state.month).then(todos => {
        if (todos != null) {
          for (let todo of todos) {
            todo.day = todo.deadline != null ? Calendar.getDayFromIso(todo.deadline) : null;
          }
        }
        this.setState({ month: this.state.month, todos: todos, isLoading: todos == null, refreshToken: guid() });
      });
    });
  }

  componentDidMount() {
    this.getTodos();
  }

  componentWillUnmount() {

  }

}

Todos.propTypes = {
  entityRef: PropTypes.string.isRequired,
  refId: PropTypes.string.isRequired
}


export default Todos;