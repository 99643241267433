import React from 'react';
import './flags.scss';


const Flags = ({ operation, transaction }) => {
  let flags = [];
  if (operation != null) {
    if (operation.isReimbursable) {
      flags.push(<span className="flag fa fa-undo" key="reimbursable"></span>);
    }
    if (operation.isReimbursement) {
      flags.push(<span className="flag fa fa-undo fa-rotate-180" key="reimbursement"></span>);
    }

    if (operation.isIncomeCost) {
      flags.push(<span className="flag fa fa-taxi" key="incomeCost"></span>);
    }

    if (operation.isPinned) {
      flags.push(<span className="flag far fa-thumbtack" key="pinned"></span>)
    }
  }
  if (transaction != null) {

    if (transaction.isPartOfTransfer) {
      flags.push(<span className="flag fa fa-exchange-alt" key="transer"></span>);
    }

    if (transaction.operations.length == 0 && !transaction.isPartOfTransfer) {
      flags.push(<span className="flag fa fa-wrench" key="adjussmnt"></span>);
    }
  }
  return flags;
}


Flags.propTypes = {

}


export default Flags;