import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../../bus';
import predef from '../../../predef';
import Button from '../button';
import Panel from '../panel';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { H3 } from '../headers';
import { Table, THead, TColumn, TBody } from '../table';
import Localization from '../../services/localization-service';
import './chart-table.scss';
import Number from '../number';
import Json from '../json';
import { copyToClipboard } from '../../../util';


const copyCSV = (props) => {
  let csv = '';
  for (let col of props.series) {
    csv += `,"${col.name}"`;
  }
  csv += "\n";

  let rows = props.x.map((x, i) => {
    let row = x;
    for (let s of props.series) {
      row += `,"${Localization.formatNumber(s.data[i].value)}"`;
    }
    row += "\n";
    return row;
  });

  for (let row of rows) {
    csv += row;
  }
  copyToClipboard(csv, false);
}


const copyHtml = (props) => {
  let html = '<table><thead><tr><th></th>';
  for (let col of props.series) {
    html += `<th>${col.name}</th>`;
  }
  html += "</tr></thead><tbody>";

  let rows = props.x.map((x, i) => {
    let row = `<tr><td>${x}</td>`;
    for (let s of props.series) {
      row += `<td>${Localization.formatNumber(s.data[i].value)}</td>`;
    }
    row += "</tr>";
    return row;
  });

  for (let row of rows) {
    html += row;
  }
  html += "</tbody></table>"
  copyToClipboard(html, false);
}

const ChartTable = (props) => {
  return <div className="chart-table-c">
    <Table>
      <THead>
        <TColumn>
          <Button link fal="copy" label="chart-table.copy-csv" onClick={() => copyCSV(props)}></Button>
          <Button link fal="copy" label="chart-table.copy-excel-html" onClick={() => copyHtml(props)}></Button>
        </TColumn>
        {props.series.map(c => <TColumn key={c.name}>{c.name}</TColumn>)}
      </THead>
      <TBody>
        {props.x.map((x, i) => <tr key={i}>
          <td>{x}</td>
          {props.series.map((s, j) => <td className="number" key={`[${i},${j}]`} ><Number value={s.data[i].value}></Number></td>)}
        </tr>)}
      </TBody>
    </Table>
  </div>
}


ChartTable.propTypes = {

}


export default ChartTable;