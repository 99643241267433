import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button, { Cancel, Remove, Save, Edit } from '../common/button';
import Panel from '../common/panel';
import PropTypes from 'prop-types';
import Page from '../common/page';
import classNames from 'classnames/bind';
import { H3, H2 } from '../common/headers';
import InvestmentForm from './investment-form'
import './add-investment.scss';
import InvestmentService from '../services/investments-service';
import { createHashHistory } from 'history';
const history = createHashHistory();

class AddInvestment extends Component {

  constructor(props) {
    super(props);
    this.state = { };
  }

  render() {
    return <div className="add-investment-c">
      <H2 header="add-investment.header"></H2>
      <Panel><InvestmentForm onSubmit={(inv) => {
        return new Promise((resolve, reject) => {
          InvestmentService.addInvestment(inv).then(() => {
            resolve();
            history.push(predef.routes.investments.index);
          }).catch((e) => reject(e))
        })
      }}></InvestmentForm></Panel>
    </div>
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

}


AddInvestment.propTypes = {

}


export default AddInvestment;