import React from 'react';
import { AccountSelect } from '../common/account-select';
import BudgetFilter from '../common/budget-filter';
import BudgetSelect from '../common/budget-select';
import CategoriesSelect from '../common/categories-select';
import CategorySelect from '../common/category-select';
import Checkbox from '../common/checkbox';
import { CurrencySelect } from '../common/currency-select';
import DateInput from '../common/date-input';
import DateRangeInput from '../common/date-range-input';
import DocumentCategorySelect from '../common/document-category-select';
import '../common/form.scss';
import FrequencySelect from '../common/frequency-select';
import FuzyDateInput from '../common/fuzy-date-input';
import IncomeStreamSelect from '../common/income-stream-select';
import MonthInput from '../common/month-input';
import NumberInput from '../common/number-input';
import Password from '../common/password';
import RadioSwitchInput from '../common/radio-switch-input';
import RealEstateCategorySelect from '../common/real-estate-category-select';
import RealEstateSelect from '../common/real-estate-select';
import ReimbursableSelect from '../common/reimbursable-select';
import ResidentSelect from '../common/resident-select';
import RichInput from '../common/rich-input';
import Select from '../common/select';
import Switch from '../common/switch';
import TagsInput from '../common/tags-input';
import Textbox from '../common/textbox';
import AccountTypesStore from '../stores/account-types-store';
import Service from './service';
import StopConditionSelect from '../common/stop-condition-select';
import DaysSelect from '../common/days-select';
import SelectInput from '../common/select-input';
import ReadOnlyInfo from '../common/read-only-info';
import OperationTagsInput from '../common/operation-tags-input';

class FormControlService extends Service {
  getControlFromField(field, props) {
    switch (field.type) {
      case 'text':
        return <Textbox {...props}></Textbox>
      case 'number':
        return <NumberInput {...props}></NumberInput>
      case 'password':
        return <Password {...props}></Password>
      case 'checkbox':
        return <Checkbox {...props}></Checkbox>
      case 'select':
        return <Select {...props}></Select>
      case 'select-input':
        return <SelectInput {...props}></SelectInput>
      case 'date-range':
        return <DateRangeInput {...props}></DateRangeInput>
      case 'currency':
        return <CurrencySelect {...props} ></CurrencySelect>
      case 'account':
        return <AccountSelect {...props} ></AccountSelect>
      case 'category':
        return <CategorySelect {...props}></CategorySelect>
      case 'switch':
        return <Switch {...props}></Switch>
      case 'budget-filter':
        return <BudgetFilter {...props}></BudgetFilter>
      case 'budget-select':
        return <BudgetSelect {...props}></BudgetSelect>
      case 'date':
        return <DateInput {...props}></DateInput>
      case 'month':
        return <MonthInput {...props}></MonthInput>
      case 'reimbursable-select':
        return <ReimbursableSelect {...props}></ReimbursableSelect>
      case 'rich':
        return <RichInput {...props}></RichInput>
      case 'real-estate-select':
        return <RealEstateSelect {...props}></RealEstateSelect>
      case 'real-estate-category-select':
        return <RealEstateCategorySelect {...props}></RealEstateCategorySelect>
      case 'account-type':
        return <Select options={AccountTypesStore.accountTypes} {...props}></Select>
      case "income-stream-select":
        return <IncomeStreamSelect {...props}></IncomeStreamSelect>
      case 'radio-switch':
        return <RadioSwitchInput {...props}></RadioSwitchInput>
      case 'tags':
        return <TagsInput {...props}></TagsInput>
      case 'residents-select':
        return <ResidentSelect {...props}></ResidentSelect>
      case 'document-category':
        return <DocumentCategorySelect {...props}></DocumentCategorySelect>
      case 'fuzy-date':
        return <FuzyDateInput {...props}></FuzyDateInput>
      case 'categories-select':
        return <CategoriesSelect {...props}></CategoriesSelect>
      case 'frequency':
        return <FrequencySelect {...props}></FrequencySelect>
      case 'stop-condition':
        return <StopConditionSelect {...props}></StopConditionSelect>
      case 'days':
        return <DaysSelect {...props}></DaysSelect>
      case 'read-only-info':
        return <ReadOnlyInfo {...props}></ReadOnlyInfo>
      case 'operation-tags':
        return <OperationTagsInput {...props}></OperationTagsInput>
      case 'raw':
        return <div key={field.name} className="raw-c">{field.render()}</div>
      default:
        console.error("Unknown field type: " + field.type, field)
    }
  }

  getValueOrDefault(type, value) {
    switch (type) {
      case 'checkbox':
      case 'switch':
        return value == null ? false : value;
      case 'number':
        return value == null ? null : value;
      case 'text':
      case 'password':
        return value == null ? '' : value;
      default:
        return value === undefined ? null : value;
    }
  }

}


let instance = new FormControlService();
export default instance;
