import React, { Component } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import predef from '../../predef';
import { PrivateRoute } from '../../private-route';
import { routeParamId, guid } from '../../util';
import LoadingPlaceholder from '../common/loading-placeholder';
import { InvestmentContext } from '../contexts';
import BreadCrumbsStateService from '../state-services/bread-crumbs-state-service-service';
import SecondaryNavStateService from '../state-services/secondary-nav-state-service-service';
import InvestmentsStore from '../stores/investments-store';
import './investment-dashboard.scss';
import InvestmentTransactions from './investment-transactions';
import InvestmentDocuments from './investment-documents';
import InvestmentNotes from './investment-notes';
import EditInvestment from './edit-investment'
import Bus from '../../bus';
import Indicator2 from '../common/indicator-2';
import Localization from '../services/localization-service';

const routesConfig = [
  { component: InvestmentTransactions, route: `/investment/:id/transactions`, url: (match) => `${match.url}/transactions`, label: 'common.transactions' },
  { component: EditInvestment, route: `/investment/:id/edit`, url: (match) => `${match.url}/edit`, label: 'investment-dashboard.info-edit' },
  { component: InvestmentDocuments, route: `/investment/:id/documents`, url: (match) => `${match.url}/documents`, label: 'common.documents' },
  { component: InvestmentNotes, route: `/investment/:id/notes`, url: (match) => `${match.url}/notes`, label: 'common.notes' },
]

class Investment extends Component {

  constructor(props) {
    super(props);
    this.state = { isLoading: true, key: guid() };
  }

  render() {
    if (this.state.isLoading) return <LoadingPlaceholder></LoadingPlaceholder>

    var pathPrefix = `/investment/${this.state.investmentId}`;

    return <div className="investment-dashboard-c" key={this.state.key}>
      <div className="indicators">
        <Indicator2 value={this.state.investment.investedAmount} currency={this.state.investment.currency} label="investment-dashboard.invested-amount"></Indicator2>

        {!this.state.investment.isClosed ? <Indicator2 value={this.state.investment.currentValue == null ? this.state.investment.transactionsBalance : this.state.investment.currentValue} currency={this.state.investment.currency} label="investment-dashboard.current-value"></Indicator2> : null}
        {this.state.investment.isClosed ?<Indicator2 value={this.state.investment.return} currency={this.state.investment.currency} label="investment-dashboard.nominal-return"></Indicator2>: null}
        {this.state.investment.isClosed ?<Indicator2 value={this.state.investment.returnPercent} currency={"%"} label="investment-dashboard.percent-return"></Indicator2>: null}
        {this.state.investment.isClosed ? <Indicator2 value={this.state.investment.averageYearlyReturnPercent} currency={"%/" + Localization.formatMessage("common.year")} label="investment-dashboard.yearly-return"></Indicator2> : null}
      </div>
      <InvestmentContext.Provider value={this.state.investment}>
        <Switch>
          <Redirect from={pathPrefix} exact to={`${pathPrefix}/transactions`} />
          {routesConfig.map(r => {
            return <PrivateRoute key={r.route} path={r.route} component={r.component}></PrivateRoute>
          })}
        </Switch>
      </InvestmentContext.Provider>
    </div>
  }

  componentDidMount() {
    let id = routeParamId(this.props);
    this.setState({ investmentId: id }, () => {
      this.loadInvestment();
    });
    this.sub = Bus.subscribe(this);
  }

  loadInvestment() {
    let id = routeParamId(this.props);

    InvestmentsStore.byId(id).then(i => {
      this.setState({
        investment: i,
        key: guid(),
        isLoading: i == null
      }, () => this.setNavs())
    });
  }

  _onInvestmentsStoreUpdated() {
    this.loadInvestment();
  }

  componentWillUnmount() {
    this.sub.unsubscribe();
    SecondaryNavStateService.clear();
    BreadCrumbsStateService.clear();
  }

  setNavs() {
    SecondaryNavStateService.set(routesConfig.select(x => { return { url: x.url(this.props.match), label: x.label } }));

    BreadCrumbsStateService.set(routesConfig.select(x => {
      return {
        url: x.url(this.props.match), crumbs: [
          { url: predef.routes.investments.index, label: 'common.investments' },
          { text: this.state.investment?.name },
          { label: x.label }
        ]
      }
    }));
  }

}


Investment.propTypes = {

}


export default Investment;