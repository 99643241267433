import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button, { Cancel, Remove, Save, Edit } from '../common/button';
import Panel from '../common/panel';
import Page from '../common/page';
import { H3 } from '../common/headers';
import Json from '../common/json';
import './income-streams.scss';
import IncomeStreamsStore from '../stores/income-streams-store';
import IncomeStreamsService from '../services/income-streams-service';
import EditableGrid from '../common/grid/editable-grid';
import * as yup from 'yup';
import { guid } from '../../util';

const validationSchema = yup.object().shape({
  name: yup.string().required('common.field-is-required'),
});

class IncomeStreams extends Component {

  constructor(props) {
    super(props);
    this.state = { isLoading: true, incomeStreams: null, refreshToken: guid() };
    this.renderBody = this.renderBody.bind(this);
  }

  save(ins) {
    return new Promise((resolve, reject)=>{
      IncomeStreamsService.update(ins)
      .then(() => {
        resolve();
      })
      .catch(() => {
        reject();
      })
    })
  }

  render() {
    return <Page
      className="income-streams-c"
      header="income-streams.header"
      loading={this.state.isLoading}
      data={true}
      renderBody={this.renderBody}
    >

    </Page>
  }

  renderBody() {
    return <React.Fragment>
      <EditableGrid
        width={76}
        id="income-streams"
        onSubmit={(ins) => this.save(ins)}
        data={this.state.incomeStreams}
        validationSchema={validationSchema}
        refreshToken={this.state.refreshToken}
        autoWidth={true}
        definition={[
          {
            show: true,
            header: 'common.name',
            type: 'text',
            name: 'name',
            headerClasses: "len-300"
          },
          {
            show: true,
            header: 'income-streams.treat-expenses-as-income-cost',
            type: 'checkbox',
            name: 'treatExpensesAsIncomeCost',
            headerClasses: 'len-200'
          },
          {
            show: true,
            type: 'raw',
            name: 'actions',
            headerClasses: "len-180",
            renderCells: (i) => {
              return i.id == null ? null : <Button link fa="chart-line" label="income-streams.go-to-dashboard" path={predef.routes.incomeStreams.dashboard(i.id)}></Button>
            }
          }
        ]} >
      </EditableGrid>
    </React.Fragment>
  }


  componentDidMount() {
    this.sub = IncomeStreamsStore.incomeStreams.subscribe(x => {
      this.setState({ incomeStreams: x, isLoading: x == null, refreshToken: guid() });
    });
  }

  componentWillUnmount() {
    this.sub.unsubscribe();
  }

}

export default IncomeStreams;