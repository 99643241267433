import React, { Component } from 'react';
import Button from '../common/button';
import BreadCrumbsStateService from '../state-services/bread-crumbs-state-service-service';
import './bread-crumbs.scss';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

class BreadCrumbs extends Component {

  constructor(props) {
    super(props);
    this.state = { breadCrumbs: null };
  }

  render() {
    if (this.state.breadCrumbs == null) return null;

    let crumbs = this.state.breadCrumbs.where(x => x != null).single(x => x.url.toLowerCase() === this.props.location.pathname.toLowerCase());
    if (crumbs == null) return null;

    return <div className="bread-crumbs-c">
      {crumbs.crumbs.map((x, i) => {
        let res = [];
        if (i != 0) {
          res.push(<span key={'a' + i} className="fad fa-chevron-double-right"></span>)
        }     
        if (x.url != null) {
          res.push(<Button key={'b'+i} link path={x.url} label={x.label}>{x.text}</Button>)
        }
        else if (x.label != null) {
          res.push(<span className="t"  key={'l'+i}><FormattedMessage id={x.label}></FormattedMessage></span>)
        }
        else {
          res.push(<span className="t" key={'t'+i}>{x.text}</span>);
        }

        return res;
      })}
    </div>

  }

  componentDidMount() {
    this.sub = BreadCrumbsStateService.breadCrumbs.subscribe(x => this.setState({ breadCrumbs: x }));
  }

  componentWillUnmount() {
    this.sub.unsubscribe();
  }


}


BreadCrumbs.propTypes = {

}


export default withRouter(BreadCrumbs);