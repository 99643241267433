import predef from '../../predef';
import Store from './store';

class RecurringTransactionsStore {
    constructor() {
        this.recurringTransactionsStore = new Store(predef.endpoints.recurringTransactions.index,
            [predef.events.recurringTransactions.recurringTransactionsChanged]);
    }

    get recurringTransactions() {
        return this.recurringTransactionsStore.asObservable();
    }

    byId(id){
        return new Promise((resolve, _)=> {
            this.recurringTransactions.subscribe(entities => {
                if (entities == null) return;

                resolve(entities.single(x=>x.id == id));
            });
        });
    }
}

const instance = new  RecurringTransactionsStore();
export default instance;
