import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames/bind'
import './tabs.scss';

export class ControlledTabs extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="tabs-c">
        <ul className="nav nav-tabs">
          {this.renderTabs()}
        </ul>
        <div className={classNames('tab-body', this.props.tabs[this.props.selected].className)} key={this.props.selected}>
          {this.props.tabs[this.props.selected].render(this.props.tabs[this.props.selected])}</div>
      </div>
    );
  }

  renderTabs() {
    let tabs = [];
    let i = 0;
    for (let tab of this.props.tabs) {
      tabs.push(this.renderTab(tab, i));
      i++;
    }

    return tabs;
  }

  renderTab(tab, i) {
    return <li key={i} className="nav-item" >
      <a className={"nav-link " + (this.props.selected != i ? null : "active")} onClick={() => this.props.onChange?.(i)}>{tab.label == null ? <span>{tab.labelText}</span> : <FormattedMessage id={tab.label}></FormattedMessage>}</a>
    </li>
  }
}

class Tabs extends Component {
  constructor(props) {
    super(props);
    this.state = { selected: 0 };
  }

  show(i) {
    if (i == this.state.selected) return;

    this.setState({ selected: i });
    if (this.props.onChange != null) {
      this.props.onChange(this.props.tabs[i], i);
    }
  }

  render() {
    return <ControlledTabs selected={this.state.selected} onChange={(i) => this.show(i)} tabs={this.props.tabs}></ControlledTabs>
  }

}

export default Tabs;