import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button, { Cancel, Remove, Save, Edit } from '../common/button';
import Panel from '../common/panel';
import PropTypes from 'prop-types';
import Page from '../common/page';
import classNames from 'classnames/bind';
import { H3 } from '../common/headers';
import Form from '../common/form'
import './note-form.scss';
import NotesService from '../services/notes-service';
import * as yup from 'yup';
import Calendar from '../services/calendar-service';

const validationSchema = yup.object().shape({
  body: yup.string().required(),
  date: yup.string().required().min(10),
});

class NoteForm extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let form = {
      fields: [
        {
          type: 'text',
          name: 'title',
          label: 'note.title',
          layout: 'c c-smp-18 c-mdp-19 c-w-800 '
        },
        {
          type: 'date',
          name: 'date',
          label: 'common.date',
          layout: 'c c-smp-6 c-mdp-5 c-w-160',
          errorMessage: 'common.field-is-required'
        },

        {
          type: 'rich',
          name: 'body',
          label: 'note.body',
          errorMessage: 'common.field-is-required',
          layout: 'c'
        },
        {
          type: 'tags',
          name: 'tags',
          label: 'note.tags',
          layout: 'c'
        }
      ]
    }

    let values = this.props.note || { date: Calendar.todayIso };
    return <div className="note-form-c">
      <Form
        initialValues={values}
        validationSchema={validationSchema}
        definition={form}
        onSubmitted={() => this.props.onSaved?.(values)}
        onSubmit={(vs) => this.onSubmit(vs)}>
        <Button link label="common.cancel" onClick={() => this.props.onCancel()}></Button>
      </Form>
    </div>
  }

  onSubmit(values) {
    values.ref = this.props.noteRef;
    values.refId = this.props.refId;
    if (values.tags != null) {
      values.tags = values.tags.select(t => { return { text: t } })
    }
    if (this.state.mode == 'add') {
      return NotesService.addNote(values)
    }
    else {
      return NotesService.updateNote(values)
    }
  }

  componentDidMount() {
    this.setState({ mode: this.props.note == null ? 'add' : 'edit', note: this.props.note });
  }

  componentWillUnmount() {

  }

}

NoteForm.propTypes = {
  noteRef: PropTypes.string.isRequired,
  refId: PropTypes.string.isRequired
}

export default NoteForm;