import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button, { Cancel, Remove, Save, Edit } from '../common/button';
import Panel from '../common/panel';
import PropTypes from 'prop-types';
import Page from '../common/page';
import classNames from 'classnames/bind';
import { H3 } from '../common/headers';
import Api from '../../api';
import Calendar from '../services/calendar-service';
import './commands.scss';
import Form from '../common/form';
import Json from '../common/json';
import { DateTime } from '../common/date'
class Commands extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  query(query) {
    return Api.post('/api/audit/command', query).then(x => {
      this.setState({ commands: x });
    });
  }

  toggle(event, state) {
    event.showContent = state;
    for (let e of event.events) {
      this.toggle(e, state);
    }
  }

  render() {
    var form = {
      fields: [
        {
          type: 'text',
          name: 'tenantId',
          label: 'Tenant id',
          layout: ''
        },
        {
          type: 'text',
          name: 'type',
          label: 'Type',
          layout: ''
        },
        {
          type: 'date',
          name: 'dateFrom',
          label: 'common.date-from',
          layout: '',
          allowClear: true
        },
        {
          type: 'date',
          name: 'dateTo',
          label: 'common.date-to',
          layout: '',
          allowClear: true
        }
      ]
    };
    return <div className="commands-c">

      <Panel>
        <Form initialValues={{
          dateFrom: Calendar.momentToIsoDate(Calendar.today.add(-3, 'day')),
          dateTo: Calendar.todayIso,
          tenantId: '15F35FF6-3CFD-46BE-ADA7-9F91059B0EDC'
        }} submitButton={{ label: 'common.search', width: 9, fa: "search" }} onSubmit={(v) => this.query(v)} definition={form}
          keepValuesAfterSubmit
        >
        </Form>
        <Button link label="expand all" onClick={() => {
          for (let command of this.state.commands) {
            this.toggle(command, true);
            this.setState({ commands: this.state.commands });
          }
        }}></Button>
        <Button link label="colapse all" onClick={() => {
          for (let command of this.state.commands) {
            this.toggle(command, false);
            this.setState({ commands: this.state.commands });
          }
        }}></Button>
      </Panel>

      {
        this.state.commands != null ? this.state.commands.map(c => <div key={c.id} className="command-tree">
          <div className="command">
            <div className="command-header" onClick={() => {
              c.showContent = !c.showContent;
              this.setState({ commands: this.state.commands })
            }}>
              <DateTime date={c.date}></DateTime> <span className="name"><span className="fad fa-terminal"></span> {c.name}</span>


            </div>
            {c.showContent ? <Json data={JSON.parse(c.content)} showOnProd></Json> : null}
          </div>

          <div className="events">
            {this.renderEvents(c.events)}
          </div>
        </div>) : null
      }
      {/*  */}
    </div>
  }

  renderEvents(events) {
    if (events == null || events.length == 0) return null;
    return events.map(e => this.renderEvent(e));
  }

  renderEvent(event) {
    return <div className="event" key={event.id}>
      <div className="event-header" onClick={() => {
        event.showContent = !event.showContent;
        this.setState({ commands: this.state.commands })
      }}>
        <DateTime date={event.date}></DateTime> <span className="name"><span className="fad fa-bolt"></span> {event.name}</span>

      </div>
      {event.showContent ? <Json showOnProd data={JSON.parse(event.content)}></Json> : null}
      {event.events != null && event.events.length > 0 ? <div className="events">
        {this.renderEvents(event.events)}
      </div> : null}
    </div>
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

}


Commands.propTypes = {

}


export default Commands;