import { Component, default as React } from 'react';
import Button from '../common/button';
import ControlledForm from '../common/controlled-form';
import RadioSwitchInput from '../common/radio-switch-input';
import FalgsService from '../services/flags-service';
import classNames from 'classnames';

import './operation-form.scss';
import PropTypes from 'prop-types';


class OperationForm extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }


  expenseIncomeChange(value) {
    let values = this.props.values;
    values.expense = value === 'expense';
    this.onChange(values);
  }

  onChange(values) {
    if (values.expense) {
      values.isReimbursement = false;
      values.reimbursementFor = null;
    }
    else {
      values.isReimbursable = false;
      values.isIncomeCost = false;
      values.budgetId = null;
    }

    if (!values.hasRealEstate) {
      values.realEstateId = null;
      values.realEstateCategoryId = null;
    }

    if (!values.isReimbursement) {
      values.reimbursementFor = null;
    }

    this.props.onChange(values, this.props.index);
  }

  onSubmit() {
    if (this.props.onSubmit != null) this.props.onSubmit();
  }

  getExpenseLabel() {
    if (this.props.mode != 'investment') return { label: 'operation-form.expense', activeClass: 'outline-danger', icon: 'fa fa-minus' };

    if (this.props.investmentTransactionType == 'capital') return { label: 'operation-form.increase-capital', activeClass: 'outline-success', icon: 'fa fa-plus' };

    return { label: 'operation-form.loss', activeClass: 'outline-danger', icon: 'fa fa-minus' };
  }

  getIncomeLabel() {
    if (this.props.mode != 'investment') return { label: 'operation-form.income', activeClass: 'outline-success', icon: 'fa fa-plus' };

    if (this.props.investmentTransactionType == 'capital') return { label: 'operation-form.decrease-capital', activeClass: 'outline-danger', icon: 'fa fa-minus' };

    return { label: 'operation-form.gain', activeClass: 'outline-success', icon: 'fa fa-plus' };
  }

  render() {
    let form = this.getFormDefinition();
    let switchItems = [{
      ...this.getExpenseLabel(),
      inactiveClass: 'outline-secondary',
      value: 'expense'
    }, {
      ... this.getIncomeLabel(),
      inactiveClass: 'outline-secondary',
      value: 'income',
    }]

    if (this.props.mode == 'investment' && this.props.investmentTransactionType == 'return') {
      var t = switchItems[0];
      switchItems[0] = switchItems[1];
      switchItems[1] = t;
    }
    
    return (
      <div
        className={classNames("operation-form-c", {
          "has-error": this.props.values.errors != null,
          "expense": this.props.values.expense,
          "income": !this.props.values.expense
        }, this.props.mode, this.props.investmentTransactionType)}
      >
        <div className="opf-top-actions">
          <RadioSwitchInput
            value={(this.props.values.expense ? 'expense' : 'income')}
            items={switchItems}
            onChange={(v) => this.expenseIncomeChange(v)} ></RadioSwitchInput>
          {this.props.allowRemove ? <Button className="remove-operation-btn" label="common.remove" danger outline fa="minus" onClick={() => this.props.onRemove(this.props.index)}></Button> : null}
        </div>
        <ControlledForm errors={this.props.values.errors} onSubmit={() => this.onSubmit()} values={this.props.values}
          onChange={(v) => this.onChange(v)}
          definition={form}
        ></ControlledForm>
      </div>
    );
  }

  getFormDefinition() {
    let form = {
      groups: [{
        name: "g1",
        fields: [
          {
            type: 'number',
            name: 'amount',
            label: 'operation-form.amount',
            layout: 'c-sm-8 c-lg-6 c-w-180'
          },
          {
            type: 'text',
            name: 'description',
            label: 'operation-form.description',
            layout: 'c-sm-16 c-lg-12  c-w-250',
            autoComplete: 'off'
          },
          {
            type: 'category',
            name: 'category',
            label: 'operation-form.category',
            income: !this.props.values.expense,
            expense: this.props.values.expense,
            layout: 'c-sm-12 c-lg-6 c-w-250',
            allowNew: true
          },
          {
            type: 'budget-select',
            name: 'budgetId',
            label: 'operation-form.budget',
            date: this.props.date,
            currency: this.props.currency,
            hidden: !this.props.values.expense || this.props.mode === 'template' || this.props.values.isIncomeCost || this.props.recurringTransaction || this.props.mode === 'investment',
            layout: 'c-sm-12 c-lg-8 c-w-250'
          },
          {
            type: 'text',
            name: 'budgetName',
            label: 'operation-form.budget-name',
            hidden: !this.props.recurringTransaction,
            layout: 'c-sm-12 c-lg-8 c-w-250'
          },
          {
            type: 'income-stream-select',
            name: 'incomeStreamId',
            label: 'operation-form.income-stream',
            layout: 'c-sm-12 c-lg-8  c-w-250',
            hidden: this.props.mode === 'investment'
          },
          {
            type: 'reimbursable-select',
            name: 'reimbursementFor',
            label: 'operation-form.reimbursement-for',
            currency: this.props.currency,
            hidden: !this.props.values.isReimbursement || this.props.mode === 'template',
            layout: 'c-sm-24 c-lg-8  c-w-250'
          },
          {
            type: 'operation-tags',
            name: 'tags',
            label: 'common.tags',
          }
        ]
      }]
    };

    if (this.props.mode !== 'investment') {
      form.groups.push({
        name: 'flags',
        fields: [
          {
            type: 'switch',
            name: 'isPinned',
            text: 'operation-form.is-pinned',
            icon: 'fa fa-thumbtack fa-rotate-90 ',

          },
          {
            type: 'switch',
            name: 'isReimbursable',
            text: 'operation-form.is-reimbursable',
            icon: 'fa fa-undo',
            hidden: !this.props.values.expense,
            disabled: this.props.values.budgetId != null
          },
          {
            type: 'switch',
            name: 'isReimbursement',
            text: 'operation-form.is-reimbursement',
            icon: 'fa fa-undo fa-rotate-180',
            hidden: this.props.values.expense,
            disabled: this.props.values.budgetId != null
          },
          {
            type: 'switch',
            name: 'isIncomeCost',
            text: 'operation-form.is-income-cost',
            icon: 'fa fa-taxi',
            hidden: !this.props.values.expense,
            disabled: this.props.values.budgetId != null
          },
          {
            type: 'switch',
            name: 'hasRealEstate',
            text: 'operation-form.has-real-estate',
            icon: 'fa fa-home',
            hidden: !FalgsService.hasRealEstateFeatureAccessFlag,
          },
        ]
      });
      form.groups.push({
        name: 'real-estate',
        hidden: !FalgsService.hasRealEstateFeatureAccessFlag,
        fields: [
          {
            type: 'real-estate-select',
            name: 'realEstateId',
            label: 'operation-form.real-estate',
            hidden: !this.props.values.hasRealEstate,
            layout: '',
            layout: 'c c-sm-12 c-mdp-8 c-lg-6'
          },
          {
            type: 'residents-select',
            name: 'residentId',
            label: 'operation-form.resident',
            hidden: !this.props.values.hasRealEstate,
            layout: '',
            realEstateId: this.props.values.realEstateId,
            missingPropsMessage: 'operation-form.select-real-estate',
            missingProps: this.props.values.realEstateId == null,
            isClearable: true,
            layout: 'c c-sm-12 c-mdp-8 c-lg-6'
          },
        ]
      });

    }

    return form;

  }

  componentDidMount() {

  }

  componentWillUnmount() {
  }

}


OperationForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  date: PropTypes.string,
  currency: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
}


export default OperationForm;