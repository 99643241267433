import React, { Component } from 'react';
import Page from '../common/page';
import './edit-transaction.scss';
import Transaction from './transaction';
import { Cancel } from '../common/button';


class EditTransaction extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Page
        className="edit-transaction-c"
        header="edit-transaction.header"
        loading={false}
        data={true}
        renderBody={()=>this.renderBody()}
      ></Page>
    );
  }

  renderBody() {
    let { match: { params } } = this.props;
    let returnPath = params.returnPath == null ? null : atob(params.returnPath) 
    return <React.Fragment>
      <Transaction returnPath={returnPath} transactionId={params.id}></Transaction>
     
    </React.Fragment>
  }
}

export default EditTransaction;