import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button from '../common/button';
import Panel from '../common/panel';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import {H3} from '../common/headers';
import Select from '../common/select';
import {BehaviorSubject} from 'rxjs';
import Localization from '../services/localization-service';

import './stop-condition-select.scss';
const getConditions = () => {
  let freq = [];

  freq.push({ label: Localization.formatMessage('stop-condition-select.untill-removed'), value: 1 });
  freq.push({ label: Localization.formatMessage('stop-condition-select.till-date'), value: 2 });
  freq.push({ label: Localization.formatMessage('stop-condition-select.after-repeated'), value: 3 });

  return freq;
}
var options;

const StopConditionSelect = (props) => {
  if (options == null) {
    options = new BehaviorSubject(getConditions());
  }
  return <Select className="stop-condition-select-c" options={options.asObservable()} {...props}></Select>
}


StopConditionSelect.propTypes = {

}


export default StopConditionSelect;