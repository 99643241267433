import Calendar from './calendar-service'
class TransactionDateService {
    constructor() {
		this.date = Calendar.todayIso
	}

	setDate(date) {
		this.date = date
	}

	getDate(date) {
		return this.date
	}
}


let instance = new TransactionDateService();
export default instance;
