import classNames from 'classnames/bind';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Bus from '../../bus';
import predef from '../../predef';
import Button from '../common/button';
import ResponsiveLens from '../common/responsive-lens';
import ProfileMenu from './profile-menu';
import Localization from '../services/localization-service';

import './toolbar.scss';

class Toolbar extends Component {

  constructor(props) {
    super(props);
    this.state = { windowWidth: window.innerWidth };
  }

  render() {

    return <div className="toolbar-c">
      <ResponsiveLens></ResponsiveLens>
      <Button className={classNames({ 'active': Localization.lang == "en" }, "lang")} link label="toolbar.en" onClick={()=>Bus.publish(predef.events.localization.languageSelected,"en")}></Button>
      <Button className={classNames({ 'active': Localization.lang == "pl" }, "lang")} link label="toolbar.pl" onClick={()=>Bus.publish(predef.events.localization.languageSelected,"pl")}></Button>
      <ProfileMenu></ProfileMenu>
      {/* <div className="n-separator">&nbsp;</div> */}
      <Button className={classNames({ 'active': this.props.location.pathname == predef.routes.transaction.transactions })} link fad="search-dollar" path={predef.routes.transaction.transactions}></Button>
      {/* <div className="n-separator">&nbsp;</div> */}
      <Button className={classNames({ 'active': this.props.location.pathname == predef.routes.transaction.transfer })} link fad="exchange" path={predef.routes.transaction.transfer}></Button>

    </div>
  }



  componentDidMount() {
  }

  componentWillUnmount() {
  }

}


Toolbar.propTypes = {

}


export default withRouter(Toolbar);