import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import Bus from '../../bus';
import Button from "../common/button";
import EditableGrid from '../common/grid/editable-grid';
import Loader from '../common/loader';
import MonthInput from '../common/month-input';
import Number from '../common/number';
import Panel from '../common/panel';
import Calendar from '../services/calendar-service';
import PlanningService from '../services/planning-service';
import BudgetsForm from './budgets-form';
import './planner.scss';
import IncomesForm from './incomes-form';
import LoadingPlaceholder from '../common/loading-placeholder';

class Planner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      month: Calendar.todayIso,
      plan: null,
      isPlanLoading: true,
    }
    this.onMonthChange = this.onMonthChange.bind(this);
  }
  onMonthChange(value) {
    this.setState({ month: value })
  }
  render() {
    return <div className="planner-c">
      <h3><FormattedMessage id="planner.planning"></FormattedMessage></h3>
      <Panel>
        <MonthInput label="planner.month" onChange={this.onMonthChange} value={this.state.month}></MonthInput>
        {this.state.plan?.exists ? <Button className="delete-plan" danger link label="planner.delete-plan" onClick={() => this.deletePlan()}></Button> : null}
      </Panel>

      {!this.state.isPlanLoading ? this.renderPlan() : <Panel>
        <LoadingPlaceholder></LoadingPlaceholder>
      </Panel>}

    </div>
  }


  createPlan() {
    PlanningService.createPlan(this.year, this.month).then(x => this.loadPlan());
  }
  deletePlan() {
    PlanningService.deletePlan(this.year, this.month).then(x => this.loadPlan());
  }
  renderPlan() {
    if (!this.state.plan.exists) {
      return <Panel><Button onClick={() => this.createPlan()} label="planner.create-plan" fa="plus"></Button></Panel>
    }

    return <React.Fragment>

      {this.renderIncomes()}
      {this.renderBudgets()}
    </React.Fragment>
  }

  renderIncomes() {
    return <Panel className="plan-panel" header="planner.income">
      <IncomesForm addRecurringPlannedIncomes={() => this.addRecurringPlannedIncomes()} month={this.state.month} incomes={this.state.plan.plannedIncomes} onSubmit={(incomes) => this.saveIncomes(incomes)}></IncomesForm>
    </Panel>
  }

  renderSums(values) {
    return values.groupByCurrency().map((g) => {
      return <Number key={g.key} value={g.values.sum(x => x.value)} currency={g.key}></Number>
    })
  }

  renderBudgets() {
    return <Panel className="plan-panel" header="planner.budgets">
      <BudgetsForm addRecurringBudgets={() => this.addRecurringBudgets()} month={this.state.month} budgets={this.state.plan.budgets} onSubmit={(budgets) => this.saveBudgets(budgets)}></BudgetsForm>
    </Panel>
  }

  saveIncomes(incomes) {
    return new Promise((resolve, reject) => {
      PlanningService.updatePlannedIncomes(incomes, this.year, this.month).then(() => {
        resolve();
        this.loadPlan()
      }).catch(() => {
        reject();
      });
    });
  }

  addRecurringBudgets() {
    PlanningService.addRecurringBudgets(this.state.month).then(() => this.loadPlan());
  }
  addRecurringPlannedIncomes() {
    PlanningService.addRecurringPlannedIncomes(this.state.month).then(() => this.loadPlan());
  }
  saveBudgets(budgets) {
    return new Promise((resolve, reject) => {
      PlanningService.updateBudgets(budgets, this.year, this.month).then(() => {
        resolve();
        this.loadPlan()
      }).catch(() => {
        reject();
      });
    });
  }

  componentDidMount() {
    this.sub = Bus.subscribe(this);
    this.loadPlan();
  }

  loadPlan() {
    this.setState({ isPlanLoading: true }, () => {
      PlanningService.getPlan(this.year, this.month)
        .then(p => {
          this.setState({ plan: p, isPlanLoading: false });
        });
    })

  }

  get month() {
    return Calendar.getMonthFromIso(this.state.month)
  }

  get year() {
    return Calendar.getYearFromIso(this.state.month);
  }

  componentWillUnmount() {
    this.sub.unsubscribe();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.month != this.state.month) {
      this.loadPlan();
    }
  }

}

export default Planner;