import Bus from '../../bus';
import predef from '../../predef';

class EventAggregator {
    init() {
        this.aggregate([
            predef.events.transaction.added,
            predef.events.transaction.updated,
            predef.events.transaction.deleted,
            predef.events.transfer.added,
            predef.events.transfer.updated
        ], predef.events.transaction.transactionsChanged);

        this.aggregate([
            predef.events.account.accountAdded,
            predef.events.account.accountUpdated,
            predef.events.account.accountDeleted,
            predef.events.account.accountRestored,
            predef.events.account.accountsReordered,
        ], predef.events.account.accountsChanged)
    }

    aggregate(events, toEvent) {
        Bus.subscribeTo(events, (args) => Bus.publish(toEvent, args));
    }

}

const instance = new EventAggregator();
export default instance;