import Service from './service';
import predef from '../../predef';
import moment from 'moment';

class ResidentsService extends Service {
    getRealEstateResidents(realEstateId) {
        return this.get(predef.endpoints.resident.realEstateResidnets(realEstateId));
    }
    getAllResidents() {
        return this.get(predef.endpoints.resident.index);
    }
    getResident(residentId) {
        return this.get(predef.endpoints.resident.indexId(residentId));
    }

    addResident(residnet) {
        return this.put(predef.endpoints.resident.index
            , residnet
            , predef.events.resident.residentsChanged
            , null
            , 'resident-service.adding-success'
            , 'resident-service.adding-error');
    }

    updateResident(residnet) {
        return this.post(predef.endpoints.resident.index
            , residnet
            , predef.events.resident.residentsChanged
            , null
            , 'resident-service.updating-success'
            , 'resident-service.updating-error');

    }
    
    deleteResident(residnetId) {
        return this.delete(predef.endpoints.resident.indexId(residnetId)
            , predef.events.resident.residentsChanged
            , null
            , 'resident-service.deleting-success'
            , 'resident-service.deleting-error');
    }


    getStatus(res) {
        if (res.leaseStart == null && res.leaseEnd == null) return null;
        if (res.leaseStart  != null &&  res.leaseEnd == null && moment(res.leaseStart, 'YYYY-MM-DD').isSameOrBefore(moment())) return {text: 'resident-service.renting', success: true};
        if (res.leaseStart != null && res.leaseEnd != null && moment(res.leaseEnd, 'YYYY-MM-DD').isAfter(moment())) return {text: 'resident-service.renting', success: true};
        if (res.leaseEnd != null && moment(res.leaseEnd, 'YYYY-MM-DD').isSameOrBefore(moment())) return {text: 'resident-service.past-resident', secondary: true};
        return null;
      }
}


let instance = new ResidentsService();
export default instance;
