import TransactionsViewStateProvider from '../transactions/transactions-view-state-provider';
class TransactionsStateService {

    constructor() {
        this.viewStateProvider = new TransactionsViewStateProvider();
        this.viewStateProvider.pauseUpdates();
    }

    get viewState() {
        this.viewStateProvider.resumeUpdates();
        return this.viewStateProvider.viewState;
    }

    getViewStateProvider() {
        return this.viewStateProvider;
    }

    updateState(state) {
        this.viewStateProvider.updateState(state);
    }

    pauseUpdates() {
        this.viewStateProvider.pauseUpdates();
    }

    // setState(state) {
    //     this.viewStateProvider.setState(state);
    // }

}


let instance = new TransactionsStateService();
export default instance;
