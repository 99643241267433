import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button, { Cancel, Remove, Save, Edit } from '../common/button';
import Panel from '../common/panel';
import PropTypes from 'prop-types';
import Page from '../common/page';
import classNames from 'classnames/bind';
import { H3 } from '../common/headers';
import SecondaryNavStateService from '../state-services/secondary-nav-state-service-service';
import {NavLink} from 'react-router-dom';

import './secondary-nav.scss';

class SecondaryNav extends Component {

  constructor(props) {
    super(props);
    this.state = { navigation: null };
  }

  render() {
    return this.state.navigation == null ? null : <div className="secondary-nav-c">
      <div className="n-nav">
        {this.state.navigation.map(item => {
          return <div className="n-nav-item" key={item.url}>
            <NavLink activeClassName="active" to={item.url}>
              <FormattedMessage id={item.label}></FormattedMessage>
            </NavLink>
          </div>
        })}
      </div>
    </div>
  }
  
  componentDidMount() {
    this.sub = SecondaryNavStateService.navigation.subscribe(x => this.setState({ navigation: x }));
  }

  componentWillUnmount() {
    this.sub.unsubscribe();
  }

}


SecondaryNav.propTypes = {

}


export default SecondaryNav;