import Service from './service';
import predef from '../../predef';
import Calendar from './calendar-service'
class TodosService extends Service {

    getTodos(ref, refId, date) {
        return this.get(predef.endpoints.todo.get(ref, refId, Calendar.getYearFromIso(date), Calendar.getMonthFromIso(date)));
    }

    getRecurringTodos(ref, refId) {
        return this.get(predef.endpoints.recurringToDo.indexRefId(ref, refId));
    }

    updateRecurringTodos(ref, refId, todos) {
        return this.post(
            predef.endpoints.recurringToDo.indexRefId(ref, refId)
            , todos
            , predef.events.recurringTodos.recurringTodosUpdated
            , todos
            , 'todos-service.recurring-todos-update-success'
            , 'todos-service.recurring-todos-update-error');
    }

    updateTodos(ref, refId, todos) {
        return this.post(
            predef.endpoints.todo.update(ref, refId)
            , todos
            , predef.events.todos.todosUpdated
            , todos
            , 'todos-service.todos-update-success'
            , 'todos-service.todos-update-error');
    }

    createFromRecurringTasks(ref, refId, date) {
        return this.post(
            predef.endpoints.todo.fromRecurring(ref, refId, Calendar.getYearFromIso(date), Calendar.getMonthFromIso(date))
            , null
            , predef.events.todos.todosUpdated
            , null
            , 'todos-service.todos-created-success'
            , 'todos-service.todos-created-error');
    }
}


let instance = new TodosService();
export default instance;
