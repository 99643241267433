import * as echarts from 'echarts/dist/echarts.js';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { guid, downloadBase64Png } from '../../../util';
import Localization from '../../services/localization-service';
import './bar-chart.scss';
import ChartTable from './chart-table';
import Button, { ToggleLink } from '../button';
import { createHashHistory } from 'history';
import Modal from '../modal';
import Number from '../number'
import predef from '../../../predef'
import TransactionList from '../../transactions/transaction-list';
import Indicator from '../../reports/indicator';
import { FormattedMessage } from 'react-intl';
class BarChart extends Component {

  constructor(props) {
    super(props);
    this.state = { showTable: false, showLabels: false, showTransactions: false, showIndicators: false };
    this.id = 'hbc-' + guid();
    this.resize = this.resize.bind(this);
  }

  render() {
    return (
      <div className="bar-chart-c">
        <Modal
          component={TransactionList}
          componentProps={{ initialFilter: this.state.transactionsQuery }}
          onClose={() => this.setState({ showTransactions: false })}
          show={this.state.showTransactions}
          title={'bar-chart.transactions'}
        ></Modal>
        <div className="toolbar">
          {this.props.definition.showIndicators ? <ToggleLink fal="sigma" turnOnLabel="bar-chart.hide-indicators" turnOffLabel="bar-chart.show-indicators" onToggle={(state) => this.setState({ showIndicators: state })} isOn={this.state.showIndicators} ></ToggleLink> : null}
          <ToggleLink fal="tags" turnOnLabel="bar-chart.hide-labels" turnOffLabel="bar-chart.show-labels" onToggle={(state) => this.setState({ showLabels: state }, () => this.setOptions())} isOn={this.state.showLabels}></ToggleLink>
          <ToggleLink fal="table" turnOnLabel="bar-chart.hide-data-in-table" turnOffLabel="bar-chart.show-data-in-table" onToggle={(state) => this.setState({ showTable: state })} isOn={this.state.showTable} ></ToggleLink>
          <Button link fal="download" onClick={() => downloadBase64Png(this.chart.getDataURL({ type: 'png', pixelRatio: 2, backgroundColor: '#fff' }), Localization.formatMessage(this.props.definition.downloadFileName || Localization.formatMessage("common.default-chart-file-name"), this.props.definition.downloadFileNameValues))} label="common.download"></Button>
        </div>
        {this.props.definition.showIndicators && this.state.showIndicators ? <div className="indicators">
          {
            this.props.definition.serie != null ? this.renderSeriesIndicators([this.props.definition.serie]) : null
          }
          {
            this.props.definition.series != null ? this.renderSeriesIndicators(this.props.definition.series) : null
          }
        </div> : null}
        <div style={{ width: "100%", height: this.props.definition.height + "px" }} className="chart" id={this.id}></div>
        {this.state.showTable ? this.renderTable() : null}
      </div>
    );
  }
  renderSeriesIndicators(series) {
    return series.select((serie, i) => {
      var color = typeof serie.itemStyle === 'function' ? serie.itemStyle(serie.y.average()) : serie.itemStyle;
      return <div key={i} style={{ borderLeft: 'solid 0.3rem ' + color?.color }} className="indicator">
        {serie.name != null ? <div className="name"> <FormattedMessage id={serie.name}></FormattedMessage></div> : null}
        <div className="stats">
          <Number value={serie.y.average()} currency={this.props.definition.unit}></Number>
          <FormattedMessage className="secondary-label" id="common.average"></FormattedMessage>
          <Number value={serie.y.sum()} currency={this.props.definition.unit}></Number>
          <FormattedMessage className="secondary-label" id="common.sum"></FormattedMessage>
        </div>
      </div>
    })
  }
  renderTable() {
    return <div className="table-wrapper">
      <ChartTable x={this.props.definition.x.select(x => this.formatXForTooltip(x))} series={this.getSeries().map(s => { return { name: s.name, data: s.data } })} ></ChartTable>
    </div>
  }

  formatXForTooltip(val) {
    if (this.props.definition.formatXForTooltip != null) return xFormatterForTooltip(val);
    if (this.props.definition.timeSeries) return Localization.formatDateMMMMYYYY(val);
    return val;
  }

  formatY(val) {
    if (this.props.definition.formatY != null) return this.props.definition.formatY(val);
    return Localization.formatNumber(val);
  }

  componentDidMount() {
    this.setState({ showLabels: this.props.showLabels }, () => {
      this.chart = echarts.init(document.getElementById(this.id));
      this.setOptions();
      if (this.props.definition.transactionsQuery != null) {
        this.chart.on('click', (params) => {
          let query = this.props.definition.transactionsQuery(this.props.definition.x[params.dataIndex], params.data.value, params.seriesIndex, params.dataIndex);
          this.setState({ transactionsQuery: query, showTransactions: true });
        })
      }
      window.addEventListener("resize", this.resize);
    });
  }

  setOptions() {
    var option = {
      title: {
      },
      legend: {
        show: this.props.definition.showLegend,
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(50,50,50,0.9)',
        formatter: this.props.definition.tooltipFormatter || ((args) => {
          let series = this.props.definition.series || [this.props.definition.serie];
          let tooltip = `<p>${this.formatXForTooltip(args[0].axisValue)}</p> `;
          args.forEach(({ marker, dataIndex, seriesIndex }) => {
            let serie = series[seriesIndex];
            tooltip += `<p>${marker} ${(serie.name ? Localization.formatMessage(serie.name) + ':' : '')} ${this.formatY(serie.y[dataIndex])}</p>`;
          });
          return tooltip;
        }),
      },
      // toolbox: {
      //   feature: {
      //     saveAsImage: { title: Localization.formatMessage('common.save-as-image') }
      //   },
      //   right: 50
      // },
      xAxis: {
        data: this.props.definition.x,
        axisTick: {
          alignWithLabel: true,
        },
        axisLabel: {
          show: true,
          formatter: this.props.definition.formatXLabel || (this.props.definition.timeSeries ? (d) => Localization.formatDateMMMYY(d) : null)
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      yAxis: {},
      series: this.getSeries()
    };
    if (this.props.definition.verticalBars) {
      let xa = option.xAxis;
      option.xAxis = option.yAxis;
      option.yAxis = xa;
    }
    if (this.props.definition.override) {
      option.grid = { ...option.grid, ...(this.props.definition.override.grid || {}) }
      option.legend = { ...option.legend, ...(this.props.definition.override.legend || {}) }
    }
    this.chart.setOption(option);


  }

  resize() {
    if (this.chart != null) {
      this.chart.resize();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }



  getSeries() {
    if (this.props.definition.serie == null) {
      return this.props.definition.series.select(x => this.getSerie(x));
    }

    return [this.getSerie(this.props.definition.serie)];
  }

  getSerie(serie) {
    return {
      name: serie.name ? Localization.formatMessage(serie.name) : '',
      type: serie.type || 'bar',
      stack: serie.type === 'line' ? null : "one",
      barWidth: '40%',
      color: serie.color,
      data: serie.y.select(x => { return { value: x, itemStyle: serie.itemStyle ? serie.itemStyle(x) : null }; }),
      label: {
        normal: {
          show: this.state.showLabels,
          position: this.getLabelPosition(),
          formatter: serie.barLabelFormatter || (v => Localization.formatNumber(v.value))
        }
      },
      lineStyle: serie.lineStyle
    }
  }

  getLabelPosition() {
    if (this.props.definition.series?.length > 1) return 'inside';
    return this.props.definition.verticalBars ? 'right' : 'top';
  }

}

BarChart.propTypes = {
  definition: PropTypes.shape({
    x: PropTypes.array.isRequired,
    serie: PropTypes.shape({
      y: PropTypes.array.isRequired,
      barLabelFormatter: PropTypes.func,
      itemStyle: PropTypes.func
    }),
    height: PropTypes.number.isRequired
  }).isRequired
}


export default BarChart;