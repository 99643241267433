import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button, { Cancel, Remove, Save, Edit } from '../common/button';
import Panel from '../common/panel';
import PropTypes from 'prop-types';
import Page from '../common/page';
import classNames from 'classnames/bind';
import { H2 } from '../common/headers';

import './close-investment.scss';
import LoadingPlaceholder from '../common/loading-placeholder';
import DateInput from '../common/date-input';
import Calendar, { IsoCalendar } from '../services/calendar-service';
import InvestmentService from '../services/investments-service';
import { routeParamId } from '../../util';
import { createHashHistory } from 'history';
const history = createHashHistory();
import CloseInvestmentForm from './close-investment-form';

class CloseInvestment extends Component {

  constructor(props) {
    super(props);
    this.state = { isLoading: true };
  }

  render() {
    return <div className="close-investment-c">
      {this.state.isLoading ? <LoadingPlaceholder></LoadingPlaceholder> : this.renderBody()}
    </div>
  }

  renderBody() {
    return <React.Fragment>
      <H2 header="close-investment.header">{this.state.investment?.name}</H2>
      <Panel><CloseInvestmentForm onSaved={()=> {
        history.push(predef.routes.investments.index);
      }} investmentId={this.state.investment.id}></CloseInvestmentForm></Panel>
    </React.Fragment>
  }



  componentDidMount() {
    let id = this.props.investmentId || routeParamId(this.props);

    InvestmentService.getInvestment(id).then(inv => this.setState({ investment: inv, isLoading: inv == null }));
  }

  componentWillUnmount() {
  }

}


CloseInvestment.propTypes = {

}


export default CloseInvestment;