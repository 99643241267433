import Api from '../../api';
import predef from '../../predef';

class ReportService {
    expensesByCategory(dateRange, includeIncomeCost) {
        return Api.post(predef.endpoints.report.expensesByCategory, { dateFrom: dateRange.dateFrom, dateTo: dateRange.dateTo, includeIncomeCost: includeIncomeCost });
    }

    expenseIncomeStructure(dateRange) {
        return Api.post(predef.endpoints.report.expenseIncomeStructure, dateRange || {});
    }

    savingFactor(dateRange) {
        return Api.post(predef.endpoints.report.savingFactor, dateRange || {});
    }

    netValueHistory() {
        return Api.get(predef.endpoints.report.netValueHistory);
    }

    netValue() {
        return Api.get(predef.endpoints.report.netValue);
    }

    budgetsInMonth(year, month) {
        return Api.get(predef.endpoints.report.budgetsInMonth(year, month));
    }

    incomeStreamsForMonth(year, month) {
        return Api.get(predef.endpoints.report.incomeStreamsForMonth(year, month));
    }

    incomeStreamIndicators(year, month, incomeStreamId) {
        return Api.get(predef.endpoints.report.incomeStreamIndicators(year, month, incomeStreamId));
    }

    incomeStreamIndicatorsOverTime(datesRange, incomeStreamId) {
        return Api.post(predef.endpoints.report.incomeStreamIndicatorsOverTime(incomeStreamId), datesRange);
    }

    getYearlyCashflowReport(reportRef, refId, year) {
        switch (reportRef) {
            case predef.yearlyCashFlowReportRefs.realEstate:
                return Api.get(predef.endpoints.realEstate.yearlyCashFlowReport(refId, year))
            case predef.yearlyCashFlowReportRefs.incomeStream:
                return Api.get(predef.endpoints.incomeStreams.cashflowMatrix(refId, year))
        }
    }

    getFutureBalance() {
        return Api.get(predef.endpoints.report.futureBalance);
    }

}


let instance = new ReportService();
export default instance;
