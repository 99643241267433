import { BehaviorSubject } from "rxjs";
import { deepCopy, deepEqual } from "../../util";
import Transactions from '../services/transactions-service';
import Bus from '../../bus';

export default class TransactionsViewStateProvider {
    constructor(filter
        , pagging
        , columnsConfig
        , disabledFilters) {
        this.initialState = {
            filter: deepCopy(filter) || {},
            pagging: pagging || { page: 1, pageSize: 50 },
            totalRows: 0,
            columnsConfig: columnsConfig,
            disabledFilters: disabledFilters || {},
            isDeletingTransactions: [],
            isEditingTransaction:[],
            transactions: [],
            isLoading: true,
        };

        this.currentState = deepCopy(this.initialState);

        this.viewStateSubject = new BehaviorSubject(this.currentState);
        this.lastQuery = null;
        this.loadTransactions();
        this.busSub = Bus.subscribe(this);

        this.stale = false;
        this.allowUpdates = true;
    }

    onTransactionDelete(transaction) {
        let idts = deepCopy(this.currentState.isDeletingTransactions);
        idts.push(transaction.id);
        this.updateState({ isDeletingTransactions: idts });

        let setNotDeleting = () => {
            let idts = deepCopy(this.currentState.isDeletingTransactions);
            idts.remove(transaction.id);
            this.updateState({ isDeletingTransactions: idts });
        }

        Transactions.deleteTransaction(transaction).then(() => {
            setNotDeleting();
        }).catch((e) => {
            setNotDeleting();
        })
    }

    onUpdateTransactionDate(id,date) {
        let ids = deepCopy(this.currentState.isEditingTransaction);
        ids.push(id)
        this.updateState({ isEditingTransaction: ids });

        let setNotEditingTransaction = () => {
            let idts = deepCopy(this.currentState.isEditingTransaction);
            idts.remove(id);
            this.updateState({ isEditingTransaction: idts });
        }

        Transactions.updateTransactionDate(id, date).then(() => {
            setNotEditingTransaction();
        }).catch((e) => {
            setNotEditingTransaction();
        })
    }

    pauseUpdates() {
        this.allowUpdates = false;
    }

    resumeUpdates() {
        this.allowUpdates = true;
        if (this.stale) {
            this.loadTransactions(true);
            this.state = false;
        }
    }

    loadTransactions(force) {
        let filter = deepCopy(this.currentState.filter);
        if (filter.date != null) {
            filter.dateFrom = filter.date.dateFrom;
            filter.dateTo = filter.date.dateTo;
            filter.date = null;
        }

        let query = {
            filter: filter,
            paging: {
                page: this.currentState.pagging.page,
                pageSize: this.currentState.pagging.pageSize
            }
        }

        if (!force && (deepEqual(query, this.lastQuery))) return;

        this.updateState({ isLoading: true });

        Transactions.query(query).then((res) => {
            this.updateState({
                isLoading: false,
                transactions: res.items,
                totalRows: res.totalItems
            });
        });
    }

    get viewState() {
        return this.viewStateSubject.asObservable();
    }

    updateState(state) {
        for (let key in state) {
            this.currentState[key] = state[key];
        }
        this.viewStateSubject.next(this.currentState);
        if (state.filter != null || state.pagging != null) {
            this.loadTransactions();
        }
    }

    // setState(state) {
    //     this.currentState = deepCopy(this.initialState);
    //     this.updateState(state);
    // }

    _onTransactionsChanged() {
        if (!this.allowUpdates) {
            this.stale = true;
            return;
        }
        this.loadTransactions(true);
    }

    finally() {
        this.busSub.unsubscribe();
    }

}