import { BehaviorSubject } from 'rxjs';
import predef from '../../predef';

class CurrenciesStore {
    constructor() {
        let currencies = predef.currencies.map(c => { return { value: c, label: c } });
        this._subject = new BehaviorSubject(currencies);
    }

    get currencies() {
        return this._subject.asObservable();
    }

}

const instance = new CurrenciesStore();
export default instance;