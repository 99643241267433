import predef from '../../predef';
import Store from './store';

class IncomeStreamsStore {
    constructor() {
        this.incomeStreamsStore = new Store(predef.endpoints.incomeStreams.index,
            [predef.events.incomeStreams.incomeStreamsChanged], predef.events.incomeStreams.incomeStreamsStoreUpdated);

    }

    get incomeStreams() {
        return this.incomeStreamsStore.asObservable();
    }

    byId(id) {
        return new Promise((resolve, _) => {
            this.incomeStreams.subscribe(entities => {
                if (entities == null) return;

                resolve(entities.single(x => x.id == id));
            });
        })
    }


}

const instance = new IncomeStreamsStore();
export default instance;
