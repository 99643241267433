import React, { Component } from 'react';
import OperationTagsStore from '../stores/operation-tags-store';
import './operation-tags-input.scss';
import TagsInput from './tags-input';

class OperationTagsInput extends Component {

  constructor(props) {
    super(props);
    this.state = { options: [] };
  }

  render() {
    return <TagsInput {...this.props} options={this.state.options}></TagsInput>
  }



  componentDidMount() {
    this.sub = OperationTagsStore.operationTags.subscribe(ts => this.setState({ options: ts }));
  }

  componentWillUnmount() {
    this.sub.unsubscribe();
  }

}


OperationTagsInput.propTypes = {

}


export default OperationTagsInput;