import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button, { Cancel, Remove, Save, Add } from '../common/button';
import Panel from '../common/panel';
import Page from '../common/page';
import { H3 } from '../common/headers';
import './assets.scss';
import AssetsService from '../services/assets-service';
import { Table, THead, TColumn, TBody } from '../common/table';
import UserSettings from '../stores/user-settings-store';
import Textbox from '../common/textbox';
import NumberInput from '../common/number-input';
import { CurrencySelect } from '../common/currency-select';
import * as yup from 'yup';
import { validateAll, validate } from '../../util';
import { createHashHistory } from 'history';
const history = createHashHistory();

const validationSchema = yup.object().shape({
  name: yup.string().required().min(1),
  value: yup.number().required().min(0),
  mortgage: yup.number().required().min(0)
});

class Assets extends Component {

  constructor(props) {
    super(props);
    this.state = { isLoading: true, assets: null, defaultCurrency: null, saving: false, submitted: false, errors: null };
    this.renderBody = this.renderBody.bind(this);
  }

  render() {
    return <Page
      className="assets-c"
      header="assets.header"
      loading={this.state.isLoading }
      data={true}
      renderBody={this.renderBody}
    >
    </Page>
  }
  onChange(i, name, value) {
    this.state.assets[i][name] = value;
    let errors = null;

    if (this.state.submitted) {
      errors = validateAll(validationSchema, this.state.assets);
    }

    this.setState({ assets: this.state.assets, errors: errors });
  }
  renderBody() {
    return <React.Fragment>
      <Table>
        <THead>
          <TColumn header="common.name"></TColumn>
          <TColumn className="len-150" header="assets.value"></TColumn>
          <TColumn className="len-150" header="assets.mortgage"></TColumn>
          <TColumn className="len-120" header="common.currency"></TColumn>
          <TColumn></TColumn>
        </THead>
        <TBody>
          {this.state.assets.map((a, i) => <tr key={i}>
            <td><Textbox onChange={(val) => { this.onChange(i, 'name', val) }}
              hasError={this.state.errors != null && this.state.errors[i].name != null}
              name="name"
              value={this.state.assets[i].name}
              errorMessage="common.field-is-required"
            ></Textbox></td>
            <td>
              <NumberInput onChange={(val) => { this.onChange(i, 'value', val) }}
                hasError={this.state.errors != null && this.state.errors[i].value != null}
                name="value"
                value={this.state.assets[i].value}
                errorMessage="common.field-is-required"
              ></NumberInput>
            </td>
            <td>
              <NumberInput onChange={(val) => { this.onChange(i, 'mortgage', val) }}
                hasError={this.state.errors != null && this.state.errors[i].mortgage != null}
                name="mortgage"
                value={this.state.assets[i].mortgage}
                errorMessage="common.field-is-required"
              ></NumberInput>
            </td>
            <td>
              <CurrencySelect onChange={(val) => { this.onChange(i, 'currency', val) }}
                name="currency"
                value={this.state.assets[i].currency}
                isClearable={false}
                errorMessage="common.field-is-required"></CurrencySelect>
            </td>
            <td>
              <Remove onClick={() => {
                let assets = this.state.assets.removeAt(i);
                this.setState({ assets: assets });
              }}></Remove>
            </td>
          </tr>)}
        </TBody>
        <tfoot>
          <tr>
            <td colSpan="5">
              <Add className="add-btn" onClick={() => {
                this.state.assets.push({ currency: this.state.defaultCurrency, name: '' });
                this.setState({ assets: this.state.assets });
              }}></Add>
            </td>
          </tr>
        </tfoot>
      </Table>
      <div className="actions">
        <Cancel path={predef.routes.netValue.index}></Cancel>
        <Save className="save-btn" loading={this.state.saving} onClick={() => this.save()}></Save>
      </div>
    </React.Fragment>;
  }

  save() {
    this.setState({ submitted: true, saving: true }, () => {
      let errors = validateAll(validationSchema, this.state.assets);
      if (errors.all(x => !x.hasErrors)) {
        AssetsService.updateAssets(this.state.assets).then(x => history.push(predef.routes.netValue.index))
          .catch(() => this.setState({ saving: false }))
      }
      else {
        this.setState({ errors: errors, saving: false })
      }
    })
  }

  componentDidMount() {
    AssetsService.getAssets()
    .then(assets => this.setState({ assets: assets, isLoading: false }))
    .catch(e => this.setState({ isLoading: false }))
    UserSettings.defaultCurrency.subscribe(c => {
      this.setState({ defaultCurrency: c })
    })
  }

  componentWillUnmount() {
  }

}

export default Assets;