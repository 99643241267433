import classNames from 'classnames/bind';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Number from '../common/number';
import './indicator.scss';


const multiIndicators = (name, color, indicators) => {
  return <div className="dropdown-menu">
    <div><FormattedMessage id={name}></FormattedMessage></div>
    {indicators.skip(1).map((ind, i) => {
      return <div className={classNames("secondary-indicator", {
        'red': (color == 'value-based' && ind.value < 0) || color == 'red',
        'green': (color == 'value-based' && ind.value > 0) || color == 'green',
        'grey': (color == 'grey') || (color == 'value-based' && ind.value == 0),
        'blue': color == 'blue',
      })} key={i}>

        <span className="value"><Number value={ind.value} ></Number></span>
        <span className="unit">{ind.currency}</span>
      </div>
    })}
  </div>
}

const Indicator = (props) => {
  if (props.data == null) return null;
  let { data: { color, name, unit, size, indicators } } = props;

  let primaryValue = indicators[0].value;
  let multiInds = indicators.length > 1;
  let hasBaseCurrency = indicators.any(x => x.baseCurrency != null);

  if (hasBaseCurrency)
    return IndicatorWithBaseCurrency(props);

  return <React.Fragment>
    <div className={classNames('indicator-c ', size, {
      'red': (color == 'value-based' && primaryValue < 0) || color == 'red',
      'green': (color == 'value-based' && primaryValue > 0) || color == 'green',
      'grey': (color == 'grey') || (color == 'value-based' && primaryValue == 0),
      'blue': color == 'blue',
      'multiple-indicators': multiInds,
      'dropdown': multiInds
    })}>
      <div data-toggle={(multiInds ? "dropdown" : null)}>
        <div className={classNames('primary-value')}>
          <span className="value"><Number value={indicators[0].value} ></Number></span>
          <span className="unit">{indicators[0].currency}</span>
        </div>
        <div className="label">
          <FormattedMessage id={name}></FormattedMessage> <span className={classNames({ 'drop-down-arrow fas fa-chevron-down': indicators.length > 1 })}></span>
        </div>
      </div>
      {(multiInds ? multiIndicators(name, color, indicators) : null)}
    </div>
  </React.Fragment>
}


const multiIndicatorsWithBaseCurrency = (name, color, indicators) => {
  var commonSum = indicators.first();
  var common = indicators.skip(1).where(x=> x.baseCurrency != null);
  var rest = indicators.skip(1).where(x=>x.baseCurrency == null);
  return <div className="dropdown-menu">
    <div><FormattedMessage id={name}></FormattedMessage></div>
    {common.map((ind, i) => {
      return <div className={classNames("secondary-indicator", {
        'red': (color == 'value-based' && ind.value < 0) || color == 'red',
        'green': (color == 'value-based' && ind.value > 0) || color == 'green',
        'grey': (color == 'grey') || (color == 'value-based' && ind.value == 0),
        'blue': color == 'blue',
      })} key={i}>
        <span className="fa fa-plus plus" ></span>
        <span className="unit">{ind.currency}</span>
        <span className="value"><Number value={ind.value} ></Number></span>
       
      </div>
    })}
    <hr></hr>
    <div className={classNames("secondary-indicator secondary-indicators-sum", {
        'red': (color == 'value-based' && commonSum.baseCurrencyValue < 0) || color == 'red',
        'green': (color == 'value-based' && commonSum.baseCurrencyValue > 0) || color == 'green',
        'grey': (color == 'grey') || (color == 'value-based' && commonSum.baseCurrencyValue == 0),
        'blue': color == 'blue',
      })} key="common-sum">
        <span className="fa fa-equals plus"></span>
        <span className="unit">{commonSum.baseCurrency}</span>
        <span className="value"><Number value={commonSum.baseCurrencyValue} ></Number></span>
        
      </div>

   {rest.length < 1 ? null : <React.Fragment>
    <hr/>
      <div className="other"><FormattedMessage id="indicator.other"></FormattedMessage></div>
        {rest.map((ind, i) => {
            return <div className={classNames("secondary-indicator", {
              'red': (color == 'value-based' && ind.value < 0) || color == 'red',
              'green': (color == 'value-based' && ind.value > 0) || color == 'green',
              'grey': (color == 'grey') || (color == 'value-based' && ind.value == 0),
              'blue': color == 'blue',
            })} key={i}>
              <span className="unit">{ind.currency}</span>
              <span className="value"><Number value={ind.value} ></Number></span>
            
            </div>
          })}</React.Fragment>}
  </div>
}

export const IndicatorWithBaseCurrency = (props) => {
  if (props.data == null) return null;
  let { data: { color, name, unit, size, indicators } } = props;
  let primaryValue = indicators[0].value;
  let multiInds = indicators.length > 1;


  return <div className={classNames('indicator-c ', size, {
    'red': (color == 'value-based' && primaryValue < 0) || color == 'red',
    'green': (color == 'value-based' && primaryValue > 0) || color == 'green',
    'grey': (color == 'grey') || (color == 'value-based' && primaryValue == 0),
    'blue': color == 'blue',
    'multiple-indicators': multiInds,
    'dropdown': multiInds
  })}>
    <div data-toggle={(multiInds ? "dropdown" : null)}>
      <div className={classNames('primary-value')}>
      { indicators[0].value> 0 || indicators.length>1 ? <span className="approx">~</span> : null}<span className="value"><Number value={indicators[0].value} ></Number></span>
        <span className="unit">{indicators[0].currency}</span>
      </div>
      <div className="label">
        <FormattedMessage id={name}></FormattedMessage> <span className={classNames({ 'drop-down-arrow fas fa-chevron-down': indicators.length > 1 })}></span>
      </div>
    </div>
    {(multiInds ? multiIndicatorsWithBaseCurrency(name, color, indicators) : null)}
  </div>
};


export default Indicator;