import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import Bus from "../../bus";
import predef from "../../predef";
import Button, { Cancel, Remove, Save, Edit } from "../common/button";
import Panel from "../common/panel";
import PropTypes from "prop-types";
import Page from "../common/page";
import classNames from "classnames/bind";
import { H3 } from "../common/headers";
import InvestmentsStore from "../stores/investments-store";
import LoadingPlaceholder from "../common/loading-placeholder";
import Grid from "../common/grid/grid";
import Date from "../common/date";
import Number from "../common/number";
import InvestmentsService from "../services/investments-service";
import "./investments.scss";
import { NoDataMessage } from "../common/message";
import { guid } from "../../util";
import Decimal from 'decimal.js'

class Investments extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true };
  }

  render() {
    return <div className="investments-c">{this.renderBody()}</div>;
  }

  renderBody() {
    return (
      <React.Fragment>
        <Panel>
          <Button
            outline
            label="investments.add-investment"
            fa="plus"
            path={predef.routes.investments.add}
          ></Button>
        </Panel>
        <div className="open-investments">
          <H3 header="investments.open-investments"></H3>
          <Panel>
            {this.state.isLoading ? (
              <LoadingPlaceholder></LoadingPlaceholder>
            ) : (
              this.renderOpenInvestmentsGrid()
            )}
          </Panel>
        </div>
        <div className="closed-investments">
          <H3 header="investments.closed-investments"></H3>
          <Panel>
            {this.state.isLoading ? (
              <LoadingPlaceholder></LoadingPlaceholder>
            ) : (
              this.renderClosedInvestmentsGrid()
            )}
          </Panel>
        </div>
      </React.Fragment>
    );
  }

  renderOpenInvestmentsGrid() {
    let open = this.state.investments.where((x) => !x.isClosed);
    if (open.length == 0) return <NoDataMessage></NoDataMessage>;
    return (
      <Grid
        key={this.state.key}
        pageSize={20}
        id="open-investments"
        data={open}
        definition={[
          {
            show: true,
            name: "name",
            headerClasses: "len-300",
            header: "investments.name",
            renderCell: (i) => (
              <Button link path={predef.routes.investments.dashboard(i.id)}>
                {i.name}
              </Button>
            ),
            sortable: true,
            value: (i) => i.name,
          },
          {
            show: true,
            headerClasses: "len-160",
            header: "investments.invested-amount",
            name: "investedAmount",
            renderCell: (inv) => <Number value={inv.investedAmount} currency={inv.currency}></Number>,
            sortable: true,
            value: (i) => i.investedAmount,
          },
          {
            show: true,
            headerClasses: "len-160",
            header: "investments.current-value",
            name: "currentValue",
            renderCell: (inv) => <Number value={inv.currentValue} currency={inv.currency}></Number>,
            sortable: true,
            value: (i) => i.currentValue,
          },
          {
            show: true,
            headerClasses: "len-160",
            header: "investments.expected-return",
            name: "expectedReturn",
            renderCell: (inv) => {
              return inv.expectedReturn != null
                ? inv.expectedReturn + "%"
                : null;
            },
            sortable: true,
            value: (i) => i.expectedReturn,
          },
          {
            show: true,
            headerClasses: "len-160",
            header: "investments.open-for",
            name: "openFor",
            renderCell: (inv) => {
              return inv.yearsOpen != null 
                ?inv.yearsOpen
                : null;
            },
            sortable: true,
            value: (i) => i.yearsOpen,
          },
          {
            show: true,
            headerClasses: "len-160",
            header: "investments.total-return-to-date",
            name: "returnPercent",
            renderCell: (inv) => {
              return inv.returnPercentToDate != null 
                ?inv.returnPercentToDate+"%"
                : null;
            },
            sortable: true,
            value: (i) => i.returnPercentToDate,
          },
          {
            show: true,
            headerClasses: "len-180",
            header: "investments.annualized-return",
            name: "annualizedReturn",
            renderCell: (inv) => {
              return inv.annualizedRateOfReturn != null
                ? inv.annualizedRateOfReturn + "%"
                : null;
            },
            sortable: true,
            value: (i) => i.annualizedRateOfReturn,
          },
          {
            show: true,
            headerClasses: "len-160",
            header: "investments.start-date",
            name: "startDate",
            renderCell: (inv) => <Date date={inv.startDate}></Date>,
            sortable: true,
            value: (i) => i.startDate,
          },
          {
            show: true,
            headerClasses: "len-160",
            header: "investments.expected-close-date",
            name: "expectedCloseDate",
            renderCell: (inv) => <Date date={inv.expectedCloseDate}></Date>,
            sortable: true,
            value: (i) => i.expectedCloseDate,
          },
          {
            show: true,
            name: "actions",
            headerDisplayName: "common.actions",
            renderCell: (inv) => (
              <React.Fragment>
                <Button
                  link
                  label="common.edit"
                  path={predef.routes.investments.edit(inv.id)}
                ></Button>
                <Button
                  link
                  label="investments.close-investment"
                  path={predef.routes.investments.close(inv.id)}
                ></Button>
                <Button
                  link
                  label="common.delete"
                  danger
                  promiseSuccessEvent={
                    predef.events.investment.investmentChanged
                  }
                  command={{
                    command: () => InvestmentsService.deleteInvestment(inv.id),
                    successEvent: predef.events.investment.investmentsChanged,
                  }}
                ></Button>
              </React.Fragment>
            ),
          },
        ]}
      ></Grid>
    );
  }
  renderClosedInvestmentsGrid() {
    var closed = this.state.investments.where((x) => x.isClosed);
    if (closed.length == 0) return <NoDataMessage></NoDataMessage>;

    return (
      <Grid
        key={this.state.key}
        id="closed-investments"
        pageSize={20}
        data={closed}
        definition={[
          {
            show: true,
            headerClasses: "len-300",
            header: "investments.name",
            renderCell: (i) => (
              <Button link path={predef.routes.investments.dashboard(i.id)}>
                {i.name}
              </Button>
            ),
            value: (i) => i.name,
            sortable: true,
          },
          {
            show: true,
            headerClasses: "len-160",
            header: "investments.return",
            renderCell: (inv) => inv.averageYearlyReturnPercent + "%",
            sortable: true,
          },
          {
            show: true,
            headerClasses: "len-160",
            header: "investments.start-date",
            renderCell: (inv) => <Date date={inv.startDate}></Date>,
            sortable: true,
          },
          {
            show: true,
            headerClasses: "len-160",
            header: "investments.close-date",
            renderCell: (inv) => <Date date={inv.closeDate}></Date>,
            sortable: true,
          },
          {
            show: true,
            headerClasses: "len-160",
            name: "actions",
            renderCell: (inv) => (
              <React.Fragment>
                <Button
                  link
                  label="common.edit"
                  path={predef.routes.investments.edit(inv.id)}
                ></Button>
                <Button
                  link
                  label="investments.reopen-investment"
                  command={{
                    command: () => InvestmentsService.reopenInvestment(inv.id),
                  }}
                ></Button>
                <Button
                  link
                  label="common.delete"
                  danger
                  command={{
                    command: () => InvestmentsService.deleteInvestment(inv.id),
                    successEvent: predef.events.investment.investmentChanged,
                  }}
                ></Button>
              </React.Fragment>
            ),
            sortable: true,
          },
        ]}
      ></Grid>
    );
  }
  renderActions() {
    return (
      <React.Fragment>
        <Button
          label="investments.add"
          fa="plus"
          width="8"
          path={predef.routes.investments.add}
        ></Button>
      </React.Fragment>
    );
  }

  componentDidMount() {
    this.sub = InvestmentsStore.investments.subscribe((investments) => {
      if (investments == null) return;
      this.setState({
        investments: investments,
        open: investments.where((x) => !x.isClosed),
        closed: investments.where((x) => x.isClosed),
        isLoading: false,
        key: guid(),
      });
    });
  }

  componentWillUnmount() {
    this.sub?.unsubscribe();
  }
}

Investments.propTypes = {};

export default Investments;
