import React, { Component } from 'react';
import './number-input.scss';
import { FormattedMessage } from 'react-intl';
import Parsing from '../services/parsing-service';
import Localization from '../services/localization-service';
import Bus from '../../bus';
import { guid, replaceAll, onEnterKey } from '../../util';
import classNames from 'classnames/bind';

export default class NumberInput extends Component {
    constructor(props) {
        super(props);
        let id = "number-input-" + replaceAll(guid(), "-", "");
        this.state = {
            id: id,
            isTourOpen: false,
            lastReportedValue: null,
            textValue: '',
        };
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onPaste = this.onPaste.bind(this);
    }

    onChange({ target }) {
        let val = target.value;
        this.setValue(val);
    }

    onBlur({ target }) {
        if (target.value == '') {
            return;
        };
        this.setState((state, props) => {
            return {
                textValue: Localization.formatNumber(state.lastReportedValue)
            }

        }, () => this.props.onBlur?.());
    }

    setValue(val) {
        if (val == '') val = null;

        if (val != null && !Parsing.isValidNumber(val)) {
            this.setState({ hasError: true, message: 'number-input.invalid-value', textValue: val });
            return;

        }

        let numVal = Parsing.parseNumber(val);
        this.props.onChange(numVal == null ? undefined : numVal);

        this.setState({
            textValue: val,
            lastReportedValue: numVal,
            hasError: false,
            errorMessage: undefined
        });
    }

    onKeyDown(event) {
        let allowdKeyCodes = [8, 17, 46, 37, 39, 32, 188, 190, 9, 13, 110];
        let allowedWithCtrl = [86, 67, 88];

        if (this.props.allowNegative) {
            allowdKeyCodes.push(173);
            allowdKeyCodes.push(109);
        }

        if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) || allowdKeyCodes.indexOf(event.keyCode) > -1 || (event.ctrlKey && allowedWithCtrl.indexOf(event.keyCode) > -1)) {
            return;
        }

        event.preventDefault();
    }

    onPaste(e) {
        e.preventDefault();
        var text = e.clipboardData.getData('Text');

        if (text == '' || text == null) return;

        text = text.replace(/[^0-9\.\,]/g, '')

        if (!Parsing.isValidNumber(text.trim())) return;

        let number = Parsing.parseNumber(text);
        if (number == null) return;

        this.props.onChange(number == null ? undefined : number);

        if (number == null) return;

        this.setState({ textValue: Localization.formatNumber(number) });
    }

    render() {
        return (
            <div className={classNames("fc number-input-fc",
                `${this.props.name}-field`,
                "form-group",
                {
                    'has-error': this.props.hasError,
                    'no-label': this.props.label == null
                }
            )} id={this.state.id} >
                {this.props.label != null ? <label ><FormattedMessage id={this.props.label}></FormattedMessage> </label> : null}

                <input type="text"
                    onKeyDown={this.onKeyDown}
                    autoComplete={this.props.autoComplete == null ? 'off' : this.props.autoComplete}
                    className={"form-control" + (this.props.hasError ? ' is-invalid' : '')}
                    name={this.props.name}
                    placeholder={this.props.placeholder ? Localization.formatMessage(this.props.placeholder) : null}
                    onBlur={this.onBlur}
                    onChange={(v) => this.onChange(v)}
                    value={this.state.textValue == null ? '' : this.state.textValue}
                    onPaste={this.onPaste}
                    onKeyPress={(e) => onEnterKey(e, this.props.onSubmit)}
                />
                {
                    this.props.hasError
                        ? <div className="error-message invalid-feedback">
                            <FormattedMessage id={this.props.errorMessage || 'number-input.invalid-number'}></FormattedMessage>
                        </div>
                        : null
                }
            </div >
        );
    }

    static getDerivedStateFromProps(props, state) {
        if (state.lastReportedValue != props.value) {

            if (props.value == null) {
                return {
                    textValue: '',
                    lastReportedValue: null
                }
            }

            return {
                textValue: Localization.formatNumber(props.value),
                lastReportedValue: props.value
            };
        }
        return null;
    }

    componentDidMount() {
        this.busSub = Bus.subscribe(this);
    }

    componentWillUnmount() {
        this.busSub.unsubscribe();
    }

    _onLanguageChanged(lang) {
        this.setValue(Localization.formatNumber(this.state.lastReportedValue));
    }

}

