import Decimal from "decimal.js";
import { createHashHistory } from "history";
import React, { Component } from "react";
import * as yup from "yup";
import predef from "../../predef";
import { deepCopy, validate } from "../../util";
import Button from "../common/button";
import Page from "../common/page";
import Calendar from "../services/calendar-service";
import Transactions from "../services/transactions-service";
import AccountsStore from "../stores/accounts-store";
import TransferForm from "./transfer-form";
import Panel from "../common/panel";
import TransactionDateService from "../services/transaction-date-service";
import "./transfer.scss";
import TransferSummary from "./transfer-summary";
const history = createHashHistory();

export const transferValidationSchema = yup.object().shape({
  accountFromId: yup.string().required(),
  accountToId: yup.string().required(),
  amount: yup.number().required(),
  date: yup.string().required().min(10),
});

class Transfer extends Component {
  constructor(props) {
    super(props);
    var transfer = this.emptyTransfer();
    if (props.amount) transfer.amount = this.props.amount;
    if (props.description) transfer.description = this.props.description;
    if (props.date) transfer.date = this.props.date;
    this.state = {
      transfer: transfer,
      errors: {},
      submitted: false,
      loading: true,
    };
  }

  emptyTransfer() {
    return {
      accountFromId: this.props.accountFrom?.id,
      accountToId: this.props.accountTo?.id,
      currencyFrom: this.props.accountFrom?.currency,
      currencyTo: this.props.accountTo?.currency,
      description: null,
      amount: null,
      date: TransactionDateService.getDate(),
      exchangeType: "total-amount",
    };
  }

  validateForm() {
    let result = {};
    if (!this.state.submitted) {
      this.setState({ errors: result });
      return result;
    }

    result = validate(transferValidationSchema, this.state.transfer);
    this.setState({ errors: result });

    return result;
  }

  getAmountTo() {
    if (this.state.currencyFrom == this.state.currencyTo) {
      return this.state.amount;
    }

    if (this.state.transfer.exchange == "exchange") {
      let at = new Decimal(this.state.transfer.amount).times(
        new Decimal(this.state.transfer.exchangeRate)
      );
      return at.toNumber();
    }

    return this.state.transfer.totalAmount;
  }

  transfer() {
    this.setState({ submitted: true }, () => {
      let result = this.validateForm();

      if (result.hasErrors) return;

      let transfer = deepCopy(this.state.transfer);
      transfer.amountTo = this.getAmountTo();

      this.setState({ isSaving: true }, () => {
        if (this.state.mode == "new") {
          Transactions.makeTransfer(transfer)
            .then(() => {
              this.setState({
                submitted: false,
                transfer: this.emptyTransfer(),
                isSaving: false,
                accountFrom: null,
                accountTo: null,
                currencyFrom: null,
                currencyTo: null,
              });
            })
            .catch(() => {
              this.setState({
                submitted: true,
                isSaving: false,
              });
            });
        } else {
          Transactions.editTransfer(transfer)
            .then(() => {
              history.push(atob(this.state.returnPath));
            })
            .catch(() => {
              this.setState({ submitted: true, isSaving: false });
            });
        }
      });
    });
  }

  onChange(values) {
    if (this.props.accountTo != null) {
      values.accountToId = this.props.accountTo.id;
    }
    if (this.props.accountFrom != null) {
      values.accountFromId = this.props.accountFrom.id;
    }
    TransactionDateService.setDate(values.date);
    this.setState({ transfer: values }, () => {
      this.validateForm();
      let accountFrom = this.state.accounts.single(
        (x) => x.id == values.accountFromId
      );
      let accountTo = this.state.accounts.single(
        (x) => x.id == values.accountToId
      );

      this.setState({
        currencyFrom: accountFrom?.currency,
        currencyTo: accountTo?.currency,
        accountFrom: accountFrom,
        accountTo: accountTo,
      });
    });
  }

  render() {
    return (
      <div className="transfer-c">
        <div className="transfer-form">
          <TransferForm
            currencyFrom={this.state.currencyFrom}
            currencyTo={this.state.currencyTo}
            onChange={(values) => this.onChange(values)}
            errors={this.state.errors}
            values={this.state.transfer}
            accountFrom={this.props.accountFrom}
            accountTo={this.props.accountTo}
          ></TransferForm>
          <Button
            className="transfer-btn"
            width="16"
            loading={this.state.isSaving}
            label="transfer.make-transfer"
            fa="fa fa-exchange"
            onClick={() => this.transfer()}
          ></Button>
        </div>
        {this.state.accountFrom != null || this.state.accountTo ? (
          <TransferSummary
            amount={this.state.transfer.amount}
            exchangeRate={this.state.transfer.exchangeRate}
            totalAmount={this.state.transfer.totalAmount}
            accountFrom={this.state.accountFrom}
            accountTo={this.state.accountTo}
          ></TransferSummary>
        ) : null}
      </div>
    );
  }

  componentDidMount() {
    let returnPath = this.props.returnPath;
    let id = this.props.transactionId;

    this.sub = AccountsStore.accounts.subscribe((acs) => {
      this.setState(
        {
          accounts: acs,
          loading:
            acs == null || (id != null && this.state.transaction == null),
          transactionId: id,
          mode: id != null ? "edit" : "new",
          returnPath: returnPath,
          accountFrom: this.props.accountFrom,
          accountTo: this.props.accountTo,
        },
        () => this.setAccounts()
      );
    });
    if (id != null) {
      Transactions.get(predef.endpoints.transaction.byId(id)).then((tran) => {
        let currencyFrom = null;
        let currencyTo = null;
        if (tran.accountId == tran.accountFromId) {
          currencyFrom = tran.currency;
          currencyTo = tran.relatedTransactionCurrency;
        } else {
          currencyTo = tran.currency;
          currencyFrom = tran.relatedTransactionCurrency;
        }

        let transfer = {
          accountFromId: tran.accountFromId,
          accountToId: tran.accountToId,
          amount: Math.abs(tran.amount),
          date: tran.date,
          description: tran.description,
          transactionId: tran.id,
          totalAmount: tran.relatedTransactionAmount,
          exchangeType: "total-amount",
        };

        this.setState(
          {
            transaction: tran,
            loading: this.state.accounts == null,
            currencyFrom: currencyFrom,
            currencyTo: currencyTo,
            transfer: transfer,
          },
          () => {
            this.setAccounts();
            this.onChange(transfer);
          }
        );
      });
    }
  }

  setAccounts() {
    if (
      this.state.mode != "edit" ||
      this.state.accounts == null ||
      this.state.accountFrom != null ||
      this.state.accountTo != null
    )
      return;

    let accountFrom = this.state.accounts.single(
      (x) => x.id == this.state.transfer.accountFromId
    );
    let accountTo = this.state.accounts.single(
      (x) => x.id == this.state.transfer.accountToId
    );

    this.setState({ accountFrom: accountFrom, accountTo: accountTo });
  }

  componentWillUnmount() {
    this.sub.unsubscribe();
  }
}

Transfer.propTypes = {};

export default Transfer;
