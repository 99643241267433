import React from 'react';
import './number.scss';
import { Copy } from './copy';
import Localization from '../services/localization-service';
const Date = ({ date , format}) => {
    { date || 'null' }
    return <div className="date-c"><Copy value={Localization.formatDate(date, format)}></Copy></div>
}

export const DateTime = ({ date }) => {
    { date || 'null' }
    return <div className="date-time-c"><Copy value={Localization.formatDateTime(date)}></Copy></div>
}

export default Date;