import Bus from '../../bus';
import Api from '../../api';

export default class Service {
    post(endpoint, payload, event, eventPayload, successMessage, errorMessage, headers) {
        var res = Api.post(endpoint, payload, successMessage, errorMessage, headers);
        res.then(() => {
            if (event != null) Bus.publish(event, eventPayload);
        });
        return res;
    }

    put(endpoint, payload, event, eventPayload, successMessage, errorMessage, headers) {
        var res = Api.put(endpoint, payload, successMessage, errorMessage, headers);
        res.then(() => {
            if (event != null) {
                Bus.publish(event, eventPayload);
            };
        });
        return res;
    }

    get(endpoint, successMessage, errorMessage, headers) {
        return Api.get(endpoint, successMessage, errorMessage, headers);
    }

    delete(endpoint, event, eventPayload, successMessage, errorMessage, headers) {
        var res = Api.delete(endpoint, successMessage, errorMessage, headers);
        res.then(() => {
            if (event != null) Bus.publish(event, eventPayload);
        });
        return res;
    }
}