import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Copy } from "../common/copy";
import LoadingPlaceholder from "../common/loading-placeholder";
import LoaderScreen from "../common/loading-screen";
import Number from "../common/number";
import { ControlledTabs } from "../common/tabs";
import AccountsStore from "../stores/accounts-store";
import TransactionTemplatesStore from "../stores/transaction-templates-store";
import QuickTransfer from "../transactions/quick-transfer";
import Transaction from "../transactions/transaction";
import TransactionList from "../transactions/transaction-list";
import "./account.scss";
import Message from "../common/message";
import Button from "../common/button";
import predef from "../../predef";
import Decimal from "decimal.js";
import Transfer from "../transactions/transfer";
import Panel from "../common/panel";
import RadioSwitchInput from "../common/radio-switch-input";
import { CalendarService } from "../services/calendar-service";

import Import from './import'

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      accounts: null,
      currentTab: 0,
      isLoadingTemplates: true,
    };
    this.renderBody = this.renderBody.bind(this);
  }

  render() {
    let account = this.getAccount();
    return (
      <div className="account-c">
        {account != null ? (
          <React.Fragment>
            <h2>
              {account.name}:{" "}
              <Number
                value={account.balance}
                currency={account.currency}
              ></Number>{" "}
              {this.renderCCLimit(account)}
            </h2>
            <h6>
              <Copy value={account.number}></Copy>
            </h6>
          </React.Fragment>
        ) : null}
        {this.renderBody()}
      </div>
    );
  }

  renderCCLimit(account) {
    if (account?.type == 4) {
      var remaining = new Decimal(account.balance)
        .add(new Decimal(account.limit))
        .toNumber();

      return (
        <React.Fragment>
          <FormattedMessage id="account.remaining-limit"></FormattedMessage>{" "}
          <Number value={remaining} currency={account.currency}></Number>
        </React.Fragment>
      );
    }
  }

  getAccount() {
    let {
      match: { params },
    } = this.props;
    if (this.state.accounts == null) return;
    return this.state.accounts.find((a) => a.id === params.id);
  }

  renderBody() {
    if (this.state.isLoading) return <LoaderScreen></LoaderScreen>;

    let account = this.getAccount();
    if (account == null) return;

    let tabs = [
      {
        label: "account.adding-transaction",
        render: () => this.renderTransactionForm(account),
        className: "tran-tab",
      },
      {
        label: "account.quick-transfer",
        render: () => this.renderQuickTransfer(account),
        className: "tran-tab",
      },
      {
        label: "account.templates",
        render: () => this.renderTemplatesTab(account.currency),
      },
      {
        label: "account.import",
        render: () =>{
          return  <Import account={account} currency={account}></Import>
        },
      },
    ];

    return (
      <React.Fragment>
        <ControlledTabs
          tabs={tabs}
          selected={this.state.currentTab}
          onChange={(i) => this.setState({ currentTab: i })}
        ></ControlledTabs>
        <h3>
          <FormattedMessage id="account.history"></FormattedMessage>
        </h3>
        <TransactionList
          key={account.id}
          initialFilter={{ includeTransfers: true, accountId: account.id }}
          showAccountColumn={false}
          disabledFilters={{ accountId: true }}
          showCurrency={false}
        ></TransactionList>
      </React.Fragment>
    );
  }

  renderTemplatesTab(currency) {
    return this.state.isLoadingTemplates ? (
      <LoadingPlaceholder></LoadingPlaceholder>
    ) : (
      <div>{this.renderTemplates(currency)}</div>
    );
  }

  applyTemplate(id) {
    let template = this.state.templates.single((x) => x.id == id);

    this.setState({ template: JSON.parse(template.template), currentTab: 0 });
  }

  renderTemplates(currency) {
    var cts = this.state.templates.where((x) => x.currency == currency);
    var ts = cts
      .where((x) => x.category != null && x.category != "")
      .groupBy((x) => x.category);
    var ncts = cts.where((x) => x.category == null || x.category == "");
    return (
      <div className="templates">
        <div className="actions">
          <Button
            fa="edit"
            link
            path={predef.routes.transactionTemplate.index}
            label="account.templates-editing"
          ></Button>
        </div>
        {ts.length == 0 && ncts.length == 0 ? (
          <div>
            <Message message="account.no-templates"></Message>
          </div>
        ) : (
          <div className="list">
            {ncts.length == 0 ? null : (
              <div className="template-category">
                <div className="header">
                  <FormattedMessage id="common.no-category"></FormattedMessage>
                </div>
                {ncts.map((x) => (
                  <a
                    className="template"
                    key={x.id}
                    onClick={() => this.applyTemplate(x.id)}
                  >
                    {x.name}
                  </a>
                ))}
              </div>
            )}
            {ts.map((cat) => {
              return (
                <div key={cat.key} className="template-category">
                  <div className="header">{cat.key}</div>
                  {cat.values.map((x) => {
                    return (
                      <a
                        className="template"
                        key={x.id}
                        onClick={() => this.applyTemplate(x.id)}
                      >
                        {x.name}
                      </a>
                    );
                  })}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }

  renderTransactionForm(account) {
    if (this.state.template != null)
      this.state.template.date = CalendarService.todayIso;
    return (
      <Transaction
        transaction={this.state.template}
        accountId={account.id}
        currency={account.currency}
      ></Transaction>
    );
  }

  renderQuickTransfer(account) {
    return (
      <div>
        <QuickTransfer account={account}></QuickTransfer>
      </div>
    );
  }

  componentDidMount() {
    this.accountsSub = AccountsStore.accounts.subscribe((acs) => {
      this.setState({ accounts: acs, isLoading: acs == null });
    });

    TransactionTemplatesStore.transactionTemplates.subscribe((ts) => {
      this.setState({ templates: ts, isLoadingTemplates: ts == null });
    });
  }

  componentWillUnmount() {
    this.accountsSub.unsubscribe();
  }
}

export default Account;
