import React, { Component } from 'react';
import Button, { Add, Edit, Remove } from '../common/button';
import Grid from '../common/grid/grid';
import Json from '../common/json';
import Page from '../common/page';
import RealEstatesStore from '../stores/real-estates-store';
import './real-estates.scss';
import predef from '../../predef';
import RealEstatesService from '../services/real-estates-service'
class RealEstates extends Component {

  constructor(props) {
    super(props);
    this.state = { isLoading: true, realEstates: null };
    this.renderBody = this.renderBody.bind(this);
  }

  render() {
    return <Page
      className="real-estates-c"
      header="real-estates.header"
      loading={this.state.isLoading}
      data={this.state.realEstates}
      renderBody={this.renderBody}
      actions={<React.Fragment>
        <Add label="real-estates.add-real-estate" path={predef.routes.realEstates.add}></Add>
      </React.Fragment>}
    >
    </Page>
  }

  renderBody() {

    return <React.Fragment>

      <Grid pageSize={20}
        id="real-estates"
        data={this.state.realEstates}
        definition={[
          {
            show: true,
            name: "name",
            headerClasses: "len-300",
            header: "common.name",
            renderCell: (re) => re.name,
            sortable: true,
            value: re => re.name
          },
          {
            show: true,
            name: "city",
            headerClasses: "len-160",
            header: "real-estates.city",
            renderCell: (re) => re.city,
            sortable: true,
            value: re => re.city
          },
          {
            show: true,
            name: "address",
            headerClasses: "len-320",
            header: "real-estates.address",
            renderCell: (re) => re.address,
            sortable: true,
            value: re => re.address
          },
          {
            show: true,
            name: "kw",
            headerClasses: "len-160",
            header: "real-estates.kw",
            renderCell: (re) => re.kwNumber,
            sortable: true,
            value: re => re.kwNumber
          },
          {
            show: true,
            name: "actions",
            renderCell: (re) => {
              return <React.Fragment>
                <Button link fa="chart-line" path={predef.routes.realEstates.dashboard(re.id)} label="real-estates.real-estate-dashboard"></Button>
                <Edit path={predef.routes.realEstates.edit(re.id)}></Edit>
                <Remove command={{
                  command: () => RealEstatesService.deleteRealEstate(re.id),
                  successEvent: predef.events.realEstates.realEstatesChanged
                }}></Remove>
              </React.Fragment>
            },
            sortable: true,
            value: re => re.name
          }
        ]}
      >

        <Json data={this.state.realEstates}></Json>
      </Grid>
    </React.Fragment>
  }


  componentDidMount() {
    this.sub = RealEstatesStore.realEstates.subscribe((res) => {
      if (res == null) return;
      this.setState({
        realEstates: res,
        isLoading: res == null,
      });
    })
  }

  componentWillUnmount() {
    this.sub.unsubscribe();
  }

}

export default RealEstates;