import { Component, default as React } from 'react';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import EditableGrid from '../common/grid/editable-grid';
import './budgets-form.scss';
import Number from '../common/number';
import Button from '../common/button';
import predef from '../../predef';

const budgetSchema = yup.object().shape({
  name: yup.string().required().min(1),
  limit: yup.number().required().positive(),
  currency: yup.string().required()
})

class BudgetsForm extends Component {
  constructor(props) {
    super(props);
    this.state = { showAddFromRecurring: false }
  }

  render() {
    return <div className="budgets-form-c">
      {/* <Button link label="budgets-form.edit-recurring-budgets" path={predef.routes.recurringBudgets.index}></Button> */}
      {(this.props.budgets == null || this.props.budgets.length == 0) && this.props.month ? <Button link label="budgets-form.add-recurring" onClick={() => this.props.addRecurringBudgets()}></Button> : null}
      <EditableGrid
        id="budgets"
        onSubmit={(budgets) => this.props.onSubmit(budgets)}
        data={this.props.budgets}
        validationSchema={budgetSchema}
        definition={[
          {
            show: true,
            header: 'common.name',
            type: 'text',
            name: 'name',
            headerClasses: "len-300"
          },
          {
            show: true,
            header: 'planner.budgets-limit',
            type: 'number',
            name: 'limit',
            headerClasses: 'len-200'
          },
          {
            show: true,
            header: 'common.currency',
            type: 'currency',
            name: 'currency',
            headerClasses: "len-180",
          },
          {
            show: true,
            name: 'categories',
            header: 'common.categories',
            type: 'categories-select',
            expense: true
          }
        ]}
        renderTotalRow={(data) => {
          return <tfoot key="sums">
            <tr>
              <td className="t-right"><FormattedMessage id="common.sum"></FormattedMessage>:</td>
              <td colSpan={4}>
                {this.renderSums(data?.select(x => { return { currency: x.currency, value: x.limit } }))}
              </td>
            </tr>
          </tfoot>
        }}
      >
      </EditableGrid>
    </div>
  }

  renderSums(values) {
    return values.groupByCurrency().map((g) => {
      return <Number key={g.key} value={g.values.sum(x => x.value)} currency={g.key}></Number>
    })
  }

  componentDidMount() {

  }
}


BudgetsForm.propTypes = {

}


export default BudgetsForm;