import PropTypes from "prop-types";
import React, { Component } from "react";
import "./form.scss";
import ControlledForm from "./controlled-form";
import Button from "../common/button";
import classNames from "classnames/bind";
import { validate } from "../../util";
import { createHashHistory } from "history";
const history = createHashHistory();

class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      errors: {},
      values: null,
      isSaving: false,
    };

    this.onChange = this.onChange.bind(this);
    this.save = this.save.bind(this);
  }

  onChange(values) {
    let newState = { values: values };
    if (this.state.submitted && this.props.validationSchema != null) {
      let validationResult = validate(this.props.validationSchema, values);
      newState.errors = validationResult;
    }

    this.setState(newState, () => {
      this.props.onChange?.(this.state.values);
    });
  }

  save() {
    this.setState({ submitted: true }, () => {
      if (this.props.validationSchema != null) {
        let errors = validate(this.props.validationSchema, this.state.values);
        if (errors.hasErrors) {
          this.setState({ errors: errors });
          return;
        }
      }

      this.setState({ isSaving: true }, () => {
        Promise.resolve(this.props.onSubmit(this.state.values))
          .then(() => {
            if (!this.unmounted) {
              this.setState(
                {
                  isSaving: false,
                  submitted: false,
                  errors: null,
                  values: this.props.keepValuesAfterSubmit
                    ? this.state.values
                    : null,
                },
                () => {
                  if (this.props.successPath) {
                    history.push(this.props.successPath);
                  }
                }
              );
            }
            this.props.onSubmitted?.();
          })
          .catch(() => {
            if (!this.unmounted) {
              this.setState({ isSaving: false });
            }
          });
      });
    });
  }

  render() {
    return (
      <div className={"form-c " + (this.props.className || "")}>
        {this.props.definition == null ? null : (
          <ControlledForm
            groupsInPanels={this.props.groupsInPanels}
            errors={this.state.errors}
            onSubmit={() => this.save()}
            onChange={this.onChange}
            definition={this.props.definition}
            values={this.state.values}
          >
            {this.props.cancelPath != null ? (
              <Button
                link
                className="cancel-btn"
                label="common.cancel"
                fa="arrow-left"
                path={this.props.cancelPath}
              ></Button>
            ) : null}
            {!this.props.hideSubmitButton ? (
              <Button
                {...(this.props.submitButton || {
                  label: "common.save",
                  fa: "save",
                  width: "9",
                })}
                className={classNames(
                  this.props.submitButton != null
                    ? this.props.submitButton.className
                    : null,
                  "submit-btn"
                )}
                loading={this.state.isSaving}
                onClick={this.save}
              ></Button>
            ) : null}
            {typeof this.props.children === "function"
              ? this.props.children(this.save)
              : this.props.children}
          </ControlledForm>
        )}
      </div>
    );
  }

  componentDidMount() {
    this.setState({ values: this.props.initialValues });
  }

  componentWillUnmount() {
    this.unmounted = true;
  }
}

Form.propTypes = {
  definition: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.any,
  hideSubmitButton: PropTypes.bool,
  initialValues: PropTypes.object,
  submitButton: PropTypes.object,
  onChange: PropTypes.func,
};

export default Form;
