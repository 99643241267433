import Api from '../../api';
import predef from '../../predef';
import Cache from '../../cache';
import Bus from '../../bus';
import { deepCopy } from '../../util';
import Calendar from '../services/calendar-service';
import Service from './service';

export class PlanningService extends Service {
    constructor() {
        super();
        this.incomesCache = new Cache([predef.events.planning.plannedIcomes.updated]);
        this.budgetsCache = new Cache([predef.events.planning.budgets.updated, predef.events.planning.palnCreated, predef.events.transaction.transactionsChanged]);
    }

    getBudgets(year, month) {
        return this.budgetsCache.tryGet(year + '-' + month, () => {
            let promise = new Promise((resolve, reject) => {
                Api.get(predef.endpoints.plan.getBudgets(year, month)).then((bs) => {
                    bs = this.splitCategories(bs);
                    resolve(bs);
                }).catch((e) => reject(e));
            });

            return promise;
        });
    }

    getRecurringBudgets() {
        let promise = new Promise((resolve, reject) => {
            Api.get(predef.endpoints.plan.getRecurringBudgets).then((bs) => {
                bs = this.splitCategories(bs);
                resolve(bs);
            }).catch((e) => reject(e));
        });

        return promise;
    }

    getPlannedIncomes(year, month) {
        return this.incomesCache.tryGet(year.toString() + "/" + month.toString(), () => Api.get(predef.endpoints.plan.getPlannedIncomes(year, month)));
    }

    getRecurringIncomes() {
        return Api.get(predef.endpoints.plan.getRecurringIncomes);
    }

    updateBudgets(budgets, year, month) {
        budgets = this.joinCategories(budgets);
        return Api.post(predef.endpoints.plan.postBudgets(year, month), budgets, "planning-service.budgets-updated", "planning-service.budgets-update-error")
            .then(x => Bus.publish(predef.events.planning.budgets.updated));
    }

    updateRecurringBudgets(budgets) {
        budgets = this.joinCategories(budgets);
        return Api.post(predef.endpoints.plan.postRecurringBudgets, budgets, "planning-service.recurring-budgets-updated", "planning-service.recurring-budgets-update-error")
            .then(x => Bus.publish(predef.events.planning.recurringBudgets.updated));
    }

    updatePlannedIncomes(incomes, year, month) {
        return Api.post(predef.endpoints.plan.postPlannedIncomes(year, month), incomes, "planning-service.planned-incomes-updated", "planning-service.planned-incomes-update-error")
            .then(x => Bus.publish(predef.events.planning.incomes.updated));
    }

    updateRecurringIncomes(incomes) {
        return Api.post(predef.endpoints.plan.postRecurringIncomes, incomes, "planning-service.recurring-incomes-updated", "planning-service.recurring-incomes-update-error")
            .then(x => Bus.publish(predef.events.planning.recurringIncomes.updated));
    }

    getPlan(year, month) {

        let promise = new Promise((resolve, reject) => {
            Api.get(predef.endpoints.plan.getPlan(year, month)).then((plan) => {
                plan.budgets = this.splitCategories(plan.budgets);
                resolve(plan);
            }).catch((e) => reject(e));
        });

        return promise;
    }

    createPlan(year, month) {
        return Api.post(predef.endpoints.plan.createPlan(year, month), null)
            .then(x => Bus.publish(predef.events.planning.planCreated))
    }

    
    deletePlan(year, month) {
        return Api.delete(predef.endpoints.plan.createPlan(year, month), null)
            .then(x => Bus.publish(predef.events.planning.planDeleted))
    }


    joinCategories(budgets) {
        budgets = deepCopy(budgets);
        for (let budget of budgets) {
            if (budget.categories != null) {
                budget.categories = budget.categories.join(";");
            }
        }

        return budgets;
    }

    splitCategories(budgets) {
        budgets = deepCopy(budgets);
        for (let budget of budgets) {
            if (budget.categories != null) {
                budget.categories = budget.categories.split(";");
            }
        }

        return budgets;
    }

    addRecurringBudgets(month) {
        return this.post(predef.endpoints.plan.addRecurringBudgets(Calendar.getYearFromIso(month), Calendar.getMonthFromIso(month))
            , null
            , predef.events.planning.budgets.updated
            , null
            , 'planning-service.add-budgets-from-recurring-success'
            , 'planning-service.add-budgets-from-recurring-error'
        )
    }

    addRecurringPlannedIncomes(month) {
        return this.post(predef.endpoints.plan.addRecurringPlannedIncomes(Calendar.getYearFromIso(month), Calendar.getMonthFromIso(month))
            , null
            , predef.events.planning.plannedIcomes.updated
            , null
            , 'planning-service.add-planned-incomes-from-recurring-success'
            , 'planning-service.add-planned-incomes-from-recurring-error'
        )
    }

}


const instance = new PlanningService();
export default instance;
