import React from 'react';
import RealEstateStore from '../stores/real-estates-store';
import './real-estate-select.scss';
import Select from './select';
import { map } from 'rxjs/operators';

const RealEstateSelect = (props) => {
  return <Select className="real-estate-select-c" {...props} options={RealEstateStore.realEstates.pipe(map(res => {
    if (res == null) return null;
    return res.select(x => {
      return {
        label: x.name,
        value: x.id
      }
    });
  }))}></Select>
}

RealEstateSelect.propTypes = {

}

export default RealEstateSelect;