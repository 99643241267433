import Api from '../../api';
import predef from '../../predef';
import Service from './service';

class AuthenticationService extends Service {
    login(creds) {
        return new Promise((resolve, reject)=> {
            let res = Api.postFormUrlEncoded(`${predef.endpoints.jwt}`, `login=${creds.login}&password=${creds.password}`);
            res.then((res) => {
                Api.setUser(res, creds.rememberme);
                resolve();
            }).catch(error=> reject(error));
        })
    }

    register(data){
        return Api.postFormUrlEncoded(`${predef.endpoints.user.register}`, `login=${data.login}&password=${data.password}&reCaptchaToken=${data.reCaptchaToken}`);
    }
}


let instance = new AuthenticationService();
export default instance;
