import PropTypes from "prop-types";
import React, { Component } from "react";
import Dropzone from "react-dropzone";
import { FormattedMessage } from "react-intl";
import api from "../../api";
import Toastr from "../services/toastr-srevice";
import "./file-drop-zone.scss";
import Bus from "../../bus";
import predef from "../../predef";
import classNames from "classnames/bind";

class FileDropZone extends Component {
  constructor(props) {
    super(props);
    this.state = { isUploading: false, progress: 0 };
  }

  onDrop(files) {
    this.setState({ isUploading: true }, () => {
      api
        .uploadFiles(this.props.endpoint, files, this.props.formData, (p) =>
          this.setState({ progress: p })
        )
        .then((data) => {
          this.setState({ isUploading: false });
          if (this.props.onUploadFinished != null)
            this.props.onUploadFinished(data);
        })
        .catch((e) => {
          this.setState({ isUploading: false });
          Toastr.error("file-drop-zone.upload-error");
        });
    });
  }

  render() {
    return (
      <div className="file-drop-zone-c">
        <Dropzone onDrop={(files) => this.onDrop(files)}>
          {({ getRootProps, getInputProps }) => (
            <div>
              {this.state.isUploading ? (
                <div className="progress">
                  <div
                    className={classNames("progress-bar", {
                      "progress-bar-striped progress-bar-animated":
                        this.state.progress >= 100,
                    })}
                    role="progressbar"
                    style={{ width: this.state.progress + "%" }}
                  ></div>
                </div>
              ) : (
                <div className="drop-zone" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <FormattedMessage id="file-drop-zone.message"></FormattedMessage>
                </div>
              )}
            </div>
          )}
        </Dropzone>
      </div>
    );
  }

  componentDidMount() {}

  componentWillUnmount() {}
}

FileDropZone.propTypes = {
  onUploadFinished: PropTypes.func,
};

export default FileDropZone;
