import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import './col.scss';


const Col = ({ layout,  children }) => {
  return <div className={classNames("col-c ", layout)}>
    {children}
  </div>
}


Col.propTypes = {
  layout: PropTypes.string
}


export default Col;