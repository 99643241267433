import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button, { Cancel, Remove, Save, Edit } from '../common/button';
import Panel from '../common/panel';
import PropTypes from 'prop-types';
import Page from '../common/page';
import classNames from 'classnames/bind';
import { H3 } from '../common/headers';

import './edit-investment.scss';
import InvestmentForm from './investment-form';
import { InvestmentContext } from '../contexts';
import InvestmentService from '../services/investments-service';
import CloseInvestment from './close-investment';
import CloseInvestmentForm from './close-investment-form';
import InvestmentsService from '../services/investments-service';

class EditInvestment extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  renderCloseOpen(investment) {
    return investment.isClosed ? <Button label="edit-investment.reopen-investment" command={{ command: () => InvestmentsService.reopenInvestment(investment.id) }}></Button> : <CloseInvestmentForm investmentId={investment.id}></CloseInvestmentForm>;
  }

  render() {
    
    return <div className="edit-investment-c">

      <InvestmentContext.Consumer>
        {investment => <div>
          <Panel>
            {this.renderCloseOpen(investment)}
          </Panel>
 
          <Panel>
            <InvestmentForm onSubmit={(inv) => {
              return new Promise((resolve, reject) => {
                InvestmentService.updateInvestment(inv).then(() => {
                  resolve();
                  history.push(predef.routes.investments.index);
                }).catch((e) => reject(e))
              })
            }} investment={investment}></InvestmentForm>
          </Panel></div>}
      </InvestmentContext.Consumer>

    </div>
  }



  componentDidMount() {
  }

  componentWillUnmount() {
  }

}


EditInvestment.propTypes = {

}


export default EditInvestment;