import React from 'react';
import { map } from 'rxjs/operators';
import IncomeStreamStore from '../stores/income-streams-store';
import './income-stream-select.scss';
import Select from './select';

const IncomeStreamSelect = (props) => {
  return <Select className="income-stream-select-c"
    isClearable
    options={IncomeStreamStore.incomeStreams.pipe(map(res => {
      if (res == null) return null;
      return res.select(x => {
        return {
          label: x.name,
          value: x.id
        }
      });
    }))}
    {...props}
  ></Select>

}


IncomeStreamSelect.propTypes = {

}


export default IncomeStreamSelect;