import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button, { Cancel, Remove, Save, Edit } from '../common/button';
import Panel from '../common/panel';
import Page from '../common/page';
import { H3 } from '../common/headers';
import Json from '../common/json';
import './recurring-transactions.scss';
import RecurringTransactionsService from '../services/recurring-transactions-service';
import recurringTransactionsStore from '../stores/recurring-transactions-store';
import Grid from '../common/grid/grid';
import { Form } from 'formik';
import Number from '../common/number'
import Localization from '../services/localization-service';

class RecurringTransactions extends Component {

  constructor(props) {
    super(props);
    this.state = { isLoading: true, recurringTransactions: null };
  }

  render() {
    return <Page
      className="recurring-transactions-c"
      header="recurring-transactions.header"
      loading={this.state.isLoading}
      data={this.state.recurringTransactions}
      renderBody={() => this.renderBody()}
      actions={this.renderActions()}
    >

    </Page>
  }

  renderActions() {
    return <Button fa="plus" label="recurring-transactions.add-recurring-transaction" path={predef.routes.recurringTransactions.add}></Button>
  }

  renderBody() {

    return <React.Fragment>
      <Grid id="recurring-transactions" pageSize={20} data={this.state.recurringTransactions} definition={[
        {
          show: true,
          headerClasses: "len-300",
          header: "common.name",
          renderCell: (rt) => rt.name,
          sortable: true,
          value: (rt) => rt.name
        },
        {
          show: true,
          headerClasses: "len-300",
          header: "common.account",
          renderCell: (rt) => rt.account.name,
          sortable: true,
          value: (rt) => rt.account.name
        },
        {
          show: true,
          headerClasses: "len-160",
          align: 'right',
          header: "common.amount",
          renderCell: (rt) => <Number value={rt.amount} currency={rt.currency}></Number>,
          sortable: true,
          value: (rt) => rt.amount?.toString() + " " + rt.currency
        },
        {
          show: true,
          headerClasses: "len-200",
          header: "recurring-transactions.repeat",
          renderCell: (rt) => this.getRepeatText(rt),
          sortable: false,
        },
        {
          show: true,
          headerClasses: "len-200",
          header: "recurring-transactions.stop-condition",
          renderCell: (rt) => this.getStopConditionText(rt),
        },
        {
          show: true,
          headerClasses: "len-160",
          name: 'actions',
          renderCell: (rt) =>
            <React.Fragment>
              <Button link label="common.edit" path={predef.routes.recurringTransactions.edit(rt.id)}></Button>
              <Button link label="common.delete" danger onClick={() => RecurringTransactionsService.deleteTransaction(rt.id)}></Button>
            </React.Fragment>,
          sortable: false
        }
      ]}>
      </Grid>
    </React.Fragment>
  }

  getRepeatText(rt) {
    switch (rt.frequency) {
      case 1:
        return rt.periods == 1 ? <FormattedMessage id="recurring-transactions.frequency-one-day"></FormattedMessage> : <FormattedMessage id="recurring-transactions.frequency-days" values={{ periods: rt.periods }}></FormattedMessage>
      case 2:
        return rt.periods == 1 ? <FormattedMessage id="recurring-transactions.frequency-one-week"></FormattedMessage> : <FormattedMessage id="recurring-transactions.frequency-weeks" values={{ periods: rt.periods }}></FormattedMessage>
      case 3:
        return rt.periods == 1 ? <FormattedMessage id="recurring-transactions.frequency-one-month"></FormattedMessage> : <FormattedMessage id="recurring-transactions.frequency-months" values={{ periods: rt.periods }}></FormattedMessage>
      case 4:
        return rt.periods == 1 ? <FormattedMessage id="recurring-transactions.frequency-one-year"></FormattedMessage> : <FormattedMessage id="recurring-transactions.frequency-years" values={{ periods: rt.periods }}></FormattedMessage>
    }
  }

  getStopConditionText(rt) {
    switch (rt.stopCondition) {
      case 1:
        return <FormattedMessage id={"recurring-transactions.until-removed"}></FormattedMessage>
      case 2:
        return <FormattedMessage id={"recurring-transactions.until-date"} values={{ stopDate: Localization.formatDate(rt.stopDate) }}></FormattedMessage>
      case 3:
        return rt.numberOfRepeats > 1 ?
          <FormattedMessage id={"recurring-transactions.until-repeated"} values={{ numberOfRepeats: rt.numberOfRepeats }}></FormattedMessage>
          : <FormattedMessage id={"recurring-transactions.until-repeated-single"}></FormattedMessage>
    }
  }


  componentDidMount() {
    this.sub = recurringTransactionsStore.recurringTransactions.subscribe(rts => {
      this.setState({ recurringTransactions: rts, isLoading: rts == null });
    });
  }

  componentWillUnmount() {
    this.sub.unsubscribe();
  }

}

export default RecurringTransactions;