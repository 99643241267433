import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import './pagger.scss';


const minPageSize = 20;

class Pagger extends Component { 

  constructor(props) {
    super(props);
    this.state = { pageCount: 0, pageSize: 50 };

    this.renderAllPages = this.renderAllPages.bind(this);
    this.renderSimplifiedPager = this.renderSimplifiedPager.bind(this);
    this.getPaggerButtonClasses = this.getPaggerButtonClasses.bind(this);
    this.goToPage = this.goToPage.bind(this);
  }

  goToPage(i) {
    if (this.props.onPageChanged != null)
      this.props.onPageChanged(i);
  }

  setPageSize(size) {
    var state = Pagger.calculatePagging(this.props.totalRows, size);
    this.setState(state);
    if (this.props.onPageSizeChanged != null)
      this.props.onPageSizeChanged(size);
  }

  render() {
    let btns = [];
    if (this.state.pageCount > 1 && this.state.pageCount <= 5) {
      btns = this.renderAllPages();
    }
    if (this.state.pageCount > 5) {
      btns = this.renderSimplifiedPager();
    }

    return (
      <div className="pagger-c">

        {this.state.pageCount > 1 ?
          <React.Fragment>
            <label ><FormattedMessage id="pagger.page"></FormattedMessage></label>
            <div role="group" className="pagger" aria-label="data pages">
              {btns}
            </div>
          </React.Fragment> : null
        }
        {
          this.props.totalRows > 0 
          && this.state.showingFrom > 0 
          && this.state.showingTo > 0 
           ? 
            <div className="showing">
              {this.state.showingFrom} - {this.state.showingTo} / {this.props.totalRows}
            </div> : null
        }
        {
          this.props.totalRows > 0 && this.props.totalRows > minPageSize ?
            <div className="page-size">
              <label><FormattedMessage id="pagger.page-size"></FormattedMessage></label>
              <div role="group" aria-label="number of elements on page">
                <a onClick={() => this.setPageSize(minPageSize)} className={this.state.pageSize === minPageSize ? 'active ' : ''}>{minPageSize}</a>
                <a onClick={() => this.setPageSize(50)} className={this.state.pageSize === 50 ? 'active ' : ''}>50</a>
                <a onClick={() => this.setPageSize(100)} className={this.state.pageSize === 100 ? 'active ' : ''}>100</a>
              </div>
            </div> : null
        }

      </div>
    );
  }

  getPaggerButtonClasses(i) {
    return this.props.selectedPage === i ? 'active' : '';
  }

  renderAllPages() {
    let pages = [];
    for (let i = 0; i < this.state.pageCount; i++) {
      pages.push(<a key={i} onClick={() => this.goToPage(i + 1)} className={this.getPaggerButtonClasses(i + 1)}>{i + 1}</a>);
    }

    return pages;
  }

  static getDerivedStateFromProps(props, state) {
    return Pagger.calculatePagging(props.totalRows, props.pageSize == null ? 50 : props.pageSize, props.selectedPage);
  }

  static calculatePagging(totalRows, pageSize, page) {
    if (!totalRows) return { pageCount: 0 };
    let state = { pageCount: Math.floor(totalRows / pageSize) + (totalRows % pageSize === 0 ? 0 : 1), pageSize: pageSize };

    state.showingFrom = (page - 1) * pageSize + 1;
    state.showingTo = state.showingFrom + pageSize - 1;
    if (state.showingTo > totalRows) {
      state.showingTo = totalRows;
    }
    else if (state.showingTo === 0) {
      state.showingFrom = 0;
    }

    return state;
  }

  renderSimplifiedPager() {
    let pages = [];
    if (this.props.selectedPage != 1) {
      pages.push(<a key={"-1"} onClick={() => this.goToPage(this.props.selectedPage - 1)}><span className="fas fa-angle-left"></span></a>)
    }
    pages.push(<a key={1} onClick={() => this.goToPage(1)} className={this.getPaggerButtonClasses(1)}>1</a>)
    if (this.props.selectedPage > 3) {
      pages.push(<span key={'dots_1'} className="">...</span>)
    }
    if (this.props.selectedPage > 2) {
      pages.push(<a key={this.props.selectedPage - 1} onClick={() => this.goToPage(this.props.selectedPage - 1)} className={this.getPaggerButtonClasses(this.props.selectedPage - 1)}>{this.props.selectedPage - 1}</a>)
    }
    if (this.props.selectedPage > 1 && this.props.selectedPage < this.state.pageCount) {
      pages.push(<a key={this.props.selectedPage} onClick={() => this.goToPage(this.props.selectedPage)} className={this.getPaggerButtonClasses(this.props.selectedPage)}>{this.props.selectedPage}</a>)
    }
    if (this.props.selectedPage < this.state.pageCount - 1) {
      pages.push(<a key={this.props.selectedPage + 1} onClick={() => this.goToPage(this.props.selectedPage + 1)} className={this.getPaggerButtonClasses(this.props.selectedPage + 1)}>{this.props.selectedPage + 1}</a>)
    }
    if (this.props.selectedPage < this.state.pageCount - 2) {
      pages.push(<span key={'dots_2'} className="">...</span>)
    }
    pages.push(<a key={this.state.pageCount} onClick={() => this.goToPage(this.state.pageCount)} className={this.getPaggerButtonClasses(this.state.pageCount)}>{this.state.pageCount}</a>)
    if (this.props.selectedPage != this.state.pageCount) {
      pages.push(<a key={"+1"} onClick={() => this.goToPage(this.props.selectedPage + 1)}><span className="fas fa-angle-right"></span></a>)
    }
    return pages;
  }

  componentDidMount() {
    let state = Pagger.calculatePagging(this.props.totalRows, this.props.pageSize != null ? this.props.pageSize : 50, 1);
    state.showingLabel = this.props.showingLabel || 'pagger.showing';
    this.setState(state);
  }

  componentWillUnmount() {
  }

}

export default Pagger;