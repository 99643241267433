import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import EditableGrid from '../common/grid/editable-grid';
import Number from '../common/number';
import './incomes-form.scss';
import './planner.scss';
import predef from '../../predef';
import Button from '../common/button';

const incomeSchema = yup.object().shape({
  name: yup.string().required().min(1),
  income: yup.number().required().positive(),
  currency: yup.string().required()
})

class IncomesForm extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="incomes-form-c">
           {/* <Button link label="incomes-form.edit-recurring-incomes" path={predef.routes.recurringPlannedIncome.index}></Button> */}
      {(this.props.incomes == null || this.props.incomes.length == 0) && this.props.month ? <Button link label="incomes-form.add-planned-incomes" onClick={() => this.props.addRecurringPlannedIncomes()}></Button> : null}
        <EditableGrid
          id="incomes"
          onSubmit={(incomes) => this.props.onSubmit(incomes)}
          data={this.props.incomes}
          validationSchema={incomeSchema}
          definition={[
            {
              show: true,
              header: 'common.name',
              type: 'text',
              name: 'name',
              headerClasses: "len-300"
            },
            {
              show: true,
              header: 'common.income',
              type: 'number',
              name: 'income',
              headerClasses: 'len-200'
            },
            {
              show: true,
              header: 'common.currency',
              type: 'currency',
              name: 'currency',
              headerClasses: "len-180",
            }
          ]}
          renderTotalRow={(data) => {
            return <tfoot key="sums">
              <tr>
                <td className="t-right"><FormattedMessage id="common.sum"></FormattedMessage>:</td>
                <td colSpan={3}>
                  {this.renderSums(data?.select(x => { return { currency: x.currency, value: x.income } }))}
                </td>
              </tr>
            </tfoot>
          }}
        >
        </EditableGrid>
      </div>

    );
  }
  renderSums(values) {
    return values.groupByCurrency().where(g => g.key != null && g.key != '').map((g) => {
      return <Number key={g.key} value={g.values.sum(x => x.value)} currency={g.key}></Number>
    })
  }

}

IncomesForm.propTypes = {

}

export default IncomesForm;