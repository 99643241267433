import React from 'react';
import { BehaviorSubject } from 'rxjs';
import './real-estate-category-select.scss';
import Select from '../common/select';

const expenseCategories = [{ value: '1a1aba61-b622-47e3-a0b6-c026d9093756', label: 'wkład własny w kredyt hipoteczny' },
{ value: '21ebc45b-5f75-4399-9361-ed27aad0f49b', label: 'rata kredytu - odsetki' },
{ value: '277012db-352b-44db-b9f2-ec1011325582', label: 'podatek od najmu (ryczałt)' },
{ value: '4d8097e8-a727-408a-bd5e-029817a205da', label: 'rata kredytu' },
{ value: '51d0f0fe-3b5e-466a-8bfe-047e20dc681c', label: 'użytkowanie wieczyste' },
{ value: '6240edc9-217e-4a5d-8bc9-df83029259bf', label: 'rata kredytu - kapitał' },
// { value: '707c7916-f0c0-43aa-84a6-d9d1a6789356', label: 'koszty okołokredytowe' },
{ value: '77821ac5-0987-4c8f-a157-fb941a899929', label: 'podatek od nieruchomości' },
{ value: '7d8f8129-e3c2-43eb-9466-bd16bb241854', label: 'odsetki od kwoty napdłaty kredytu' },
{ value: 'a98c99de-d7dd-42e6-8e4a-b5f11a57bb05', label: 'zakup mieszkania' },
{ value: 'afcd1f6a-ebcd-4b95-bc3f-6d81c3c1b4f1', label: 'wyposażenie mieszkania' },
{ value: 'b09b3cd0-3238-4ca7-b155-46cad874f307', label: 'podatek od najmu (skala podatkowa)' },
{ value: 'cd261d4e-dfc3-4697-8c7d-7d963fa3d19f', label: 'podatek od najmu (liniowy)' },
// { value: 'd2d77c38-2fe4-4486-ba8a-104b325ad0dc', label: 'ubezpieczenie brakującego wkładu własnego' },
{ value: 'daef23ca-1322-40c6-97e8-ead0c66a29cd', label: 'zarządzanie mieszkaniem' },
{ value: 'e6f731e3-1964-49cb-bc8b-60e23c4df4c4', label: 'nadpłata kredytu hipotecznego' },
{ value: 'e8457b70-6d27-4b93-8662-2f18d4bbda02', label: 'ubezpieczenie' },
{ value: 'f155e94e-b61d-44d6-96bd-d93b3df66661', label: 'remont mieszkania' },
{ value: '93c0df7a-5003-418a-b882-b55a5d6ce8a2', label: 'utrzymanie mieszkania' },
{ value: 'c744cf54-3e19-4e64-bf0b-103a3df3709c', label: 'amortyzacja' }
];

const incomeCategories = [{ value: '94f86b15-ee5e-4eb4-ae4f-89a6bebea6cb', label: 'cashback z kredytu' },
{ value: '8f56b533-1492-422f-b653-230d8edcbc41', label: 'dochód z najmu' },
{ value: 'ad219cbc-932d-4a9e-bcb1-622fa61768eb', label: 'zwrot ubezpieczenia brakującego wkładu własnego' },
{ value: 'd66b3a3c-6eed-4fe8-9bee-e29be1a97896', label: 'zwrot nadpłaconego podatku' },
{ value: '273dddc2-8c41-446c-af23-7ca785c529e0', label: 'amortyzacja' }]

const getOptions = (isExpense) => {
  return new BehaviorSubject(isExpense ? expenseCategories : incomeCategories).asObservable();
}

const categories = new BehaviorSubject(null);
// TODO: dont do next in render 
const RealEstateCategorySelect = (props) => {
  categories.next(props.expense ? expenseCategories : incomeCategories);
  return <Select isClearable className="real-estate-category-select-c" {...props} options={categories.asObservable()}></Select>
}


RealEstateCategorySelect.propTypes = {

}


export default RealEstateCategorySelect;