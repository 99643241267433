import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect, withRouter } from "react-router";
import * as yup from 'yup';
import Button from '../common/button';
import Error from '../common/error';
import Form from '../common/form';
import LanguageSelector from '../common/language-selector';
import Authentication from '../services/authentication-service';
import './login.scss';

export const loginValidationSchema = yup.object().shape({
  login: yup.string()
    .required('common.field-is-required'),
  password: yup.string()
    .required('common.field-is-required')
})

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = { redirectToReferrer: false, from: null, error: false };
    this.login = this.login.bind(this);
  }

  render() {
    const urlParams = new URLSearchParams(window.location.search);
    let fromUrl = urlParams.get('from');
    let form = {
      fields: [
        {
          type: 'text',
          name: 'login',
          label: 'login.login',
          autoComplete: 'username',
          layout: 'c'
        },
        {
          type: 'password',
          name: 'password',
          label: 'login.password',
          layout: 'c'
        },
        {
          type: 'checkbox',
          name: 'rememberme',
          label: 'login.rememberme',
          layout: 'c'
        }
      ]
    }

    let from = null;
    if (this.props.location.state != null) {
      from = this.props.location.state.from;
    }

    if (fromUrl != null) {
      from = { pathname: fromUrl }
    }

    from = from || { pathname: "/" };

    if (this.state.redirectToReferrer) return <Redirect to={from} />;

    return <div className="login-c">
      <LanguageSelector></LanguageSelector>

      <div className="panel">
        <div className="header">
          <FormattedMessage id={"login.login"}></FormattedMessage>
        </div>
        <div className="body">
          {this.state.error ? <Error text={this.state.errorText}></Error> : null}
          <Form
            validationSchema={loginValidationSchema}
            definition={form}
            submitButton={{ label: "login.login" }}
            onSubmit={this.login}
            initialValues={{ rememberme: true }}>
          </Form>
          <Button link path="/" label="login.forgot-password"></Button>
        </div>
      </div>
    </div>
  }

  login(values) {
    return Authentication.login(values).then(() => {
      this.setState({ redirectToReferrer: true });
    }).catch(error => {
      let text = error.response == null || error.response.status != 400 ? 'login.generic-error' : 'login.wrong-login-or-password-error';
      this.setState({ error: true, errorText: text });
    });
  }

}

export default withRouter(Login)