import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button, { Cancel, Remove, Save, Edit } from '../common/button';
import Panel from '../common/panel';
import PropTypes from 'prop-types';
import Page from '../common/page';
import classNames from 'classnames/bind';
import { H3, H2 } from '../common/headers';

import './add-recurring-transaction.scss';
import RecurringTransactionForm from './recurring-transaction-form';

class AddRecurringTransaction extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <div className="add-recurring-transaction-c">
      <H2 header="add-recurring-transaction.header"></H2>
      <RecurringTransactionForm></RecurringTransactionForm>
    </div>
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

}

AddRecurringTransaction.propTypes = {

}

export default AddRecurringTransaction;