import React, { Component } from 'react';
import './transaction-list.scss';
import ControlledTransactionList from './controlled-transaction-list';
import TransactionsViewStateProvider from './transactions-view-state-provider';

class TransactionList extends Component {
  constructor(props) {
    super(props);
    this.viewStateProvider = new TransactionsViewStateProvider(props.initialFilter, null, null, props.disabledFilters);
    this.state = { viewState: null }
  }

  render() {
    return this.state.viewState == null ? null :
      <ControlledTransactionList
        onViewStateChanged={(vs) => this.viewStateProvider.updateState(vs)}
        viewState={this.state.viewState}
        showCurrency={this.props.showCurrency == null ? true : this.props.showCurrency}
        showAccountColumn={this.props.showAccountColumn == null ? true : this.props.showAccountColumn}
        onTransactionDelete={(tran) => this.viewStateProvider.onTransactionDelete(tran)}
        onUpdateTransactionDate={(id,date) => this.viewStateProvider.onUpdateTransactionDate(id,date)}
        showRealEstateBadges={this.props.showRealEstateBadges}
        showIncomeStreamBadges={this.props.showIncomeStreamBadges}

        initialFilter={this.props.initialFilter}
        hideFilters={this.props.hideFilters}
        hideBudgetColumn={this.props.hideBudgetColumn}
      >
      </ControlledTransactionList>
  }

  componentDidMount() {
    this.sub = this.viewStateProvider.viewState.subscribe(x => this.setState({ viewState: x }));
  }

  componentWillUnmount() {
    this.sub.unsubscribe();
  }
}

export default TransactionList;