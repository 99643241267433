import React, { Component } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import predef from '../../predef';
import { PrivateRoute } from '../../private-route';
import { routeParamId } from '../../util';
import Page from '../common/page';
import Calendar from '../services/calendar-service';
import BreadCrumbsStateService from '../state-services/bread-crumbs-state-service-service';
import SecondaryNavStateService from '../state-services/secondary-nav-state-service-service';
import IncomeStreamStore from '../stores/income-streams-store';
import './income-stream-dashboard.scss';
import IncomeStreamReports from './income-stream-reports';
import incomeStreamTransactions from './income-stream-transactions';
import IncomeStreamNotes from './income-stream-notes';
import DocumentsGrid from '../documents/documents-grid';
import IncomeStreamRecurringTodos from './income-stream-recurring-todos';
import CashFlowMatrixReport from '../reports/cash-flow-matrix-report';
import IncomeStreamStatements from './income-stream-statements';

const IncomeStreamDocuments = (props) => {
  let id = routeParamId(props);

  return <div className="income-stream-notes-c">
    <DocumentsGrid id="income-stream" docRef={predef.documentRefs.incomeStream} refId={id}></DocumentsGrid>
  </div>
}

const IncomeStreamCashFlowMatrix = (props) => {
  let id = routeParamId(props);

  return <CashFlowMatrixReport
    transactionFilter={{ incomeStreamId: id }}
    reportRef={predef.yearlyCashFlowReportRefs.incomeStream}
    refId={id}>
  </CashFlowMatrixReport>
}

const routesConfig = [
  { component: IncomeStreamStatements, route: `/incomestream/:id/statements`, url: (match) => `${match.url}/statements`, label: 'income-stream-dashboard.statements-tab' },
  { component: IncomeStreamReports, route: `/incomestream/:id/reports`, url: (match) => `${match.url}/reports`, label: 'income-stream-dashboard.reports-tab' },
  { component: IncomeStreamCashFlowMatrix, route: `/incomestream/:id/cashflow`, url: (match) => `${match.url}/cashflow`, label: 'income-stream-dashboard.cash-flow-tab' },
  { component: incomeStreamTransactions, route: `/incomestream/:id/transactions`, url: (match) => `${match.url}/transactions`, label: 'common.transactions' },
  { component: IncomeStreamDocuments, route: `/incomestream/:id/documents`, url: (match) => `${match.url}/documents`, label: 'common.documents' },
  { component: IncomeStreamNotes, route: `/incomestream/:id/notes`, url: (match) => `${match.url}/notes`, label: 'common.notes' },
  { component: IncomeStreamRecurringTodos, route: `/incomestream/:id/recurringtodos`, url: (match) => `${match.url}/recurringtodos`, label: 'common.recurring-todos' },
]

class IncomeStreamDashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      incomeStream: null,
      month: Calendar.todayIso,
      isLoadingIndicators: true
    };
    this.renderBody = this.renderBody.bind(this);
  }

  render() {
    return <Page
      className="income-stream-dashboard-c"
      loading={this.state.isLoading}
      data={this.state.incomeStream}
      renderBody={this.renderBody}
      noPanel
    >
    </Page>
  }

  onMonthChange(m) {
    this.setState({ month: m }, () => this.updateIndicators());
  }

  renderBody() {
    var id = routeParamId(this.props);
    var pathPrefix = `/incomestream/${id}`;

    return <Switch>
      <Redirect from={pathPrefix} exact to={`${pathPrefix}/statements`} />
      {routesConfig.map(r => {
        return <PrivateRoute key={r.route} path={r.route} component={r.component}></PrivateRoute>
      })}
    </Switch>
  }

  componentDidMount() {
    var id = routeParamId(this.props);
    this.setState({ incomeStreamId: id }, () => {
      this.fetchIncomeStream();

    });
  }

  static getDerivedStateFromProps(props, state) {
    var id = routeParamId(props);
    if (id != state.incomeStreamId) {
      return { incomeStreamId: id };
    }

    return null;
  }

  setNavs() {
    SecondaryNavStateService.set(routesConfig.select(x => { return { url: x.url(this.props.match), label: x.label } }));

    BreadCrumbsStateService.set(routesConfig.select(x => {
      return {
        url: x.url(this.props.match), crumbs: [
          { url: predef.routes.incomeStreams.index, label: 'income-stream-dashboard.income-streams' },
          { text: this.state.incomeStream.name },
          { label: x.label }
        ]
      }
    }));

  }

  fetchIncomeStream() {
    IncomeStreamStore.byId(this.state.incomeStreamId).then(i => {
      this.setState({ isLoading: i == null, incomeStream: i }, () => this.setNavs());
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.incomeStreamId != this.state.incomeStreamId) {
      this.fetchIncomeStream();
    }
  }

  componentWillUnmount() {
    SecondaryNavStateService.clear();
    BreadCrumbsStateService.clear();
  }

}

export default IncomeStreamDashboard;