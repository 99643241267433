import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button from '../common/button';
import Panel from '../common/panel';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { H3 } from '../common/headers';
import Localization from '../services/localization-service';

import './days-select.scss';
import Select from '../common/select';
import { BehaviorSubject } from 'rxjs';
import SelectInput from './select-input';
import Calendar from '../services/calendar-service';

const getDays = (month, fullDateLabel) => {
  let max = 31;
  let year = null;
  let monthNumber = null;
  if (month != null) {
    let momentDate = Calendar.momentFromIsoDate(month);
    year = momentDate.year();
    monthNumber = momentDate.month()+1;
    max = momentDate.daysInMonth();
  }
  let days = [];
  for (let i = 1; i <= max; i++) {
    days.push({ label: fullDateLabel && month!=null ? Localization.formatDate(`${year}-${monthNumber}-${i}`) : i.toString(), value: i });
  }
  if (month == null) {
    days[30].label = "31 / " + Localization.formatMessage('days-select.last-day')
  }
  return days;
}

var options = null;

const DaysSelect = (props) => {
  if (options == null) {
    options = new BehaviorSubject(getDays());
  }
  return <SelectInput onChange={v =>{
    props?.onChange(v)
  }} value={props.value} className="days-select-c" options={getDays(props.month, props.fullDateLabel)} ></SelectInput>
}


DaysSelect.propTypes = {

}


export default DaysSelect;