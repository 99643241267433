import React, { Component } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import predef from '../../predef';
import { PrivateRoute } from '../../private-route';
import { routeParamId } from '../../util';
import Page from '../common/page';
import BreadCrumbsStateService from '../state-services/bread-crumbs-state-service-service';
import SecondaryNavStateService from '../state-services/secondary-nav-state-service-service';
import RealEstateStore from '../stores/real-estates-store';
import './real-estate-dashboard.scss';
import { EditRealEstate } from './real-estate-form';
import Residents from './residents';
import CashFlowMatrixReport from '../reports/cash-flow-matrix-report';
import { RealEstateContext } from '../contexts';
import TransactionList from '../transactions/transaction-list';
import Notes from '../notes/notes';
import DocumentsGrid from '../documents/documents-grid';

const RealEstateYearlyReport = (props) => {
  let id = routeParamId(props);

  return <CashFlowMatrixReport
    transactionFilter={{ realEstateId: id }}
    reportRef={predef.yearlyCashFlowReportRefs.realEstate}
    refId={id}>
  </CashFlowMatrixReport>
}

const RealEstateDocuments = (props) => {
  let id = routeParamId(props);
  return <div className="real-estate-documents-c">
    <RealEstateContext.Consumer>
      {re => <DocumentsGrid id="real-estate" docRef={predef.documentRefs.realEstate} refId={id} fileNamePrefix={re.name}></DocumentsGrid>}
    </RealEstateContext.Consumer>
  </div>
}

const RealEstateTransactions = (props) => {
  let id = routeParamId(props);
  return <div className="real-estate-transactions-c">
    <TransactionList
      showRealEstateBadges={false}
      initialFilter={{ realEstateId: id }}
      disabledFilters={{ realEstateId: true }}>

    </TransactionList>
  </div>
}

const RealEstateNotes = (props) => {
  let id = routeParamId(props);
  return <div className="real-estate-notes-c">
    <Notes noteRef={predef.noteRefs.realEstate} refId={id}></Notes>
  </div>
}

const routesConfig = [
  { component: RealEstateYearlyReport, route: `/realestate/:id/yearlyreport`, url: (match) => `${match.url}/yearlyreport`, label: 'real-estate-dashboard.yearly-report' },
  { component: RealEstateTransactions, route: `/realestate/:id/transactions`, url: (match) => `${match.url}/transactions`, label: 'common.transactions' },
  { component: RealEstateDocuments, route: `/realestate/:id/documents`, url: (match) => `${match.url}/documents`, label: 'common.documents' },
  { component: RealEstateNotes, route: `/realestate/:id/notes`, url: (match) => `${match.url}/notes`, label: 'common.notes' },
  { component: Residents, route: `/realestate/:id/residents`, url: (match) => `${match.url}/residents`, label: 'real-estate-dashboard.residents' },
  { component: EditRealEstate, route: `/realestate/:id/edit`, url: (match) => `${match.url}/edit`, label: 'real-estate-dasbhoard.info-edit' },
]

class RealEstateDashboard extends Component {

  constructor(props) {
    super(props);
    this.state = { isLoading: true, realEstate: null };
  }

  render() {
    return <Page
      className="real-estate-dashboard-c"
      loading={this.state.isLoading}
      data={this.state.realEstate}
      renderBody={() => this.renderBody()}
      noPanel
    >
    </Page>
  }

  renderBody() {
    var id = routeParamId(this.props);
    var pathPrefix = `/realestate/${id}`;

    return <RealEstateContext.Provider value={this.state.realEstate}>
      <Switch>
        <Redirect from={pathPrefix} exact to={`${pathPrefix}/yearlyreport`} />
        {routesConfig.map(r => {
          return <PrivateRoute key={r.route} path={r.route} component={r.component}></PrivateRoute>
        })}
      </Switch>
    </RealEstateContext.Provider>
  }

  componentDidMount() {
    var id = routeParamId(this.props);
    this.setState({ realEstateId: id }, () => {
      this.fetchRealEstate();
    });
  }

  static getDerivedStateFromProps(props, state) {
    var id = routeParamId(props);
    if (id != state.realEstateId) {
      return { realEstateId: id };
    }

    return null;
  }

  setNavs() {
    SecondaryNavStateService.set(routesConfig.select(x => { return { url: x.url(this.props.match), label: x.label } }));

    BreadCrumbsStateService.set(routesConfig.select(x => {
      return {
        url: x.url(this.props.match), crumbs: [
          { url: predef.routes.realEstates.index, label: 'real-estate-dashboard.real-estates' },
          { text: this.state.realEstate.name },
          { label: x.label }
        ]
      }
    }));
  }

  fetchRealEstate() {
    RealEstateStore.byId(this.state.realEstateId).then(re => {
      this.setState({ isLoading: re == null, realEstate: re }, () => this.setNavs());
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.realEstateId != this.state.realEstateId) {
      this.fetchRealEstate();
    }
  }

  componentWillUnmount() {
    SecondaryNavStateService.clear();
    BreadCrumbsStateService.clear();
  }
}

export default RealEstateDashboard;