import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button, { Cancel, Remove, Save, Edit } from '../common/button';
import Panel from '../common/panel';
import PropTypes from 'prop-types';
import Page from '../common/page';
import classNames from 'classnames/bind';
import { H3 } from '../common/headers';
import CategoryStore from '../stores/categories-store';

import './category-select.scss';
import TextSuggestInput from './text-suggest-input';

class CategorySelect extends Component {

  constructor(props) {
    super(props);
    this.state = { options: null };
  }

  render() {
    return <div className="category-select-c">
      <TextSuggestInput {...this.props} options={this.state.options} ></TextSuggestInput>
    </div>
  }

  componentDidMount() {
    this.sub = CategoryStore.categories.subscribe(cs => {
      if (cs == null) return;
      this.setState({ categories: cs, options: cs.where(x => this.props.expense == true ? x.type == 1 : x.type == 2).select(x=>x.path) })
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.expense != this.props.expense && this.state.options != null){
      this.setState({options: this.state.categories.where(x => this.props.expense == true ? x.type == 1 : x.type == 2).select(x=>x.path) });
    }
  }

  componentWillUnmount() {
    this.sub.unsubscribe();
  }

}


CategorySelect.propTypes = {

}


export default CategorySelect;