import Service from './service';
import predef from '../../predef';

class RecurringTransactionsService extends Service {
    add(rt) {
      return  this.put(
            predef.endpoints.recurringTransactions.index
            , rt
            , predef.events.recurringTransactions.recurringTransactionsChanged
            , rt
            , 'recurring-transactions-service.add-success'
            , 'recurring-transactions-service.add-error'
        )
    }

    update(rt){
        return this.post(
            predef.endpoints.recurringTransactions.index
            , rt
            , predef.events.recurringTransactions.recurringTransactionsChanged
            , rt
            , 'recurring-transactions-service.update-success'
            , 'recurring-transactions-service.update-error'
        )
    }

    deleteTransaction(id){
        return this.delete(
            predef.endpoints.recurringTransactions.indexId(id)
            , predef.events.recurringTransactions.recurringTransactionsChanged
            , id
            , 'recurring-transactions-service.delete-success'
            , 'recurring-transactions-service.delete-error'
        )
    }
}


let instance = new RecurringTransactionsService();
export default instance;
