import { guid } from './util';
import BusSubscription from './bus-subscription';


class Bus {
    constructor() {
        this.logs = [];
        this.subscriptions = [];
    }

    publish(event, payload) {
        if (event == null) throw "Event can't be null or undefined.";

        this.log({ event: event, payload: payload, type: 'event' });
        for (let i = 0; i < this.subscriptions.length; i++) {
            let sub = this.subscriptions[i];
            if (sub.event === event) {
                sub.handler(payload);
            }
        }
    }

    log(log) {
        // if (location.hostname === "localhost") {
        //     this.logs.push(log);
        //     console.info("BUS:", log);
        // }
    }

    subscribe(obj) {
        let props = Object.getOwnPropertyNames(obj).concat(Object.getOwnPropertyNames(Object.getPrototypeOf(obj)));
        let subid = guid();

        for (let i = 0; i < props.length; i++) {
            let funcName = props[i];
            if (funcName.startsWith('_on')) {
                let func = obj[funcName];
                if (typeof func === 'function') {

                    let name = funcName.substr(3);
                    name = name.charAt(0).toLowerCase() + name.slice(1);
                    this.subscriptions.push({ handler: func.bind(obj), event: name, subid: subid, name: obj.constructor?.name });

                }
            }
        }

        return new BusSubscription(subid);
    }

    subscribeTo(event, func) {
        let subid = guid();
        if (Array.isArray(event)) {
            for (let key in event) {
                this.subscriptions.push({ handler: func, event: event[key], subid: subid, name: func.name })
            }
        }
        else {
            this.subscriptions.push({ handler: func, event: event, subid: subid, name: func.name })
        }
        return new BusSubscription(subid);
    }

    unsubscribeById(subid) {
        let subs = [];
        for (let i = 0; i < this.subscriptions.length; i++) {
            let s = this.subscriptions[i];
            if (s.subid !== subid) {
                subs.push(s);
            }
        }

        this.subscriptions = subs;
    }
}

const instance = new Bus();
export default instance;
