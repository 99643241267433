import Service from './service';
import predef from '../../predef';

class AccountsService extends Service {
    addAccount(account) {
        return this.post(
            predef.endpoints.account.index,
            account,
            predef.events.account.accountAdded,
            null,
            'account-service.add-success',
            'account-service.add-error');
    }

    deleteAccount(id) {
        return this.delete(
            predef.endpoints.account.delete(id),
            predef.events.account.accountDeleted,
            null,
            'account-service.delete-success',
            'account-service.delete-error');
    }

    updateAccount(account) {
        return this.put(
            predef.endpoints.account.index,
            account,
            predef.events.account.accountUpdated,
            null,
            'account-service.update-success',
            'account-service.update-error');
    }

    restoreAccount(id) {
        return this.put(
            predef.endpoints.account.restore(id),
            null,
            predef.events.account.accountRestored,
            null,
            'account-service.restore-success',
            'account-service.restore-error');
    }

    orderAccounts(order) {
        return this.put(
            predef.endpoints.account.order
            , order
            , predef.events.account.accountsReordered
            , null
            , 'account-service.change-order-success'
            , 'account-service.change-order-error'

        );
    }

    showHideOnSidebar(id, show) {
        return this.put(predef.endpoints.account.showHideOnSidebar
            , { accountId: id, showOnSidebar: show }
            , predef.events.account.accountUpdated
            , null
            , show ? 'account-service.account-will-be-shown' : 'account-service.account-wont-be-shown'
            , show ? 'account-service.account-will-be-shown-error' : 'account-service.account-wont-be-shown-error'
        )
    }
}


let instance = new AccountsService();
export default instance;
