import predef from '../../predef';
import Store from './store';

class InvestmentsStore {
    constructor() {
        this.investmentsStore = new Store(predef.endpoints.investment.index,
            [predef.events.investment.investmentsChanged], predef.events.investment.investmentsStoreUpdated);

    }

    get investments() {
        return this.investmentsStore.asObservable();
    }

    byId(id) {
        return new Promise((resolve, _) => {
            this.investments.subscribe(entities => {
                if (entities == null) return;

                resolve(entities.single(x => x.id == id));
            });
        });
    }
}

const instance = new InvestmentsStore();
export default instance;
