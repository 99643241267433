import React, { Component } from 'react';
import NoteForm from '../notes/note-form';
import Localization from '../services/localization-service';
import Button from './button';
import Panel from './panel';
import Tags from './tags';
import './timeline-item.scss';
import classNames from 'classnames/bind';
import { guid } from '../../util';

export default class TimelineItem extends Component {

    constructor(props) {
        super(props);
        this.state = { mode: 'view' };
    }

    render() {
        return !this.props.missingItems ? <div key={this.props.item.id} className={classNames('timeline-item', { deleting: this.state.isDeleting })} date-is={Localization.formatDate(this.props.item.date)}>
            {this.state.mode == 'view' ? <div className={classNames("tl", { highlight: this.props.highlight })}>
                {/* {this.state.isDeleting ? <div className="deleting-bg"></div> : null} */}
                <div className="deleting-bg"></div>
                <div className="tl-header">
                    <h4>{this.props.item.title}</h4>
                    {this.props.showRemove || this.props.showEditNote ? <div className="actions">
                        {this.props.showRemove ? <Button link fa="times" label="common.remove" danger onClick={() => {
                            this.setState({ isDeleting: true }, () => {
                                Promise.resolve(this.props.onRemove?.(this.props.item)).then(x => this.setState({ isDeleting: false }));
                            });
                        }}></Button> : null}
                        {this.props.showEditNote ? <Button link fa="edit" label="common.edit" onClick={() => this.setState({ mode: 'edit' })}></Button> : null}
                    </div> : null}
                </div>
                <div className="tl-body">
                    <p dangerouslySetInnerHTML={{ __html: this.props.item.body }}>
                    </p>
                </div>
                <div className="tl-footer">
                    <Tags tags={this.props.item.tags}></Tags>
                </div>
            </div> : <Panel><NoteForm noteRef={this.props.item.ref} refId={this.props.item.refId} note={this.props.item} onCancel={() => this.setState({ mode: 'view' })} onSave={() => this.setState({ mode: 'view' })}></NoteForm></Panel>}
        </div> : <div key={guid()} className="timeline-item missing-tiems"></div>
    }

}