import React from 'react';
import { H2 } from '../common/headers';
import Panel from '../common/panel';
import Transfer from '../transactions/transfer';
import './transfer-page.scss';


const TransferPage = (props) => {
  let returnPath = props.match?.params?.returnPath;
  let id = props.match?.params?.id;

  return <div className="transfer-page-c">
    <H2 header="transfer-page.header"></H2>
    <Panel>
      <Transfer transactionId={id} returnPath={returnPath}></Transfer>
    </Panel>
  </div>
}


TransferPage.propTypes = {

}


export default TransferPage;