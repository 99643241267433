import Service from './service';
import predef from '../../predef';

class UserSettingsService extends Service {
   save(settings){
    return this.post(
        predef.endpoints.userSettings.index,
        settings,
        predef.events.userSettings.userSettingsUpdated,
        null,
        'user-settings-service.saving-settings-success',
        'user-settings-service.saving-settings-error'
    );
   }

}


let instance = new UserSettingsService();
export default instance;
