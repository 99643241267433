import moment from 'moment';
import React, { Component } from 'react';
import Bus from '../../bus';
import predef from '../../predef';
import { routeParamId } from '../../util';
import Badge from '../common/badge';
import Button, { Remove } from '../common/button';
import Date from '../common/date';
import Grid from '../common/grid/grid';
import LoadingScreen from '../common/loading-screen';
import Number from '../common/number';
import Panel from '../common/panel';
import ResidentsService from '../services/residents-service';
import './residents.scss';

class Residents extends Component {

  constructor(props) {
    super(props);
    this.state = { isLoading: true, residents: null, realEstateId: null };
  }

  render() {
    return <Panel
      className="residents-c"
    >
      {this.isLoading ? <LoadingScreen></LoadingScreen> : this.renderBody()}
    </Panel>
  }

  renderBody() {
    return <React.Fragment>
      <Button label="resients.add-resident" fa="plus" path={predef.routes.residents.addResident(this.state.realEstateId)}></Button>

      <Grid
        pageSize={20}
        id="residents"
        data={this.state.residents}
        definition={[
          {
            show: true,
            name: "name",
            headerClasses: "len-200",
            header: "residents.name",
            renderCell: (res) => res.name,
            sortable: true,
            value: res => res.name
          },
          {
            show: true,
            name: "room",
            headerClasses: "len-120",
            header: "residents.room",
            renderCell: (res) => res.room,
            sortable: true,
            value: res => this.getStatus(res)?.text || ''
          },
          {
            show: true,
            name: "status",
            headerClasses: "len-120",
            header: "residents.status",
            renderCell: (res) => {
              let status = ResidentsService.getStatus(res);
              if (status == null) return null;

              return <Badge {...status}></Badge>
            },
            sortable: true,
            value: res => this.getStatus(res)?.text || ''
          },
          {
            show: true,
            name: "leaseStart",
            headerClasses: "len-180",
            header: "residents.lease-start",
            renderCell: (res) => <Date date={res.leaseStart}></Date>,
            sortable: true,
            value: res => res.leaseStart || ''
          },
          {
            show: true,
            name: "leaseEnd",
            headerClasses: "len-180",
            header: "residents.lease-end",
            renderCell: (res) => <Date date={res.leaseEnd}></Date>,
            sortable: true,
            value: res => res.leaseEnd || ''
          },
          {
            show: true,
            name: "monthlyRent",
            headerClasses: "len-120",
            header: "residents.monthly-rent",
            renderCell: (res) => <Number value={res.monthlyRent}></Number>,
            sortable: true,
            value: res => res.monthlyRent || ''
          },
          {
            show: true,
            name: "utilities",
            headerClasses: "len-120",
            header: "residents.utilities",
            renderCell: (res) => <Number value={res.utilities}></Number>,
            sortable: true,
            value: res => res.utilities || ''
          },
          {
            show: false,
            name: "address",
            headerClasses: "len-280",
            header: "residents.address",
            renderCell: (res) => res.address,
            sortable: false,
            value: res => res.address || ''
          },
          {
            show: false,
            name: "email",
            headerClasses: "len-180",
            header: "residents.email",
            renderCell: (res) => res.email,
            sortable: true,
            value: res => res.email || ''
          },
          {
            show: false,
            name: "phoneNumber",
            headerClasses: "len-180",
            header: "residents.phone-number",
            renderCell: (res) => res.phoneNumber,
            sortable: true,
            value: res => res.phoneNumber || ''
          },
          {
            show: false,
            name: "icePhoneNumber",
            headerClasses: "len-180",
            header: "residents.ice-phone-number",
            renderCell: (res) => res.icePhoneNumber,
            sortable: true,
            value: res => res.icePhoneNumber || ''
          },
          {
            show: true,
            name: "actions",
            headerDisplayName: 'common.actions',
            renderCell: (res) => {
              return <React.Fragment>
                <Button link path={predef.routes.residents.dashboard(res.id)} label="residents.dashboard" ></Button>
                <Remove></Remove>
              </React.Fragment>
            },
            sortable: false,
          }

        ]}>

      </Grid>
    </React.Fragment>
  }



  componentDidMount() {
    let id = routeParamId(this.props);
    this.setState({ realEstateId: id }, () => this.fetchResidents())
    this.busSub = Bus.subscribe(this);
  }

  fetchResidents() {
    if (this.state.realEstateId == null) return;
    ResidentsService.getRealEstateResidents(this.state.realEstateId).then(rs => this.setState({
      residents: rs,
      isLoading: rs == null
    }))
  }

  _onResidentsChanged() {
    this.fetchResidents();
  }

  componentWillUnmount() {
    this.busSub.unsubscribe();
  }

}

export default Residents;