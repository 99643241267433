import React from 'react';
import CurrenciesStore from '../stores/currencies-store';
import UserSettingsStore from '../stores/user-settings-store';
import Select from './select';
import { combineLatest, map } from 'rxjs/operators';
import TextSuggestInput from './text-suggest-input';
import predef from '../../predef';

const formatGroupLabel = (data) => {
    return <div>
        <span className="currency-select-group-label">{
            data.label
        }</span>
    </div>
}

const getGroupedCurrencies = (onlyUsed) => {
    return CurrenciesStore.currencies.pipe(combineLatest(UserSettingsStore.userSettings)).pipe(map(([currencies, settings]) => {
        var used = settings.currenciesOrder.map(x => currencies.find(y => y.value === x));
        if (onlyUsed) {
            return used;
        }

        return [
            {
                label: 'currency-select.used-currencies',
                translate: true,
                options: used
            },
            {
                label: 'currency-select.other-currencies',
                translate: true,
                options: currencies.filter(x => !settings.currenciesOrder.contains(x.value))
            }
        ]
    }));
}


export const CurrencySelect = (props) => {
    if (props.onlyUsed){
        return <Select className="currency-select-c" 
            isClearable={props.isClearable == null || props.isClearable} 
            {...props} 
            formatGroupLabel={props.onlyUsed ? null : formatGroupLabel} 
            options={getGroupedCurrencies(props.onlyUsed)} ></Select> 
    }

    return <TextSuggestInput options={predef.currencies} {...props}></TextSuggestInput>
}