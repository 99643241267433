import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button, { Cancel, Remove, Save, Edit } from '../common/button';
import Panel from '../common/panel';
import PropTypes from 'prop-types';
import Page from '../common/page';
import classNames from 'classnames/bind';
import { H3 } from '../common/headers';
import ReactSelect from 'react-select';
import './select-input.scss';


const selectStyles = {
  menuPortal: (base, state) => ({
    ...base,
    zIndex: "100030"
  }),
  control: (base) => ({
    ...base,
    height: '34px',
    'min-height': '34px',
  })
};



class SelectInput extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  onChange(v) {
    this.props.onChange(v?.value);
  }

  render() {
    let value = this.props.options == null ? null : this.props.options.single(x => x.value == this.props.value);
    return <div className={classNames('select-input-c', this.props.className)}>
       {this.props.label ? <label ><FormattedMessage id={this.props.label}></FormattedMessage></label> : null}
      <ReactSelect
        styles={selectStyles}
        // isDisabled={this.state.isLoading || this.props.disabled}
        classNamePrefix='react-select'
        className='react-select-container'
        placeholder={this.props.placeholder ? Localization.formatMessage(this.props.placeholder) : null}
        onChange={(v) => this.onChange(v)}
        value={value}
        options={this.props.options}
        menuPortalTarget={document.body}
        isSearchable={true}
        onKeyDown={(e) => {
          if (e.keyCode === 13 && this.props.value != null && this.props.value != '' && this.props.onSubmit != null) {
            this.props.onSubmit();
          }
        }}
      // menuIsOpen={true}
      />
    </div>
  }



  componentDidMount() {
  }

  componentWillUnmount() {
  }

}


SelectInput.propTypes = {
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func.isRequired,

}


export default SelectInput;