import { BehaviorSubject } from 'rxjs';


class BreadCrumbsStateServiceService {
    constructor() {
        this._breadCrumbs = new BehaviorSubject(null);
    }

    get breadCrumbs() {
        return this._breadCrumbs.asObservable();
    }

    set(breadCrumbs) {
        this._breadCrumbs.next(breadCrumbs);
    }

    clear() {
        this._breadCrumbs.next(null);
    }
}


let instance = new BreadCrumbsStateServiceService();
export default instance;
