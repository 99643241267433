import React from 'react';
import MonthInput from './month-input';
import './months-range-input.scss';


const MonthsRangeInput = (props) => {
  return <div className="months-range-input-c">
    <MonthInput name="monthFrom"
      label="months-range-input.month-from"
      value={props.value?.dateFrom}
      onChange={(v) => props.onChange({dateFrom: v, dateTo: props.value?.dateTo})}
    ></MonthInput>
    <MonthInput name="monthTo"
      label="months-range-input.month-to"
      value={props.value?.dateTo}
      onChange={(v) => props.onChange({dateFrom: props.value?.dateFrom, dateTo: v})}
    ></MonthInput>
  </div>
}


MonthsRangeInput.propTypes = {

}


export default MonthsRangeInput;