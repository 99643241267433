import { replaceAll } from '../../util';

class ParsingService {
    parseNumber(str) {
        if (str == null) return null;
        let isNegative = false;
        if (typeof str !== 'string') {
            str = str.toString();
        }

        if (str.startsWith("-")) {
            str = str.substring(1);
            isNegative = true;
        }
        const comma = ",";
        const dot = ".";

        str = replaceAll(str, " ", "");
        str = replaceAll(str, "\t", "");

        if (!(/^\-?([0-9]|\.|\,)+$/g).test(str)) {
            return null;
        }

        let lastComma = str.lastIndexOf(comma);
        let lastDot = str.lastIndexOf(dot);
        let cutoff = lastComma > lastDot ? lastComma : lastDot;
        let fraction = str.substring(cutoff, str.length);
        let whole = str.substring(0, cutoff);
        whole = replaceAll(whole, "\\,", "");
        whole = replaceAll(whole, "\\.", "");
        fraction = replaceAll(fraction, "\\,", ".");
        let numStr = whole + fraction;

        let num = parseFloat(numStr);

        if (isNaN(num) || !isFinite(num))
            return null;

        return !isNegative ? num : num * -1;
    }

    isValidNumber(str) {
        if (str == null) return false;

        if (typeof str !== 'string') {
            str = str.toString();
        }

        if (str.startsWith("-")) {
            str = str.substring(1);
        }

        const comma = ",";
        const dot = ".";

        str = replaceAll(str, " ", "");
        str = replaceAll(str, "\t", "");

        if (!(/^\-?([0-9]|\.|\,)+$/g).test(str)) {
            return false;
        }

        let lastComma = str.lastIndexOf(comma);
        let lastDot = str.lastIndexOf(dot);
        let cutoff = lastComma > lastDot ? lastComma : lastDot;
        let fraction = str.substring(cutoff, str.length);
        let whole = str.substring(0, cutoff);
        whole = replaceAll(whole, "\\,", "");
        whole = replaceAll(whole, "\\.", "");
        fraction = replaceAll(fraction, "\\,", ".");
        let numStr = whole + fraction;

        let num = parseFloat(numStr);

        return !isNaN(num) && isFinite(num);
    }
}

const instance = new ParsingService();
export default instance;
