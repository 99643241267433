import Api from "../../api";
import predef from "../../predef";
import Bus from "../../bus";
import Service from "./service";

class TransactionsService extends Service {
  query(query) {
    return this.post(predef.endpoints.transaction.query2, query);
  }

  add(transaction) {
    return this.post(
      predef.endpoints.transaction.index,
      transaction,
      predef.events.transaction.added,
      transaction,
      "transaction-service.adding-transaction-success",
      "transaction-service.adding-transaction-error"
    );
  }

  deleteTransaction(transaction) {
    return this.delete(
      predef.endpoints.transaction.delete(transaction.id),
      predef.events.transaction.deleted,
      transaction,
      "transaction-service.deleting-transaction-success",
      "transaction-service.deleting-transaction-error"
    );
  }

  update(transaction, originalTransaction) {
    return this.put(
      predef.endpoints.transaction.update(transaction.id),
      transaction,
      predef.events.transaction.updated,
      { newVersion: transaction, prevVersion: originalTransaction },
      "transaction-service.updating-transaction-success",
      "transaction-service.updating-transaction-error"
    );
  }

  makeTransfer(transfer) {
    return this.post(
      predef.endpoints.transaction.makeTransfer,
      transfer,
      predef.events.transfer.added,
      transfer,
      "transaction-service.making-transfer-success",
      "transaction-service.making-transfer-error"
    );
  }

  editTransfer(transfer) {
    return this.post(
      predef.endpoints.transaction.editTransfer,
      transfer,
      predef.events.transfer.updated,
      transfer,
      "transaction-service.updating-transfer-success",
      "transaction-service.v-transfer-error"
    );
  }

  getTransaction(id) {
    return this.get(predef.endpoints.transaction.get(id));
  }

  getNotReimbursed(query) {
    return this.post(predef.endpoints.transaction.notReimbursed, query);
  }

  getReimbursed(query) {
    return this.post(predef.endpoints.transaction.reimbursed, query);
  }

  getReimbursements(operationId) {
    return this.get(predef.endpoints.transaction.reimbursements(operationId));
  }

  getTransactionByOperationId(operationId) {
    return this.get(
      predef.endpoints.transaction.getTransactionByOperationId(operationId)
    );
  }

  getReimbursableById(id) {
    return this.get(predef.endpoints.transaction.reimbursableById(id));
  }

  updateTransactionDate(id, date) {

    return this.post(
        predef.endpoints.transaction.updateTransactionDate(id),
        {date},
        predef.events.transaction.updated,
        {transactionId: id, newDate: date},
        "transaction-service.updating-transaction-date-success",
        "transaction-service.updating-transaction-date-error"
      );
  }
}

let instance = new TransactionsService();
export default instance;
