import PropTypes from 'prop-types';
import React from 'react';
import './radio-switch-input.scss';
import { FormattedMessage } from 'react-intl';

const getButtonClasses = (value, item) => {
  let classes = 'btn ';
  classes += 'btn-' + (value === item.value ? item.activeClass : item.inactiveClass);
  return classes;
}

const RadioSwitchInput = (props) => {
  return (
    <div className="radio-switch-input-c">
      <div className="btn-group" role="group" >
        {props.items.map(item =>
          <button key={item.value}
            onClick={() => props.onChange(item.value)}
            type="button" className={getButtonClasses(props.value, item)}>{(item.icon != null ? <span className={item.icon}></span> : null)} <FormattedMessage id={item.label}></FormattedMessage></button>)}
      </div>
    </div>
  );
}

RadioSwitchInput.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    activeClass: PropTypes.string.isRequired,
    inactiveClass: PropTypes.string.isRequired
  })),
  onChange: PropTypes.func.isRequired
}

export default RadioSwitchInput;