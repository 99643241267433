import React, { Component } from 'react'
import './currency-tabs.scss';
import Tabs from './tabs';
import { NoDataMessage } from './message';
import PropTypes from 'prop-types';
import UserSettingsStore from '../stores/user-settings-store';
import Loader from './loader';
import LoadingPlaceholder from './loading-placeholder';



export default class CurrencyTabs extends Component {

  constructor(props) {
    super(props);
    this.state = { currencies: null, isLoading: true }
  }
  render() {
    let props = this.props;
    return (
      <div className="currency-tabs-c">
        {this.state.isLoading ? <LoadingPlaceholder></LoadingPlaceholder> : props.currencies == null || !props.currencies.any() ? <NoDataMessage></NoDataMessage> :
          <Tabs tabs={this.order(props.currencies).map(curr => {
            return {
               labelText: (curr.isBaseCurrency ? '~' : '') + curr.currency
              , currency: curr.currency
              , isBaseCurrency: curr.isBaseCurrency
              , render: (tab) => {
                return <div>{props.renderTab(tab.currency, tab.isBaseCurrency)}</div>;
              }
            }
          })}></Tabs>}
      </div>
    )
  }

  componentDidMount() {
    UserSettingsStore.userSettings.subscribe(ts => this.setState({
      currenciesOrder: ts.currenciesOrder,
      isLoading: false
    }));
  }

  order(currencies,) {
    var res = [];
    for (let c of currencies) {
      if (c.isBaseCurrency) {
        res.push(c);
      }
    }
    if (this.state.currenciesOrder == null) {
      var rest = currencies.where(x => !x.isBaseCurrency).orderBy(x => x.currency);
      for (let c of rest) res.push(c);
    }
    else {
      var rest = currencies.where(x => !x.isBaseCurrency).orderBy(x => this.state.currenciesOrder.indexOf(x.currency));
      for (let c of rest) res.push(c);
    }
    return res;
  }

}

CurrencyTabs.propTypes = {
  currencies: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderTab: PropTypes.func.isRequired
}
