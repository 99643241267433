import React, { Component } from 'react';
import { Redirect, Switch, withRouter } from 'react-router-dom';
import { PrivateRoute } from '../private-route';
import { shellRoutes } from '../routes';
import './shell.scss';
import AppHeader from './shell/app-header';
import SideBar from './shell/side-bar';
import classNames from 'classnames/bind';
import Bus from '../bus';
import predef from '../predef';
import SecondaryNav from './shell/secondary-nav';
import BreadCrumbs from './shell/bread-crumbs';
import SideBarHeader from './shell/side-bar-header';
import SideBarStateService from './state-services/side-bar-state-service';
import ErrorBoundary from './common/error-boundary';
import { guid } from '../util';

class Shell extends Component {

  constructor(props) {
    super(props);
    this.state = { tooSmallScreen: false, pinnedSideBar: true, sideBarOpen: true };
  }

  render() {
    return this.renderShell();//(this.state.tooSmallScreen ? <div>too small screen</div> : this.renderShell())
  }

  renderShell() {
    return <div className="shell-c">
      <div className={classNames("side", {
        open: this.state.sideBarOpen,
        closed: !this.state.sideBarOpen,
        pinned: this.state.pinnedSideBar,
        unpinned: !this.state.pinnedSideBar
      })}>
        <SideBarHeader>

        </SideBarHeader>
        <SideBar>

        </SideBar>
      </div>
      <div className={classNames("top", { "side-bar-closed": !this.state.sideBarOpen, "side-bar-unpinned": !this.state.pinnedSideBar })}>
        <div className="app-header"><AppHeader match={this.props.match}></AppHeader></div>
      </div>
      <div className={classNames("bottom", { "side-bar-closed": !this.state.sideBarOpen, "side-bar-unpinned": !this.state.pinnedSideBar })}>
        <div className="main">
          <SecondaryNav></SecondaryNav>
          <BreadCrumbs></BreadCrumbs>
          <div className="content">
            <div>
              <Switch>
                <Redirect from="" exact to="/dashboard" />
                
                <ErrorBoundary key={ this.props.location.pathname}>
                  {this.renderShellRoutes(this.props.match)}
                </ErrorBoundary>
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </div>
  }

  renderShellRoutes(match) {
    let res = [];
    let routes = shellRoutes(match);
    for (let route of routes) {
      res.push(<PrivateRoute key={route.path} path={route.path} component={route.component}></PrivateRoute>)
    }

    return res;
  }

  componentDidMount() {
    this.busSub = SideBarStateService.state.subscribe(s => {
      this.setState({ pinnedSideBar: s.isPinned, sideBarOpen: s.isOpen });
    });
  }

  componentWillUnmount() {
    this.busSub.unsubscribe();
  }

}

export default withRouter(Shell);