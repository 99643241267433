import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button, { Cancel, Remove, Save, Edit } from '../common/button';
import Panel from '../common/panel';
import PropTypes from 'prop-types';
import Page from '../common/page';
import classNames from 'classnames/bind';
import { H3 } from '../common/headers';
import Localization from '../services/localization-service';
import './inline-month-input.scss';
import { guid } from '../../util';
import Calendar, { IsoCalendar } from '../services/calendar-service';
import moment from 'moment';

class InlineMonthInput extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.id = 'imi-' + guid();
    }

    render() {
        this.setDate(this.props.value, true);
        return <div className="inline-month-input-c">
            <div id={this.id}></div>
        </div>
    }

    get $() {
        return $('#' + this.id);
    }

    componentDidMount() {
        this.busSub = Bus.subscribe(this);
        this.createDatePicker(this.props.value, true)
    }

    removeOnChange() {
        this.$.datepicker().off('changeDate');
    }

    addOnChange() {
        this.$.datepicker().on('changeDate', () => this.onChange())
    }

    createDatePicker(value, noOnChange) {
        this.$.datepicker({
            language: Localization.lang,
            format: Localization.formatting.datePicker.monthAndYearFormat,
            minViewMode: 1,
            value: this.valueToDate(value),
        });
        this.addOnChange();
        this.setDate(value, noOnChange);
    }

    componentWillUnmount() {
        this.busSub.unsubscribe();

    }

    _onLanguageChanged(lang) {
        let date = this.getDate();
        this.$.datepicker('destroy');
        this.createDatePicker(date, true);
    }

    getDate() {
        return this.$.datepicker('getDate');
    }


    onChange() {
        let selectedDate = this.getDate();
        if (selectedDate == null) {
            if (this.props.value == null) return;
            this.props?.onChange(null);
            return;
        }

        let date = Calendar.toIsoDate(selectedDate, null);
        let month = IsoCalendar.getMonth(date);
        let year = IsoCalendar.getYear(date)
        if (this.props.value != null && this.props.value.month == month && this.props.value.year == year) return;

        this.props.onChange?.({ year: year, month: month });
    }

    valueToIsoDate(value) {
        return value != null ? `${value.year}-${value.month}-01` : null;
    }

    valueToDate(value) {
        let iso = this.valueToIsoDate(value);
        if (iso == null) return null;
        return Calendar.fromIsoDate(iso);
    }

    setDate(value, noOnChange) {
        let isoDate = this.valueToIsoDate(value);
        let date = null;
        if (isoDate != null) {
            date = Calendar.fromIsoDate(isoDate);
        }

        if (noOnChange) {
            this.removeOnChange();
        }
        this.$.datepicker('setStartDate', this.props.minDate == null ? null : this.valueToDate(this.props.minDate))
        this.$.datepicker('setDate', date);
        if (noOnChange) {
            this.addOnChange();
        }
    }

}


InlineMonthInput.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func
}


export default InlineMonthInput;