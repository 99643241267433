import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button, { Cancel, Remove, Save , Edit} from '../common/button';
import Panel from '../common/panel';
import {H3} from '../common/headers';
import Json from '../common/json';
import Loader from '../common/loader';
import './resident-reports.scss';
import { routeParamId } from '../../util';
import ResientsService from '../services/residents-service'
class ResidentReports extends Component {

  constructor(props) {
    super(props);
    this.state = { isLoading: true, resident: null };
  }

  render() {
    return <Panel className="resident-reports-c">
        {this.state.isLoading ? <Loader show={true}></Loader> : this.renderPanelBody()}
      </Panel>
  }

  renderPanelBody() {
    return <React.Fragment>
      <Json data={this.state.resident}></Json>
    </React.Fragment>
  }


  componentDidMount() {
    let id = routeParamId(this.props);
    ResientsService.getResident(id).then(x=> this.setState({resident: x, isLoading: x== null}))
  }

  componentWillUnmount() {
  }

}

export default ResidentReports;