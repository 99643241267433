
import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import './checkbox.scss';
import classNames from 'classnames/bind';
import { FormattedMessage } from 'react-intl';


const Checkbox = (props) => {
    return <div className={classNames("fc checkbox-fc", `${props.name}-field`, { 'no-label': props.label == null })}>
        <div className="wrapper">
            <div onClick={() => props.onChange(!props.value)} className={"ncheckbox"} >
                <input type="checkbox" onClick={() => props.onChange(!props.value)} onChange={() => { }} checked={props.value}></input>
                <span className="tick"></span>
            </div>
            {props.label == null ? null : <div className="label" onClick={e => $(e.target).parents('.wrapper').children('.ncheckbox').click()}>{props.label ? <FormattedHTMLMessage values={props.labelData} id={props.label}></FormattedHTMLMessage> : null}</div>}
            {
                props.hasError
                    ? <div className="error-message invalid-feedback">
                        <FormattedMessage id={props.errorMessage}></FormattedMessage>
                    </div>
                    : null
            }
        </div>
    </div>
}

export default Checkbox;