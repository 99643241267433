import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button, { Cancel, Remove, Save, Edit } from '../common/button';
import Panel from '../common/panel';
import PropTypes from 'prop-types';
import Page from '../common/page';
import classNames from 'classnames/bind';
import { H3 } from '../common/headers';

import './change-investment-current-value-form.scss';
import NumberInput from '../common/number-input';
import InvestmentService from '../services/investments-service';
import Badge from '../common/badge';

class ChangeInvestmentCurrentValueForm extends Component {


  constructor(props) {
    super(props);
    this.state = { isSaving: false, isSavingAutoCalcualte: false, currentValue: null };
  }

  render() {
    return <div className="change-investment-current-value-form-c">
      <Panel>
        <div className="form">
          <NumberInput allowNegative label="change-investment-current-value-form.current-value" name="currentValue" onChange={v => this.setState({ currentValue: v })} value={this.state.currentValue}></NumberInput>
          <Button loading={this.state.isSaving} className="save-btn" label="change-investment-current-value-form.set-investment-current-value" onClick={() => this.setCurrentValue(this.state.currentValue)}></Button>
        </div>
      </Panel>
    </div>
  }

  setInvestmentCurrentValue() {

  }

  autoCalculate() {
    this.setState({ isSavingAutoCalcualte: true }, () => {
      InvestmentService.autoCalculate(this.props.investmentId)
        .then(() => this.setState({ isSavingAutoCalcualte: false, currentValue: null }))
        .catch(() => this.setState({ isSavingAutoCalcualte: false }))
    })
  }

  setCurrentValue(value) {
    this.setState({ isSaving: true }, () => {
      InvestmentService.setCurrentValue(this.props.investmentId, value)
        .then(() => this.setState({ isSaving: false })).catch(() => this.setState({ isSaving: false }));
    })
  }

  componentDidMount() {
    this.setState({ currentValue: this.props.currentValue })
  }

  componentWillUnmount() {
  }

}


ChangeInvestmentCurrentValueForm.propTypes = {

}


export default ChangeInvestmentCurrentValueForm;