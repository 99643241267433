import predef from '../../predef';
import { BehaviorSubject } from 'rxjs';
import { deepCopy } from '../../util';

class SideBarStateService {
    constructor() {
        let state = window.localStorage.getItem(predef.localStorage.sideBarState);
        if (state != null) {
            state = JSON.parse(state);
        }
        this.currentState = state || { isOpen: true, isPinned: true };
        this.updateBody();
        this._subject = new BehaviorSubject(this.currentState);
    }

    get _state() {
        return deepCopy(this.currentState);
    }

    set _state(state) {
        this.currentState = state;
        this._subject.next(state);
        window.localStorage.setItem('side-bar-state', JSON.stringify(state))
    }

    get state() {
        return this._subject.asObservable();
    }

    open() {
        let state = this._state;
        state.isOpen = true;
        this._state = state;
        this.updateBody();
    }

    close() {
        let state = this._state;
        state.isOpen = false;
        this._state = state;
        this.updateBody();
    }

    toggle() {
        let state = this._state;
        state.isOpen = !state.isOpen;
        this._state = state;
        this.updateBody();
    }

    togglePinned() {
        let state = this._state;
        state.isPinned = !state.isPinned;
        this._state = state;
        this.updateBody();
    }

    closeUnpinned() {
        if (!this._state.isPinned && this._state.isOpen) {
            this.close();
        }
    }

    updateBody() {
        if (this._state.isPinned && this._state.isOpen) {
            $('body').addClass('pinned-sidebar-open');
        }
        else{
            $('body').removeClass('pinned-sidebar-open');
        }
    }
}


let instance = new SideBarStateService();
export default instance;
