import React from 'react';
import AccountsStore from '../stores/accounts-store';
import Select from './select';
import './account-select.scss';
import AccountTypesStore from '../stores/account-types-store'
import { map, filter, combineLatest } from 'rxjs/operators';
import Localization from '../services/localization-service';
import Highlighter from 'react-highlight-words';
import Number from './number';

const formatAccountGroupLabel = (data) => {
    return <div>
        <span className="account-select-group-label">{
            data.label
        }</span>
    </div>
}


const formatOptionLabel = (data, { inputValue }) => {
    return (
        <div className="account-select-option">
            <span className="account-select-option-account-name"><Highlighter
                searchWords={[inputValue]}
                textToHighlight={`${data.label}`}
                highlightClassName="highlighted"
            /></span>
            <span  className="account-select-option-account-balance">{` ${Localization.formatNumber(data.balance)} ${data.currency}`}</span>
        </div>
    );
}

const groupedAccounts = () => {
    return AccountTypesStore.accountTypes.pipe(combineLatest(AccountsStore.accounts)).pipe(map(([types, acs]) => {
        if (types == null || acs == null) return null;

        for (let acc of acs) {
            let type = types.single(x => x.value === acc.type);
            acc.grouping = (type == null ? acc.type : Localization.formatMessage(type.label)) + ' ' + acc.currency;
        }

        let grouped = acs.groupBy(x => x.grouping);
        let source = grouped.select(group => {
            return {
                label: group.key,
                translate: false,
                options: group.values.map(a => { return { label: a.name, value: a.id, currency: a.currency, balance: a.balance } }),

            }
        });

        return source;
    }));
}

const getFilter = (props) => {
    if (props.currencyFilter == null && props.excludeAccount == null) return undefined;

    return (a) => {
        return (props.currencyFilter == null || a.currency === props.currencyFilter) && (props.excludeAccount == null || a.value != props.excludeAccount)
    };
}

export const AccountSelect = (props) =>
    <Select className="account-select-c"
        filter={getFilter(props)}
        isClearable={props.isClearable == null || props.isClearable}
        formatOptionLabel={formatOptionLabel}
        options={groupedAccounts()}
        formatGroupLabel={formatAccountGroupLabel}
        {...props}
    ></Select>