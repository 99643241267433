import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import BarChart from '../common/charts/bar-chart';
import ChartColor from '../common/charts/chart-color';
import CurrencyTabs from '../common/currency-tabs';
import LoadingScreen from '../common/loading-screen';
import { PanelMessage } from '../common/message';
import MonthInput from '../common/month-input';
import Panel from '../common/panel';
import Switch from '../common/switch';
import Calendar, { IsoCalendar } from '../services/calendar-service';
import Localization from '../services/localization-service';
import Report from '../services/report-service';
import './expenses-by-category-report.scss';


class ExpensesByCategoryReport extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      month: Calendar.todayIso,
      includeIncomeCost: false
    };
    this.onIncludeIncomeCostChange = this.onIncludeIncomeCostChange.bind(this);
    this.onMonthChange = this.onMonthChange.bind(this);
    this.noCategory = Localization.formatMessage('expenses-by-category.no-category');
  };

  onMonthChange(value) {
    this.setState({ month: value }, () => this.reloadReport())
  }
  onIncludeIncomeCostChange(value) {
    this.setState({ includeIncomeCost: value }, () => this.reloadReport());
  }
  render() {
    return (
      <div className="expenses-by-category-report-c">
        <h2><FormattedMessage id="expenses-by-category-report.header"></FormattedMessage></h2>
        <Panel>
          <div className="filter">
            <MonthInput onChange={this.onMonthChange} label="common.month" value={this.state.month}></MonthInput>
            <Switch onChange={this.onIncludeIncomeCostChange} text="expenses-by-category.include-income-cost" value={this.state.includeIncomeCost}></Switch>
          </div>
        </Panel>
        {this.state.loading ? <LoadingScreen></LoadingScreen> : <div>
          {this.renderReports()}</div>}
      </div>
    );
  }

  renderReports() {
    if (this.state.data.length == 0) return <PanelMessage message="common.no-data"></PanelMessage>;

    return <CurrencyTabs currencies={this.state.data} renderTab={(currency) => {

      let activeCurrencyItem = this.state.data.single(x => x.currency == currency);
      let items = activeCurrencyItem.items.orderByAsc(x => x.expenses)
      let chartDefinition = {
        x: items.select(x => x.category == '' || x.category == null ? this.noCategory : x.category),
        serie: {
          y: items.select(y => y.expenses),
          itemStyle: (v) => ChartColor.blue.solid
        },
        downloadFileName: "expenses-by-category-report.download-file-name",
        height: items.length * 45 + 100,
        verticalBars: true,
        transactionsQuery: (x, y, seriesIndex, dataIndex) => {
          let query = {
            date: {
              dateFrom: IsoCalendar.startOfMonth(this.state.month),
              dateTo: IsoCalendar.endOfMonth(this.state.month),
            },
            isExpense: true,
            currency: currency,
            isIncomeCost: this.state.includeIncomeCost,
            isReimbursable: false,
            isInvestedAmount: false,
          }

          if (x === this.noCategory) {
            query.hasEmptyCategory = true;
          }
          else {
            query.category = x;
          }
          return query;
        }
      }

      return <BarChart showLabels={true} definition={chartDefinition} ></BarChart>
    }}></CurrencyTabs>
  }


  componentDidMount() {
    this.reloadReport();
  }

  reloadReport() {
    this.setState({ loading: true }, () => {
      Report.expensesByCategory(Calendar.monthDateRange(this.state.month), this.state.includeIncomeCost).then((res) => this.setState({ data: res, loading: false, activeCurrency: this.state.activeCurrency || (res.length > 0 ? res[0].currency : null) }));
    });
  }

  componentWillUnmount() {
  }

}




ExpensesByCategoryReport.propTypes = {

}


export default ExpensesByCategoryReport;