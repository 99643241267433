import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import BarChart from '../common/charts/bar-chart';
import ChartColor from '../common/charts/chart-color';
import CurrencyTabs from '../common/currency-tabs';
import LoadingScreen from '../common/loading-screen';
import { PanelMessage } from '../common/message';
import Report from '../services/report-service';
import './expenses-income-structure-report.scss';
import Calendar, { IsoCalendar } from '../services/calendar-service';
import MonthsRangeInput from '../common/months-range-input';
import Panel from '../common/panel';

class ExpensesIncomeStructureReport extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      dateRange: Calendar.last12MonthsRange
    };
  }

  render() {
    return (
      <div className="expenses-income-structure-report-c">
        <h2><FormattedMessage id="expenses-income-structure-report.header"></FormattedMessage></h2>
        <Panel>
          <MonthsRangeInput value={this.state.dateRange} onChange={(v) => this.setState({ dateRange: v }, () => this.reloadReport())}></MonthsRangeInput>
        </Panel>
        {this.state.loading ? <LoadingScreen></LoadingScreen> : <div>
          {this.renderReports()}</div>}
      </div>
    );
  }

  renderReports() {
    if (this.state.data.currencyItems.length == 0) return <PanelMessage message="common.no-data"></PanelMessage>;

    return <CurrencyTabs currencies={this.state.data.currencyItems} renderTab={(currency, isBaseCurrency) => {

      let activeCurrencyItem = this.state.data.currencyItems.single(x => x.currency == currency && x.isBaseCurrency == isBaseCurrency);
      let items = activeCurrencyItem.items;
      let chartDefinition = {
        unit: currency,
        showIndicators: true,
        x: items.select(x => x.date),
        timeSeries: true,
        showLegend: true,
        height: 600,
        downloadFileName: 'expenses-income-structure-report.download-file-name',
        series: [
          {
            y: items.select(y => y.value.income),
            itemStyle: (v) => ChartColor.green.solid,
            name: 'common.income',
            color: ChartColor.green.standard
          },

          {
            y: items.select(y => y.value.reimbursements),
            itemStyle: (v) => ChartColor.blue.solid,
            name: 'common.reimbursements',
            color: ChartColor.blue.standard
          },
          {
            y: items.select(y => y.value.incomeCost),
            itemStyle: (v) => ChartColor.violet.solid,
            name: 'common.income-cost',
            color: ChartColor.violet.standard
          },
          {
            y: items.select(y => y.value.reimbursables),
            itemStyle: (v) => ChartColor.yellow.solid,
            name: 'common.reimbursables',
            color: ChartColor.yellow.standard
          },
          {
            y: items.select(y => y.value.expenses),
            itemStyle: (v) => ChartColor.red.solid,
            name: 'common.expenses',
            color: ChartColor.red.standard
          },
        ],
        override: {
          legend: {
            top: 35,
            left: 25
          },
          grid: {
            top: 100,
          }
        },
        transactionsQuery: (x, y, seriesIndex, dataIndex) => {
          let query = {
            date: {
              dateFrom: IsoCalendar.startOfMonth(x),
              dateTo: IsoCalendar.endOfMonth(x),
            },
            currency: currency
          }

          switch (seriesIndex) {
            case 0:
              query.isIncome = true;
              query.isReimbursement = false;
              break;
            case 1:
              query.isReimbursement = true;
              break;
            case 2:
              query.isIncomeCost = true;
              break;
            case 3:
              query.isReimbursable = true;
              break;
            case 4:
              query.isExpense = true;
              query.isReimbursable = false;
              query.isIncomeCost = false;
              break;
            case 5:
              query.isExpense = true;
              break;
          }
          return query;
        }
      }



      return <BarChart definition={chartDefinition} key={(isBaseCurrency ? "~" : "") + currency}></BarChart>
    }}></CurrencyTabs>
  }

  reloadReport() {
    this.setState({ loading: true }, () => {
      Report.expenseIncomeStructure(this.state.dateRange).then((res) => {
        this.setState({
          data: res
          , loading: false
          , activeCurrency: this.state.activeCurrency || (res.currencyItems.length > 0 ? res.currencyItems[0].currency : null)
        })
      });
    });
  }

  componentDidMount() {
    this.reloadReport();
    this.busSub = Bus.subscribe(this);
  }

  componentWillUnmount() {
    this.busSub.unsubscribe();
  }

  _onLanguageChanged() {
    this.reloadReport();
  }

}


ExpensesIncomeStructureReport.propTypes = {

}


export default ExpensesIncomeStructureReport;