import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { BehaviorSubject } from 'rxjs';
import Bus from '../../bus';
import DocumentsService from '../services/documents-service';
import AutocompleteInput from './autocomplete-input';
import './document-category-select.scss';
import TextSuggestInput from '../common/text-suggest-input';

class DocumentCategorySelect extends Component {

  constructor(props) {
    super(props);
    this.state = { options: null };
  }

  render() {
    return <div className="document-category-select-c">
      <TextSuggestInput
        value={this.props.value}
        onChange={this.props.onChange}
        label={this.props.label}
        newSelectionPrefix=''
        options={this.state.options}
      ></TextSuggestInput>
    </div>
  }

  loadCategories() {
    DocumentsService.getCategories(this.props.docRef).then(cats => {
      if (this.unmounted) return;
      this.setState({ options: cats })
    });
  }

  componentDidMount() {
    this.busSub = Bus.subscribe(this);
    this.loadCategories();
  }

  _onDocumentsChanged(ref) {
    if (ref == null || ref == this.props.ref)
      this.loadCategories()
  }

  componentWillUnmount() {
    this.busSub.unsubscribe();
    this.unmounted = true;
  }

}

DocumentCategorySelect.propTypes = {
  docRef: PropTypes.string.isRequired
}


export default DocumentCategorySelect;