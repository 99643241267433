import predef from '../../predef';
import Store from './store';
import { map } from 'rxjs/operators';

class UserSettingsStore {
    constructor() {
        let defaultValue = { currenciesOrder: new Array(0) };
        this.userSettingsStore = new Store(predef.endpoints.userSettings.index,
            [predef.events.account.accountAdded
                , predef.events.account.accountDeleted
                , predef.events.account.accountRestored
                , predef.events.userSettings.userSettingsUpdated], predef.events.userSettings.userSettingsStoreUpdated, defaultValue);

    }

    get userSettings() {
        return this.userSettingsStore.asObservable();
    }

    get defaultCurrency() {
        return this.userSettings.pipe(map(ts => {
            if (ts == null || ts.currenciesOrder == null || ts.currenciesOrder.length == 0) {
                return 'PLN';
            }

            return ts.currenciesOrder[0];
        }))
    }

}

const instance = new UserSettingsStore();
export default instance;
