import predef from '../../predef';
import Store from './store';

class RealEstatesStore {
    constructor() {
        this.realEstatesStore = new Store(predef.endpoints.realEstate.index,
            [predef.events.realEstates.realEstatesChanged], predef.events.realEstates.realEstatesStoreUpdated);

    }

    get realEstates() {
        return this.realEstatesStore.asObservable();
    }

    byId(id) {
        return new Promise((resolve, _) => {
            this.realEstates.subscribe(res => {
                if (res == null) return;

                resolve(res.single(x => x.id == id));
            });
        })
    }

}
 
const instance = new RealEstatesStore();
export default instance;
