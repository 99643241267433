import React, { Component } from 'react';
import * as yup from 'yup';
import Form from '../common/form';
import { H2, H3 } from '../common/headers';
import Panel from '../common/panel';
import RadioSwitchInput from '../common/radio-switch-input';
import Categories from '../services/categories-service';
import './category-editor.scss';

const changeCategoryValiationSchema = yup.object().shape({
  fromCategory: yup.string().required().min(1),
  toCategory: yup.string().required().min(1),
});

const deleteCategoryValiationSchema = yup.object().shape({
  category: yup.string().required().min(1),
});

class CategoryEditor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      changeCategoryMode: 'expense',
      deleteCategoryMode: 'expense'
    };
    this.switchItems = [{
      label: 'category-editor.for-expenses',
      icon: 'fa fa-minus',
      activeClass: 'primary',
      inactiveClass: 'outline-secondary',
      value: 'expense'
    }, {
      label: 'category-editor.for-incomes',
      activeClass: 'primary',
      inactiveClass: 'outline-secondary',
      value: 'income',
      icon: 'fa fa-plus'
    }];
  }

  render() {
    return <div className="category-editor-c">
      <H3 header="category-editor.change-category"></H3>
      <Panel>
        {this.renderChangeCategoryForm()}
      </Panel>
      <H3 header="category-editor.delete-category"></H3>
      <Panel>
        {this.renderDeleteCategoryForm()}
      </Panel>
    </div>
  }

  renderChangeCategoryForm() {
    let form = {
      fields: [
        {
          type: 'category',
          name: 'fromCategory',
          label: 'category-editor.from-category',
          income: this.state.changeCategoryMode == 'income',
          expense: this.state.changeCategoryMode == 'expense',
          layout: '',
          errorMessage: "common.field-is-required"
        },
        {
          type: 'category',
          name: 'toCategory',
          label: 'category-editor.to-category',
          income: this.state.changeCategoryMode == 'income',
          expense: this.state.changeCategoryMode == 'expense',
          layout: '',
          errorMessage: "common.field-is-required"
        },
      ]
    }

    return <div>
      <RadioSwitchInput
        value={this.state.changeCategoryMode}
        items={this.switchItems}
        onChange={(v) => this.setState({ changeCategoryMode: v })} ></RadioSwitchInput>
      <Form submitButton={{ width: 15, label: 'category-editor.change-category', fa: 'arrow-right' }} definition={form} onSubmit={(vals) => this.onChangeCategory(vals)} validationSchema={changeCategoryValiationSchema}></Form>
    </div>
  }

  onDeleteCategory(vals) {
    vals.isExpenseCategory = this.state.deleteCategoryMode == 'expense';
    return Categories.deleteCategory(vals);
  }

  onChangeCategory(vals) {
    vals.isExpenseCategory = this.state.changeCategoryMode == 'expense';
    return Categories.changeCategory(vals);
  }

  renderDeleteCategoryForm() {
    let form = {
      fields: [
        {
          type: 'category',
          name: 'category',
          label: 'common.category',
          income: this.state.deleteCategoryMode == 'income',
          expense: this.state.deleteCategoryMode == 'expense',
          layout: '',
          errorMessage: "common.field-is-required"
        }
      ]
    }

    return <div>
      <RadioSwitchInput
        value={this.state.deleteCategoryMode}
        items={this.switchItems}
        onChange={(v) => this.setState({ deleteCategoryMode: v })} ></RadioSwitchInput>
      <Form submitButton={{ width: 15, label: 'category-editor.delete-category-btn', fa: 'minus' }} definition={form} onSubmit={(vals) => this.onDeleteCategory(vals)} validationSchema={deleteCategoryValiationSchema}></Form>

    </div>
  }


  componentDidMount() {

  }

  componentWillUnmount() {
  }

}


CategoryEditor.propTypes = {

}


export default CategoryEditor;