
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import './indicator-2.scss';
import Number from '../common/number';

class Indicator2 extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (this.props.value == null) return null;
    return <div className={classNames('indicator-2-c', this.state.color)}>
      <div>
        <span className="value">{this.props.isEstimated ? "~" : ""}<Number value={this.props.value}></Number></span><span className="currency"> {this.props.currency}</span>
      </div>
      {this.props.label ? <div className="label"><FormattedMessage id={this.props.label}></FormattedMessage></div>  : null}
    </div>
  }
  
  componentDidMount() {
    this.setState({ color: this.props.color || this.getValueBasedColor() });
  }

  getValueBasedColor() {
    if (this.props.value < 0) return 'red';
    if (this.props.value == 0) return 'grey';
    if (this.props.value > 0) return 'green';
  }

  componentWillUnmount() {
  }

}


Indicator2.propTypes = {
  value: PropTypes.number,
  currency: PropTypes.string,

}


export default Indicator2;