import React, { Component } from 'react';
import { copyToClipboard } from '../../util';
import './copy.scss';

export const Copy = ({ value }) => {
    if (value == null || value == '') return null;

    return <div className="copy-c" onClick={() => copyToClipboard(value)}>
        {value}
    </div>
    //  <span className="fa fa-copy"></span>
}