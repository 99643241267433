import React from 'react';
import TransactionList from '../transactions/transaction-list';
import {routeParamId} from '../../util';
import './income-stream-transactions.scss';


const IncomeStreamTransactions = (props) => {
  let id = routeParamId(props);
  return <div className="income-stream-transactions-c">
    <TransactionList
      showIncomeStreamBadges={false}
      initialFilter={{ incomeStreamId: id }}
      disabledFilters={{ incomeStreamId: true }}>
    </TransactionList>
  </div>
}

export default IncomeStreamTransactions;