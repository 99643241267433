import React from 'react';
import { FormattedMessage } from 'react-intl';
import './headers.scss';


export const H3 = (props) => {
  return <h3 className={props.className}>{props.header != null ? <FormattedMessage id={props.header} values={props.values}></FormattedMessage> : props.children}</h3>
}

export const H2 = (props) => {
return <h2>{props.header != null ? <FormattedMessage id={props.header} values={props.values}></FormattedMessage> : null}{props.children}</h2>
}

export const H4 = (props) => {
  return <h4>{props.header != null ? <FormattedMessage id={props.header} values={props.values}></FormattedMessage> : null}{props.children}</h4>
}


export const H5 = (props) => {
  return <h5><FormattedMessage id={props.header} values={props.values}></FormattedMessage></h5>
}

export const H1 = (props) => {
  return <h1><FormattedMessage id={props.header} values={props.values}></FormattedMessage></h1>
}
