import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button, { Cancel, Remove, Save, Edit } from '../common/button';
import Panel from '../common/panel';
import PropTypes from 'prop-types';
import Page from '../common/page';
import classNames from 'classnames/bind';
import { H3 } from '../common/headers';
import Error from '../common/error';

import './server-errors.scss';

class ServerErrors extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (this.props.errors == null) return null;
    return <div className="server-errors-c">
      <Error>{this.props.errors?.errors != null ? this.props.errors.errors.map(e => <FormattedMessage id={e}></FormattedMessage>) : <FormattedMessage id="server-errors.server-error"></FormattedMessage>}</Error>
    </div>
  }



  componentDidMount() {
  }

  componentWillUnmount() {
  }

}


ServerErrors.propTypes = {

}


export default ServerErrors;