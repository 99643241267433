import React, { Component } from 'react';
import * as yup from 'yup';
import { guid } from '../../../util';
import TodosService from '../../services/todos-service';
import EditableGrid from '../grid/editable-grid';
import Loader from '../loader';
import Panel from '../panel';
import './todos.scss';

const validationSchema = yup.object().shape({
  description: yup.string().required('common.field-is-required'),
  deadlineDayOfMonth: yup.number().moreThan(0, 'common.field-is-required')
});

class RecurringTodos extends Component {

  constructor(props) {
    super(props);
    this.state = { isLoading: true, todos: null };
  }

  render() {
    return <Panel className="recurring-todos-c">
      {this.state.isLoading ? <Loader show={true}></Loader> : this.renderPanelBody()}
    </Panel>
  }

  submit(todos) {
    return new Promise((resolve, reject) => {
      TodosService.updateRecurringTodos(this.props.entityRef, this.props.refId, todos)
      .then(() => {
        resolve();
        this.getTodos();
      })
      .catch(() => { 
        reject();
      });
    });
  }

  renderPanelBody() {
    return <EditableGrid
      width={78}
      id="todos"
      onSubmit={(todos) => this.submit(todos)}
      data={this.state.todos}
      validationSchema={validationSchema}
      refreshToken={this.state.refreshToken}
      autoWidth={true}
      newItemTemplate={() => { return { deadlineDayOfMonth: 31 } }}
      definition={[
        {
          show: true,
          header: 'common.description',
          type: 'text',
          name: 'description',
          headerClasses: "len-500"
        },
        {
          show: true,
          header: 'recurring-todos.deadline-day-of-month',
          type: 'days',
          name: 'deadlineDayOfMonth',
          headerClasses: 'len-200',
          clearable: false
        }
      ]} >
    </EditableGrid>
  }

  getTodos() {
    TodosService.getRecurringTodos(this.props.entityRef, this.props.refId).then(todos => {
      this.setState({ todos: todos, isLoading: todos == null, refreshToken: guid() });
    });
  }

  componentDidMount() {
    this.getTodos();
  }

}

export default RecurringTodos;