import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import Bus from "../../bus";
import { guid } from "../../util";
import Localization from "../services/localization-service";
import Calendar from "../services/calendar-service";
import classNames from "classnames/bind";
import moment from "moment";

import "./date-input.scss";

class DateInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "date-" + guid(),
      showCalendarButton: props.showCalendarButton ?? true,
    };
    this.openCalendar = this.openCalendar.bind(this);
    this.addOneDay = this.addOneDay.bind(this);
    this.subtractOneDay = this.subtractOneDay.bind(this);
  }

  openCalendar() {
    this.$.datepicker("show");
  }

  onChange() {
    let date = Calendar.toIsoDate(this.getDate());

    if (date === this.props.value) return;

    this.props.onChange(date);
  }

  addOneDay() {
    this.props.onChange(
      Calendar.momentToIsoDate(moment(this.getDate()).add(1, "day"))
    );
  }

  subtractOneDay() {
    this.props.onChange(
      Calendar.momentToIsoDate(moment(this.getDate()).add(-1, "day"))
    );
  }

  render() {
    this.setDate(this.props.value, true);
    return (
      <div
        className={classNames(
          "fc date-input-c",
          `${this.props.name}-field`,
          "form-group",
          {
            "has-error": this.props.hasError,
            "no-label": this.props.label == null,
          },
          this.props.className
        )}
      >
        {this.props.label != null ? (
          <label>
            <FormattedMessage id={this.props.label}></FormattedMessage>
          </label>
        ) : null}

        <div className="input-group">
          {this.props.showDateAdjustmentButtons ? (
            <div className="input-group-prepend">
              <span
                onClick={this.subtractOneDay}
                className="input-group-text fa fa-chevron-left"
              ></span>
            </div>
          ) : null}
          <input
            className={classNames("form-control", {
              "is-invalid": this.props.hasError,
            })}
            type="text"
            id={this.state.id}
          ></input>
          {this.state.showCalendarButton ? (
            <div className="input-group-append">
              <span
                onClick={this.openCalendar}
                className="input-group-text fa fa-calendar"
              ></span>
            </div>
          ) : null}
          {this.props.showDateAdjustmentButtons ? (
            <div className="input-group-append ">
              <span
                onClick={this.addOneDay}
                className="input-group-text fa fa-chevron-right"
              ></span>
            </div>
          ) : null}
        </div>
        {this.props.hasError ? (
          <div className="error-message invalid-feedback">
            <FormattedMessage id={this.props.errorMessage}></FormattedMessage>
          </div>
        ) : null}
      </div>
    );
  }

  get $() {
    return $("#" + this.state.id);
  }
  getDate() {
    return this.$.datepicker("getDate");
  }
  setDate(date, noOnChange) {
    if (typeof date === "string") {
      date = Calendar.fromIsoDate(date);
    }

    if (noOnChange) {
      this.removeOnChange();
    }

    this.$.datepicker("setDate", date);
    if (noOnChange) {
      this.addOnChange();
    }
  }

  createDatePicker(date, noOnChange) {
    let options = {
      autoclose: true,
      language: Localization.lang,
      format: Localization.formatting.dateFormat.toLowerCase(),
      clearBtn: this.props.allowClear,
    };
    this.$.datepicker(options);
    this.setDate(date, noOnChange);
  }

  removeOnChange() {
    this.$.datepicker().off("changeDate");
  }

  addOnChange() {
    this.$.datepicker().on("changeDate", () => this.onChange());
  }

  _onLanguageChanged(lang) {
    let date = this.getDate();
    this.$.datepicker("destroy");
    this.createDatePicker(date, true);
  }

  componentDidMount() {
    this.busSub = Bus.subscribe(this);
    this.createDatePicker(this.isoToJsDates(this.props.value), true);
  }

  componentWillUnmount() {
    this.busSub.unsubscribe();
  }

  isoToJsDates(date) {
    if (date == null) return null;
    return Calendar.fromIsoDate(date);
  }
}

DateInput.propTypes = {
  name: PropTypes.string.isRequired,
};

export default DateInput;
