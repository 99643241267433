import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import predef from '../../predef';
import Button from '../common/button';
import CurrencyTabs from '../common/currency-tabs';
import { H3 } from '../common/headers';
import LoadingPlaceholder from '../common/loading-placeholder';
import Number from '../common/number';
import Panel from '../common/panel';
import ReportService from '../services/report-service';
import './income-streams-report.scss';
import Message from '../common/message'

class IncomeStreamsReport extends Component {

  constructor(props) {
    super(props);
    this.state = { isLoading: true };
  }

  render() {
    if (this.state.isLoading) return <LoadingPlaceholder></LoadingPlaceholder>
    return <div className="income-streams-report-c">
      {!this.state.isLoading && (this.state.report == null || this.state.report.length == 0) ? <Message message="common.no-data"></Message> : null}
      {!this.state.isLoading && this.state.report != null && this.state.report.length > 0 ?
        <React.Fragment>
          <H3 header="income-streams-report.header"></H3>
          {this.state.report.map(i => {
            return <div className="income-stream" key={i.incomeStreamId}>
              <Panel>
                <h4>{i.name} <Button path={predef.routes.incomeStreams.dashboard(i.incomeStreamId)} link fa="chart-line"></Button></h4>
                <CurrencyTabs currencies={i.currencyItems} renderTab={(c, isBaseCurrency) => this.renderForCurrency(i, c, isBaseCurrency)}></CurrencyTabs>
              </Panel>
            </div>
          })}
        </React.Fragment> : null}
    </div>
  }

  renderForCurrency(incomeStreamReportItem, currency, isBaseCurrency) {
    var currencyItem = incomeStreamReportItem.currencyItems.single(x => x.currency == currency && x.isBaseCurrency == isBaseCurrency);

    if (currencyItem == null) return null;

    var indicators = currencyItem.item;

    return <React.Fragment>
      <table >
        <tbody>
          <tr>
            <td className="text-right"><FormattedMessage id="common.incomes"></FormattedMessage></td>
            <td className=" text-right"><Number value={indicators.income} ></Number></td>
          </tr>
          <tr>
            <td className="text-right"><FormattedMessage id="common.income-costs"></FormattedMessage></td>
            <td className="text-right"><Number value={indicators.incomeCosts} ></Number></td>
          </tr>
          <tr className="top-separator">
            <td className="text-right"><FormattedMessage id="common.net-income"></FormattedMessage></td>
            <td className="text-right"><Number color em value={indicators.netIncome} ></Number></td>
          </tr>
          <tr>
            <td className="text-right"><FormattedMessage id="income-stream-report.other-expenses"></FormattedMessage></td>
            <td className="text-right"><Number value={indicators.expenses} ></Number></td>
          </tr>
          <tr className="top-separator">
            <td className="text-right"><FormattedMessage id="common.cash-flow"></FormattedMessage></td>
            <td className="text-right"><Number color em value={indicators.cashFlow} ></Number></td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  }

  componentDidMount() {
    ReportService.incomeStreamsForMonth(this.props.year, this.props.month).then(report => {
      this.setState({
        report: report,
        isLoading: report == null
      })
    });
  }

  componentWillUnmount() {
  }

}


IncomeStreamsReport.propTypes = {

}


export default IncomeStreamsReport;