import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { deepCopy } from '../../util';
import Button, { Cancel } from '../common/button';
import LoadingPlaceholder from '../common/loading-placeholder';
import Number from '../common/number';
import Calendar from '../services/calendar-service';
import PlanningService from '../services/planning-service';
import AccountsStore from '../stores/accounts-store';
import IncomeStreamStore from '../stores/income-streams-store';
import TransactionsTemplates from '../stores/transaction-templates-store';
import OperationForm from './operation-form';
import TransactionForm from './transaction-form';
import './transaction-input.scss';
import { Redirect } from 'react-router-dom';
import TransactionDateService from '../services/transaction-date-service'


class TransactionInput extends Component {

  constructor(props) {
    super(props);
    this.state = {
      transaction: { date: TransactionDateService.getDate(), operations: [] }
      , budgets: null
      , incomeStreams: null
      , accounts: null
      , templates: null
      , amount: 0
      , redirect: null
    };
  }

  onTransactionChange(values) {
    TransactionDateService.setDate(values.date)
    var acc = this.state.accounts.single(x => x.id == values.accountId);
    values.currency = acc?.currency;
    this.setState({ transaction: values }, () => this.fetchBudgets());
    this.props.onChange?.(this.state.transaction);
  }

  getIsLoading() {
    return this.state.incomeStreams == null
      || (this.state.accounts == null && !this.props.templateMode)
      || (this.state.budgets == null && !this.props.templateMode)
      || this.state.templates == null;
  }

  fetchBudgets() {
    if (this.props.templateMode) {
      this.setState({ isLoading: this.getIsLoading() })
    }
    PlanningService.getBudgets(Calendar.getYearFromIso(this.state.transaction.date), Calendar.getMonthFromIso(this.state.transaction.date)).then(bs => {
      this.setState({ budgets: bs }, () => this.setState({ isLoading: this.getIsLoading() }))
    });
  }

  render() {
    if (this.state.redirect != null) return <Redirect to={this.state.redirect}></Redirect>

    return <div className="transaction-input-c">
      {this.state.isLoading ? <LoadingPlaceholder></LoadingPlaceholder> : this.renderBody()}
    </div>
  }

  renderBody() {
    return <div>
      <div className="f">
        <div className="header">
          <div className="sum">
            <Number value={this.state.amount}></Number>
          </div>
          <TransactionForm 
            currency={this.props.currency}
            values={this.state.transaction}
            recurringTransaction={this.props.recurringTransaction}
            submitted={this.state.submitted}
            hideDate={this.props.hideDate || this.props.templateMode}
            hideAccount={this.props.accountId != null || this.props.templateMode}
            disableDescription={this.state.transaction.operations.length < 2}
            onChange={(v) => this.onTransactionChange(v)}></TransactionForm>
        </div>
        {this.state.transaction?.operations?.map((op, i) => {
          return <OperationForm key={i}
            values={op}
            mode={this.getMode()}
            recurringTransaction={this.props.recurringTransaction}
            date={this.state.transaction.date}
            currency={this.props.currency || this.state.transaction.currency}
            accountId={this.props.accountId || this.state.transaction.accountId}
            index={i}
            onChange={(op, i) => this.onOperationChange(op, i)}
            allowRemove={this.state.transaction.operations.length > 1}
            onRemove={(i) => this.removeOperation(i)}
            onSubmit={() => this.save()}
            investmentTransactionType={this.props.investmentTransactionType}
          >
          </OperationForm>
        })}
        <Button className="add-op-bt" outline label="transaction-form.add-operation" fa="plus" onClick={() => this.addOperation()}></Button>
      </div>
      {
        this.props.templateMode || this.props.recurringTransaction ? null : <div className="actions">
          {this.props.returnPath != null ? <Cancel path={this.props.returnPath}></Cancel> : null}
          <Button width={9} label="common.save" loading={this.props.isSubmitting} fa="save" onClick={() => this.save()}> </Button>
        </div>
      }
    </div>
  }

  getMode() {
    if (this.props.templateMode) return 'template';
    if (this.props.mode == 'investment') return 'investment';
  }

  save() {
    this.setState({ submitted: true }, (state) => {
      this.setState((state, props) => {
        state.transaction.operations = TransactionInput.setOperationsErrors(state.transaction.operations);
        return {
          transaction: state.transaction
        }
      }, () => {

        if (this.state.transaction.operations.all(x => x.errors == null) && (this.props.accountId != null || this.state.transaction.accountId != null)) {
          let tran = deepCopy(this.state.transaction);
          tran.accountId = this.props.accountId || tran.accountId;
          tran.currency = this.props.currency || tran.currency;

          if (this.props.mode === 'investment') {
            tran.investmentId = this.props.investmentId;
            tran.isInvestmentCapital = this.props.investmentTransactionType === 'capital';
            tran.isInvestmentReturn = this.props.investmentTransactionType === 'return';
          }

          if (tran.operations.length < 2) tran.description = null;
          for (let op of tran.operations) {
            op.accountId = tran.accountId;
            op.currency = tran.currency;
            op.amount = op.amount * (op.expense ? -1 : 1);
            if (this.props.mode === 'investment') {
              op.investmentId = this.props.investmentId;
              op.isInvestmentCapital = tran.isInvestmentCapital;
              op.isInvestmentReturn = tran.isInvestmentReturn;
            }
          }

          this.props.onSubmit(tran)
            .then(x => {
              if (this.props.returnPath != null) {
                this.setState({ redirect: this.props.returnPath });
                return;
              }
              this.setState({ submitted: false, transaction: this.emptyTransaction(), amount: 0 });
            });
        }
      });
    });
  }

  addOperation() {
    this.setState((state, props) => {
      state.transaction.operations.push({ expense: true })
      return {
        transaction: state.transaction
      }
    })

  }

  removeOperation(i) {
    this.setState((state, props) => {
      state.transaction.operations = state.transaction.operations.removeAt(i)
      return {
        transaction: state.transaction
      }
    });
  }

  onOperationChange(op, i) {
    this.setState((state, props) => {
      if (op.expense
        && op.category != null
        && (op.lastCategory == null
          || (op.lastCategory != op.category
            && op.category != null))) {

        for (let b of state.budgets) {
          if (b.categories != null
            && b.categories.length > 0
            && b.categories.single(x => x.toLowerCase() === op.category.toLowerCase()) != null) {
            op.budgetId = b.id;
            break;
          }
        }
      }

      if (op.expense
        && op.incomeStreamId != null
        && state.incomeStreams.any()
        && op.budgetId == null
        && op.investmentId == null
        && !op.isReimbursable
        && (op.lastIncomeStreamId == null || (op.lastIncomeStreamId != op.incomeStreamId))) {
        var incomeStream = state.incomeStreams.single(x => x.id === op.incomeStreamId);
        if (incomeStream.treatExpensesAsIncomeCost) {
          op.isIncomeCost = true;
        }
      }

      op.lastIncomeStreamId = op.incomeStreamId;
      op.lastCategory = op.category;


      state.transaction.operations[i] = op;
      for (let op of state.transaction.operations) {
        delete op.errors;
      }

      if (this.state.submitted || this.props.recurringTransactionSubmitted) {
        state.transaction.operations = TransactionInput.setOperationsErrors(state.transaction.operations);
      }

      this.props.onChange?.(state.transaction);

      return {
        transaction: state.transaction,
        amount: this.calculateAmount(state.transaction.operations)
      }

    });


  }

  static getDerivedStateFromProps(props, state) {
    if (props.recurringTransactionSubmitted && props.recurringTransaction) {
      return { operations: TransactionInput.setOperationsErrors(state.transaction?.operations) };
    }

    return null;
  }


  static setOperationsErrors(operations) {
    if (operations == null) return null;
    for (let i = 0; i < operations.length; i++) {
      let op = operations[i];
      delete op.errors;
      let errors = { hasErrors: false };
      if (op.amount == null) {
        errors.amount = 'transaction-form.provide-amount';
        errors.hasErrors = true;
      }
      if (op.isReimbursement && op.reimbursementFor == null) {
        errors.reimbursementFor = 'transaction-form.provide-reimbursement-for';
        errors.hasErrors = true;
      }
      if (op.isReimbursable && (op.description == null || op.description == '')) {
        errors.description = 'transaction.description-is-required-for-reimbursables'
        errors.hasErrors = true;
      }
      if (op.hasRealEstate && op.realEstateId == null) {
        errors.realEstateId = 'transaction.real-estate-is-required'
        errors.hasErrors = true;
      }
      if (op.isReimbursement && op.reimbursementFor == null) {
        errors.reimbursementFor = 'transaction.reimbursemnt-for-is-required'
        errors.hasErrors = true;
      }

      if (errors.hasErrors) {
        op.errors = errors;
      }
    }
    return operations;
  }

  emptyTransaction() {
    if (this.props.recurringTransaction) {
      return { operations: [{ expense: true }] }
    }

    if (this.state.transaction?.date != null) {
      TransactionDateService.setDate( this.state.transaction.date)
      return { date: this.state.transaction.date, operations: [{ expense: !this.isInvestmentReturn() }] }
    }

    return {
      date: TransactionDateService.getDate() , operations: [{ expense: !this.isInvestmentReturn() }]
    };
  }

  isInvestmentReturn() {
    return this.props.mode == 'investment' && this.props.investmentTransactionType == 'return';
  }

  componentDidMount() {
    let tran = this.convertOperations(this.props.transaction);
    let amount = this.calculateAmount(tran?.operations);

    if (tran != null) {
      tran.date = tran.date || TransactionDateService.getDate();
    }
    this.setState({
      amount: amount,
      transaction: tran || this.emptyTransaction()
    }, () => this.fetchData());
  }

  convertOperations(tran) {
    if (tran == null) return null;
    for (let op of tran.operations) {
      op.expense = op.expense != null ? op.expense : (op.amount == null ? true : op.amount < 0);
      op.amount = op.amount == null ? null : Math.abs(op.amount);
      op.hasRealEstate = op.realEstateId != null;
    }

    return tran;
  }

  calculateAmount(operations) {
    if (operations == null) return 0;

    let sum = operations.where(x => x.amount != null).sum(x => {
      if (x.amount == null) return 0;
      return x.amount * (x.expense ? -1 : 1)
    });

    return sum;
  }

  fetchData() {
    this.accountsSub = AccountsStore.accounts.subscribe(acs => this.setState({ accounts: acs }), () => this.setState({ isLoading: this.getIsLoading() }));
    this.incomeStreamsSub = IncomeStreamStore.incomeStreams.subscribe(ics => this.setState({ incomeStreams: ics }), () => this.setState({ isLoading: this.getIsLoading() }));
    this.templatesSub = TransactionsTemplates.transactionTemplates.subscribe(tts => {
      return this.setState({ templates: tts }, () => this.setState({ isLoading: this.getIsLoading() }));
    })
    this.fetchBudgets();
  }

  componentWillUnmount() {
    this.unmounted = true;
    this.accountsSub.unsubscribe();
    this.incomeStreamsSub.unsubscribe();
  }

}

TransactionInput.propTypes = {
  transaction: PropTypes.object,
  hideDate: PropTypes.bool,
  accountId: PropTypes.string,
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool
}


export default TransactionInput;