import PropTypes from 'prop-types';
import React, { Component } from 'react';
import FormControlService from '../../services/form-control-service';
import './controlled-editable-grid.scss';
import Grid from './grid';

class ControlledEditableGrid extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  onFieldChange(value, index, name) {
    this.props.data[index][name] = value;
    this.props.onChange(this.props.data);
  }

  onChange() {

  }

  render() {
    let definition = [];
    for (let col of this.props.definition) {
      if (col.hidden) continue;
      definition.push({
        ...col,
        renderCell: (ent, rowNumber) => {
          if (col.type === "raw") {
            return col.renderCells(ent, rowNumber);
          }
          let field = {
            type: col.type,
            name: col.name,
            value: FormControlService.getValueOrDefault(col.type, this.props.data?.[rowNumber][col.name])
          };
          let props = {
            name: field.name
            , value: field.value
            , onChange: (v) => this.onFieldChange(v, rowNumber, field.name)
            , hasError: this.props.errors?.[rowNumber]?.[field.name] != null
            , errorMessage: col.errorMessage || this.props.errors?.[rowNumber]?.[field.name]
            , ...col
          };
          if (col.controlProps != null) {
            for (let k in col.controlProps) {
              props[k] = col.controlProps[k];
            }
          }
          let control = FormControlService.getControlFromField(field, props);
          return control;
        }
      });
    }
    return <div className="controlled-editable-grid-c">
      <Grid renderTotalRow={this.props.renderTotalRow}
        tableStriped={false}
        pageSize={20}
        id={this.props.id}
        data={this.props.data}
        definition={definition}
        autoWidth={this.props.autoWidth}
      ></Grid>
    </div>
  }


}


ControlledEditableGrid.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  definition: PropTypes.arrayOf(PropTypes.shape({
    show: PropTypes.bool,
    headerClasses: PropTypes.string,
    header: PropTypes.string,
    name: PropTypes.string.isRequired,
    hasError: PropTypes.bool,
    errorMessage: PropTypes.string,
    type: PropTypes.string.isRequired
  }))
}


export default ControlledEditableGrid;