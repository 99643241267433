import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button from '../common/button';
import Panel from '../common/panel';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { H3 } from '../common/headers';
import { Table, THead, TBody, TColumn } from '../common/table';
import Number from '../common/number';
import Decimal from 'decimal.js';
import { createHashHistory } from 'history';
const history = createHashHistory();

import './transfer-summary.scss';

const TransferSummary = (props) => {
  let fromAfter = null;
  let toAfter = null;
  let amountFrom = null;
  let amountTo = null;
  let exchangeRate = null;

  if (props.amount != null && props.accountFrom) {
    fromAfter = (new Decimal(props.accountFrom.balance).sub(props.amount))
    amountFrom = new Decimal(props.amount).mul(-1);
  }

  if (props.accountTo != null && props.accountFrom != null && props.amount != null && props.accountFrom.currency === props.accountTo.currency) {
    toAfter = new Decimal(props.accountTo.balance).add(new Decimal(props.amount));
    amountTo = props.amount;
  }

  if (props.accountTo != null && props.accountFrom != null && props.amount != null && props.accountFrom.currency !== props.accountTo.currency) {
    if (props.exchangeRate != null) {
      amountTo = new Decimal(props.amount).mul(props.exchangeRate);
      toAfter = new Decimal(props.accountTo.balance).add(amountTo);
      exchangeRate = props.exchangeRate;
    }
    else if (props.totalAmount != null) {
      amountTo = props.totalAmount;
      toAfter = new Decimal(props.accountTo.balance).add(amountTo);
      exchangeRate = new Decimal(amountTo).div(props.amount)
    }

  }


  return <div className="transfer-summary-c">

    <div className="summary">
      <Table>
        <THead>
          <TColumn></TColumn>
          <TColumn className="len-300">{props.accountFrom?.name}</TColumn>
          <TColumn className="len-300">{props.accountTo?.name}</TColumn>
        </THead>
        <TBody>
          <tr>
            <td><FormattedMessage id="transfer.balance"></FormattedMessage></td>
            <td><Number value={props.accountFrom?.balance} currency={props.accountFrom?.currency}></Number> </td>
            <td><Number value={props.accountTo?.balance} currency={props.accountTo?.currency}></Number></td>
          </tr>
          <tr>
            <td><FormattedMessage id="transfer.transfer"></FormattedMessage></td>
            <td><Number value={amountFrom} currency={props.accountFrom?.currency}></Number></td>
            <td><Number value={amountTo} currency={props.accountTo?.currency}></Number></td>
          </tr>
          {
            exchangeRate != null ?
              <tr>
                <td><FormattedMessage id="transfer.exchange-rate"></FormattedMessage></td>
                <td className="exchange-rate">{props.accountFrom.currency}-{props.accountTo.currency} <Number value={exchangeRate}></Number></td>
                <td className="exchange-rate">{props.accountTo.currency}-{props.accountFrom.currency} <Number value={new Decimal(1).dividedBy(new Decimal(exchangeRate)).toNumber()}></Number></td>
              </tr> : null
          }
          <tr>
            <td><FormattedMessage id="transfer.balance-after-transfer"></FormattedMessage></td>
            <td><Number value={fromAfter} currency={props.accountFrom?.currency}></Number></td>
            <td><Number value={toAfter} currency={props.accountTo?.currency}></Number></td>
          </tr>
        </TBody>
      </Table>
    </div>
  </div>
}


TransferSummary.propTypes = {

}


export default TransferSummary;

