import Service from './service';
import predef from '../../predef';

class InvestmentsService extends Service {
    addInvestment(investment) {
        return this.put(predef.endpoints.investment.index
            , investment
            , predef.events.investment.investmentsChanged
            , investment
            , 'investments-service.investment-added'
            , 'investments-service.investment-add-error');
    }

    deleteInvestment(investmentId) {
        return this.delete(
            predef.endpoints.investment.indexId(investmentId)
            , predef.events.investment.investmentsChanged
            , investmentId
            , 'investments-service.investment-deleted'
            , 'investments-service.investment-delete-error')
    }

    getInvestment(id) {
        return this.get(predef.endpoints.investment.indexId(id));
    }

    closeInvestment(id, closeDate) {
        return this.post(
            predef.endpoints.investment.close(id)
            , { closeDate }
            , predef.events.investment.investmentsChanged
            , { id, closeDate }
            , 'investments-service.investment-closed'
            , 'investments-service.investment-closed-error'
        );
    }

    reopenInvestment(id) {
        return this.post(
            predef.endpoints.investment.reopen(id)
            , { id }
            , predef.events.investment.investmentsChanged
            , { id }
            , 'investments-service.reopen-investment-success'
            , 'investments-service.reopen-investment-error'
        );
    }

    updateInvestment(investment) {
        return this.post(
            predef.endpoints.investment.index
            , investment
            , predef.events.investment.investmentsChanged
            , investment
            , 'investments-service.investment-updated'
            , 'investments-service.investment-update-error'
        )
    }

    autoCalculate(investmentId) {
        return this.post(predef.endpoints.investment.autoCalculate(investmentId)
            , null
            , predef.events.investment.investmentsChanged
            , null
            , 'investments-service.auto-calculate-success'
            , 'investments-service.auto-calculate-error')
    }

    setCurrentValue(investmentId, currentValue) {
        return this.post(predef.endpoints.investment.setCurrentValue(investmentId)
            , { currentValue }
            , predef.events.investment.investmentsChanged
            , null
            , 'investments-service.set-current-value-success'
            , 'investments-service.set-current-value-error');
    }
}


let instance = new InvestmentsService();
export default instance;
