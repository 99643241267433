import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button, { Cancel, Remove, Save, Edit } from '../common/button';
import Panel from '../common/panel';
import { H3 } from '../common/headers';
import Json from '../common/json';
import Loader from '../common/loader';
import './notes.scss';
import NotesSrevice from '../services/notes-service';
import PropTypes from 'prop-types';
import NoteForm from './note-form';
import Note from './note';
import Badge from '../common/badge';
import Timeline from '../common/timeline';
import { guid } from '../../util';
import { NoDataMessage } from '../common/message';

class Notes extends Component {

  constructor(props) {
    super(props);
    this.state = { isLoading: true, notes: null, showAddForm: false, timelineKey: guid() };
  }

  render() {
    return <div className="notes-c">
      {this.state.isLoading ? <Loader show={true}></Loader> : this.renderPanelBody()}
    </div>
  }

  renderPanelBody() {
    return <React.Fragment>

      {this.state.showAddForm ? <Panel>
        <NoteForm onSaved={() => {
          this.setState({ showAddForm: false });
          this.fetchNotes();
        }} onCancel={() => this.setState({ showAddForm: false })}
          noteRef={this.props.noteRef}
          refId={this.props.refId}>

        </NoteForm>
      </Panel>
        : <Panel><Button className="add-note" fa="plus" label="notes.add-note" onClick={() => this.setState({ showAddForm: true })}></Button></Panel>}
      {this.renderNotes()}
    </React.Fragment>
  }

  renderNotes() {
    return <div className="notes">
      {this.state.notes != null && this.state.notes.length > 0 ?
        <Timeline showRemove onRemove={(note) => this.remove(note)} key={this.state.timelineKey} items={this.state.notes} showEditNote></Timeline>
        : <NoDataMessage></NoDataMessage>}
    </div>
  }
  remove(note) {
    return NotesSrevice.deleteNote(note);
  }
  fetchNotes() {
    NotesSrevice.getNotes(this.props.noteRef, this.props.refId).then(ns => {
      this.setState({ notes: ns, isLoading: ns == null, timelineKey: guid() });
    });
  }

  componentDidMount() {
    this.fetchNotes();
    this.busSub = Bus.subscribe(this);
  }

  _onNotesChanged(event) {
    if (event?.ref == this.props.noteRef && event?.refId == this.props.refId) {
      this.fetchNotes();
    }
  }

  componentWillUnmount() {
    this.busSub.unsubscribe();
  }

}

Notes.propTypes = {
  noteRef: PropTypes.string.isRequired,
  refId: PropTypes.string.isRequired
}

export default Notes;