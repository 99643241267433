import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Panel from '../common/panel';
import Message from '../common/message';
import UserSettingsStore from '../stores/user-settings-store';
import { Table, THead, TColumn, TBody, ChangeOrderButtons } from '../common/table';
import LoadingScreen from '../common/loading-screen';
import UserSettingsService from '../services/user-settings-service';
import './user-settings.scss';
import SelectInput from '../common/select-input';
import CurrenciesStore from '../stores/currencies-store';
import { Save } from '../common/button';
import LoadingPlaceholder from '../common/loading-placeholder';

class UserSettings extends Component {

  constructor(props) {
    super(props);
    this.state = { loading: true, currencies: [], baseCurrency: null };
  }

  render() {
    return this.state.loading ? <LoadingPlaceholder></LoadingPlaceholder> : this.renderView()
  }

  renderView() {
    return (
      <div className="user-settings-c">
        <h3><FormattedMessage id="user-settings.currencies-header"></FormattedMessage></h3>
        <Panel>
          <Message message="user-settings.currencies-info"></Message>
          <Table autoWidth>
            <THead>
              <TColumn className="len-75"></TColumn>
              <TColumn className="len-120" header="user-settings.currency"></TColumn>
            </THead>
            <TBody>
              {this.renderCurrencies()}
            </TBody>
          </Table>
          <SelectInput label="user-settings.base-currency" value={this.state.baseCurrency} options={this.state.currencies?.select(c => { return { value: c, label: c } }) || []}
            onChange={(c) => {
              this.setState({ baseCurrency: c })
            }}></SelectInput>

          <div className="actions">
            <Save loading={this.state.isSaving} onClick={() => this.save()}></Save>
          </div>
        </Panel>
        {/* <h3><FormattedMessage id="user-settings.features"></FormattedMessage></h3>
        <Panel>

        </Panel> */}

      </div>
    );
  }

  save() {
    this.setState({ isSaving: true }, () => {
      UserSettingsService.save({ currenciesOrder: this.state.currencies, baseCurrency: this.state.baseCurrency })
        .then(() => this.setState({ isSaving: false }))
        .catch(() => this.setState({ isSaving: false }));
    });
  }

  moveCurrency(i, delta) {
    this.state.currencies.moveBy(i, delta);
    var order = [];

    for (let i = 0; i < this.state.currencies.length; i++) {
      order.push({ currency: this.state.currencies[i], order: i });
    }

    this.setState({ currencies: this.state.currencies });
  }

  renderCurrencies() {
    return this.state.currencies.map((c, i) => {
      return <tr key={c}>
        <td>
          <ChangeOrderButtons
            total={this.state.currencies.length}
            i={i}
            moveUp={() => this.moveCurrency(i, -1)}
            moveDown={() => this.moveCurrency(i, 1)}></ChangeOrderButtons>
        </td>
        <td>{c}</td>
      </tr>
    })
  }

  componentDidMount() {
    this.sub = UserSettingsStore.userSettings.subscribe(ts => {
      this.setState({ 
        currencies: ts.currenciesOrder, 
        baseCurrency: ts.baseCurrency, 
        features: ts.features, 
        loading: ts == null })
  
    });
  }

  componentWillUnmount() {
    this.sub.unsubscribe();
  }

}

export default UserSettings;