import React from 'react';
import { Redirect } from 'react-router-dom';
import './app.scss';
import Account from './app/accounts/account';
import { AddAccount, EditAccount } from './app/accounts/account-form';
import Accounts from './app/accounts/accounts';
import RestoreAccount from './app/accounts/restore-account';
import Assets from './app/assets/assets';
import Commands from './app/audit/commands';
import Login from "./app/authentication/login";
import Profile from './app/authentication/profile';
import Register from './app/authentication/register';
import UserSettings from './app/authentication/user-settings';
import CategoryEditor from './app/category-editor/category-editor';
import Dashboard from './app/dashboard/dashboard';
import Documents from './app/documents/documents';
import IncomeStreamDashboard from './app/income-streams/income-stream-dashboard';
import IncomeStreams from './app/income-streams/income-streams';
import Unavailable from './app/pages/unavailable';
import Planner from './app/planning/planner';
import RecurringBudgets from './app/planning/recurring-budgets';
import RecuringIncomes from './app/planning/recurring-incomes';
import { AddRealEstate, EditRealEstate } from './app/real-estates/real-estate-form';
import RealEstates from './app/real-estates/real-estates';
import Reimbursables from './app/reimbursables/reimbursables';
import Reimbursements from './app/reimbursables/reimbursements';
import ExpensesByCategoryReport from './app/reports/expenses-by-category-report';
import ExpensesIncomeStructureReport from './app/reports/expenses-income-structure-report';
import NetValueReport from './app/reports/net-value-report';
import SavingFactorReport from './app/reports/saving-factor-report';
import FlagsService from './app/services/flags-service';
import AddTransactionTemplate from './app/transaction-templates/add-transaction-template';
import EditTransactionTemplate from './app/transaction-templates/edit-transaction-template';
import TransactionTemplates from './app/transaction-templates/transaction-templates';
import EditTransaction from './app/transactions/edit-transaction';
import Transactions from './app/transactions/transactions';
import Transfer from './app/transactions/transfer';
import CashflowReport from './app/reports/cashflow-report';
import RealEstateDashboard from './app/real-estates/real-estate-dashboard';
import { AddResident } from './app/real-estates/resident-form';
import ResidentDashboard from './app/real-estates/resident-dashboard';
import AccountsOrder from './app/accounts/accounts-order';
import FutureBalance from './app/planning/future-balance';
import RecurringTransactions from './app/recurring-transactions/recurring-transactions';
import AddRecurringTransaction from './app/recurring-transactions/add-recurring-transaction';
import EditRecurringTransaction from './app/recurring-transactions/edit-recurring-transaction';
import CreateBetaAccount from './app/shell/create-beta-account';
import AllDocuments from './app/documents/all-documents';
import TransferPage from './app/transactions/transfer-page';
import Investments from './app/investments/investments';
import AddInvestments from './app/investments/add-investment';
import EditInvestment from './app/investments/edit-investment';
import InvestmentDashboard from './app/investments/investment-dashboard';
import CloseInvestment from './app/investments/close-investment';
import AddInvestmentTransaction from './app/investments/add-investment-transaction';
 import User from './app/user/user';

 
export const NRedirect = (props) => {
    let { match: { params } } = props;
    return <Redirect from="" exact to={atob(params.route)} />
}


export const shellRoutes = (match) => {
    var routes = [
        { path: match.url + 'dashboard', component: Dashboard },
        { path: match.url + 'accountsorder', component: AccountsOrder },
        { path: match.url + 'accounts', component: Accounts },
        { path: match.url + 'transactions', component: Transactions },
        { path: match.url + 'transaction/:id/:returnPath?', component: EditTransaction },
        { path: match.url + 'addaccount/:type?', component: AddAccount },
        { path: match.url + 'editaccount/:id', component: EditAccount },
        { path: match.url + 'account/:id', component: Account },
        { path: match.url + 'planning', component: Planner },
        { path: match.url + 'futurebalance', component: FutureBalance },
        { path: match.url + 'restoreaccount', component: RestoreAccount },
        { path: match.url + 'profile', component: Profile },
        { path: match.url + 'usersettings', component: UserSettings },
        { path: match.url + 'recurringbudgets', component: RecurringBudgets },
        { path: match.url + 'recurringincomes', component: RecuringIncomes },
        { path: match.url + 'reimbursables', component: Reimbursables },
        { path: match.url + 'reimbursements/:id', component: Reimbursements },
        { path: match.url + 'reports/expensesbycategory', component: ExpensesByCategoryReport },
        { path: match.url + 'reports/expensesincomestructure', component: ExpensesIncomeStructureReport },
        { path: match.url + 'reports/savingfactor', component: SavingFactorReport },
        { path: match.url + 'reports/cashflow', component: CashflowReport },
        { path: match.url + 'transfer/:id?/:returnPath?', component: TransferPage },
        { path: match.url + 'netvalue', component: NetValueReport },
        { path: match.url + 'assets', component: Assets },
        { path: match.url + 'categoryeditor', component: CategoryEditor },
        { path: match.url + 'transactiontemplates', component: TransactionTemplates },
        { path: match.url + 'edittransactiontemplate/:id', component: EditTransactionTemplate },
        { path: match.url + 'transactiontemplate', component: AddTransactionTemplate },
        { path: match.url + 'documents', component: AllDocuments },
        { path: match.url + 'redirect/:route', component: NRedirect },
        { path: match.url + 'incomestreams', component: IncomeStreams },
        { path: match.url + 'incomestream/:id', component: IncomeStreamDashboard },
        { path: match.url + 'recurringTransactions', component: RecurringTransactions },
        { path: match.url + 'recurringTransaction', component: AddRecurringTransaction },
        { path: match.url + 'editrecurringtransaction/:id', component: EditRecurringTransaction },
        { path: match.url + 'investments', component: Investments },
        { path: match.url + 'addinvestment', component: AddInvestments },
        { path: match.url + 'investment/:id', component: InvestmentDashboard },
        { path: match.url + 'closeinvestment/:id', component: CloseInvestment },
        { path: match.url + 'closeinvestment/:id/:{type}', component: CloseInvestment },
        { path: match.url + 'addinvestmenttransaction/:id/:type', component: AddInvestmentTransaction },
        { path: match.url + 'user', component: User },

    ];

        routes = routes.concat([
            { path: match.url + 'realestates', component: RealEstates },
            { path: match.url + 'addrealestate', component: AddRealEstate },
            { path: match.url + 'realestate/:id', component: RealEstateDashboard },
            { path: match.url + 'addresident/:id', component: AddResident },
            { path: match.url + 'resident/:id', component: ResidentDashboard },
        ]);

    if (FlagsService.hasAuditFlag) {
        routes = routes.concat([
            { path: match.url + 'commands', component: Commands },
        ]);
    }

    return routes;
}

export const topLevelRoutes = [
    { path: '/login', component: Login },
    { path: '/register', component: Register },
    { path: '/unavailable', component: Unavailable },
    { path: '/beta', component: CreateBetaAccount },
]

