import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button, { Cancel, Remove, Save, Edit } from '../common/button';
import Panel from '../common/panel';
import PropTypes from 'prop-types';
import Page from '../common/page';
import classNames from 'classnames/bind';
import { H3 } from '../common/headers';
import DocumentsService from '../services/documents-service';
import './all-documents.scss';
import { guid } from '../../util';
import Documents from '../documents/documents'
class AllDocuments extends Component {

  constructor(props) {
    super(props);
    this.state = { docsKey: guid() };
  }

  render() {
    return <div className="all-documents-c">
      <Documents key={this.state.docsKey} renderActions={(doc) => {
        return <Remove onClick={() => DocumentsService.deleteDocument(doc)}></Remove>
      }}>
      </Documents>
    </div>
  }



  componentDidMount() {
  }

  componentWillUnmount() {
  }

}


AllDocuments.propTypes = {

}


export default AllDocuments;