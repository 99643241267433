import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TransactionInput from './transaction-input';
import './transaction.scss';
import TransactionService from '../services/transactions-service';
import { deepCopy } from '../../util';
import LoadingPlaceholder from '../common/loading-placeholder';


class Transaction extends Component {

  constructor(props) {
    super(props);
    this.state = { isSubmitting: false, isLoading: true };
  }

  render() {
    return <div className="transaction-c">
      {this.state.isLoading ? <LoadingPlaceholder></LoadingPlaceholder> :
        <TransactionInput
          onSubmit={(v) => {
            var promise = this.onSubmit(v);
            this.props.onSave?.(promise)
            return promise;
          }}
          isSubmitting={this.state.isSubmitting}
          accountId={this.props.accountId || this.state.transaction?.accountId}
          currency={this.props.currency || this.state.transaction?.currency}
          hideDate={this.props.hideDate}
          hideAccount={this.props.hideAccount}
          transaction={this.props.transaction || this.state.transaction}
          returnPath={this.props.returnPath}
          mode={this.props.mode || this.state.mode}
          investmentId={this.props.investmentId || this.state.investmentId}
          investmentTransactionType={ this.getInvestmentTransactionType()}
        >

        </TransactionInput>}
    </div>
  }

  getInvestmentTransactionType() {

    if (this.props.investmentTransactionType != null) return this.props.investmentTransactionType;

    if(this.state.transaction == null || !this.state.transaction.isInvestment) return null
    
    if (this.state.transaction.isInvestment && this.state.transaction.isInvestmentCapital) return 'capital';
    if (this.state.transaction.isInvestment && this.state.transaction.isInvestmentReturn) return 'return';

    throw 'Unknown investment transaction type state';

  }

  onSubmit(transaction) {
    return new Promise((resolve, reject) => {
      this.setState({ isSubmitting: true }, () => {
        if (this.props.transactionId == null) {
          TransactionService.add(transaction)
            .then(() => {
              this.setState({ isSubmitting: false });
              resolve();
            })
            .catch(() => {
              reject();
              this.setState({ isSubmitting: false });
            });
        }
        else {
          TransactionService.update(transaction, this.state.originalTransaction)
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
              this.setState({ isSubmitting: false });
            });
        }
      })
    })
  }

  componentDidMount() {
    if (this.props.transactionId != null) {
      TransactionService.getTransaction(this.props.transactionId).then(t => {
        this.setState({ 
          originalTransaction: deepCopy(t), 
          transaction: t, 
          isLoading: false, 
          mode: t.isInvestment ? 'investment' : null, 
          investmentId: t.investmentId });
      }).catch(e => this.setState({ isLoading: false }));
    }
    else {
      this.setState({ isLoading: false });
    }
  }

  componentWillUnmount() {
  }

}


Transaction.propTypes = {
  transactionId: PropTypes.string,
  accountId: PropTypes.string,
  currency: PropTypes.string,
  returnPath: PropTypes.string,
  onSave: PropTypes.func
}


export default Transaction;