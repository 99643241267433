import predef from '../../predef';
import Store from './store';

class TransactionTemplatesStore {
    constructor() {
        this.transactionTemplatesStore = new Store(predef.endpoints.transactionTemplate.index,
            [predef.events.transactionTemplate.transactionTemplatesChanged], predef.events.transactionTemplate.transactionTemplatesStoreUpdated);

    }

    get transactionTemplates() {
        return this.transactionTemplatesStore.asObservable();
    }

    byId(id) {
        return new Promise((resolve, reject) => {
            this.transactionTemplates.subscribe(tts => {
                if (tts == null) return;

                resolve(tts.single(x => x.id === id));
            })
        })
    }

}

const instance = new TransactionTemplatesStore();
export default instance;
