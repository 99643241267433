import React, { Component } from 'react';
import { BehaviorSubject } from 'rxjs';
import * as yup from 'yup';
import predef from '../../predef';
import { validate, deepCopy } from '../../util';
import Button, { Cancel } from '../common/button';
import ControlledForm from '../common/controlled-form';
import Error from '../common/error';
import { H3 } from '../common/headers';
import Panel from '../common/panel';
import RecurringTransactionServices from '../services/recurring-transactions-service';
import AccountsStore from '../stores/accounts-store';
import TransactionInput from '../transactions/transaction-input';
import './recurring-transaction-form.scss';
import { createHashHistory } from 'history';
import Calendar from '../services/calendar-service';
const history = createHashHistory();


const validationSchema = yup.object().shape({
  name: yup.string().min(1).required(),
  accountId: yup.string().min(36).required(),
  stopCondition: yup.number(),
  periods: yup.number().required(),
  startDate: yup.string().min(10).required(),
  stopDate: yup.mixed().when(['stopCondition'], {
    is: 2,
    then: yup.string().min(10).required()
  }),
  numberOfRepeats: yup.mixed().when(['stopCondition'], {
    is: 3,
    then: yup.number().required()
  })
})

class RecurringTransactionForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      errors: null,
      recurringTransaction: { frequency: 3, periods: 1, stopCondition: 1, startDate: Calendar.todayIso },
    };
    let days = [];
    for (let i = 1; i <= 31; i++) {
      days.push({ label: i, value: i });
    }
    this.days = new BehaviorSubject(days);
  }

  onRecurringTransactionChange(v) {
    let updateState = (currency, v) => {
      this.setState({ recurringTransaction: v, currency: currency, errors: this.state.submitted ? validate(validationSchema, v) : null });
    };
    if (v.accountId != null) {
      AccountsStore.byId(v.accountId).then(a => {
        updateState(a.currency, v);
      });
    }
    else {
      updateState(null, v);
    }
  }

  render() {
    return <div className="recurring-transaction-form-c">
      <H3 header="recurring-transaction-form.basic-information"></H3>
      <Panel>
        <ControlledForm
          errors={this.state.errors}
          values={this.state.recurringTransaction}
          onChange={(v) => this.onRecurringTransactionChange(v)}
          definition={this.getFormDefinition()}>
        </ControlledForm>
      </Panel>
      <H3 header="recurring-transaction-form.transaction-information"></H3>
      <Panel>
        {
          this.props.transaction == null || this.state.loaded ?
            <TransactionInput currency={this.state.currency} recurringTransaction recurringTransactionSubmitted={this.state.submitted} transaction={this.state.template} onChange={(v) => this.setState({ template: v })} >

            </TransactionInput> : null
        }
      </Panel>
      {
        this.renderError()
      }
      <Panel>
        <div className="actions">
          <Cancel path={predef.routes.recurringTransactions.index}></Cancel>
          <Button isLoading={this.state.isSaving} className="save" width={23} label="recurring-transaction-form.save" fa="save" onClick={() => this.save()}></Button>
        </div>
      </Panel>
    </div>
  }
  renderError() {
    if (!this.state.submitted) return;
    if (!this.templateHasError && !this.basicInfoHasError) return null;

    let message = 'recurring-transaction-form.fix-errors'
    if (this.templateHasError && !this.basicInfoHasError) {
      message = 'recurring-transaction-form.fix-template-errors'
    }
    if (!this.templateHasError && this.basicInfoHasError) {
      message = 'recurring-transaction-form.fix-basic-info-errors'
    }

    return <Panel>
      <Error text={message}></Error>
    </Panel>
  }

  get basicInfoHasError() {
    return this.state.errors?.hasErrors;
  }

  get templateHasError() {
    return this.state.template?.operations == null || this.state.template.operations.length == 0 || this.state.template.operations.any(x => x.errors?.hasErrors);
  }

  save() {

    this.setState({ submitted: true, errors: validate(validationSchema, this.state.recurringTransaction) }, () => {
      if (!this.templateHasError && !this.basicInfoHasError) {
        var rt = deepCopy(this.state.recurringTransaction);
        rt.template = JSON.stringify(this.state.template.operations);
        rt.amount = this.state.template.operations.sum(x => x.amount * (x.expense ? -1 : 1));
        rt.currency = this.state.currency;

        this.setState({ isSaving: true }, () => {
          if (this.props.transaction != null) {
            RecurringTransactionServices.update(rt)
              .then(x => history.push(predef.routes.recurringTransactions.index))
              .catch(e => this.setState({ isSaving: false }));
          }
          else {
            RecurringTransactionServices.add(rt)
              .then(x => history.push(predef.routes.recurringTransactions.index))
              .catch(e => this.setState({ isSaving: false }));
          }
        });
      }
    });
  }

  componentDidMount() {
    if (this.props.transaction != null) {
      this.setState({
        recurringTransaction: this.props.transaction,
        template: { operations: JSON.parse(this.props.transaction.template) },
        loaded: true,
        currency: this.props.transaction.currency
      });
    }
  }

  componentWillUnmount() {
  }

  getFormDefinition() {
    return {
      groups: [
        {
          name: 'basic',
          fields: [
            {
              type: 'text',
              name: 'name',
              label: 'common.name',
              errorMessage: 'common.field-is-required',
              layout: 'c c-sm-12  c-md-12 c-w-450',
              isClearable: false
            },
            {
              type: 'account',
              name: 'accountId',
              label: 'common.account',
              errorMessage: 'common.field-is-required',
              layout: 'c c-sm-12  c-md-12 c-w-450'
            },
          ]
        },
        {
          name: 'repeat',
          fields: [{
            type: 'number',
            name: 'periods',
            label: 'recurring-transaction-form.periods',
            errorMessage: 'common.field-is-required',
            layout: 'c c-sm-4  c-md-4 c-w-150',
            isClearable: false
          },
          {
            type: 'frequency',
            name: 'frequency',
            errorMessage: 'common.field-is-required',
            layout: 'c c-sm-8  c-md-8 c-w-300',
            isClearable: false
          },]
        },
        {
          name: 'startdate',
          fields: [
            {
              type: 'date',
              name: 'startDate',
              label: "recurring-transaction-form.start-date",
              errorMessage: 'common.field-is-required',
              layout: 'c c-sm-8  c-md-8 c-w-180',
            }
          ]
        },
        {
          name: 'stop',
          fields: [{
            type: 'stop-condition',
            name: 'stopCondition',
            label: "recurring-transaction-form.stop-condition",
            errorMessage: 'common.field-is-required',
            layout: 'c c-sm-8  c-md-8 c-w-180',
            isClearable: false
          },
          {
            type: 'date',
            name: 'stopDate',
            label: "common.date",
            errorMessage: 'common.field-is-required',
            layout: 'c c-sm-8  c-md-8 c-w-180',
            isClearable: false,
            hidden: this.state.recurringTransaction.stopCondition != 2
          },
          {
            type: 'number',
            name: 'numberOfRepeats',
            label: "recurring-transaction-form.number-of-repeats",
            errorMessage: 'common.field-is-required',
            layout: 'c c-sm-8  c-md-8 c-w-180',
            hidden: this.state.recurringTransaction.stopCondition != 3
          }]
        }]
    }
  }
}


RecurringTransactionForm.propTypes = {

}


export default RecurringTransactionForm;