import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import LoadingScreen from './loading-screen';
import Panel from './panel';
import Message from './message';
import PropTypes from 'prop-types';

import './page.scss';

class Page extends Component {

  constructor(props) {
    super(props);

    this.hasData = this.hasData.bind(this);
  }

  render() {
    return this.props.loading ? <LoadingScreen></LoadingScreen> : this.renderView()
  }

  renderView() {
    return <div className={'page-c ' + this.props.className}>
      {this.props.header != null ? <h2 className="page-header">{typeof this.props.header === "object" && this.props.header != null ? this.props.header.header : <FormattedMessage id={this.props.header} values={this.props.headerValues}></FormattedMessage>} </h2> : null}
      {this.props.noPanel ? this.renderBody() : <Panel>
        {this.renderBody()}
      </Panel>}
    </div>
  }


  renderBody() {
    return <React.Fragment>
      {
        this.props.actions == null ? null : <div className="top-actions">{this.props.actions}</div>
      }
      {
        this.hasData() ? this.props.renderBody() : <Message message={this.props.noDataMessage || "common.no-data"}></Message>
      }
    </React.Fragment>
  }

  hasData() {
    if (this.props.data == null) return false;
    return !Array.isArray(this.props.data) || this.props.data.length > 0;
  }
}

Page.propTypes = {
  renderBody: PropTypes.func.isRequired,
  loading: PropTypes.bool
}

export default Page;