import React, { Component } from 'react';
import ControlledGrid from './controlled-grid';
import './grid.scss';
const getData = (data, sort, paging) => {
  if (data == null) return [];

  // sort
  if (sort != null && sort.column != null) {
    data = data.orderBy(x => sort.value(x), sort.order)
  }

  // page
  if (paging != null && data != null) {
    data = data.skip((paging.page - 1) * paging.pageSize).take(paging.pageSize);
  }

  return data;
}

class Grid extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      paging: {
        page: 1,
        pageSize: 50
      },
      sort: {

      }
    };
    this.onSortingChange = this.onSortingChange.bind(this);
    this.onPagingChange = this.onPagingChange.bind(this);
  }

  onSortingChange(sort) {
    this.setState({ sort: sort, data: getData(this.props.data, sort, this.state.paging) });
  }

  onPagingChange(paging) {
    this.setState({ paging: paging, data: getData(this.props.data, this.state.sort, paging) });
  }

  render() {

    return <div className="grid-c">
      <ControlledGrid
        id={this.props.id}
        definition={this.props.definition}
        data={this.state.data}
        totalRows={this.props.data != null ? this.props.data.length : null}
        loading={false}
        onSortingChange={this.onSortingChange}
        onPagingChange={this.onPagingChange}
        paging={this.state.paging}
        sorting={this.state.sort}
        allowColumnConfigChange={this.props.allowColumnConfigChange}
        tableStriped={this.props.tableStriped}
        renderTotalRow={this.props.renderTotalRow}
        autoWidth={this.props.autoWidth}
      ></ControlledGrid>
    </div>
  }

  static getDerivedStateFromProps(props, state) {
    return { data: getData(props.data, state.sort, state.paging) }
  }

  componentDidMount() {
    this.setState({ paging: { page: 1, pageSize: this.props.pageSize == null ? 50 : this.props.pageSize } })
  }

  componentWillUnmount() {
  }

}


Grid.propTypes = {

}


export default Grid;