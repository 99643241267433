import predef from '../../predef';
import Store from './store';

class IndicatorsStore {
    constructor() {
        this.indicatorsStore = new Store(predef.endpoints.report.indicators,
            [predef.events.transaction.transactionsChanged
                , predef.events.account.accountsChanged
            , predef.events.userSettings.userSettingsStoreUpdated
            ]);

    }

    get indicators() {
        return this.indicatorsStore.asObservable();
    }

}

const instance = new  IndicatorsStore();
export default instance;
