import React, { Component } from 'react';
import predef from '../../predef';
import Page from '../common/page';
import ResidentService from '../services/residents-service';
import './resident-dashboard.scss';
import { EditResident } from './resident-form';
import ResidentReports from './resident-reports';
import RealEstateStore from '../stores/real-estates-store';
import { routeParamId } from '../../util';
import BreadCrumbsStateService from '../state-services/bread-crumbs-state-service-service';
import SecondaryNavStateService from '../state-services/secondary-nav-state-service-service';
import { Redirect, Switch } from 'react-router-dom';
import DocumentsGrid from '../documents/documents-grid';
import { PrivateRoute } from '../../private-route';
import Notes from '../notes/notes';

const ResidnetNotes = (props) => {
  let id = routeParamId(props);
  return <div className="resident-notes-c">
    <Notes noteRef={predef.noteRefs.resident} refId={id}></Notes>
  </div>
}


const ResidentDocuments = (props) => {
  let id = routeParamId(props);
  return <DocumentsGrid id="resident" docRef={predef.documentRefs.resident} refId={id}></DocumentsGrid>
}

const routesConfig = [
  { component: ResidentReports, route: `/resident/:id/reports`, url: (match) => `${match.url}/reports`, label: 'resident-dashboard.reports' },
  { component: ResidentDocuments, route: `/resident/:id/documents`, url: (match) => `${match.url}/documents`, label: 'common.documents' },
  { component: ResidnetNotes, route: `/resident/:id/notes`, url: (match) => `${match.url}/notes`, label: 'common.notes' },
  { component: EditResident, route: `/resident/:id/edit`, url: (match) => `${match.url}/edit`, label: 'resident-dashboard.info-edit' },
]
class ResidentDashboard extends Component {

  constructor(props) {
    super(props);
    this.state = { isLoading: true, resident: null };
  }

  render() {
    return <Page
      className="resident-dashboard-c"
      loading={this.state.isLoading}
      data={this.state.resident}
      renderBody={() => this.renderBody()}
      noPanel
    >
    </Page>
  }

  renderBody() {
    var id = routeParamId(this.props);
    var pathPrefix = `/resident/${id}`;

    return <Switch>
      <Redirect from={pathPrefix} exact to={`${pathPrefix}/reports`} />
      {routesConfig.map(r => {
        return <PrivateRoute key={r.route} path={r.route} component={r.component}></PrivateRoute>
      })}
    </Switch>
  }


  static getDerivedStateFromProps(props, state) {
    var id = routeParamId(props);
    if (id != state.residentId) {
      return { residentId: id };
    }

    return null;
  }
  fetchResident() {
    ResidentService.getResident(this.state.residentId).then(resident => {
      this.setState({ resident: resident }, () => {
        if (this.state.resident == null) return;
        RealEstateStore.byId(this.state.resident.realEstateId).then(re => {
          this.setState({ realEstate: re, isLoading: resident == null || re == null }, () => {
            this.setNavs();
          });
        });
      });
    });
  }


  componentDidMount() {
    var id = routeParamId(this.props);
    this.setState({ realEstateId: id }, () => {
      this.fetchResident();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.residentId != this.state.residentId) {
      this.fetchResident();
    }
  }


  componentWillUnmount() {
    SecondaryNavStateService.clear();
    BreadCrumbsStateService.clear();
  }

  setNavs() {
    SecondaryNavStateService.set(routesConfig.select(x => { return { url: x.url(this.props.match), label: x.label } }));

    BreadCrumbsStateService.set(routesConfig.select(x => {
      return {
        url: x.url(this.props.match), crumbs: [
          { url: predef.routes.residents.index, label: 'common.real-estates' },
          { text: this.state.realEstate.name, url: predef.routes.realEstates.dashboard(this.state.resident.realEstateId) },
          { label: 'common.residents', url: predef.routes.residents.realEstateResidents(this.state.resident.realEstateId) },
          { text: this.state.resident.name },
          { label: x.label }
        ]
      }
    }));
  }

}

export default ResidentDashboard;