import React, { Component } from 'react';
import * as yup from 'yup';
import { guid } from '../../util';
import EditableGrid from '../common/grid/editable-grid';
import LoadingPlaceholder from '../common/loading-placeholder';
import Panel from '../common/panel';
import FutureExpensesStore from '../stores/future-expenses-store';
import FutureExpensesService from '../services/future-expenses-service';

import './future-expenses.scss';

const validationSchema = yup.object().shape({
  description: yup.string().required('common.field-is-required'),
  amount: yup.number().required('common.field-is-required'),
  date: yup.string().required('common.field-is-required').min(10, 'common.field-is-required'),
  currency: yup.string().required('common.field-is-required')
});

class FutureExpenses extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      expenses: null,
      refreshToken: guid()
    };
  }

  render() {
    return <Panel
      className="future-expenses-c"
    >
      {this.state.isLoading ? <LoadingPlaceholder></LoadingPlaceholder> : this.renderBody()}
    </Panel>
  }

  save(fes) {
    return new Promise((resolve, reject) => {
      FutureExpensesService.update(fes)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        })
    })
  }

  renderBody() {
    return <React.Fragment>

      <EditableGrid
        width={90}
        id="future-expenses"
        onSubmit={(fes) => this.save(fes)}
        data={this.state.expenses}
        validationSchema={validationSchema}
        key={this.state.refreshToken}
        autoWidth={true}
        definition={[
          {
            show: true,
            header: 'common.description',
            type: 'text',
            name: 'description',
            headerClasses: "len-300"
          },
          {
            show: true,
            header: 'common.amount',
            type: 'number',
            name: 'amount',
            headerClasses: "len-160"
          },
          {
            show: true,
            header: 'common.currency',
            type: 'currency',
            name: 'currency',
            headerClasses: "len-160",
            controlProps: {
              onlyUsed: true,
              isClearable: false
            }
          },
          {
            show: true,
            header: 'common.date',
            type: 'date',
            name: 'date',
            headerClasses: "len-160"
          }
        ]} >
      </EditableGrid>
    </React.Fragment>
  }

  componentDidMount() {
    this.sub = FutureExpensesStore.futureExpenses.subscribe(x => {
      this.setState({ expenses: x, isLoading: x == null, refreshToken: guid() });
    });
  }

  componentWillUnmount() {
    this.sub.unsubscribe();
  }

}


FutureExpenses.propTypes = {

}


export default FutureExpenses;