import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { BehaviorSubject } from 'rxjs';
import Select from '../common/select';
import PlanningService from '../services/planning-service';
import Calendar from '../services/calendar-service';
import Localization from '../services/localization-service';
import './budget-select.scss';

// do not remove
let lastResult = null;
class BudgetSelect extends Component {

  constructor(props) {
    super(props);
    this.state = { date: null };
    this.options = new BehaviorSubject();
    this.loading = false;
    this.filter = this.filter.bind(this);
    this.unmouted = false;
  }

  filter(budget) {
    return budget.currency === this.props.currency;
  }

  updateOptions() {
    if (this.loading || this.unmouted) return;
    this.loading = true;

    var mDate = Calendar.momentFromIsoDate(this.props.date);

    PlanningService.getBudgets(mDate.year(), mDate.month() + 1).then((budgets) => {
      if (this.unmouted) return;
      this.setState({ date: this.props.date });

      if (budgets == null) return [];
      var items = budgets.map(x => { return { value: x.id, label: x.name, translate: false, currency: x.currency } });
      items.unshift({ value: '', label: Localization.formatMessage('budget-select.no-budget') });
      lastResult = items;
      this.loading = false;
      this.options.next(items);
    }).catch(() => this.loading = false);
  }

  isDateMissing() {
    return this.props.date == null || this.props.date == '';
  }

  isCurrencyMissing() {
    return this.props.currency == null || this.props.currency == '';
  }

  getMissingPropsMessage() {
    if (this.isDateMissing() && this.isCurrencyMissing()) return "budget-select.select-date-and-account";
    if (this.isDateMissing()) return "budget-select.select-date";
    if (this.isCurrencyMissing()) return "budget-select.select-account"
  }

  render() {
    return <Select className="budget-select-c"
      isClearable {...this.props}
      options={this.options.asObservable()}
      filter={this.filter}
      missingProps={this.isDateMissing() || this.isCurrencyMissing()}
      missingPropsMessage={this.getMissingPropsMessage()}
      label={this.props.label}
    ></Select>
  }

  componentDidUpdate() {
    if (this.props.date != this.state.date) {
      this.updateOptions();
    }
  }

  componentDidMount() {
    this.updateOptions();
  }

  componentWillUnmount() {
    this.unmouted = true;
  }
}

BudgetSelect.propTypes = {
  currency: PropTypes.string,
  date: PropTypes.string
}

export default BudgetSelect;
