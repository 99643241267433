import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button, { Cancel, Remove, Save, Edit } from '../common/button';
import Panel from '../common/panel';
import PropTypes from 'prop-types';
import Page from '../common/page';
import classNames from 'classnames/bind';
import { H3 } from '../common/headers';
import InvestmentStore from '../stores/investments-store';
import InvestmentsService from '../services/investments-service';

import './add-investment-transaction.scss';
import { routeParamId, routeParam } from '../../util';
import LoadingPlaceholder from '../common/loading-placeholder';
import Transaction from '../transactions/transaction';

class AddInvestmentTransaction extends Component {

  constructor(props) {
    super(props);
    this.state = { isLoading: true };
  }

  render() {
    return this.state.isLoading ? <LoadingPlaceholder></LoadingPlaceholder> : this.renderBody();
  }

  renderBody() {
    return <React.Fragment>
      <H3 header="add-investment-transaction.header" values={{ name: this.state.investment.name }}></H3>
      <Panel>
        <Transaction currency={this.state.investment.currency} returnPath={predef.routes.investments.dashboard(this.state.investment.id)} mode="investment" investmentTransactionType={this.state.type} investmentId={this.state.investment.id}></Transaction>
      </Panel>
    </React.Fragment>
  }

  componentDidMount() {
    let id = routeParamId(this.props);
    let type = routeParam(this.props, 'type');

    InvestmentStore.byId(id).then(inv => {
      this.setState({ investment: inv, type: type, isLoading: false });
    });
  }

  componentWillUnmount() {
  }

}


AddInvestmentTransaction.propTypes = {

}


export default AddInvestmentTransaction;