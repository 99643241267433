import Bus from '../../bus';
import predef from '../../predef';

class EventsPipes {
    init() {
        this.pipe(predef.events.transaction.transactionsChanged,
            (args) => {
                if (args == null) return;
                let prev = null;
                let tran = null;
                if (args.newVersion != null && args.prevVersion != null) {
                    prev = args.prevVersion;
                    tran = args.newVersion;
                }
                else {
                    tran = args;
                }

                if(tran.operations == null) return;

                if (tran.investmentId != null) Bus.publish(predef.events.investment.investmentsChanged, tran.investmentId);

                for (let op of tran.operations) {
                    if (op.isReimbursable || op.isReimbursement) {
                        Bus.publish(predef.events.transaction.reimbursablesChanged, args);
                        return;
                    }
                }

                if (prev != null) {
                    for (let op of prev.operations) {
                        if (op.isReimbursable || op.isReimbursement) {
                            Bus.publish(predef.events.transaction.reimbursablesChanged, args);
                            return;
                        }
                    }
                }
            });
    }

    pipe(events, pipe) {
        Bus.subscribeTo(events, (args) => {
            pipe(args);
        });
    }

}

const instance = new EventsPipes();
export default instance;