import React, { Component } from 'react';
import classNames from 'classnames/bind';
import './panel.scss';
import { FormattedMessage } from 'react-intl';

class Panel extends Component {
  render() {
    return (
      // <div className={"panel-c " + (this.props.className ? this.props.className : '')}>
      <div className={classNames("panel-c", this.props.className)}>
        {this.props.header != null ? <h3><FormattedMessage id={this.props.header}></FormattedMessage></h3> : null}
        <div className="panel-body">
          {this.props.children}
        </div>
      </div>
    );
  }
}



export default Panel;