import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button from '../common/button';
import Panel from '../common/panel';
import Page from '../common/page';
import { H3, H4 } from '../common/headers';
import Reports from '../services/report-service';
import './net-value-report.scss';
import Indicator from './indicator';
import Indicator2 from '../common/indicator-2';
import UserSettings from '../stores/user-settings-store';
import NetValueHistoryReport from './net-value-history-report';
import { Table, THead, TColumn, TBody } from '../common/table';
import Number from '../common/number';
import Json from '../common/json';
import CurrencyTabs from '../common/currency-tabs';
import { guid } from '../../util';
import classNames from 'classnames'
import { NoDataMessage } from '../common/message'
class NetValueReport extends Component {

  constructor(props) {
    super(props);
    this.state = { isLoading: true, netValue: null };
    this.renderBody = this.renderBody.bind(this);
  }

  render() {
    return <React.Fragment>
      <Page
        className="net-value-report-c"
        header="net-value-report.header"
        loading={this.state.isLoading}
        data={true}
        renderBody={this.renderBody}
        noPanel
      >
      </Page>
    </React.Fragment>
  }

  renderBody() {
    if (this.state.netValue == null) return null;

    return <div>
      <section className="indicators">
        {this.state.netValue.length == 0 ? <NoDataMessage></NoDataMessage> :
          this.state.netValue.map(nv => <Indicator2 key={nv.currency + nv.isBaseCurrency} isEstimated={nv.isBaseCurrency} value={nv.item.netValue} currency={nv.currency}></Indicator2>)
        }</section>

      <section>
        <NetValueHistoryReport></NetValueHistoryReport>
      </section>
      <section>
        <H3 className="details-header" header="net-value-report.details"></H3>
        <CurrencyTabs currencies={this.state.netValue} renderTab={(currency, isBaseCurrency) => {
          var report = this.state.netValue.single(x => x.currency == currency && x.isBaseCurrency == isBaseCurrency).item;

          return <Table autoWidth className="report-table">

            <thead>

              <tr>
                <TColumn className="t-left len-300" header="common.name"></TColumn>
                <TColumn className="t-right len-180" header="net-value-report.value"></TColumn>
                <TColumn className="t-right len-180" header="net-value-report.liabilities"></TColumn>
                <TColumn className="t-right len-180" header="common.net-value"></TColumn>
              </tr>

            </thead>
            <TBody>
              <tr>
                <td className="t-left"><FormattedMessage id="net-value-report.accounts-sum"></FormattedMessage></td>
                <td ><Number value={report.accountsSum}></Number></td>
                <td ></td>
                <td ><Number value={report.accountsSum}></Number></td>
              </tr>

            </TBody>
            {report.realEstates == null || report.realEstates.length == 0 ? null : <TBody>
              {<tr >
                <TColumn className="asset-type" colSpan="4" header="common.real-estates"></TColumn>
              </tr>}
              {report.realEstates == null || report.realEstates.length == 0 ? null : report.realEstates.map(re => {
                return <tr key={re.id}>
                  <td className="t-left">{re.name}</td>
                  <td><Number value={re.marketValue}></Number></td>
                  <td><Number value={re.mortgage}></Number></td>
                  <td><Number value={re.netValue}></Number></td>
                </tr>
              })}
            </TBody>}
            {report.investments == null || report.investments.length == 0 ? null : <TBody>
              <tr>
                <TColumn className="asset-type" colSpan="4" header="common.investments"></TColumn>
              </tr>
              {report.investments.map(inv => {
                return <tr key={inv.id}>
                  <td className="t-left">{inv.name}</td>
                  <td><Number value={inv.currentValue}></Number></td>
                  <td></td>
                  <td><Number value={inv.currentValue}></Number></td>
                </tr>
              })}
            </TBody>}
            {report.assets == null || report.assets.length == 0 ? null : <TBody>
              <tr>
                <TColumn className="asset-type" colSpan="4" header="common.assets"></TColumn>
              </tr>
              {report.assets.map(a => {
                return <tr key={a.id}>
                  <td className="t-left">{a.name}</td>
                  <td><Number value={a.value}></Number></td>
                  <td><Number value={a.mortgage}></Number></td>
                  <td><Number value={a.netValue}></Number></td>
                </tr>
              })}
            </TBody>}
            <tfoot>
              <tr>
                <td className="t-left"><FormattedMessage id="common.sum"></FormattedMessage></td>
                <td className={classNames("t-right", { "red": report.marketValue < 0, "green": report.marketValue > 0, "grey": report.marketValue == 0 })}><Number value={report.marketValue}></Number></td>
                <td className={classNames("t-right", { "green": report.marketValue < 0, "red": report.marketValue > 0, "grey": report.marketValue == 0 })}><Number value={report.liabilities}></Number></td>
                <td className={classNames("t-right", { "red": report.marketValue < 0, "green": report.marketValue > 0, "grey": report.marketValue == 0 })}><Number value={report.netValue}></Number></td>
              </tr>
            </tfoot>
          </Table>
        }}></CurrencyTabs>
      </section>

    </div>
  }

  componentDidMount() {
    this.tsSub = UserSettings.userSettings.subscribe(ts => this.setState({ currenciesOrder: ts.currenciesOrder }));
    Reports.netValue()
      .then(nv => {
        this.setState({ netValue: nv, isLoading: false })
      })
      .catch(e => this.setState({ isLoading: false }))
  }

  componentWillUnmount() {
    this.tsSub.unsubscribe();
  }

}

export default NetValueReport;