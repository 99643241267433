import PropTypes from 'prop-types';
import React from 'react';
import ControlledForm from '../common/controlled-form';
import './transaction-form.scss';

const validate = (props) => {
  if (!props.values.hideAccount && props.values.accountId == null)
    return { 'accountId': true }
  return null;
}
const TransactionForm = (props) => {
  let { submitted, onChange, disableDescription, hideDate, hideAccount, values } = props;
  return <div className="transaction-form-c">
    <ControlledForm
      values={values}
      onChange={(v) => onChange(v)}
      definition={{
        fields: [
          {
            type: 'account',
            name: 'accountId',
            label: 'common.account',
            layout: 'c c-sm-16 c-mdp-6 c-lg-8 c-w-400',
            hidden: hideAccount || props.recurringTransaction,
            errorMessage: 'common.field-is-required',
            currencyFilter: props.currency
          },
          {
            type: 'date',
            name: 'date',
            label: 'common.date',
            layout: hideAccount ? 'c c-sm-24 c-smp-9 c-mdp-6  c-lg-5 c-lgp-5  c-w-160' : 'c c-sm-8 c-mdp-6 c-lg-4 c-w-160',
            hidden: hideDate || props.recurringTransaction,
            showDateAdjustmentButtons: true,
            showCalendarButton: false
          },
          {
            type: 'text',
            name: 'description',
            label: 'common.description',
            disabled: disableDescription,
            disabledMessage: 'transaction-form.description-disabled-message',
            layout: hideAccount || props.recurringTransaction ? 'c c-sm-24 c-smp-15 c-mdp-18 c-lg-19 c-lgp-19 c-w-600' : 'c c-sm-24 c-mdp-12 c-lg-12 c-w-600',
          }
        ]
      }}
      errors={!submitted ? null : validate(props)}
    >
    </ControlledForm>
  </div>
}

TransactionForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.object,
  disableDescription: PropTypes.bool,
  hideDate: PropTypes.bool,
}


export default TransactionForm;