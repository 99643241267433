
import Bus from './bus';

export default class BusSubscription {
    constructor(subId){
        this.subId = subId;
    }

    unsubscribe() {
        Bus.unsubscribeById(this.subId);
    }
}


