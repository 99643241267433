import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button, { Cancel, Remove, Save, Edit } from '../common/button';
import Panel from '../common/panel';
import PropTypes from 'prop-types';
import Page from '../common/page';
import classNames from 'classnames/bind';
import { H3, H4 } from '../common/headers';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import './accounts-order.scss';
import AccountsStore from '../stores/accounts-store';
import { guid, deepCopy } from '../../util';
import AccountTypesStore from '../stores/account-types-store';
import LoadingScreen from '../common/loading-screen';
import AccountService from '../services/accounts-service';

const arrayMoveMutate = (array, from, to) => {
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
};

const arrayMove = (array, from, to) => {
  array = array.slice();
  arrayMoveMutate(array, from, to);
  return array;
};


const AccountItem = SortableElement(({ acc }) => <li className="account-order-item">{acc?.name}</li>);

const AccountsList = SortableContainer(({ items }) => {
  return (
    <ul>
      {items?.map((acc, index) => (
        <AccountItem key={`item-${acc.id}`} index={index} acc={acc} />
      ))}
    </ul>
  );
});


class AccountsOrder extends Component {

  constructor(props) {
    super(props);
    this.state = { isLoading: true, isSaving: false };
    this.id = 'ao-' + guid
  }

  onSortEnd({ oldIndex, newIndex }, g) {
    let order = deepCopy(this.state.order);
    let i = order.findIndex(x => x.currency == g.currency && x.type == g.type);
    order[i].items = arrayMove(order[i].items, oldIndex, newIndex);
    this.setState({ order: order })
  };

  render() {
    return this.state.isLoading ? <LoadingScreen></LoadingScreen> : <div className="accounts-order-c">
      <H3 header="accounts-order.header"></H3>
      {this.renderSavePanel()}
      {this.state.order.map((g => {
        return <React.Fragment key={g.currency + g.type}>
          <H4><FormattedMessage id={this.state.accountTypes.single(x => x.value == g.type).label}></FormattedMessage> {g.currency} </H4>
          <AccountsList items={g.items} onSortEnd={(v) => this.onSortEnd(v, g)} ></AccountsList>
        </React.Fragment>
      }))
      }
      {this.renderSavePanel()}
    </div>
  }

  renderSavePanel() {
    return <Panel className="actions">
      <Button link fa="arrow-left" label="accounts-order.accounts" path={predef.routes.accounts.index}></Button>
      <Button loading={this.state.isSaving} width={9} label="common.save" fa="save" onClick={() => this.save()}></Button>
    </Panel>
  }

  save() {
    if (this.state.order == null) return;
    this.setState({ isSaving: true }, () => {
      AccountService.orderAccounts(this
        .state.order
        .selectMany(x => x.items)
        .select((x, i) => ({ accountId: x.id, order: i })))
        .then(() => this.setState({ isSaving: false }))
        .catch(() => this.setState({ isSaving: false }));
    })
  }

  componentDidMount() {
    AccountTypesStore.accountTypes.subscribe(at => {
      this.setState({ accountTypes: at }, () => {
        if (at != null) {
          AccountsStore.accounts.subscribe(acs => {
            this.setState({
              isLoading: acs == null,
              order: this.getOrder(acs)
            });
          });
        }
      })
    })
  }

  getOrder(acs) {
    if (acs == null) return null;

    let res = [];
    for (let tg of acs.groupBy(x => x.type)) {
      let cgs = tg.values.groupBy(acc => acc.currency);

      for (let cg of cgs) {
        res.push({ type: tg.key, currency: cg.key, items: cg.values });
      }
    }

    return res;
  }

  componentWillUnmount() {
  }

}


AccountsOrder.propTypes = {

}


export default AccountsOrder;