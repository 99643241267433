import React, { Component } from 'react';
import { validateAll } from '../../../util';
import Button, { Remove, Save } from '../../common/button';
import Message from '../message';
import ControlledEditableGrid from './controlled-editable-grid';
import './editable-grid.scss';
import PropTypes from 'prop-types';

class EditableGrid extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: []
      , submitted: false
      , errors: null
      , definition: []
    };
  }

  submit() {
    this.setState({ submitted: true, isSaving: true }, () => {
      let errors = validateAll(this.props.validationSchema, this.state.data);
      this.setState({ errors: errors }, () => {
        if (!this.state.errors.hasErrors) {
          var res = this.props.onSubmit(this.state.data);
          if (res && res.then && typeof res.then === 'function') {
            res.then(() => this.setState({ submitted: false, isSaving: false })).catch(() => {
              this.setState({ isSaving: false });
            })
          } else {
            this.setState({ isSaving: false });
          }
        } else {
          this.setState({ isSaving: false });
        }
      })
    });
  }

  onChange(data) {
    let errors = null;
    if (this.props.validationSchema != null && this.state.submitted) {
      errors = validateAll(this.props.validationSchema, data);
    }
    this.setState({ data: data, errors: errors });
  }

  render() {
    return <div className="editable-grid-c" style={{ "width": this.props.width == null ? 'auto' : this.props.width + "rem" }}>
      {this.hideAddButton && (this.state.data == null || this.state.data.length === 0) ? <Message message="common.no-data"></Message> : null}
      {this.hideAddButton && (this.state.data == null || this.state.data.length === 0) ? null :
        <ControlledEditableGrid
          id={this.props.id}
          errors={this.state.errors}
          onChange={(v) => this.onChange(v)}
          data={this.state.data || []}
          definition={this.state.definition}
          autoWidth={this.props.autoWidth}
          renderTotalRow={
            () => {
              let res = [];

              if (!this.props.hideAddButton)
                res.push(<tfoot key="add">
                  <tr>
                    <td colSpan={this.state.definition.length}>
                      <Button onClick={() => this.add()} outline label={this.props.addLabel || "common.add"} fa="plus"></Button>
                    </td>
                  </tr>
                </tfoot>);

              if (this.props.renderTotalRow)
                res.push(this.props.renderTotalRow(this.state.data))
              return res;
            }
          }
        ></ControlledEditableGrid>
      }
      {!this.props?.hideAddButton ? <div className="add-row"></div> : null}
      {this.state.data == null ? null : <div className="save-row"><Save loading={this.state.isSaving} className="save-btn" onClick={(data) => this.submit(data)}></Save></div>}
    </div>
  }

  add() {
    var newItem = this.props.newItemTemplate ? this.props.newItemTemplate() : {};
    if (newItem == null) throw "New item in editable grid can't be a null."
    this.state.data.push(newItem);
    this.setState({ data: this.state.data })
  }

  componentDidMount() {
    if (!this.props.hideRemoveColumn) {
      this.props.definition.push({
        show: true,
        name: 'remove-action',
        type: 'raw',
        headerClasses: 'len-80',
        renderCells: (row, rowNumber) => {
          return <Remove onClick={() => this.remove(rowNumber)}></Remove>
        }
      })
    }
    this.setState({ data: this.props.data || [], refreshToken: this.props.refreshToken, definition: this.props.definition })
  }

  remove(rowNumber) {
    this.setState({ data: this.state.data.removeAt(rowNumber) });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.refreshToken !== state.refreshToken) return { data: props.data };

    return null;
  }

}

EditableGrid.propTypes = {
  newItemTemplate: PropTypes.func
}

export default EditableGrid;