import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import './tags-input.scss';
import TextSuggestInput from '../common/text-suggest-input';
import { deepCopy } from '../../util';

class TagsInput extends Component {

  constructor(props) {
    super(props);
    this.state = { newTag: null, backspacesOnEmpty: 0, highlightLast: false }
  }

  addTag(tag) {
    if (tag == null || tag.trim() == '') return;
    var tags = deepCopy(this.props.value || []);

    let ts = tag.split(';');

    for (let t of ts) {
      if (t == null || t.trim() == '') continue;
      if (tags.where(x => x != null).any(x => x.toLowerCase() == t.trim().toLowerCase())) {
        this.setState({ newTag: null })
        return;
      }
      tags.push(t);
    }

    this.props.onChange(tags);
    this.setState({ newTag: null })
  }

  removeTag(i) {
    var tags = deepCopy(this.props.value || []);
    tags = tags.removeAt(i);
    this.props.onChange(tags);
  }

  onKeyUp(e) {
    var key = e.keyCode || e.charCode;

    if ((key == 8 || key == 46) && (this.state.newTag == null || this.state.newTag == '')) {
      this.setState({ backspacesOnEmpty: this.state.backspacesOnEmpty + 1 }, () => {
        if (this.props.value != null && this.props.value.length > 0) {
          if (this.state.backspacesOnEmpty == 2) {
            this.setState({ highlightLast: true })
          }
          if (this.state.backspacesOnEmpty == 3) {
            this.removeTag(this.props.value.length - 1);
            this.setState({ backspacesOnEmpty: 0, highlightLast: false });
          }
        }
      });
    }
    else {
      this.setState({ backspacesOnEmpty: 0, highlightLast: false })
    }

  }
  render() {
    return <div

      className={classNames(
        "fc tags-input-c",
        `${this.props.name}-field`,
        "form-group",
        {
          'has-error': this.props.hasError,
          'no-label': this.props.label == null
        })}>

      {this.props.label ? <label ><FormattedMessage id={this.props.label}></FormattedMessage></label> : null}
      <div className="tags">
        {this.props.value != null ? this.props.value.map((x, i) => this.renderTag(x, i)) : null}
        <TextSuggestInput submitOnSelect={true} placeholder={this.props.placeholder} options={this.props.options} onKeyUp={(e) => this.onKeyUp(e)} onChange={(v) => this.setState({ newTag: v })} onSubmit={(v) => this.addTag(v)} value={this.state.newTag}></TextSuggestInput>
      </div>
      {
        this.props.hasError && this.props.errorMessage
          ? <div className="error-message invalid-feedback">
            <FormattedMessage id={this.props.errorMessage}></FormattedMessage>
          </div>
          : null
      }
    </div>
  }

  renderTag(tag, i) {
    return <div key={i} className={classNames("tag", { "highlight": this.state.highlightLast && this.props.value != null && this.props.value.length - 1 == i })}>
      <div className="label">{tag}</div>
      <div className="x" onClick={() => this.removeTag(i)}><span className="fal fa-times"></span></div>
    </div>
  }

}


TagsInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired
}


export default TagsInput;