import React, { Component } from 'react';
import predef from '../../predef';
import Button from '../common/button';
import DateInput from '../common/date-input';
import Calendar from '../services/calendar-service';
import './close-investment-form.scss';
import InvestmentService from '../services/investments-service';
import { createHashHistory } from 'history';
const history = createHashHistory();




class CloseInvestmentForm extends Component {

  constructor(props) {
    super(props);
    this.state = {isSaving : false, date: Calendar.todayIso};
  }

  render() {
    return <div className="close-investment-form-c">
      <DateInput label="close-investment.close-date" name="closeDate" onChange={v => this.setState({ date: v })} value={this.state.date}></DateInput>
      <Button loading={this.state.isSaving} label="close-investment.close-investment" onClick={() => this.closeInvestment()}></Button>
    </div>
  }

  closeInvestment() {
    this.setState({ isSaving: true }, () => {
      InvestmentService.closeInvestment(this.props.investmentId, this.state.date).then(() => {
        this.props.onSaved?.();
      }).catch(() => this.setState({ isSaving: false }));
    });
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

}


CloseInvestmentForm.propTypes = {

}


export default CloseInvestmentForm;