import React, { Component } from 'react';
import predef from '../../predef';
import Button from '../common/button';
import ControlledForm from '../common/controlled-form';
import CurrencyTabs from '../common/currency-tabs';
import Loader from '../common/loader';
import Modal from '../common/modal';
import Number from '../common/number';
import Panel from '../common/panel';
import { Table, TBody, TColumn } from '../common/table';
import ViewTransactions from '../common/view-transactions';
import YearSelect from '../common/year-select';
import Calendar, { IsoCalendar } from '../services/calendar-service';
import Kv from '../services/kv-service';
import Localization from '../services/localization-service';
import ReportService from '../services/report-service';
import classNames from 'classnames';

import './cash-flow-matrix-report.scss';
import { FormattedMessage } from 'react-intl';
import LoadingPlaceholder from '../common/loading-placeholder';

const CashFlowMatrixReportConfig = (props) => {
  let form = {
    fields: [
      {
        type: 'categories-select',
        name: 'highlightedIncome',
        label: 'cash-flow-matrix-report-config.highlighted-income',
        layout: "c",
        expense: false
      },
      {
        type: 'categories-select',
        name: 'highlightedExpenses',
        label: 'cash-flow-matrix-report-config.highlighted-expenses',
        layout: "c",
        expense: true
      },
      {
        type: 'categories-select',
        name: 'incomeRemovedFromCashFlow',
        label: 'cash-flow-matrix-report-config.income-removed-from-cash-flow',
        layout: "c",
        expense: false
      },
      {
        type: 'categories-select',
        name: 'expensesRemovedFromCashFlow',
        label: 'cash-flow-matrix-report-config.expenses-removed-from-cash-flow',
        layout: "c",
        expense: true
      }
    ]
  }

  return <ControlledForm
    values={props.config}
    definition={form}
    onChange={v => {
      props.onChange(v);
    }}
  ></ControlledForm>
}

class CashflowMatrixReport extends Component {

  constructor(props) {
    super(props);
    this.state = { showConfig: false, isLoading: true, report: null, year: Calendar.currentYear, showTransactions: false, isSavingConfig: false };
  }

  render() {
    return <div className="cash-flow-matrix-report-c">
      {this.state.isLoading ? <LoadingPlaceholder></LoadingPlaceholder> : this.renderPanelBody()}
    </div>
  }

  showConfig() {
    this.setState({ showConfig: true })
  }

  saveConfig() {
    if (!this.state.configChanged) {
      this.setState({ showConfig: false });
      return;
    }
    this.setState({ isSavingConfig: true }, () => {
      Kv.save(predef.valueKeys.cashFlowMatrixReportConfig(this.props.reportRef, this.props.refId)
        , {
          highlightedIncome: (this.state.config.highlightedIncome || []).select(x => x),
          highlightedExpenses: (this.state.config.highlightedExpenses || []).select(x => x),
          incomeRemovedFromCashFlow: (this.state.config.incomeRemovedFromCashFlow || []).select(x => x),
          expensesRemovedFromCashFlow: (this.state.config.expensesRemovedFromCashFlow || []).select(x => x),
        }
        , predef.events.cashFlowMatrixReportConfig.cashFlowMatrixReportConfigChanged
        , 'cash-flow-matrix-report-config.config-save-success'
        , 'cash-flow-matrix-report-config.config-save-error'
      ).then(() => {
        this.setState({ showConfig: false, isSavingConfig: false });
        this.fetchReport();
      });
    })
  }
  renderPanelBody() {
    return <React.Fragment>
      <Panel> 
        <YearSelect value={this.state.year} onChange={(y) => this.setState({ year: y }, () => this.fetchReport())}></YearSelect>
        <Button className="config-link" fal="cog" link label="cash-flow-matrix-report.report-configuration" onClick={() => this.showConfig()} ></Button>
      </Panel>
      <CurrencyTabs currencies={this.state.report.items} renderTab={(currency, isBaseCurrency) => this.renderReport(currency, isBaseCurrency)}></CurrencyTabs>

      <Modal
        component={CashFlowMatrixReportConfig}
        componentProps={{
          onChange: (v) => {
            this.setState({ config: v, configChanged: true });
          },
          config: this.state.config
        }}
        title={'cash-flow-matrix-report.configuration'}
        onClose={(m) => {
          if (m == 'cancel') {
            this.setState({ showConfig: false, configChanged: false });
            return;
          }

          this.saveConfig();
        }}
        show={this.state.showConfig}
        isSaving={this.state.isSavingConfig}
        closeButton={{
          label: "common.save",
          fas: "save",
          width: 8.2
        }}

        onSave={(v) => this.saveConfig()}

      >
      </Modal>
    </React.Fragment>
  }
  capitalizeFirstLetter(str) {
    if (str == null) return '';
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  renderReport(currency, isBaseCurrency) {
    var currencyReport = this.state.report.items.single(x => x.currency == currency && x.isBaseCurrency == isBaseCurrency);
    return <div key={(isBaseCurrency ? '~' : '') + currency}>
      <Table custom className="report-table">
        <thead>
          <tr>
            <TColumn rowSpan={1} header="common.month" className="len-120"></TColumn>
            {this.state.report.config.highlightedIncome.map(cat => <TColumn className="min-len-160 max-len-320 income-col" key={cat}>{this.capitalizeFirstLetter(cat)}</TColumn>)}
            <TColumn className="min-len-160 max-len-320 income-col" header={this.state.report.config.highlightedIncome.length > 0 ? "cash-flow-matrix-report.other-income" : "cash-flow-matrix-report.income"} ></TColumn>
            {this.state.report.config.highlightedExpenses.map(cat => <TColumn className="min-len-160 max-len-320 expenses-col" key={cat}>{this.capitalizeFirstLetter(cat)}</TColumn>)}
            <TColumn className="min-len-160 max-len-320  expenses-col" header={this.state.report.config.highlightedExpenses.length > 0 ? "cash-flow-matrix-report.other-expenses" : "cash-flow-matrix-report.expenses"} ></TColumn>
            {this.state.showIncomeRemovedFromCashFlow ?
              <TColumn className="min-len-140" rowSpan={2} header="cash-flow-matrix-report.income-removed-from-cash-flow"></TColumn>
              : null}

            {this.state.showExpensesRemovedFromCashFlow ?
              <TColumn className="min-len-140" rowSpan={2} header="cash-flow-matrix-report.expenses-removed-from-cash-flow"></TColumn>
              : null}
            <TColumn rowSpan={1} header="common.cash-flow" className="min-len-160 max-len-320"></TColumn>
          </tr>
        </thead>
        <TBody>
          {currencyReport.items.map(ri => {
            let item = ri;
            return <tr key={item.month}>
              <td className={classNames("t-cell", { "inactive": !item.isActive })} key="month">{Localization.formatMonthName(item.month)}</td>
              {Object.keys(item.highlightedIncome).map(cat => {
                return <td key={'in' + cat}>
                  <Number color blanksFor={0} key={cat} value={item.highlightedIncome[cat]}></Number>
                  {item.highlightedIncome[cat] != 0 ? <ViewTransactions filter={this.getFilter(ri.month, [cat], null, false)}></ViewTransactions> : null}
                </td>
              })}
              <td key="otherIncome">
                <Number color blanksFor={0} value={item.otherIncome}></Number>
                {item.otherIncome != 0 ? <ViewTransactions filter={this.getFilter(ri.month, null, (this.state.report.config.highlightedIncome || []).concat(this.state.report.config.incomeRemovedFromCashFlow || []), false)}></ViewTransactions> : null}
              </td>
              {Object.keys(item.highlightedExpenses).map(cat => {
                return <td key={'ex' + cat}>
                  <Number color blanksFor={0} key={cat} value={item.highlightedExpenses[cat]}></Number>
                  {item.highlightedExpenses[cat] != 0 ? <ViewTransactions filter={this.getFilter(ri.month, [cat], null, true)}></ViewTransactions> : null}
                </td>
              })}
              <td key="otherExpenses">
                <Number color blanksFor={0} value={item.otherExpenses}></Number>
                {item.otherExpenses != 0 ? <ViewTransactions filter={this.getFilter(ri.month, null, (this.state.report.config.highlightedExpenses || []).concat(this.state.report.config.expensesRemovedFromCashFlow || []), true)}></ViewTransactions> : null}
              </td>
              <td key="cashFlow"><Number color blanksFor={0} value={item.cashFlow}></Number></td>
              {this.state.showIncomeRemovedFromCashFlow ? <td key="irfcf">
                <Number color blanksFor={0} value={item.incomeRemovedFromCashFlow}></Number>
                {item.incomeRemovedFromCashFlow != 0 ? <ViewTransactions filter={this.getFilter(ri.month, this.state.report.config.incomeRemovedFromCashFlow, null, true)}></ViewTransactions> : null}
              </td> : null}
              {this.state.showExpensesRemovedFromCashFlow ?
                <td key="erfcf">
                  <Number color blanksFor={0} value={item.expensesRemovedFromCashFlow}></Number>
                  {item.expensesRemovedFromCashFlow != 0 ? <ViewTransactions filter={this.getFilter(ri.month, this.state.report.config.expensesRemovedFromCashFlow, null, true)}></ViewTransactions> : null}
                </td> : null}
            </tr>
          })}
        </TBody>
        <tfoot>
          <tr>
            <td key="month"><FormattedMessage id="common.sum"></FormattedMessage>:</td>
            {this.state.report.config.highlightedIncome.map(cat => {
              return <td key={"fsi" + cat}><Number color blanksFor={0} value={currencyReport.sum.highlightedIncome[cat]}></Number></td>
            })}
            <td key="oi"><Number color blanksFor={0} value={currencyReport.sum.otherIncome}></Number></td>
            {this.state.report.config.highlightedExpenses.map(cat => {
              return <td key={'fse' + cat}><Number color blanksFor={0} value={currencyReport.sum.highlightedExpenses[cat]}></Number></td>
            })}
            <td key="oe"><Number color value={currencyReport.sum.otherExpenses} blanksFor={0} ></Number></td>
            <td key="cf" className="no-view"><Number color value={currencyReport.sum.cashFlow} blanksFor={0} ></Number></td>
            {this.state.showIncomeRemovedFromCashFlow ? <td key="irfcf" className="no-view"><Number color blanksFor={0} value={currencyReport.sum.incomeRemovedFromCashFlow}></Number></td> : null}
            {this.state.showExpensesRemovedFromCashFlow ? <td key="erfcf" className="no-view"><Number color blanksFor={0} value={currencyReport.sum.expensesRemovedFromCashFlow}></Number></td> : null}
          </tr>
          <tr className="average-row">
            <td key="month"><FormattedMessage id="common.average"></FormattedMessage>:</td>
            {this.state.report.config.highlightedIncome.map(cat => {
              return <td key={"fsi" + cat}><Number color blanksFor={0} value={currencyReport.average.highlightedIncome[cat]}></Number></td>
            })}
            <td key="oi"><Number color blanksFor={0} value={currencyReport.average.otherIncome}></Number></td>
            {this.state.report.config.highlightedExpenses.map(cat => {
              return <td key={'fse' + cat}><Number color blanksFor={0} value={currencyReport.average.highlightedExpenses[cat]}></Number></td>
            })}
            <td key="oe"><Number color value={currencyReport.average.otherExpenses} blanksFor={0} ></Number></td>
            <td className="no-view" key="cf"><Number color value={currencyReport.average.cashFlow} blanksFor={0} ></Number></td>
            {this.state.showIncomeRemovedFromCashFlow ? <td className="no-view" key="irfcf"><Number color blanksFor={0} value={currencyReport.average.incomeRemovedFromCashFlow}></Number></td> : null}
            {this.state.showExpensesRemovedFromCashFlow ? <td className="no-view" key="erfcf"><Number color blanksFor={0} value={currencyReport.average.expensesRemovedFromCashFlow}></Number></td> : null}
          </tr>

        </tfoot>
      </Table >
    </div>
  }

  getFilter(month, exactCategories, excludedCategories, expense) {
    var dateFrom = `${this.state.report.year}-${month}-01`;
    var dateTo = IsoCalendar.endOfMonth(dateFrom);
    var filter = {
      exactCategories: exactCategories,
      excludeCategories: excludedCategories,
      isExpense: expense,
      isIncome: !expense,
      dateFrom: dateFrom,
      dateTo: dateTo,
    }

    if (this.props.transactionFilter != null) {
      Object.keys(this.props.transactionFilter).forEach(k => {
        filter[k] = this.props.transactionFilter[k];
      });
    }
    return filter;
  }

  fetchReport() {
    this.setState({ isLoading: true }, () => {
      ReportService
        .getYearlyCashflowReport(this.props.reportRef, this.props.refId, this.state.year)
        .then(x => {

          let state = { report: x, isLoading: x == null, isLoading: false };
          if (x != null) {
            state.showExpensesRemovedFromCashFlow = x.config.expensesRemovedFromCashFlow != null && x.config.expensesRemovedFromCashFlow.length > 0;
            state.showIncomeRemovedFromCashFlow = x.config.incomeRemovedFromCashFlow != null && x.config.incomeRemovedFromCashFlow.length > 0;
          }
          this.setState(state);
        }).catch(e => this.setState({ isLoading: false }));
    })
  }

  componentDidMount() {
    Kv.getValue(predef.valueKeys.cashFlowMatrixReportConfig(this.props.reportRef, this.props.refId)).then(x => {
      this.setState({ config: x || {} });
    }).catch(x => this.setState({ config: {} }));
    this.fetchReport();
  }
}

export default CashflowMatrixReport;