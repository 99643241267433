import React, { Component } from 'react';
import logo from '../../assets/logo.svg';
import './app-header.scss';
import Toolbar from './toolbar';
import TopNavigation from './top-navigation';
import Button from '../common/button';
import Bus from '../../bus';
import predef from '../../predef'
import SideBarStateService from '../state-services/side-bar-state-service';
import classNames from 'classnames/bind';

class AppHeader extends Component {

  constructor(props) {
    super(props);
    this.state = { showMenuButton: false };
  }

  render() {

    return <div className="app-header-c">
      <Button className={classNames('show-menu-btn', { hidden: !this.state.showMenuButton })} fa="bars" link onClick={() => {
        if (!this.state.showMenuButton) return;
        SideBarStateService.toggle();
      }}></Button>
      <div className="menu"><TopNavigation match={this.props.match}></TopNavigation></div>
      <div className="toolbar"><Toolbar></Toolbar></div>
    </div>
  }

  componentDidMount() {
    this.ssub = SideBarStateService.state.subscribe(s => {
      this.setState({ showMenuButton: !s.isOpen });
    });
  }

  componentWillUnmount() {
    this.ssub.unsubscribe();
  }

}


AppHeader.propTypes = {

}


export default AppHeader;