import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Calendar from '../services/calendar-service';
import './year-select.scss';
import predef from '../../predef';
import { all } from 'q';

class YearSelect extends Component {

  constructor(props) {
    super(props);
  }


  onKeyDown(event) {
    if (event.keyCode == 13) {
      this.props.onChange(parseInt(this.state.text));
      return;
    }
    let allowed = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, predef.keyCodes.backspace, predef.keyCodes.delete,].concat(predef.keyCodes.arrows)
    if (allowed.indexOf(event.keyCode) != -1) return;

    event.preventDefault();
  }

  changeYear(delta) {
    this.props.onChange(this.props.value + delta);
  }

  render() {
    return <div className="year-select-c">
      <label ><FormattedMessage id={this.props.label || 'year-select.year'}></FormattedMessage></label>
      <div className="input-group mb-3">
        <div className="input-group-prepend">
          <button className="btn btn-outline-secondary" onClick={() => this.changeYear(-1)} type="button" id="button-addon1"><span className="fa fa-chevron-left"></span></button>
        </div>
        <input 
        value={this.props.value == null ? '' : this.props.value}
          type="text"
          className="form-control"
          onPaste={(e) => e.preventDefault()}
          onChange={(v) => this.props.onChange(parseInt(v.target.value))}
          onKeyDown={(e) => this.onKeyDown(e)}
          placeholder=""
        />
        <div className="input-group-append">
          <button className="btn btn-outline-secondary" onClick={() => this.changeYear(1)} type="button" id="button-addon2"><span className="fa fa-chevron-right"></span></button>
        </div>
      </div>
    </div>
  }

}


YearSelect.propTypes = {

}


export default YearSelect;