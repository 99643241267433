import React, { Component } from 'react';
import './rich-input.scss';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames/bind';
import { guid } from '../../util';

class RichInput extends Component {
  constructor(props) {
    super(props);
  }

  onChange(data) {
    this.props.onChange(data);
  }

  render() {
    return <div className={classNames(
      "fc rich-input-fc", {'has-error': this.props.hasError}
    )}>
      {this.props.label != null ? <label><FormattedMessage id={this.props.label}></FormattedMessage></label> : null}
      <textarea rows={5} value={this.props.value} onChange={(v)=>this.onChange(v.target.value)}></textarea>
      {
        this.props.hasError && this.props.errorMessage
          ? <div className="error-message invalid-feedback">
            <FormattedMessage id={this.props.errorMessage}></FormattedMessage>
          </div>
          : null
      }
    </div>
  }
}


export default RichInput