import React, { Component } from 'react';
import predef from '../../predef';
import { deepCopy, guid } from '../../util';
import Button from '../common/button';
import InfoBox from '../common/info-box';
import DocumentsService from '../services/documents-service';
import Localization from '../services/localization-service';
import IncomeStreamStore from '../stores/income-streams-store';
import RealEstatesStore from '../stores/real-estates-store';
import './attach-document.scss';
import Documents from './documents';
class AttachDocument extends Component {

  constructor(props) {
    super(props);
    this.state = { refType: null, refName: null, category: null, date: null, documentsKey: guid() }
  }

  render() {
    return <div className="attach-document-c">

      <div className="info">
        <InfoBox blue label={this.state.refType} value={this.state.refName}></InfoBox>
        <InfoBox blue label={this.state.categoryLabel} value={this.state.category}></InfoBox>
        <InfoBox blue label={this.state.dateLabel} value={this.state.date}></InfoBox>
      </div>
      <Documents
        key={this.state.documentsKey}
        actionsHeaderClasses="len-100"
        renderActions={(doc) => {
          if(!doc.links.any(x=>x.ref == this.props.link.ref && x.refId == this.props.link.refId) ) {
            return <Button key="attach" link label="attach-document.attach" onClick={() => this.attach(doc)}></Button>
          }
          
          return null;
        }}></Documents>
    </div>
  }

  attach(doc) {
    let link = deepCopy(this.props.link);
    link.documentId = doc.id;
    DocumentsService.attachDocument(link)
      .then(x => {
        this.setState({ documentsKey: guid()});
      });
  }

  componentDidMount() {
    this.fillInfoBoxes();
  }

  fillInfoBoxes() {
    let state = {};
    if (this.props.link.ref === predef.documentRefs.incomeStream) {
      state.refType = "common.income-stream";
      IncomeStreamStore.byId(this.props.link.refId).then(is => this.setState({ refName: is.name }));
    }
    else if (this.props.link.ref === predef.documentRefs.realEstate) {
      state.refType = "common.real-estate";
      RealEstatesStore.byId(this.props.link.refId).then(re => this.setState({ refName: re.name }));
    }

    if (this.props.link.categoryId != null) {
      switch (this.props.link.ref) {
        case predef.documentRefs.incomeStream:
          state.categoryLabel = "attach-document.income-stream-document-category"
          break;
      };
      DocumentCategoriesService.getDocumentCategories(this.props.link.ref, this.props.link.refId).then(dcs => {
        var category = dcs.single(x => x.id == this.props.link.categoryId);
        if (category != null) {
          this.setState({ category: category.category });
        }
      })
    }

    if (this.props.link.date != null) {
      state.dateLabel = "common.month"
      state.date = Localization.formatDateMMMMYYYY(this.props.link.date);

    }

    this.setState(state);
  }

  componentWillUnmount() {

  }

}

export default AttachDocument;