import React, { Component } from 'react';
import * as yup from 'yup';
import Form from '../common/form';
import './investment-form.scss';

const openInvestmentValidationSchema = yup.object().shape({
  name: yup.string().required().min(1, 'common.field-is-required'),
  currency: yup.string().required().min(3, 'common.field-is-required'),
  startDate: yup.string().required().min(10, 'common.field-is-required')
});

const closedInvestmentValidationSchema = yup.object().shape({
  name: yup.string().required().min(1, 'common.field-is-required'),
  currency: yup.string().required().min(3, 'common.field-is-required'),
  startDate: yup.string().required().min(10, 'common.field-is-required')
});


class InvestmentForm extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let isOpen = this.props.investment == null || !this.props.investment.isClosed;
    return <div className="investment-form-c">
      <Form keepValuesAfterSubmit validationSchema={isOpen ? closedInvestmentValidationSchema : openInvestmentValidationSchema} initialValues={this.props.investment} onSubmit={(inv) => this.props.onSubmit?.(inv)} definition={this.getForm(isOpen)}></Form>
    </div>
  }

  getForm(isOpen) {
    return {
      fields: [
        {
          type: 'text',
          name: 'name',
          label: 'common.name',
          layout: 'c c-smp-8 c-w-260',
          errorMessage: 'common.field-is-required'
        },
        {
          type: 'text',
          name: 'type',
          label: 'investment-form.type',
          layout: 'c c-smp-8 c-w-260',
        },
        {
          type: 'currency',
          name: 'currency',
          label: 'common.currency',
          layout: 'c  c-smp-8 c-w-260',
          errorMessage: 'common.field-is-required',
          hidden: this.props.investment != null,
          onlyUsed: true
        },
        {
          type: 'date',
          name: 'startDate',
          label: 'investment-form.start-date',
          layout: 'c  c-smp-8 c-w-280',
          errorMessage: 'common.field-is-required',
        },
        {
          type: 'line-break',
          layout: 'sm-hide xl-show'
        },
        {
          type: 'date',
          name: 'expectedCloseDate',
          label: 'investment-form.expected-close-date',
          layout: 'c  c-smp-12 c-lg-8 c-w-260'
        },
        {
          type: 'number',
          name: 'expectedReturn',
          label: 'investment-form.expected-return',
          layout: 'c  c-smp-12 c-lg-8  c-w-260'
        },
        {
          type: 'number',
          name: 'expectedYearlyReturn',
          label: 'investment-form.expected-yearly-return',
          layout: 'c  c-smp-12 c-lg-8  c-w-280'
        },
        {
          type: 'date',
          name: 'closeDate',
          label: 'investment-form.close-date',
          layout: 'c  c-smp-12 c-lg-8  c-w-280',
          hidden: isOpen
        },
        {
          type: 'rich',
          name: 'description',
          label: 'common.notes',
          layout: 'c c-w-1200'
        }
      ]
    }
  }

}




InvestmentForm.propTypes = {

}


export default InvestmentForm;