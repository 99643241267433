import Toastr from './app/services/toastr-srevice';
import $ from 'jquery';
import { combineLatest } from 'rxjs/operators';
import classNames from 'classnames/bind';
import predef from './predef';
import { saveAs } from 'file-saver';

export const guid = () => {
    let d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now();
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

export const replaceAll = (str, search, replacement) => {
    return str.replace(new RegExp(search, 'g'), replacement);
}

export const copyToClipboard = (text, showTextInNotification = true) => {
    if (window.clipboardData && window.clipboardData.setData) {
        // IE specific code path to prevent textarea being shown while dialog is visible.
        return (window).clipboardData.setData("Text", text);

    } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        let textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in MS Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
            var res = document.execCommand("copy");  // Security exception may be thrown by some browsers.
            Toastr.success(showTextInNotification ? 'common.copied-to-clipboard' : 'common.copied-to-clipboard-no-value', { value: text });
            return res;
        } catch (ex) {
            Toastr.error('common.couldnt-copy-to-clipboard');
            return false;
        } finally {
            document.body.removeChild(textarea);
        }
    }
}

export const redirect = (route) => {
    return `/redirect/${btoa(route)}`;
}

export const readClipboard = (e) => {
    return e.clipboardData.getData('Text');
}

export const routeParam = (props, param) => {
    let { match: { params } } = props;

    return params[param];
}

export const routeParamId = (props) => {
    return routeParam(props, "id");
}


export const deepEqual = (obj1, obj2) => {
    if (obj1 == null && obj2 == null) return true;
    if ((obj1 == null && obj2 != null) || (obj1 != null && obj2 == null) || (typeof obj1 !== typeof obj2)) return false;
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export const deepCopy = (obj) => {
    if (obj == null) return obj;
    return JSON.parse(JSON.stringify(obj));
}

const yupToFormErrors = (yupError) => {
    let errors = { hasErrors: yupError.inner != null && yupError.inner.length > 0 };
    for (let e of yupError.inner) {
        if (errors[e.path] == null) {
            errors[e.path] = e.message;
        }
        else {
            errors[e.path] = errors[e.path] + "; " + e.message;
        }
    }
    return errors;
}


export const validate = (schema, obj, context) => {
    try {
        schema.validateSync(obj, { abortEarly: false });
    }
    catch (ex) {
        return yupToFormErrors(ex)
    }
    return { hasErrors: false };
}

export const validateAll = (schema, objs, context) => {
    let result = [];
    for (let obj of objs) {
        result.push(validate(schema, obj));
    }
    result.hasErrors = result.any(x => x.hasErrors);
    return result;
}

export const onEnterKey = (event, func) => {
    if (event.charCode === 13 && func != null) {
        func();
    }
}

export const combine = (first, second, func) => {
    return first.pipe(combineLatest(second)).subscribe(([fv, sv]) => {
        func(fv, sv);
    });
}

export const getIndicatorDetails = (ind) => {
    ind = deepCopy(ind);
    let size = 'm';
    let color = 'value-based';
    if (ind.name == "indicators.average-passive-income-6m") {
        size = 'l';
    }
    switch (ind.name) {
        case 'indicators.expenses':
        case 'indicators.income-costs':
        case 'indicators.blocked':
            color = 'red';
            break;
        case 'indicators.income':
        case 'indicators.average-passive-income-6m':
            color = 'green';
            break;
        case 'indicators.investments':
            color = 'blue'
            break;
    }

    ind.color = color;
    ind.size = size;

    return ind;
}

export const hoverAndTouchDropDown = (selector) => {
    function toggleDropdown(e) {
        const _d = $(e.target).closest('.dropdown'),
            _m = $('.dropdown-menu', _d);
        setTimeout(function () {
            const shouldOpen = e.type !== 'click' && _d.is(':hover');
            _m.toggleClass('show', shouldOpen);
            _d.toggleClass('show', shouldOpen);
            $('[data-toggle="dropdown"]', _d).attr('aria-expanded', shouldOpen);
        }, 50); // e.type === 'mouseleave' ? 50 : 0
    }
    $(selector)
        .on('mouseenter mouseleave ', '.dropdown', toggleDropdown)
        .on('click', '.dropdown-menu a', toggleDropdown);


}

function b64toFile(b64Data, filename, contentType) {
    var sliceSize = 512;
    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
        var byteNumbers = new Array(slice.length);

        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    var file = new File(byteArrays, filename, { type: contentType });
    return file;
}

export const downloadBase64Png = (image, name) => {
    name += ".png";
    if (image == null || image.length < 22 || name == null || name == '') return;
    image = image.substring(22);
    var file = b64toFile(image, name, '"image/png');
    saveAs(file, name);
}