import classNames from 'classnames/bind';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import './badge.scss';
import Button from './button'
import Number from '../common/number'
const Badge = (props) => {
  return <div className={classNames('badge-c badge badge-outlined badge-pill ', {
    'badge-primary': props.primary,
    'badge-success': props.success,
    'badge-secondary': props.secondary,
    'badge-danger': props.danger,
  }, props.className)}>
    {props.fa != null ? <span className={"fa fa-" + props.fa}></span> : null} {props.text != null ? <FormattedMessage id={props.text} values={props.values}></FormattedMessage> : null} {props.children != null ? props.children : null}
    {props.showRemove ? <Button link fa="times  " onClick={() => props.onRemove?.()}></Button> : null}
  </div>
}

export const NumberBadge = ({ value, currency, ...rest }) => {
  return <Badge className="number-badge-c" {...rest} 
  danger={value < 0} success={value > 0} secondary={value == 0}><span className={classNames( {
    "fa fa-caret-down negative": value < 0,
    "fa fa-caret-up positive": value > 0,
    "fa fa-square zero": value == 0
  })}></span>
    <Number value={value} currency={currency} ></Number></Badge>
}

Badge.propTypes = {

}


export default Badge;