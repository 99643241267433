import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Message } from '../common/message';
import Bus from '../../bus';
import predef from '../../predef';
import Button from '../common/button';
import Panel from '../common/panel';

import './profile.scss';

class Profile extends Component {
  
  constructor(props) {
    super(props);
    this.state = { };
  }

  render() {
    return (
      <div className="profile-c">

      </div>
    );
  }

  

  componentDidMount() {
  }

  componentWillUnmount() {
  }

}

export default Profile;