import classNames from "classnames/bind";
import Popper from "popper.js";
import React, { Component } from "react";
import { createPortal } from "react-dom";
import { FormattedMessage } from "react-intl";
import { guid } from "../../util";
import Button from "../common/button";
import "./split-link.scss";

class SplitLink extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.id = "sl-" + guid();
    this.closeDropDownWhenClickOutside =
      this.closeDropDownWhenClickOutside.bind(this);
  }

  render() {
    return (
      <div
        className={classNames("split-link-c", this.props.className)}
        id={this.id}
      >
        <Button
          {...this.props.button}
          link
          label={this.props.label}
          onClick={() => this.props.onClick?.(() => this.closeDropDown())}
        ></Button>
        <Button
          className="split-trigger"
          link
          fa="caret-down"
          onClick={() => this.toggleDropDown()}
        ></Button>
        {createPortal(
          <div id={"split-menu-" + this.id} className="split-menu">
            {this.props.items?.map((item) => {
              return (
                <a
                  className="menu-item"
                  key={guid()}
                  onClick={() => {
                    item.onClick?.(() => this.toggleDropDown());
                  }}
                >
                  {" "}
                  {item.rawLabel ? (
                    item.rawLabel
                  ) : (
                    <FormattedMessage id={item.label}></FormattedMessage>
                  )}
                </a>
              );
            })}
          </div>,
          $("body")[0]
        )}
      </div>
    );
  }

  closeDropDown() {
    if (this.$dropDown.is(":visible")) {
      this.$dropDown.hide();
      this.popper?.destroy();
    }
  }

  toggleDropDown() {
    if (this.$dropDown.is(":visible")) {
      this.$dropDown.hide();
      this.popper?.destroy();
    } else {
      this.$dropDown.show();
      this.popper = new Popper(this.$toggle[0], this.$dropDown[0], {
        placement: "bottom",
        modifiers: {
          preventOverflow: { enabled: true, boundariesElement: $("body")[0] },
        },
      });
    }
  }

  closeDropDownWhenClickOutside(e) {
    if (
      $.contains($("#" + this.id)[0], e.target) ||
      $.contains(this.$dropDown[0], e.target)
    ) {
      return;
    }
    this.closeDropDown();
  }
  get $toggle() {
    return $("#" + this.id + " .split-trigger");
  }

  get $dropDown() {
    return $("#" + "split-menu-" + this.id);
  }

  componentDidMount() {
    this.$dropDown.hide();
    $(document).bind("click", this.closeDropDownWhenClickOutside);
  }

  componentWillUnmount() {
    $(document).unbind("click", this.closeDropDownWhenClickOutside);
  }
}

SplitLink.propTypes = {};

export default SplitLink;
