import PropTypes from 'prop-types';
import React, { Component } from 'react';
import predef from '../../predef';
import { routeParamId } from '../../util';
import Form from '../common/form';
import ResidentsService from '../services/residents-service';
import './resident-form.scss';
import Panel from '../common/panel'
import * as yup from 'yup';
import LoadingScreen from '../common/loading-screen';

export const validationSchema = yup.object().shape({
  name: yup.string().required(),
});

class ResidentForm extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let form = {
      fields: [
        {
          type: 'text',
          name: 'name',
          label: 'common.name',
          errorMessage: 'common.field-is-required'
        },
        {
          type: 'text',
          name: 'room',
          label: 'resident-form.room',
        },
        {
          type: 'date',
          name: 'leaseStart',
          label: 'resident-form.lease-start',
          allowClear: true
        },
        {
          type: 'date',
          name: 'leaseEnd',
          label: 'resident-form.lease-end',
          allowClear: true
        },
        {
          type: 'text',
          name: 'address',
          label: 'resident-form.address',
          errorMessage: 'common.field-is-required'
        },
        {
          type: 'text',
          name: 'phoneNumber',
          label: 'resident-form.phone-number',
        },
        {
          type: 'text',
          name: 'email',
          label: 'resident-form.email',
        },
        {
          type: 'text',
          name: 'IcePhoneNumber',
          label: 'resident-form.ice-phone-number',
        },
        {
          type: 'number',
          name: 'monthlyRent',
          label: 'resident-form.monthly-rent',
        },
        {
          type: 'number',
          name: 'utilities',
          label: 'account-form.utilities',
          hidden: this.props.account != null,
        }
      ]
    }
    return <div className="resident-form-c">
      <Form validationSchema={validationSchema} initialValues={this.props.resident} successPath={predef.routes.residents.realEstateResidents(this.props.realEstateId)} cancelPath={predef.routes.residents.realEstateResidents(this.props.realEstateId)} definition={form} onSubmit={res => this.save(res)}></Form>
    </div>
  }

  save(resident) {

    resident.realEstateId = this.props.realEstateId;
    if (this.props.resident == null) {
      return ResidentsService.addResident(resident);
    }
    return ResidentsService.updateResident(resident);
  }

}


ResidentForm.propTypes = {
  realEstateId: PropTypes.string.isRequired
}


export default ResidentForm;



export const AddResident = (props) => {
  let id = routeParamId(props);
  return <Panel><ResidentForm realEstateId={id}></ResidentForm></Panel>
}

export class EditResident extends Component {
  constructor(props) {
    super(props);
    this.state = {resident: null, isLoading: true};
  }

  render() {
    return this.state.isLoading ? <LoadingScreen></LoadingScreen> : <Panel><ResidentForm resident={this.state.resident} realEstateId={this.state.resident.realEstateId}></ResidentForm></Panel>
  }

  componentDidMount() {
    let id = routeParamId(this.props)
    ResidentsService.getResident(id).then(x => this.setState({ resident: x, isLoading: x == null }));
  }

}
