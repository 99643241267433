import React, { Component } from "react";
import Login from "./app/authentication/login";
import startup from "./startup";
import Shell from "./app/shell";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "./private-route";
import "./app.scss";
import Bus from "./bus";
import messages_pl from "./translations/pl.json";
import messages_en from "./translations/en.json";
import { addLocaleData } from "react-intl";
import locale_en from "react-intl/locale-data/en";
import locale_pl from "react-intl/locale-data/pl";
import { IntlProvider } from "react-intl";
import Register from "./app/authentication/register";
import Localization from "./app/services/localization-service";
import predef from "./predef";
import Unavailable from "./app/pages/unavailable";
import { topLevelRoutes } from "./routes";
import * as moment from "moment";
import "../node_modules/moment/locale/pl";
import "../node_modules/moment/locale/en-gb";
import api from "./api";
addLocaleData([...locale_en, ...locale_pl]);
const messages = {
  pl: messages_pl,
  en: messages_en,
};
window.apiLogs = true;

class App extends Component {
  constructor(props) {
    super(props);

    // TODO: get language from cookies
    this.state = { lang: "pl", messages: messages.pl };
    moment.locale("pl");
    Localization.intl = new IntlProvider(
      { locale: "pl", messages: messages.pl },
      {}
    ).getChildContext();
    startup();
  }

  render() {
    return (
      <IntlProvider locale={this.state.lang} messages={this.state.messages}>
        <React.Fragment>
          <div className="bar">&nbsp;</div>
          <div className="app-c">
            <Switch>
              {this.renderTopLevelRoutes()}
              <PrivateRoute path="" component={Shell}></PrivateRoute>
            </Switch>
          </div>
        </React.Fragment>
      </IntlProvider>
    );
  }

  renderTopLevelRoutes() {
    let res = [];
    for (let route of topLevelRoutes) {
      res.push(
        <Route
          key={route.path}
          path={route.path}
          component={route.component}
        ></Route>
      );
    }

    return res;
  }

  componentDidMount() {
    this.busSub = Bus.subscribe(this);
    api
      .get(predef.endpoints.version)
      .then((v) => (v?.version != 'Unknown' ? console.info("App version: " + v.version) : null));
  }

  componentWillUnmount() {
    this.busSub.unsubscribe();
  }

  _onLanguageSelected(lang) {
    let ms = [];
    switch (lang) {
      case "pl":
        ms = messages.pl;
        moment.locale("pl");

        break;
      case "en":
        ms = messages.en;
        moment.locale("en-gb");

        break;
    }

    Localization.intl = new IntlProvider(
      { locale: lang, messages: ms },
      {}
    ).getChildContext();
    this.setState({ lang: lang, messages: ms });
    Bus.publish(predef.events.localization.languageChanged, lang);
  }
}

export default App;
