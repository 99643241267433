import { BehaviorSubject } from 'rxjs';


class AccountTypesStore {
    constructor() {
        this._subject = new BehaviorSubject([
            { value: 1, label: "account-form.types.billing", translate: true }
            , { value: 2, label: "account-form.types.saving", translate: true }
            , { value: 3, label: "account-form.types.wallet", translate: true }
            , { value: 4, label: "account-form.types.credit-card", translate: true }
        ]);
    }

    get accountTypes() {
        return this._subject.asObservable();
    }

}

const instance = new AccountTypesStore();
export default instance;
