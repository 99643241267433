import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import api from '../../api';
import Button from '../common/button';
import Error from '../common/error';
import LoadingPlaceholder from '../common/loading-placeholder';
import Panel from '../common/panel';
import { Table, TBody, TColumn, THead } from '../common/table';
import './create-beta-account.scss';

class CreateBetaAccount extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  createAccount() {
    this.setState({ isLoading: true }, () => {
      api.get('/api/beta/profiles')
        .then(x => this.setState({ data: x, isLoading: false }))
        .catch(x => this.setState({ isLoading: false, error: x }));
    });
  }

  render() {
    return <div className="create-beta-account-c">
      {this.state.error != null ? <Error raw={this.state.error}></Error> : null}

      {this.state.isLoading ? <LoadingPlaceholder> </LoadingPlaceholder> : this.renderBody()}
    </div>
  }
  renderBody() {
    return this.state.data == null ? <Panel>
      <Button label="create-beta-account.create-account" onClick={() => this.createAccount()}></Button>
    </Panel>
      : this.renderAccountsInfo();
  }
  renderAccountsInfo() {
    return <Panel>
      <Button path="/login" label="login.login"></Button>
      <Table>
        <THead>
          <TColumn header="create-beta-account.profile"></TColumn>
          <TColumn header="create-beta-account.login"></TColumn>
          <TColumn header="create-beta-account.password"></TColumn>
          <TColumn header="create-beta-account.profile-id"></TColumn>
        </THead>
        <TBody>
          <tr>
            <td><FormattedMessage id="create-beta-account.user-1"></FormattedMessage></td>
            <td>{this.state.data.login1}</td>
            <td>{this.state.data.password1}</td>
            <td>{this.state.data.tenant1}</td>
          </tr>
          <tr>
            <td><FormattedMessage id="create-beta-account.user-2"></FormattedMessage></td>
            <td>{this.state.data.login2}</td>
            <td>{this.state.data.password2}</td>
            <td>{this.state.data.tenant2}</td>
          </tr>
          <tr>
            <td><FormattedMessage id="create-beta-account.common"></FormattedMessage></td>
            <td></td>
            <td></td>
            <td>{this.state.data.commonTenant}</td>
          </tr>
        </TBody>
      </Table>
    </Panel>

    // <pre>
    //   {JSON.stringify(this.state.data, null, 4)}
    // </pre>

  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

}


CreateBetaAccount.propTypes = {

}


export default CreateBetaAccount;