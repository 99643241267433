import React from 'react';
import TransactionList from './transaction-list';
import './transactions-modal.scss';
import Modal from '../common/modal';

const TransactionsModal = ({ onClose, show, filter }) => {
  return <Modal className="transactions-modal-c"
    component={TransactionList}
    componentProps={{ initialFilter: filter }}
    onClose={() => onClose()}
    show={show}
    title={'transactions-modal.transactions'}
  ></Modal>
}


TransactionsModal.propTypes = {

}


export default TransactionsModal;