import React, { Component } from 'react';
import { routeParamId } from '../../util';
import IncomeStreamIndicatorsOverTimeReport from '../reports/income-stream-indicators-over-time-report';
import './income-stream-reports.scss';
import predef from '../../predef';
import Todos from '../common/todos/todos';
import { H3 } from '../common/headers';
import CashFlowMatrixReport from '../reports/cash-flow-matrix-report';
class IncomeStreamReports extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return this.state.incomeStreamId == null ? null : <div className="income-stream-reports-c">
      <H3 header="income-stream-reports.todos"></H3>

      <Todos entityRef={predef.todosRefs.incomeStream} refId={this.props.match.params.id} ></Todos>
      <IncomeStreamIndicatorsOverTimeReport incomeStreamId={this.state.incomeStreamId}></IncomeStreamIndicatorsOverTimeReport>
    </div>;
  }

  componentDidMount() {
    let id = routeParamId(this.props);
    this.setState({ incomeStreamId: id });
  }

  componentWillUnmount() {
  }

}


IncomeStreamReports.propTypes = {

}


export default IncomeStreamReports;