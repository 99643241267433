import { Decimal } from 'decimal.js';

export default function extendArray() {
    Array.prototype.remove = function (elem) {
        return this.filter(e => e !== elem);
    };

    Array.prototype.removeAt = function (index) {
        this.splice(index, 1);
        return this;
    };

    Array.prototype.sum = function (selector) {
        if (typeof selector !== 'function') {
            selector = (item) => item;
        }
        let sum = new Decimal(0);
        for (let i = 0; i < this.length; i++) {
            let val = selector(this[i]);
            if (val != null && val != '')
                sum = sum.plus(new Decimal(val));
        }
        return sum.toNumber();
    };

    Array.prototype.average = function (selector) {
        var sum = new Decimal(this.sum(selector));

        if (sum == null) return null;

        return sum.div(this.length).toNumber();
    }

    Array.prototype.contains = function (v) {
        for (let i = 0; i < this.length; i++) {
            if (this[i] === v) { return true; }
        }
        return false;
    };

    Array.prototype.unique = function (selector) {
        let arr = [];
        for (let i = 0; i < this.length; i++) {
            if (!arr.contains(selector(this[i]))) {
                arr.push(selector(this[i]));
            }
        }
        return arr;
    };


    Array.prototype.withUniqueKey = function (selector) {
        let arr = [];
        for (let i = 0; i < this.length; i++) {
            if (!arr.any(e => selector(e) == selector(this[i]))) {
                arr.push(this[i]);
            }
        }
        return arr;
    };

    Array.prototype.where = function (predicate) {
        let arr = [];
        for (let i = 0; i < this.length; i++) {
            if (predicate(this[i])) {
                arr.push(this[i]);
            }
        }
        return arr;
    };

    Array.prototype.select = function (selector) {
        let arr = [];
        for (let i = 0; i < this.length; i++) {
            arr.push(selector(this[i], i));
        }
        return arr;
    };

    Array.prototype.selectMany = function (selector) {
        let arr = [];
        for (let i = 0; i < this.length; i++) {
            arr = arr.concat(selector(this[i]));
        }
        return arr;
    };


    Array.prototype.single = function (predicate) {
        for (let i = 0; i < this.length; i++) {
            if (predicate(this[i])) {
                return this[i];
            }
        }

        return null;
    };

    Array.prototype.first = function (predicate) {
        if (predicate == null) return this.length == 0 ? null : this[0];
        return this.single(predicate);
    };



    Array.prototype.any = function (predicate) {
        if (predicate == null) return this.length > 0;

        for (let i = 0; i < this.length; i++) {
            if (predicate(this[i])) {
                return true;
            }
        }

        return false;
    };


    Array.prototype.all = function (predicate) {
        for (let i = 0; i < this.length; i++) {
            if (!predicate(this[i])) {
                return false;
            }
        }

        return true;
    };


    Array.prototype.orderBy = function (valueSelector, order) {
        if (order == null) order = 'asc';
        let swapped;
        do {
            swapped = false;
            for (let i = 0; i < this.length - 1; i++) {
                if ((valueSelector(this[i]) > valueSelector(this[i + 1]) && order === 'asc') || (valueSelector(this[i]) < valueSelector(this[i + 1]) && order === 'desc')) {
                    let temp = this[i];
                    this[i] = this[i + 1];
                    this[i + 1] = temp;
                    swapped = true;
                }
            }
        } while (swapped);

        return this;
    };

    Array.prototype.orderByAsc = function (valueSelector) {
        return this.orderBy(valueSelector, 'asc');
    };

    Array.prototype.orderByDesc = function (valueSelector) {
        return this.orderBy(valueSelector, 'desc');
    };

    Array.prototype.groupBy = function (keySelector) {
        let ret = [];

        for (let el of this) {
            let key = keySelector(el);
            let group = ret.single(x => x != null && x.key === key);
            if (group == null) {
                group = { key: key, values: [] };
                ret.push(group);
            }
            group.values.push(el);
        }

        return ret;
    };

    Array.prototype.orderByCurrency = function () {
        return this.orderByCurrencySelector(x => x.currency);
    };


    Array.prototype.orderByAccountType = function (accountTypeSelector) {
        return this.orderBy(x => {
            switch (accountTypeSelector(x)) {
                case 1: return 1;
                case 2: return 3;
                case 3: return 2;

            }
        });
    };

    Array.prototype.groupByCurrency = function () {
        return this.groupBy(x => x.currency);
    }

    Array.prototype.moveTo = function (from, to) {
        while (from < 0) {
            from += this.length;
        }
        while (to < 0) {
            to += this.length;
        }
        if (to >= this.length) {
            let k = to - arr.length;
            while ((k--) + 1) {
                arr.push(undefined);
            }
        }
        this.splice(to, 0, this.splice(from, 1)[0]);
        return this;
    }

    Array.prototype.skip = function (number) {
        let res = [];
        for (let i = 0; i < this.length; i++) {
            if (i >= number) res.push(this[i]);
        }
        return res;
    }

    Array.prototype.take = function (number) {
        if (number > this.length) number = this.length;

        return this.slice(0, number);
    }

    Array.prototype.moveUp = function (index) {
        return this.moveTo(index, index - 1);
    }


    Array.prototype.moveDown = function (index) {
        return this.moveTo(index, index + 1);
    }

    Array.prototype.moveBy = function (index, delta) {
        return this.moveTo(index, index + delta);
    }

    Array.prototype.distinct = function () {
        let result = [];

        for (let el of this) {
            if (!result.contains(el)) {
                result.push(el);
            }
        }

        return result;
    }

    Array.prototype.findIndex = function (predicate) {

        for (let i = 0; i < this.length; i++) {
            if (predicate(this[i])) return i;
        }

        return -1;
    }

} 