import predef from '../../predef';
import Store from './store';

class FutureIncomesStore {
    constructor() {
        this.futureIncomesStore = new Store(predef.endpoints.futureIncome.index,
            [predef.events.futureIncome.futureIncomesChanged]);

    }

    get futureIncomes() {
        return this.futureIncomesStore.asObservable();
    }

    byId(id){
        return new Promise((resolve, _)=> {
            this.futureIncomes.subscribe(entities => {
                if (entities == null) return;

                resolve(entities.single(x=>x.id == id));
            });
        });
    }


}

const instance = new  FutureIncomesStore();
export default instance;
