import React, { Component } from 'react';
import { deepCopy, guid } from '../../util';
import Button from '../common/button';
import Form from '../common/form';
import './documents-filter.scss';

class DocumentsFilter extends Component {

  constructor(props) {
    super(props);
    this.state = { id: guid() };
  }

  render() {
    let form = {
      fields: [
        {
          type: 'text',
          label: 'common.name',
          name: 'name',
          layout: 'c c-sm-12 c-mdp-6 c-lg-6'
        },
        {
          type: 'text',
          label: 'common.description',
          name: 'description',
          layout: 'c c-sm-12 c-mdp-6 c-lg-6'
        },
        {
          type: 'document-category',
          label: 'common.category',
          name: 'category',
          layout: 'c c-sm-12 c-mdp-6 c-lg-6',
          docRef: this.props.docRef
        },
        {
          type: 'date-range',
          label: 'common.date-range',
          name: 'date',
          layout: 'c c-sm-12 c-mdp-6 c-lg-6'
        }
      ],
    }

    return <div className="documents-filter-c">
      <Form key={this.state.id} keepValuesAfterSubmit submitButton={{ label: 'common.filter', outline: true, fa: 'filter' }} definition={form}
        onSubmit={(values) => this.onChange(values)}>
        <Button className="clear-btn" outline secondary label="common.clear" fa="times" onClick={() => {
          this.setState({ id: guid() });
          this.onChange(null);
        }} ></Button>
      </Form>
    </div>
  }

  onChange(values) {
    if (values == null) {
      this.props.onChange?.(null);
      return;
    }

    let filter = deepCopy(values);
    if (filter.date != null) {
      filter.dateFrom = filter.date.dateFrom;
      filter.dateTo = filter.date.dateTo;
      delete filter.date;
    }
    this.props.onChange?.(filter);

  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

}


DocumentsFilter.propTypes = {

}


export default DocumentsFilter;