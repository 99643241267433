import React, { Component } from 'react';
import * as yup from 'yup';
import { deepEqual, validate } from '../../util';
import Button from '../common/button';
import ControlledForm from '../common/controlled-form';
import RadioSwitchInput from '../common/radio-switch-input';
import Calendar from '../services/calendar-service';
import Toastr from '../services/toastr-srevice';
import Transactions from '../services/transactions-service';
import Transfer from '../transactions/transfer';

import './quick-transfer.scss';

export const transferValidationSchema = yup.object().shape({
  accountId: yup.string().required(),
  amount: yup.number().required(),
  date: yup.string().required().min(10)
})

class QuickTransfer extends Component {

  constructor(props) {
    super(props);
    this.state = { mode: 'from' }
  }

  render() {
    let switchItems = [{
      label: 'quick-transfer.from-option',
      activeClass: 'primary',
      inactiveClass: 'outline-secondary',
      value: 'from'
    }, {
      label: 'quick-transfer.to-option',
      activeClass: 'primary',
      inactiveClass: 'outline-secondary',
      value: 'to',
    }]

    return <div className="quick-transfer-c">
      <div className="f">
        <RadioSwitchInput
          value={this.state.mode}
          items={switchItems}
          onChange={(val) => this.setState({ mode: val })} ></RadioSwitchInput>

        <Transfer key={this.state.mode}
          accountFrom={this.state.mode == 'from' ? this.props.account : null}
          accountTo={this.state.mode == 'to' ? this.props.account : null}
        ></Transfer>
      </div>
    </div>
  }

}


QuickTransfer.propTypes = {

}


export default QuickTransfer;