import React, { Component } from 'react';
import Button from '../common/button';
import BarChart from '../common/charts/bar-chart';
import ChartColor from '../common/charts/chart-color';
import CurrencyTabs from '../common/currency-tabs';
import { H3 } from '../common/headers';
import LoadingPlaceholder from '../common/loading-placeholder';
import Modal from '../common/modal';
import Panel from '../common/panel';
import ReportService from '../services/report-service';
import './future-balance.scss';
import FutureExpenses from './future-expenses';
import FutureIncomes from './future-incomes';
import Indicator from '../reports/indicator';
import NumberInput from '../common/number-input';
import { FormattedMessage } from 'react-intl';
import { guid } from '../../util';
import Bus from '../../bus';
import predef from '../../predef';
import KVService from '../services/kv-service';
import { PanelMessage } from '../common/message'
class FutureBalance extends Component {

  constructor(props) {
    super(props);
    this.state = { report: null, isLoading: true, unexpectedExpenses: 0, chartKey: guid() };
  }

  render() {
    return <div className="future-balance-c">
      {this.state.isLoading ? <LoadingPlaceholder></LoadingPlaceholder> : this.renderBody()}
    </div>
  }

  renderBody() {
    if (this.state.report.length == 0) return <PanelMessage message="common.no-data"></PanelMessage>;

    return <React.Fragment>
      <CurrencyTabs currencies={this.state.report} renderTab={(currency) => {
        let activeCurrencyItem = this.state.report.single(x => x.currency == currency);
        let items = activeCurrencyItem.item.items;
        let greyDashed = ChartColor.grey.solid;
        greyDashed.type = 'dashed';


        let series = [
          {
            y: items.select(y => y.value.balance),
            itemStyle: x => ChartColor.green.solid,
            type: 'line',
            lineStyle: ChartColor.green.solid
          }
        ]

        if (this.state.unexpectedExpenses > 0) {
          if (this.state.unexpectedExpenses > 0) { }
          series.push({
            y: items.select(y => y.value.balance - this.state.unexpectedExpenses),
            itemStyle: x => ChartColor.grey.solid,
            type: 'line',
            lineStyle: greyDashed
          })
        }


        let chartDefinition = {
          x: items.select(x => x.date),
          timeSeries: true,
          showLegend: true,
          downloadFileName: 'future-balance-report.download-file-name',
          series: series,
          height: 600,
          color: ChartColor.blue.gradient.leftRight,

        }

        var rExpenses = {
          indicators: [{ value: activeCurrencyItem.item.recurringExpenses, currency }],
          name: 'future-balance.recurring-expenses',
          unit: currency,
          color: 'red'
        }

        var rIncome = {
          indicators: [{ value: activeCurrencyItem.item.recurringIncome, currency }],
          name: 'future-balance.recurring-income',
          unit: currency,
          color: 'green'
        }

        return <div className="report">
          <div className="indicators">
            <Indicator data={rIncome}></Indicator>
            <Indicator data={rExpenses}></Indicator>
            <div className="unexpected">
              <NumberInput onBlur={x => {
                KVService.save(predef.valueKeys.futureBalanceUnexpectedExpenses, this.state.unexpectedExpenses);
              }} onChange={(v) => this.onUnexpectedChanged(v)} value={this.state.unexpectedExpenses}></NumberInput>
              <div className="label">
                <FormattedMessage id="future-balance.unexpected-expenses-budget"></FormattedMessage>
              </div>
            </div>
          </div>

          <BarChart showLegend={true} showLabels={false} key={this.state.chartKey} definition={chartDefinition} ></BarChart>

        </div>
      }}></CurrencyTabs>

      <H3 header="future-balance.future-expenses"></H3>
      <Panel>
        <FutureExpenses></FutureExpenses>
      </Panel>
      <H3 header="future-balance.future-incomes"></H3>
      <Panel>
        <FutureIncomes></FutureIncomes>
      </Panel>
    </React.Fragment>
  }

  onUnexpectedChanged(v) {
    this.setState({ unexpectedExpenses: v == null ? '' : v, chartKey: guid() });

  }

  componentDidMount() {
    this.getReport();
    this.busSub = Bus.subscribeTo([predef.events.futureExpense.futureExpenseChanged, predef.events.futureIncome.futureIncomesChanged], () => this.getReport());
    KVService.getValue(predef.valueKeys.futureBalanceUnexpectedExpenses).then(x => this.setState({ unexpectedExpenses: x || 0 }));
  }

  getReport() {
    ReportService.getFutureBalance().then(r => this.setState({ report: r, isLoading: r == null, chartKey: guid() }));

  }

  componentWillUnmount() {
    this.busSub?.unsubscribe();
  }

  _onFutureExpensesChanged() {
    this.getReport();
  }

  _onFutureIncomeChanged() {
    this.getReport();

  }


}


FutureBalance.propTypes = {

}


export default FutureBalance;