import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button, { Cancel, Remove, Save, Edit } from '../common/button';
import Panel from '../common/panel';
import PropTypes from 'prop-types';
import Page from '../common/page';
import classNames from 'classnames/bind';
import { H2 } from '../common/headers';
import RecurringTransactionsStore from '../stores/recurring-transactions-store';
import './edit-recurring-transaction.scss';
import { routeParamId } from '../../util';
import LoadingPlaceholder from '../common/loading-placeholder';
import RecurringTransactionForm from './recurring-transaction-form';

class EditRecurringTransaction extends Component {

  constructor(props) {
    super(props);
    this.state = { isLoading: true };
  }

  render() {
    return <div className="edit-recurring-transaction-c">
      {this.state.isLoading ? <LoadingPlaceholder> </LoadingPlaceholder> : this.renderBody()}
    </div>
  }

  renderBody() {
    return <React.Fragment>
      <H2 header="edit-recurring-transaction.header"></H2>
      <RecurringTransactionForm transaction={this.state.transaction}></RecurringTransactionForm>
    </React.Fragment>
  }


  componentDidMount() {
    let id = routeParamId(this.props);

    RecurringTransactionsStore.byId(id).then(x => {
      this.setState({ isLoading: x == null, transaction: x });
    })
  }

  componentWillUnmount() {
  }

}


EditRecurringTransaction.propTypes = {

}


export default EditRecurringTransaction;