import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button from '../common/button';
import Panel from '../common/panel';
import PropTypes from 'prop-types';
import Page from '../common/page';
import { routeParamId } from '../../util'
import './reimbursements.scss';
import Transactions from '../services/transactions-service';
import Json from '../common/json';
import { Table, TBody, TColumn, THead } from '../common/table';
import Date from '../common/date';
import Number from '../common/number';
import {withRouter} from 'react-router-dom';

class Reimbursements extends Component {

  constructor(props) {
    super(props);
    this.state = { transactionLoading: true, reimbursementsLoading: true };
  }

  render() {
    return <React.Fragment>
      <Page loading={this.state.transactionLoading || this.state.reimbursementsLoading} data={true} className="reimbursements-c" renderBody={() => this.renderBody()}>

      </Page>
    </React.Fragment>
  }

  renderBody() {
    return <div>
      <div className="top-actions">
        <Button link fa="arrow-left" label="reimbursements.back-to-reimbursable" path={predef.routes.reimbursables.index}></Button>
      </div>
      <h2><FormattedMessage id="reimbursements.reimbursable-info"></FormattedMessage></h2>
      <div className="reimbursable-info">
        <div className="info">
          <label><FormattedMessage id="common.amount"></FormattedMessage></label>
          <div className="value"><Number value={this.state.operation.amount} currency={this.state.operation.currency}></Number></div>
        </div>
        <div className="info">
          <label><FormattedMessage id="common.description"></FormattedMessage></label>
          <div className="value">{this.state.operation.description}</div>
        </div>

        <div className="info">
          <label><FormattedMessage id="common.account"></FormattedMessage></label>
          <div className="value">{this.state.transaction.account.name}</div>
        </div>
        <div className="info">
          <label><FormattedMessage id="common.date"></FormattedMessage></label>
          <div className="value"><Date date={this.state.operation.date}></Date></div>
        </div>
      </div>
      <div className="clear-both edit-main-tran">
        <Button link fa="edit" label="reimbursements.edit-transaction" path={predef.routes.transaction.edit(this.state.transaction.id, )}></Button>
      </div>
      <div className="reimbursements-list">
        <h2><FormattedMessage id="reimbursements.reimbursements"></FormattedMessage></h2>
        <Table>
          <THead>
            <TColumn className="len-60" header="common.no"></TColumn>
            <TColumn className="len-160" header="common.amount"></TColumn>
            <TColumn className="len-300" header="common.description"></TColumn>
            <TColumn className="len-160" header="common.date"></TColumn>
            <TColumn className="len-300" header="common.account"></TColumn>
            <TColumn className="len-165"></TColumn>
          </THead>
          <TBody>
            {this.state.data.map((o, i) => <tr key={o.id}>
              <td>{i + 1}</td>
              <td><Number value={o.amount} currency={o.currency}></Number></td>
              <td>{o.description}</td>
              <td><Date date={o.date}></Date></td>
              <td>{o.account.name}</td>
              <td>
                <Button link label="reimbursements.edit-transaction" fa="edit" path={predef.routes.transaction.edit(o.transactionId, this.props.location)}></Button>
              </td>
            </tr>)}
          </TBody>
        </Table>
      </div>
    </div>
  }


  componentDidMount() {
    let id = routeParamId(this.props);
    Transactions.getReimbursements(id).then((rs) => {
      this.setState({
        transactionLoading: false, data: rs.selectMany(t => {
          for (let o of t.operations) {
            o.account = t.account;
          }
          return t.operations;
        }).where(o => o.isReimbursement && o.reimbursementFor == id)
      });
    });

    Transactions.getTransactionByOperationId(id).then(t => {
      this.setState({
        transaction: t,
        operation: t.operations.single(x => x.id == id),
        reimbursementsLoading: false,
      })
    })
  }

  componentWillUnmount() {

  }

}


Reimbursements.propTypes = {

}


export default withRouter(Reimbursements);