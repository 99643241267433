import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button from '../common/button';
import Panel from '../common/panel';
import PropTypes from 'prop-types';
import PlanningService from '../services/planning-service';
import { validateAll } from '../../util';
import IncomesForm, { incomeSchema } from './incomes-form';
import Page from '../common/page';

import './recurring-incomes.scss';

class RecuringIncomes extends Component {

  constructor(props) {
    super(props);
    this.state = {
      incomes: [],
      incomesSubmitted: false,
      incomesErrors: null,
      incomesLoaded: false,
      savingIncomes: false,
    };
  }

  render() {
    return (
      <Page className="recurring-incomes-c" loading={!this.state.incomesLoaded} renderBody={() => this.renderBody()} header="recurring-incomes.header" data={true}></Page>
    );
  }

  renderBody() {
    return <IncomesForm incomes={this.state.incomes} onSubmit={(incomes)=> this.saveIncomes(incomes)}></IncomesForm>
  }

  saveIncomes() {
    return new Promise((resolve, reject) => {
      PlanningService.updateRecurringIncomes(this.state.incomes).then(() => {
        resolve();
        this.loadIncomes()
      }).catch(() => {
        reject();
      });
    })

  }
  loadIncomes() {
    PlanningService.getRecurringIncomes()
      .then(is => this.setState({ incomes: is, incomesLoaded: true }));
  }
  componentDidMount() {
    this.loadIncomes();
  }



}


RecuringIncomes.propTypes = {

}


export default RecuringIncomes;