import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';

import './loading-screen.scss';

class LoadingScreen extends Component {
  render() {
    return (
      <div className="loading-screen-c">
        <span className="fas fa-cog fa-spin"></span>
      </div>
    );
  }


}

export default LoadingScreen;