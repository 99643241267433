import "./income-stream-statements.scss";

import React, { useState, useEffect } from "react";
import MonthInput from "./../common/month-input";
import Calendar, { IsoCalendar } from "./../services/calendar-service";
import DocumentsService from "../services/documents-service";
import predef from "../../predef";
import { guid } from "../../util";
import { TBody, THead, TRow, Table, TColumn } from "../common/table";
import FileDropZone from "../common/file-drop-zone";
import LoadingPlaceholder from "../common/loading-placeholder";
import Button, { Save, Edit } from "../common/button";
import Localization from "../services/localization-service";
import Loader from "../common/loader";
var lastSelectedMonth = Calendar.todayIso;
import Textbox from "../common/textbox";
import SplitLink from "./../common/split-link";
import DocumentCategorySelect from "../common/document-category-select";
import Bus from '../../bus'
import Toastr from '../services/toastr-srevice'

function FileName({ name }) {
  if (!name) return name;

  let newName = name;

  return (
    <div style={{ textOverflow: "ellipsis" }} title={name}>
      {newName}
    </div>
  );
}

function RowActions({ categoryFilter, categories, link, onChange }) {
  const actions = categories
    .filter((x) => !categoryFilter.hasCategory || categoryFilter.category != x)
    .map((c) => {
      const doc = link;
      const cat = c;
      return {
        rawLabel:
          Localization.formatMessage("income-stream-statements.move-to") +
          " " +
          cat,
        onClick: () => {
          DocumentsService.updateLink({
            name: doc.name,
            description: doc.description,
            category: cat,
            year: doc.year,
            month: doc.month,
            date: doc.date,
            id: doc.id,
          }).then(() => onChange());
        },
      };
    });

  if (categoryFilter.hasCategory) {
    actions.push({
      label: "income-stream-statements.remove-category",
      onClick: () => {
        DocumentsService.updateLink({
          name: link.name,
          description: link.description,
          category: null,
          year: link.year,
          month: link.month,
          date: link.date,
          id: link.id,
        }).then(() => onChange());
      },
    });
  }

  return <SplitLink items={actions}></SplitLink>;
}

const Row = ({
  index,
  link,
  onChange,
  categories,
  categoryFilter,
  showCategory,
  ...rest
}) => {
  const [isDetaching, setIsDetaching] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [name, setName] = useState(link.name);
  const [description, setDescription] = useState(link.description);
  const [category, setCategory] = useState(link.category);
  const cancelEdit = () => {
    setIsEditMode(false);
    setName(link.name);
    setDescription(link.description);
  };

  const saveRow = () => {
    if (name != null && name != "") {
      setIsSaving(true);
      DocumentsService.updateLink({
        name: name,
        description: description,
        category: category,
        year: link.year,
        month: link.month,
        date: link.date,
        id: link.id,
      })
        .then(() => {
          setIsEditMode(false);
          setIsSaving(false);
          onChange();
        })
        .catch(() => setIsSaving(false));
    }
  };

  if (!isEditMode)
    return (
      <tr>
        <td>{index}</td>
        <td className="name-cell">
          <Button link documentName={link.name} documentId={link.documentId}>
            <FileName name={link.name}></FileName>
          </Button>
        </td>
        <td>{link.description}</td>
        {showCategory ? <td>{link.category}</td> : null}
        <td className="actions-cell">
          <div className="row-actions">
            <Edit onClick={() => setIsEditMode(true)}></Edit>
            {isDetaching ? (
              <Loader show={true}></Loader>
            ) : (
              <>
                {" "}
                <Button
                  onClick={() => {
                    setIsDetaching(true);
                    DocumentsService.detachDocument(link)
                      .then(() => {
                        setIsDetaching(false);
                        onChange();
                      })
                      .catch(() => setIsDetaching(false));
                  }}
                  link
                  danger
                  label="documents-grid.detach"
                ></Button>
                <RowActions
                  link={link}
                  categoryFilter={categoryFilter}
                  categories={categories}
                  onChange={() => onChange()}
                ></RowActions>
              </>
            )}
          </div>
        </td>
      </tr>
    );

  return (
    <tr>
      <td>{index}</td>
      <td>
        <Textbox
          hasError={name == null || name == ""}
          errorMessage="common.field-is-required"
          value={name || ""}
          onChange={(v) => setName(v)}
        ></Textbox>
      </td>
      <td>
        <Textbox
          value={description || ""}
          onChange={(v) => setDescription(v)}
        ></Textbox>
      </td>
      {showCategory ? (
        <td>
          {" "}
          <DocumentCategorySelect
            docRef={link.ref}
            value={category || ""}
            onChange={(v) => setCategory(v)}
          ></DocumentCategorySelect>
        </td>
      ) : null}
      <td>
        {isSaving ? (
          <Loader></Loader>
        ) : (
          <>
            <Button link label="common.save" onClick={() => saveRow()}></Button>
            <Button
              link
              danger
              label="common.cancel"
              onClick={() => cancelEdit()}
            ></Button>
          </>
        )}
      </td>
    </tr>
  );
};
function Documents({
  categories,
  categoryFilter,
  month,
  refId,
  id,
  setId,
  showCategory = false,
}) {
  const [data, setData] = useState();
  const onChange = () => setId(guid());
  const onUploadFinished = () => {
    Toastr.success("file-drop-zone.upload-success");
    Bus.publish(
      predef.events.document.documentsChanged,
      predef.documentRefs.incomeStream
    );
    onChange()
  };
  useEffect(() => {
    DocumentsService.getPagedDocuments({
      filter: {
        ref: predef.documentRefs.incomeStream,
        refId: refId,
        category: categoryFilter.category,
        categoryNotIn: categoryFilter.other ? categories : undefined,
        dateFrom: IsoCalendar.startOfMonth(month),
        dateTo: IsoCalendar.endOfMonth(month),
      },
    }).then((docs) => {
      setData(docs);
    });
  }, [id, month]);

  if (!data) return null;
  return (
    <>
      <h2>
        {!categoryFilter.other
          ? categoryFilter.category
          : Localization.formatMessage("income-stream-statements.other")}
      </h2>
      <div className="documents">
        <FileDropZone
          onUploadFinished={onUploadFinished}
          endpoint={predef.endpoints.document.index}
          formData={{
            link: {
              ref: predef.documentRefs.incomeStream,
              refId: refId,
              category: categoryFilter.category,
              month: IsoCalendar.getMonth(month),
              year: IsoCalendar.getYear(month),
            },
          }}
        ></FileDropZone>
        {data ? (
          <div>
            <Table>
              <THead>
                <TColumn className="n-col len-40">#</TColumn>
                <TColumn className="name-col" header="common.name"></TColumn>
                <TColumn
                  className="desc-col "
                  header="common.description"
                ></TColumn>
                {showCategory ? (
                  <TColumn header="common.category"></TColumn>
                ) : null}
                <TColumn className="actions-col len-160"></TColumn>
              </THead>

              <TBody>
                {data.items?.map((docLink, i) => {
                  return (
                    <Row
                      onChange={() => onChange()}
                      docRef={predef.documentRefs.incomeStream}
                      key={docLink.id}
                      index={i + 1}
                      link={docLink}
                      categories={categories}
                      categoryFilter={categoryFilter}
                      showCategory={showCategory}
                    ></Row>
                  );
                })}
              </TBody>
            </Table>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default function IncomeStreamStatements(props) {
  const [month, setMonth] = useState(lastSelectedMonth);
  const [id, setId] = useState(guid());
  const refId = props.match?.params?.id;
  const categories = ["Koszty", "Przychody", "Uzupełniające"];
  return (
    <div className="income-stream-statements-c">
      <div className="header">
        <MonthInput
          value={month}
          onChange={(v) => {
            lastSelectedMonth = v;
            setMonth(v);
          }}
        ></MonthInput>
        <Button
          fa="download"
          label="common.download-all"
          onClick={() => {
            const filter = {
              ref: predef.documentRefs.incomeStream,
              refId: refId,
              dateFrom: IsoCalendar.startOfMonth(month),
              dateTo: IsoCalendar.endOfMonth(month),
              groupBy: "category",
            };
            DocumentsService.download(
              filter,
              Localization.formatDateMMMMYYYY(month)
            );
          }}
        ></Button>
      </div>

      {categories.map((c) => {
        return (
          <Documents
            key={c}
            categories={categories}
            categoryFilter={{ other: false, category: c }}
            refId={refId}
            month={month}
            id={id}
            setId={setId}
          ></Documents>
        );
      })}
      <Documents
        categories={categories}
        categoryFilter={{ other: true }}
        refId={refId}
        month={month}
        id={id}
        setId={setId}
        showCategory
      ></Documents>
    </div>
  );
}
