import classNames from 'classnames/bind';
import React, { Component } from 'react';
import Button from '../common/button';
import SideBarStateService from '../state-services/side-bar-state-service';
import './side-bar-header.scss';


class SideBarHeader extends Component {

  constructor(props) {
    super(props);
    this.state = { isPinned: true, isOpen: true };
  }

  render() {
    return <div className={classNames('side-bar-header-c', { open: this.state.isOpen, closed: !this.state.isOpen })}>
      {this.state.isOpen ?
        <React.Fragment>
          <Button onClick={() => SideBarStateService.close()} link fal="times-circle"></Button>
          <Button onClick={() => SideBarStateService.togglePinned()} link {...this.getPinClass()}></Button>
        </React.Fragment> : <React.Fragment>
          <Button link fal="bars" onClick={() => this.state.isOpen ? SideBarStateService.close() : SideBarStateService.open()} ></Button>
        </React.Fragment>}
    </div>
  }

  getPinClass() {
    if (this.state.isPinned) {
      return { fad: "thumbtack " };
    }

    return { fal: "thumbtack fa-rotate-90" }
  }


  componentDidMount() {
    this.sbss = SideBarStateService.state.subscribe(s => this.setState(s));
  }

  componentWillUnmount() {
    this.sbss.unsubscribe();
  }

}


SideBarHeader.propTypes = {

}


export default SideBarHeader;