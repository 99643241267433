import React , {Component} from 'react';
import BudgetsNamesStore from '../stores/budgets-names-store';
import './budget-filter.scss';
import TextSuggestInput from './text-suggest-input';


class BudgetFilter extends Component {

  constructor(props) {
    super(props);
    this.state = { options: null };
  }

  render() {
    return <div className="budget-filter-c">
      <TextSuggestInput {...this.props} options={this.state.options}></TextSuggestInput>
    </div>
  }

  componentDidMount() {
    this.sub = BudgetsNamesStore.budgetsNames.subscribe(bns => {
      if (bns == null) return;
      this.setState({ categories: bns, options: bns })
    });
  }

  componentWillUnmount() {
    this.sub.unsubscribe();
  }

}


BudgetFilter.propTypes = {

}


export default BudgetFilter;