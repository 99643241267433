import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import {  guid } from '../../util';
import Localization from '../services/localization-service';
import Calendar from '../services/calendar-service';
import Button from './button'
import moment from 'moment';
import classNames from 'classnames/bind';

import './month-input.scss';

class MonthInput extends Component {
  constructor(props) {
    super(props);
    this.state = { id: 'date-' + guid() };
    this.openCalendar = this.openCalendar.bind(this);
  }

  openCalendar() {
    this.$.datepicker('show')
  }

  onChange() {
    let date = Calendar.toIsoDate(this.getDate(), null);

    if (date === this.props.value) return;

    this.props.onChange(date);
  }

  setMonth(delta) {
    let date = this.getDate();
    if (date == null) return;

    let mdate = moment(date);

    mdate = mdate.add(delta, 'month');

    this.setDate(mdate.toDate());
  }

  render() {
    this.setDate(this.props.value, true)
    return <div className={classNames("fc month-input-c",  this.props.name + "-field", "form-group")}>
      {this.props.label != null ? <label ><FormattedMessage id={this.props.label}></FormattedMessage></label> : null}
      <div className="input-group ">
        <Button fa="arrow-left" className="prev-month" onClick={() => this.setMonth(-1)} outline></Button><input className="form-control" type="text" id={this.state.id}></input><Button fa="arrow-right" outline className="next-month" onClick={() => this.setMonth(1)}></Button>
      </div>
    </div>
  }

  get $() {
    return $('#' + this.state.id);
  }

  setDate(date, noOnChange) {
    if (typeof date === 'string') {
      date = Calendar.fromIsoDate(date);
    }

    if (noOnChange) {
      this.removeOnChange();
    }
    this.$.datepicker('setDate', date);
    if (noOnChange) {
      this.addOnChange();
    }
  }

  getDate() {
    return this.$.datepicker('getDate');
  }

  createDatePicker(date, noOnChange) {
    let options = {
      autoclose: true,
      language: Localization.lang,
      format: Localization.formatting.datePicker.monthAndYearFormat,
      minViewMode: 1,
    }
    this.$.datepicker(options);
    this.addOnChange();
    this.setDate(date, noOnChange);
  }

  removeOnChange() {
    this.$.datepicker().off('changeDate');
  }

  addOnChange() {
    this.$.datepicker().on('changeDate', () => this.onChange())
  }

  _onLanguageChanged(lang) {
    let date = this.getDate();
    this.$.datepicker('destroy');
    this.createDatePicker(date, true);
  }

  componentDidMount() {
    this.busSub = Bus.subscribe(this);
    this.createDatePicker(this.props.value);
  }

  componentWillUnmount() {
    this.busSub.unsubscribe();
  }
}


MonthInput.propTypes = {
  onChange: PropTypes.func.isRequired
}


export default MonthInput;