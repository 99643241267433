import React, { Component } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FormattedMessage } from 'react-intl';
import { guid } from '../../util';
import Localization from '../services/localization-service';
import './autocomplete-input.scss';
import Loader from './loader';
import classNames from 'classnames/bind';

export default class AutocompleteInput extends Component {
    constructor(props) {
        super(props);
        this.state = { options: null, isLoading: true, id: guid(), isMenuOpen: false, value: [] };
        this.onChange = this.onChange.bind(this);
    }

    onChange(selected) {
        if (selected == null && this.props.multiple) {
            this.props.onChange([]);
        }

        if (selected == null && !this.props.multiple) {
            this.props.onChange('');
        }

        if (!this.props.multiple) {
            if (Array.isArray(selected)) {
                selected = selected[0];
            }

            this.props.onChange(this.getValue(selected));
        }
        else {
            if (Array.isArray(selected)) {
                let values = selected.select(x => this.getValue(x));
                this.props.onChange(values);
            }
            else {
                this.props.onChange([this.getValue(selected)]);
            }
        }
    }

    getValue(item) {
        if (typeof item === 'object') {
            return item.label;
        }

        return item;
    }

    getFilteredOptions() {
        if (this.props.filter == null) {
            return this.state.allOptions.select(x => x.suggestion);
        }

        var filtered = this.props.filter(this.state.allOptions);

        return filtered.select(x => x.suggestion);

    }

    onKeyDown(e) {
        if (!this.state.isMenuOpen && e.keyCode === 13 && this.props.onSubmit != null) {
            this.props.onSubmit();
        }
    }

    onKeyUp(e) {
        if (this.props.multiple) return;

        this.onChange(e.target.value)
    }

    render() {
        let options = this.state.allOptions == null ? [] : this.getFilteredOptions();
        return <div className={classNames(
            "fc autocomplete-fc",
            `${this.props.name}-field`,
            "form-group",
            {
                'has-error': this.props.hasError,
                'no-label': this.props.label == null
            })}>
            {this.props.label != null ? <label ><FormattedMessage id={this.props.label}></FormattedMessage></label> : null}
            <Typeahead
                onChange={this.onChange}
                onInputChange={(selected) => {
                    if (this.props.multiple) return;
                    this.onChange(selected);
                }}
                allowNew={this.props.multiple}
                onKeyDown={(e) => this.onKeyDown(e)}
                onKeyUp={(e) => this.onKeyUp(e)}
                onMenuToggle={(isOpen) => this.setState({ isMenuOpen: isOpen })}
                options={options}
                id={this.state.id}
                positionFixed={true}
                emptyLabel={Localization.formatMessage('autocomplete-input.no-matches')}
                newSelectionPrefix={Localization.formatMessage(this.props.newSelectionPrefix ? this.props.newSelectionPrefix : 'autocomplete-input.new-selection')}
                paginationText={Localization.formatMessage('autocomplete-input.display-more')}
                minLength={1}
                selected={this.state.value}
                multiple={this.props.multiple}
            >
            </Typeahead>
            <Loader show={this.state.isLoading}></Loader>
            {
                this.props.hasError
                    ? <div className="error-message invalid-feedback">
                        <FormattedMessage id={this.props.errorMessage}></FormattedMessage>
                    </div>
                    : null
            }
        </div>
    }

    componentDidUpdate(prevProps, prevState) {
        let val = this.propsValueToInputValue(this.props);

        if (this.state.value == null || val.length != this.state.value.length) {
            this.setState({ value: val });
            return;
        }

        for (let i = 0; i < val.length; i++) {
            if (val[i] != this.state.value[i]) {
                this.setState({ value: val });
                return;
            }
        }
    }

    propsValueToInputValue(props) {
        return props.value == null ? [] : (Array.isArray(props.value) ? props.value : [props.value])
    }

    componentDidMount() {
        this.setState({ value: this.propsValueToInputValue(this.props) }, () => {
            this.optionsSub = this.props.options.subscribe(opts => {
                if (opts == null) {
                    this.setState({ allOptions: null, isLoading: true });
                    return;
                }

                this.setState({ allOptions: opts, isLoading: false });
            });
        });
    }

    componentWillUnmount() {
        if (this.optionsSub != null)
            this.optionsSub.unsubscribe();
    }

}
