import React, { Component } from 'react';
import { deepCopy } from '../../util';
import Button from '../common/button';
import ControlledForm from '../common/controlled-form';
import Panel from '../common/panel';
import Textbox from '../common/textbox';
import FlagsService from '../services/flags-service';
import './transaction-filter.scss';
import { FormattedMessage } from 'react-intl';

class TransactionFilter extends Component {

  constructor(props) {
    super(props);
    this.state = { mode: 'quick', quickFilter: '' };
  }

  componentDidMount() {
    this.setState({ advancedFilterValues: deepCopy(this.props.initialFilter) });
  }

  advancedFilterChanged(values) {
    if (values && values.isExpense && this.state.advancedFilterValues && !this.state.advancedFilterValues.isExpense) {
      values.isIncome = false;
    }
    if (values && values.isIncome && this.state.advancedFilterValues && !this.state.advancedFilterValues.isIncome) {
      values.isExpense = false;
    }

    this.setState({
      advancedFilterValues: values
    });
  }

  render() {
    return <Panel className="transaction-filter-c">
      {this.state.mode == 'quick' ? this.renderQuickFilter() : this.renderAdvancedFilter()}
    </Panel>
  }

  search(search) {

    let filter = this.props.initialFilter == null ? {} : JSON.parse(JSON.stringify(this.props.initialFilter));
    for (let key in search) {
      filter[key] = search[key];
    }

    if (filter.date != null) {
      filter.dateFrom = filter.date.dateFrom;
      filter.dateTo = filter.date.dateTo;
      delete filter.date;
    }

    if (filter.amountFrom != null && filter.amountTo != null && filter.amountTo < filter.amountFrom) {
      let to = filter.amountTo;
      filter.amountTo = filter.amountFrom;
      filter.amountFrom = to;
    }

    this.props.onFilterChanged(filter);
  }

  advancedSearch() {
    let filter = deepCopy(this.state.advancedFilterValues);
    if (filter != null && filter.amountFrom != null && filter.amountTo != null && filter.amountTo < filter.amountFrom) {
      let to = filter.amountTo;
      filter.amountTo = filter.amountFrom;
      filter.amountFrom = to;

      this.setState({ advancedFilterValues: filter })
    }
    this.search(filter);

  }

  renderQuickFilter() {
    return <div className="quick-filter">
      <div className="search-box">
        <Textbox value={this.state.quickFilter} onChange={(v) => this.setState({ quickFilter: v })} placeholder="transaction-filter.quick-filter-placeholder" onSubmit={() => this.search({ search: this.state.quickFilter })}></Textbox>

      </div>

      <div className="filter-btn">
        <Button outline fal="filter" onClick={() => this.search({ search: this.state.quickFilter })} label="transaction-filter.search"></Button>
      </div>
      <div className="advanced">
        <div className="advanced-filter-btn" onClick={() => this.setMode('advanced')}>
          <span className="advaced-icon fal fa-sliders-h"></span>
          <FormattedMessage id="transaction-filter.advanced-filter"></FormattedMessage>
        </div>
      </div>
    </div>
  }


  setMode(mode) {
    if (mode == 'advanced') {
      this.advancedSearch();
    }
    else {
      this.search({ search: this.state.quickFilter });
    }

    this.setState({ mode: mode })
  }
  renderAdvancedFilter() {
    let advancedFilter = this.getAdvancedFilterForm()
    return <div className="advanced-filter filter-panel">
      <Button link label="transaction-filter.quick-filter" fa="arrow-left" onClick={() => this.setMode('quick')}></Button>
      <ControlledForm onSubmit={() => this.advancedSearch()} onChange={(vs) => this.advancedFilterChanged(vs)} values={this.state.advancedFilterValues} definition={advancedFilter}>
        <Button onClick={() => this.advancedSearch()} label="common.filter" fa="filter"></Button>
        <Button onClick={() => this.setState({ advancedFilterValues: deepCopy(this.props.initialFilter) })} outline className="clear-btn" label="common.clear" fa="times"></Button>
      </ControlledForm>
    </div >
  }


  getAdvancedFilterForm() {
    let currentValues = this.state.advancedFilterValues || {};
    let showCurrency = (this.props.disabled?.currency == null || !this.props.disabled.currency) &&
      (this.props.disabled?.accountId == null || !this.props.disabled.accountId);

    return {
      groups: [
        {
          name: 'main', fields: [{
            type: 'currency',
            name: 'currency',
            label: 'transaction-filter.currency',
            onlyUsed: true,
            hidden: !showCurrency,
            layout: 'c c-sm-8 c-md-6 c-lg-4'
          },
          {
            type: 'account',
            name: 'accountId',
            label: 'transaction-filter.account',
            currencyFilter: currentValues.currency,
            hidden: this.props.disabled && this.props.disabled.accountId,
            layout: 'c c-sm-16 c-md-18 c-lg-20'
          },
          {
            type: 'date-range',
            name: 'date',
            label: 'common.date-range',
            layout: 'c c-sm-12 c-lg-8'
          },

          {
            type: 'text',
            name: 'description',
            label: 'transaction-filter.description',
            layout: 'c c-sm-12 c-lg-8',
          },
          {
            type: 'category',
            name: 'category',
            label: 'transaction-filter.category',
            layout: 'c c-sm-12 c-lg-8',
            allowNew: true
          },
          {
            type: 'number',
            name: 'amountFrom',
            label: 'transaction-filter.amount-from',
            allowNegative: true,
            layout: 'c c-sm-12 c-lg-8'
          },
          {
            type: 'number',
            name: 'amountTo',
            label: 'transaction-filter.amount-to',
            allowNegative: true,
            layout: 'c c-sm-12 c-lg-8'
          },
          {
            type: 'budget-filter',
            name: 'budget',
            label: 'transaction-filter.budget',
            layout: 'c c-sm-12 c-lg-8',
            allowNew: true
          },
          {
            type: 'income-stream-select',
            name: 'incomeStreamId',
            label: 'common.income-stream',
            layout: 'c c-sm-12 c-lg-8',
            hidden: this.props.disabled?.incomeStreamId
          },
          {
            type: 'operation-tags',
            name: 'withOneOfTags',
            label: 'transaction-filter.one-of-tags',
            layout: 'c c-sm-12 c-lg-8',
          },
          {
            type: 'operation-tags',
            name: 'withAllOfTags',
            label: 'transaction-filter.all-of-tags',
            layout: 'c c-sm-12 c-lg-8',
          },
          {
            type: 'real-estate-select',
            name: 'realEstateId',
            label: 'transaction-filter.real-estate',
            layout: 'c c-sm-12 c-lg-8',
            hidden: !FlagsService.hasRealEstateFeatureAccessFlag || (this.props.disabled?.realEstateId),
            isClearable: true
          },
          {
            type: 'residents-select',
            name: 'residentId',
            label: 'transaction-filter.resident',
            layout: 'c c-sm-12 c-lg-8',
            hidden: !FlagsService.hasRealEstateFeatureAccessFlag || (this.props.disabled?.realEstateId),
            fetchAll: true
          }
          ]
        },
        {
          name: 'switches', fields: [{
            type: 'switch',
            name: 'isIncome',
            text: 'transaction-filter.income',
            icon: 'fa fa-plus',
          },
          {
            type: 'switch',
            name: 'isExpense',
            text: 'transaction-filter.expense',
            icon: 'fa fa-minus',
          },
          {
            type: 'switch',
            name: 'isPinned',
            text: 'transaction-filter.pinned',
            icon: 'fa fa-thumbtack fa-rotate-90 '
          },
          {
            type: 'switch',
            name: 'isReimbursable',
            text: 'transaction-filter.is-reimbursable',
            icon: 'fa fa-undo',
            disabled: currentValues.isIncome
          },
          {
            type: 'switch',
            name: 'isReimbursement',
            text: 'transaction-filter.is-reimbursement',
            icon: 'fa fa-undo fa-rotate-180',
            disabled: currentValues.isExpense,
          },
          {
            type: 'switch',
            name: 'isIncomeCost',
            text: 'transaction-filter.is-income-cost',
            icon: 'fa fa-taxi',
            disabled: currentValues.isIncome
          },
          {
            type: 'switch',
            name: 'hasNoBudget',
            text: 'transaction-filter.has-no-budget',
            disabled: (currentValues.budget != null && currentValues.budget != '') || currentValues.isIncome
          },
          {
            type: 'switch',
            name: 'includeTransfers',
            icon: 'fa fa-exchange',
            text: 'transaction-filter.include-transfers'
          }]
        }
      ]
    };
  }
}

export default TransactionFilter;