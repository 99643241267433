import React, { Component } from 'react';
import predef from '../../predef';
import Button from '../common/button';
import Grid from '../common/grid/grid';
import Page from '../common/page';
import TransactionTemplatesStore from '../stores/transaction-templates-store';
import TransactionTemplatesService from '../services/transaction-templates-service';
import './transaction-templates.scss';
import Json from '../common/json';

class TransactionTemplates extends Component {

  constructor(props) {
    super(props);
    this.state = { isLoading: true, templates: null };
    this.renderBody = this.renderBody.bind(this);
  }

  render() {
    return <React.Fragment>
      <Page
        className="transaction-templates-c"
        header="transaction-templates.header"
        loading={this.state.isLoading}
        data={this.state.templates}
        renderBody={this.renderBody}
        actions={<Button path={predef.routes.transactionTemplate.add} label="transaction-templates.add" fa="plus"></Button>}
      >
      </Page>
    </React.Fragment>
  }

  renderBody() {

    return <React.Fragment>
      <Grid id="transaction-templates" pageSize={20} data={this.state.templates} definition={[
        {
          show: true,
          headerClasses: "len-300",
          header: "common.name",
          renderCell: (tt) => tt.name,
          sortable: true,
          value: (tt) => tt.name
        },
        {
          show: true,
          headerClasses: "len-60",
          header: "common.currency",
          renderCell: (tt) => tt.currency,
          sortable: true,
          value: (tt) => tt.currency
        },
        {
          show: true,
          headerClasses: "len-200",
          header: "common.category",
          renderCell: (tt) => tt.category,
          sortable: true,
          value: (tt) => tt.category
        },
        {
          show: true,
          headerClasses: "len-160",
          name: 'actions',
          renderCell: (tt) =>
            <React.Fragment>
              <Button link label="common.edit" path={predef.routes.transactionTemplate.edit(tt.id)}></Button>
              <Button link label="common.delete" danger command={{
                command: () => TransactionTemplatesService.deleteTemplate(tt.id),
                successEvent: predef.events.transactionTemplate.transactionTemplatesChanged
              }}></Button>
            </React.Fragment>,
          sortable: false
        },
      ]}>
      </Grid>
    </React.Fragment>
  }

  componentDidMount() {
    this.sub = TransactionTemplatesStore.transactionTemplates.subscribe(tt => {
      this.setState({ templates: tt, isLoading: tt == null });
    })
  }

  componentWillUnmount() {
    this.sub.unsubscribe();
  }

}

export default TransactionTemplates;