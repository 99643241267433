import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button from '../common/button';
import Panel from '../common/panel';
import PropTypes from 'prop-types';
import Page from '../common/page';
import classNames from 'classnames/bind';
import AccountsStore from '../stores/accounts-store';
import Loader from '../common/loader';
import Json from '../common/json';
import { Table, TColumn, THead, TBody } from '../common/table';
import AccountTypesStore from '../stores/account-types-store';
import './accounts-balances-report.scss';
import Number from '../common/number';
import AccountTypeBadge from '../common/account-type-badge';
import LoadingPlaceholder from '../common/loading-placeholder';

class AccountsBalancesReport extends Component {

  constructor(props) {
    super(props);
    this.state = { isLoading: true, accounts: null };
  }

  render() {
    if (!this.state.isLoading) {
      let accounts = this.state.accounts.groupByCurrency();
      return <div className="accounts-balances-report-c">
        <h3><FormattedMessage id="accounts-balances-report.header"></FormattedMessage></h3>
        <Panel >
          <Table >
            {accounts.map(ag => <React.Fragment key={ag.key}>
              <THead>
                <TColumn className="currency" className="len-200">
                  {ag.key}
                </TColumn>
                <TColumn></TColumn>
              </THead>
              <TBody>
                {this.renderAccounts(ag.values)}
              </TBody>

            </React.Fragment>)}
          </Table>
        </Panel>
      </div>
    }
    else {
      return <LoadingPlaceholder></LoadingPlaceholder>
    }
  }

  renderAccounts(accounts) {
    return accounts.groupBy(a => a.type).orderByAccountType(x => x.key).map(ag => {
      return <React.Fragment key={'ag' + ag.key}>
        <tr className="type"  >
          <td colSpan="2"><AccountTypeBadge typeId={ag.key}></AccountTypeBadge>   </td></tr>
        {ag.values.map(a => {
          return <tr className="account" key={a.id}>
            <td>
              <Button link path={predef.routes.accounts.view(a.id)}>{a.name}</Button>
            </td>
            <td className="number-cell"><Number value={a.balance} currency={a.currency}> </Number></td>
          </tr>
        })}
      </React.Fragment>
    })
  }



  componentDidMount() {
    this.accountsSub = AccountsStore.accounts.subscribe((acs) => {
      this.setState({ accounts: acs }, () => {
        if (this.state.accounts != null && this.state.accountTypes != null) {
          this.setState({ isLoading: false })
        }
      })
    });

    this.typesSub = AccountTypesStore.accountTypes.subscribe(at => {
      this.setState({ accountTypes: at }, () => {
        if (this.state.accounts != null && this.state.accountTypes != null) {
          this.setState({ isLoading: false })
        }
      })
    });
  }

  componentWillUnmount() {
    this.accountsSub.unsubscribe();
    this.typesSub.unsubscribe();
  }

}


AccountsBalancesReport.propTypes = {

}


export default AccountsBalancesReport;