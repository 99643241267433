import React from 'react';
import predef from '../../predef';
import { routeParamId } from '../../util';
import Notes from '../notes/notes';
import './income-stream-notes.scss';

const IncomeStreamNotes = (props) => {
  let id = routeParamId(props);

  return <div className="income-stream-notes-c">
    <Notes noteRef={predef.noteRefs.incomeStream} refId={id}></Notes>
  </div>
}


IncomeStreamNotes.propTypes = {

}


export default IncomeStreamNotes;