import React from 'react';
import Localization from '../services/localization-service';
import './frequency-select.scss';
import { BehaviorSubject } from 'rxjs';
import Select from '../common/select';
const getFreq = () => {
  let freq = [];

  freq.push({ label: Localization.formatMessage('frequency-select.day'), value: 1 });
  freq.push({ label: Localization.formatMessage('frequency-select.week'), value: 2 });
  freq.push({ label: Localization.formatMessage('frequency-select.month'), value: 3 });
  freq.push({ label: Localization.formatMessage('frequency-select.year'), value: 4 });

  return freq;
}

var options;

const FrequencySelect = (props) => {
  if (options == null) {
    options = new BehaviorSubject(getFreq());
  }
  return <Select className="frequency-select-c" options={options.asObservable()} {...props}></Select>
}


FrequencySelect.propTypes = {

}


export default FrequencySelect;