import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button, { Cancel, Remove, Save, Edit } from '../common/button';
import Panel from '../common/panel';
import PropTypes from 'prop-types';
import Page from '../common/page';
import classNames from 'classnames/bind';
import { H3, H2 } from '../common/headers';
import Form from '../common/form';
import Api from '../../api';
import ControlledForm from '../common/controlled-form';
import UserService from '../services/user-service';

import './user.scss';
import ServerErrors from '../common/server-errors';

class User extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <div className="user-c">
      <H2 header="user.change-password"></H2>
      <ServerErrors errors={this.state.changePasswordErrors}></ServerErrors>

      <Panel>

        <Form definition={{
          fields: [{
            type: 'password',
            name: 'currentPassword',
            label: 'user.current-password',
            layout: 'c'
          }, {
            type: 'password',
            name: 'newPassword',
            label: 'user.new-password',
            layout: 'c'
          }, {
            type: 'password',
            name: 'newPasswordConfirmation',
            label: 'user.new-password-confirmation',
            layout: 'c'
          }]
        }
        } onSubmit={(v) => this.changePassword(v)}>

        </Form>
      </Panel>

      <H2 header="user.change-login"></H2>
      <ServerErrors errors={this.state.changeLoginErrors}></ServerErrors>

      <Panel>

        <Form definition={{
          fields: [{
            type: 'password',
            name: 'currentPassword',
            label: 'user.current-password',
            layout: 'c'
          }, {
            type: 'text',
            name: 'newLogin',
            label: 'user.new-login',
            layout: 'c'
          }]
        }
        } onSubmit={(v) => this.changeLogin(v)}>

        </Form>
      </Panel>
    </div>
  }

  changePassword(model) {
    this.setState({changePasswordErrors: null},()=> {
      UserService.changePassword(model).catch(x => this.setState({ changePasswordErrors: x }));
    });
  }

  changeLogin(model) {
    this.setState({changeLoginErrors: null},()=>{
      UserService.changeLogin(model).catch(x => this.setState({ changeLoginErrors: x }));
    });
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

}


User.propTypes = {

}


export default User;