import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Bus from '../../bus';
import predef from '../../predef';
import Button from '../common/button';
import Panel from '../common/panel';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { H3 } from '../common/headers';
import Api from '../../api'
import './profile-menu.scss';
import { guid, hoverAndTouchDropDown, deepCopy } from '../../util';
import { createHashHistory } from 'history';
const history = createHashHistory();



class ProfileMenu extends Component {

  constructor(props) {
    super(props);
    this.state = { id: "d" + guid(), contentId: "content-" + guid() };
  }

  render() {
    let user = Api.getUser();
    return <div id={this.state.id} className="profile-menu-c ">
      <div className="dropdown">
        <a data-toggle="dropdown" >{user.login} <span className="fa fa-chevron-down"></span></a>
        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
          {user.availableTenants.map(t => {
            return <a key={t.id} onClick={() => Api.switchTenant(t.id)} className={classNames('item', { 'active': t.id == Api.currentTenant() })}><span className={classNames('fa-database fad')}></span> {t.name == 'tenant.primary' ? <FormattedMessage id="tenant.private"></FormattedMessage> : t.name}</a>
          })}
          <hr />
          <a onClick={() => history.push(predef.routes.userSettings)} className="item"><span className="fad fa-cogs"></span> <FormattedMessage id="profile-menu.user-profile-settings"></FormattedMessage></a>
          <a onClick={() => history.push(predef.routes.user)} className="item"><span className="fad fa-user-cog"></span> <FormattedMessage id="profile-menu.user-settings"></FormattedMessage></a>
          <a onClick={() => Api.logout()} className="item"><span className="fad fa-sign-out"></span> <FormattedMessage id="profile-menu.logout"></FormattedMessage></a>

        </div>
      </div>
    </div >
  }

  componentDidMount() {
    hoverAndTouchDropDown('.profile-menu-c');
    this.setState({ user: Api.getUser() });

    this.sub = Bus.subscribe(this);
  }

  _onUserLoginChanged(login) {
    var user = deepCopy(this.state.user);
    user.login = login;
    this.setState({ user: user });
  }
  componentWillUnmount() {
    this.sub.unsubscribe();
  }

}


ProfileMenu.propTypes = {

}


export default ProfileMenu;