import { FormattedMessage } from "react-intl";
import React from 'react';
import './table.scss';
import classNames from 'classnames/bind';

export const Table = ({ custom, children, className, autoWidth, fixed, tableStriped }) => <div className="table-c">
    {/* <table className={"table" + (className ? className : "") + (autoWidth ? " width-auto" : "") + (fixed ? " fixed" : "")}>{children}</table></div> */}
    <table className={classNames(className, {
        "table": !custom,
        "auto-width": autoWidth,
        fixed: fixed,
        "table-striped": (tableStriped == null && !custom) || tableStriped
    })}>{children}</table></div>
export const THead = ({ children }) => <thead><tr>{children}</tr></thead>
export const TColumn = ({ children, className, header, colSpan, onClick, ...rest }) => <th onClick={onClick} scope="column" colSpan={colSpan} className={className} {...rest} >
    {header != null ? <FormattedMessage id={header}></FormattedMessage> : null}
    {children}
</th>
export const TBody = (props) => <tbody>{props.children}</tbody>
export const ChangeOrderButtons = ({ moveUp, moveDown, i, total }) =>
    <span>
        {i != 0 ? <span className="change-order-buttons-c fa fa-arrow-up pointer" onClick={() => moveUp()}></span> : null}
        {i < total - 1 ? <span className="change-order-buttons-c fa fa-arrow-down pointer" onClick={() => moveDown()}></span> : null}
    </span>
