
const getReturnPath = (returnUrl) => {
    if (returnUrl != null) return btoa(returnUrl);
    if (window.location.hash.length > 0) return btoa(window.location.hash.substring(1));
    return '';
}


export default {
    events: {
        account: {
            accountAdded: 'accountAdded',
            accountUpdated: 'accountUpdated',
            accountDeleted: 'accountDeleted',
            accountRestored: 'accountRestored',
            accountsReordered: 'accountsReordered',
            accountsChanged: 'accountsChanged',
            accountsStoreUpdated: 'accountsStoreUpdated'
        },
        userSettings: {
            userSettingsUpdated: 'userSettingsUpdated',
            userSettingsStoreUpdated: 'userSettingsStoreUpdated'
        },
        localization: {
            languageChanged: 'languageChanged',
            languageSelected: 'languageSelected'
        },
        transaction: {
            added: 'transactionAdded',
            deleted: 'transactionDeleted',
            updated: 'transactionUpdated',
            transactionsChanged: 'transactionsChanged',
            reimbursablesChanged: 'reimbursablesChanged',
            categoriesStoreUpdated: 'categoriesStoreUpdated',
            operationTagsStoreUpdated: 'operationTagsStoreUpdated',
            reimbursablesStoreUpdated: 'reimbursablesStoreUpdated'
        },
        transfer: {
            added: 'transferAdded',
            updated: 'transferUpdated'
        },
        planning: {
            budgets: {
                updated: 'budgetsUpdated',
                budgetsNamesStoreUpdated: 'budgetsNamesStoreUpdated'
            },
            plannedIcomes: {
                updated: 'plannedIncomesUpdated'
            },
            recurringIncomes: {
                updated: 'recuringIncomesUpdated'

            },
            recurringBudgets: {
                updated: 'recurringBudgetsUpdated'
            },
            planCreated: 'planCreated',
            planDeleted: 'planDeleted'
        },
        asset: {
            assetsChanged: 'assetsChanged'
        },
        category: {
            categoriesChanged: 'categoriesChanged'
        },
        transactionTemplate: {
            transactionTemplatesChanged: 'transactionTemplatesChanged',
            transactionTemplatesStoreUpdated: 'transactionTemplatesStoreUpdated'
        },
        realEstates: {
            realEstatesChanged: 'realEstatesChanged',
            realEstatesStoreUpdated: 'realEstatesStoreUpdated'
        },
        document: {
            documentsChanged: 'documentsChanged',
            documentsStoreUpdated: 'documentsStoreUpdated'
        },
        incomeStreams: {
            incomeStreamsChanged: 'incomeStreamsChanged',
            incomeStreamDocumentsGroupsChanged: 'incomeStreamDocumentsGroupsChanged',
            incomeStreamsStoreUpdated: 'incomeStreamsStoreUpdated'
        },
        shell: {
            toggleSideBarPinned: "toggleSideBarPinned",
            closeSideBar: "closeSideBar"
        },
        documentCategory: {
            documentCategoriesUpdated: 'documentCategoriesUpdated'
        },
        note: {
            notesChanged: 'notesChanged'
        },
        resident: {
            residentsChanged: 'residentsChanged'
        },
        cashFlowMatrixReportConfig: {
            cashFlowMatrixReportConfigChanged: 'cashFlowMatrixReportConfigChanged'
        },
        futureIncome: {
            futureIncomesChanged: 'futureIncomesChanged'
        },
        futureExpense: {
            futureExpenseChanged: 'futureExpensesChanged'
        },
        recurringTransactions: {
            recurringTransactionsChanged: 'recurringTransactionsChanged'
        },
        recurringTodos: {
            recurringTodosUpdated: 'recurringTodosUpdated'
        },
        todos: {
            todosUpdated: 'todosUpdated'
        },
        investment: {
            investmentsChanged: 'investmentsChanged',
            investmentsStoreUpdated: 'investmentsStoreUpdated'
        },
        user: {
            userLoginChanged: 'userLoginChanged'
        }

    },

    queries: {
        accounts: 'accounts'
    },
    endpoints: {
        version: '/version',
        jwt: '/api/jwt',
        user: {
            register: '/api/user',
            changePassword: '/api/user/password',
            changeLogin: '/api/user/login'
        },
        account: {
            index: 'api/account',
            'delete': (id) => `api/account/${id}`,
            update: (id) => `api/account/${id}`,
            deleted: 'api/account/deleted',
            restore: (id) => `api/account/restore/${id}`,
            order: 'api/account/order',
            showHideOnSidebar: 'api/account/showHideOnSidebar'
        },
        userSettings: {
            index: 'api/userSettings',
            currenciesOrder: 'api/userSettings/currenciesorder',
            baseCurrency: (currency) => `api/userSettings/baseCurrency/${currency}`
        },
        category: {
            index: 'api/category',
            change: 'api/category/change',
            delete: 'api/category/delete'
        },
        plan: {
            budgetName: 'api/plan/budget/name',
            getBudgets: (year, month) => `api/plan/budget/${year}/${month}`,
            postBudgets: (year, month) => `api/plan/budget/${year}/${month}`,
            getPlannedIncomes: (year, month) => `api/plan/plannedincome/${year}/${month}`,
            postPlannedIncomes: (year, month) => `api/plan/plannedincome/${year}/${month}`,
            getRecurringIncomes: `api/plan/recuringplannedincome`,
            postRecurringIncomes: `api/plan/recuringplannedincome`,
            getRecurringBudgets: `api/plan/recuringbudget`,
            postRecurringBudgets: `api/plan/recuringbudget`,
            getPlan: (year, month) => `api/plan/${year}/${month}`,
            createPlan: (year, month) => `api/plan/${year}/${month}`,
            addRecurringBudgets: (year, month) => `api/plan/addRecurringBudgets/${year}/${month}`,
            addRecurringPlannedIncomes: (year, month) => `api/plan/addRecurringPlannedIncomes/${year}/${month}`
        },
        transaction: {
            query2: 'api/transaction/query2',
            index: 'api/transaction',
            delete: (id) => `api/transaction/${id}`,
            update: (id) => `api/transaction/${id}`,
            get: (id) => `api/transaction/${id}`,
            reimbursed: `api/transaction/reimbursed`,
            makeTransfer: `api/transaction/transfer`,
            editTransfer: `api/transaction/edittransfer`,
            notReimbursed: `api/transaction/notreimbursed`,
            reimbursableById: (id) => `api/transaction/reimbursables/${id}`,
            getTransactionByOperationId: (id) => `api/transaction/operation/${id}`,
            byId: (id) => `api/transaction/${id}`,
            updateTransactionDate: (id)=> `api/transaction/${id}/date`
        },
        report: {
            expensesByCategory: `api/report/expensesbycategory`,
            expenseIncomeStructure: `api/report/expensesincomestructure`,
            savingFactor: `api/report/savingFactor`,
            netValueHistory: `api/report/netvaluehistory`,
            indicators: `api/report/currentmonthindicators`,
            netValue: `api/report/netvalue`,
            budgetsInMonth: (year, month) => `api/report/budgetsinmonth/${year}/${month}`,
            incomeStreamsForMonth: (year, month) => `api/report/incomestreams/${year}/${month}`,
            incomeStreamIndicators: (year, month, incomeStreamId) => `api/report/incomestream/${incomeStreamId}/indicators/${year}/${month}`,
            incomeStreamIndicatorsOverTime: (incomeStreamId) => `api/report/incomestream/${incomeStreamId}/indicators`,
            futureBalance: `api/report/futureBalance`
        },
        asset: {
            index: `api/asset`
        },
        transactionTemplate: {
            index: `api/transactionTemplate`,
            indexId: (id) => `api/transactionTemplate/${id}`
        },
        realEstate: {
            index: `api/realestate`,
            indexId: (id) => `api/realestate/${id}`,
            dashbaordReport: (id, year) => `api/realestate/${id}/report/${year}`,
            addNote: (realEstateId, month, year) => `api/realestate/${realEstateId}/note/${month}/${year}`,
            updateNote: (id) => `api/realestate/note/${id}`,
            yearlyCashFlowReport: (realEstateId, year) => `api/realestate/${realEstateId}/yearlycashflowreport/${year}`
        },
        document: {
            indexId: (id) => `api/document/${id}`,
            index: `api/document`,
            link: `api/document/link`,
            linkId: (id) => `api/document/link/${id}`,
            linkQuery: `api/document/link/query`,
            downloadDocuments: (lang) => `api/document/download/${lang}`,
            categories: (ref) => `api/document/category/${ref}`
        },
        incomeStreams: {
            index: `api/incomestream`,
            cashflowMatrix: (id, year) => `api/incomestream/${id}/yearlycashflowreport/${year}`
        },
        note: {
            index: `api/note`,
            indexId: (id) => `api/note/${id}`,
            getNotes: (ref, refId) => `api/note/${ref}/${refId}`
        },
        resident: {
            index: `api/resident`,
            indexId: (id) => `api/resident/${id}`,
            realEstateResidnets: (realEstateId) => `api/resident/realestate/${realEstateId}`,
        },
        kv: {
            index: key => `api/kv/${key}`
        },
        futureIncome: {
            index: `api/futureincome`
        },
        futureExpense: {
            index: `api/futureexpense`
        },
        recurringTransactions: {
            index: `api/recurringTransaction`,
            indexId: (id) => `api/recurringTransaction/${id}`
        },
        todo: {
            index: '/api/todo',
            get: (ref, refId, year, month) => `/api/todo/${ref}/${refId}/${year}/${month}`,
            update: (ref, refId) => `/api/todo/${ref}/${refId}`,
            fromRecurring: (ref, refId, year, month) => `/api/todo/fromRecurring/${ref}/${refId}/${year}/${month}`
        },
        recurringToDo: {
            index: '/api/recurringtodo',
            indexRefId: (ref, refId) => `/api/recurringtodo/${ref}/${refId}`
        },
        operationTag: {
            index: '/api/operationTag'
        },
        investment: {
            index: '/api/investment',
            indexId: (id) => `/api/investment/${id}`,
            close: (id) => `/api/investment/close/${id}`,
            reopen: (id) => `/api/investment/reopen/${id}`,
            autoCalculate: (id) => `api/investment/${id}/currentvalue/autocalculate`,
            setCurrentValue: (id) => `api/investment/${id}/currentvalue`
        },
        import: {
            transactions: {
                alior: '/api/import/transactions/alior'
            }
        }
    },
    routes: {
        login: '/login',
        dashboard: '/dashboard',
        profile: '/profile',
        userSettings: '/userSettings',
        apiUnavailable: '/unavailable',
        accounts: {
            index: '/accounts',
            add: (t) => `/addaccount/${t}`,
            edit: (id) => `/editaccount/${id}`,
            restore: `/restoreaccount`,
            view: (id) => `/account/${id}`,
            order: `/accountsorder`
        },
        transaction: {
            edit: (id, returnPath) => '/transaction/' + id + '/' + (getReturnPath(returnPath)),
            editTransfer: (id, returnPath) => '/transfer/' + id + '/' + (getReturnPath(returnPath)),
            transfer: '/transfer',
            transactions: '/transactions'
        },
        reimbursables: {
            index: '/reimbursables',
        },
        netValue: {
            index: `/netvalue`,
            assets: `/assets`
        },
        transactionTemplate: {
            index: `/transactiontemplates`,
            edit: (id) => `/edittransactiontemplate/${id}`,
            add: `/transactiontemplate`
        },
        realEstates: {
            index: `/realestates`,
            add: `/addrealestate`,
            dashboard: (id) => `/realestate/${id}`,
            edit: (id) => `/realestate/${id}/edit`
        },
        documents: {
            attachDocument: `/documents/attach`
        },
        incomeStreams: {
            dashboard: (id) => `/incomestream/${id}`,
            index: `/incomestreams`
        },
        reports: {
            expensesByCategory: '/reports/expensesbycategory',
            expensesIncomeStructure: '/reports/expensesincomestructure',
            savingFactor: '/reports/savingfactor',
            cashFlow: '/reports/cashflow',
        },
        residents: {
            addResident: (realEstateId) => `/addresident/${realEstateId}`,
            realEstateResidents: (realEstateId) => `/realestate/${realEstateId}/residents`,
            dashboard: (residentId) => `/resident/${residentId}`
        },
        recurringTransactions: {
            edit: (id) => `/editrecurringtransaction/${id}`,
            add: `/recurringtransaction`,
            index: `/recurringtransactions`
        },
        investments: {
            add: `/addinvestment`,
            edit: (id) => `/investment/${id}/edit`,
            index: `/investments`,
            dashboard: (id) => `/investment/${id}`,
            close: (id) => `/closeinvestment/${id}`,
            addTransaction: (id, type) => `/addinvestmenttransaction/${id}/${type}`
        },
        recurringBudgets: {
            index: `/recurringbudgets`
        },
        recurringPlannedIncome: {
            index: `/recurringincomes`
        },
        user: '/user'
    },
    methods: {
        post: 'POST',
        put: 'PUT',
        delete: 'DELETE',
        get: 'GET',

    },

    flags: {
        realEstateFeatureAccess: "RealEstateFeatureAccess",
        audit: "Audit"
    },
    keyCodes: {
        enter: 13,
        backspace: 8,
        delete: 46,
        arrowUp: 38,
        arrowDown: 40,
        arrowLeft: 37,
        arrowRight: 39,
        tab: 9,
        arrows: [38, 40, 37, 39]
    },
    localStorage: {
        sideBarState: 'sideBarState'
    },
    noteRefs: {
        realEstate: 'real-estate',
        incomeStream: 'income-stream',
        resident: 'resident',
        investment: 'investment'
    },
    documentRefs: {
        incomeStream: 'incomeStream',
        realEstate: 'realestate',
        resident: 'resident',
        investment: 'investment'
    },
    documentGroups: {
        categoryId: "categoryId",
        month: "month",
        year: "year"
    },
    todosRefs: {
        incomeStream: 'income-stream'
    },
    yearlyCashFlowReportRefs: {
        realEstate: 'real-estate',
        incomeStream: 'income-stream'
    },
    valueKeys: {
        cashFlowMatrixReportConfig: (ref, refId) => `cash-flow-report-config__${ref}_${refId}`,
        futureBalanceUnexpectedExpenses: 'future-balance-unexpected-expenses'
    },
    currencies: ['AFN', 'ALL', 'DZD', 'USD', 'EUR', 'AOA', 'XCD', 'ARS', 'AMD', 'AWG', 'AUD', 'AZN', 'BSD', 'BHD', 'BDT', 'BBD', 'BYR', 'BZD', 'XOF', 'BMD', 'BTN', 'INR', 'BOB', 'BOV', 'BAM', 'BWP', 'NOK', 'BRL', 'BND', 'BGN', 'BIF', 'CVE', 'KHR', 'XAF', 'CAD', 'KYD', 'CLF', 'CLP', 'CNY', 'COP', 'COU', 'KMF', 'CDF', 'NZD', 'CRC', 'HRK', 'CUC', 'CUP', 'ANG', 'CZK', 'DKK', 'DJF', 'DOP', 'EGP', 'SVC', 'ERN', 'ETB', 'FKP', 'FJD', 'XPF', 'GMD', 'GEL', 'GHS', 'GIP', 'GTQ', 'GBP', 'GNF', 'GYD', 'HTG', 'HNL', 'HKD', 'HUF', 'ISK', 'IDR', 'XDR', 'IRR', 'IQD', 'ILS', 'JMD', 'JPY', 'JOD', 'KZT', 'KES', 'KPW', 'KRW', 'KWD', 'KGS', 'LAK', 'LBP', 'LSL', 'ZAR', 'LRD', 'LYD', 'CHF', 'MOP', 'MKD', 'MGA', 'MWK', 'MYR', 'MVR', 'MRU', 'MUR', 'XUA', 'MXN', 'MXV', 'MDL', 'MNT', 'MAD', 'MZN', 'MMK', 'NAD', 'NPR', 'NIO', 'NGN', 'OMR', 'PKR', 'PAB', 'PGK', 'PYG', 'PEN', 'PHP', 'PLN', 'QAR', 'RON', 'RUB', 'RWF', 'SHP', 'WST', 'STN', 'SAR', 'RSD', 'SCR', 'SLL', 'SGD', 'XSU', 'SBD', 'SOS', 'SSP', 'LKR', 'SDG', 'SRD', 'SZL', 'SEK', 'CHE', 'CHW', 'SYP', 'TWD', 'TJS', 'TZS', 'THB', 'TOP', 'TTD', 'TND', 'TRY', 'TMT', 'UGX', 'UAH', 'AED', 'USN', 'UYI', 'UYU', 'UZS', 'VUV', 'VEF', 'VND', 'YER', 'ZMW', 'ZWL']
}