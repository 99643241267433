import React, { Component } from 'react';
import { H3 } from '../common/headers';
import { InvestmentContext } from '../contexts';
import InvestmentTransactionsList from './investment-transactions-list';
import './investment-transactions.scss';
import ChangeInvestmentCurrentValueForm from './change-investment-current-value-form';

class InvestmentTransactions extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  renderCurrentValue(investment) {
    return investment.isClosed ? null : <ChangeInvestmentCurrentValueForm investmentId={investment.id} transactionsBalance={investment.transactionsBalance} currentValue={investment.currentValue}></ChangeInvestmentCurrentValueForm>
  }
  render() {
    return <InvestmentContext.Consumer>
      {(investment) => <div>
        {this.renderCurrentValue(investment)}
        <H3 header="investment-transactions.capital"></H3>
        <InvestmentTransactionsList investmentId={investment.id} investmentReturn={false} investmentCapital={true}></InvestmentTransactionsList>
        <H3 header="investment-transactions.returns"></H3>
        <InvestmentTransactionsList investmentId={investment.id} investmentReturn={true} investmentCapital={false}></InvestmentTransactionsList>
      </div>
      }
    </InvestmentContext.Consumer>

  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

}


InvestmentTransactions.propTypes = {

}


export default InvestmentTransactions;