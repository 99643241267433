import React, { Component } from 'react';
import {onEnterKey} from '../../util';
import './password.scss';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames/bind';


export default class Password extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange({ target }) {
        let val = target.value;
        this.props.onChange(val);
    }

    render() {
        return (
            <div className={classNames("fc password-fc",
                `${this.props.name}-field`,
                "form-group",
                {
                    'has-error': this.props.hasError,
                    'no-label': this.props.label == null
                })}>
                <label><FormattedMessage id={this.props.label}></FormattedMessage></label>
                <input type="password"
                 autoComplete="current-password" 
                 className={"form-control" + (this.props.hasError ? ' is-invalid' : '')} 
                 name={this.props.name} 
                 onChange={this.onChange} 
                 onKeyPress={(e) => {
                    onEnterKey(e, this.props.onSubmit);
                }}
                 value={this.props.value} />
                {
                    this.props.hasError
                        ? <div className="error-message invalid-feedback">
                            <FormattedMessage id={this.props.errorMessage}></FormattedMessage>
                        </div>
                        : <div className="message"> </div>
                }
            </div>
        );
    }
}
