import predef from '../../predef';
import Store from './store';

class BudgetsNamesStore {
    constructor() {
        this.budgetsNamesStore = new Store(
            predef.endpoints.plan.budgetName
            , [predef.events.transaction.added, predef.events.transaction.deleted, predef.events.transaction.updated]
            , predef.events.planning.budgets.budgetsNamesStoreUpdated);
    }

    get budgetsNames() {
        return this.budgetsNamesStore.asObservable();
    }

}

const instance = new  BudgetsNamesStore();
export default instance;
