import classNames from 'classnames/bind';
import React from 'react';
import './info-box.scss';
import { FormattedMessage } from 'react-intl';


const InfoBox = (props) => {
  return props.value == null ? null : <div className="info-box-c">
    <div className={classNames("value", { grey: props.grey, blue: props.blue })}>{props.value}</div>
    <div className="label">
      {props.label != null ? <FormattedMessage id={props.label}></FormattedMessage> : null}
    </div>
  </div>
}


InfoBox.propTypes = {

}


export default InfoBox;