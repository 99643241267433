import React, { Component } from 'react';
import predef from '../../predef';
import './account-form.scss';
import Loader from '../common/loader';
import { routeParamId, combine, deepEqual, validate } from '../../util';
import Panel from '../common/panel';
import Button from '../common/button';
import Form from '../common/form';
import { FormattedMessage } from 'react-intl';
import AccountsStore from '../stores/accounts-store';
import LoadingScreen from '../common/loading-screen';
import AccountTypesStore from '../stores/account-types-store';
import UserSettingsStore from '../stores/user-settings-store';
import * as yup from 'yup';
import AccountsService from '../services/accounts-service';
import { createHashHistory } from 'history';
const history = createHashHistory();

const addValidationSchema = yup.object().shape({
  name: yup.string().min(1, 'common.field-is-required'),
  startingBalance: yup.number().required('common.field-is-required'),
  currency: yup.string().min(1, 'common.field-is-required').max(3,'common.mas-currency-length')
})

const editValidationSchema = yup.object().shape({
  name: yup.string().min(1, 'common.field-is-required'),
});

export class AccountForm extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
    this.save = this.save.bind(this);
  }

  render() {
    const form = {
      fields: [
        {
          type: 'text',
          name: 'name',
          label: 'account-form.name',
          layout: 'c c-sm-24'
        },
        {
          type: 'number',
          name: 'startingBalance',
          label: 'account-form.balance',
          hidden: this.props.account != null,
          layout: 'c c-sm-24',
          allowNegative: true
        },
        {
          type: 'currency',
          name: 'currency',
          label: 'common.currency',
          isClearable: false,
          hidden: this.props.account != null,
          layout: 'c c-sm-24'
        },
        {
          type: 'account-type',
          name: 'type',
          label: 'account-form.type',
          layout: 'c c-sm-24'
        },
        {
          type: 'number',
          name: 'limit',
          label: 'account-form.limit',
          layout: 'c c-sm-24',
          hidden: this.state.account == null || this.state.account.type != 4
        },
        {
          type: 'text',
          name: 'number',
          label: 'account-form.number',
          info: 'account-form.number-info',
          layout: 'c c-sm-24'
        }
      ]
    };

    return (
      <div className="account-form-c">
        <Panel>
          {this.state.loading ? <Loader show></Loader> :

            <Form onSubmit={this.save}
              definition={form}
              initialValues={this.state.account}
              validationSchema={this.props.account != null ? editValidationSchema : addValidationSchema}
              onChange={(a) => this.setState({ account: a })}
            >
              <Button link label="common.cancel" fa="arrow-left" path={predef.routes.accounts.index} ></Button>
            </Form>
          }
        </Panel>
      </div>
    );
  }

  save() {
    if (this.props.account != null) {
      return AccountsService.updateAccount(this.state.account)
        .then(() => {
          history.push(predef.routes.accounts.index)
        });
    }

    return AccountsService.addAccount(this.state.account)
      .then(() => {
        history.push(predef.routes.accounts.index)
      });
  }

  componentDidMount() {
    if (this.props.account != null) {
      this.setState({
        loading: false,
        account: {
          number: this.props.account.number,
          type: this.props.account.type,
          name: this.props.account.name,
          id: this.props.account.id,
          limit: this.props.account.limit
        }
      });
    }
    else {
      this.sub = combine(AccountTypesStore.accountTypes, UserSettingsStore.defaultCurrency, (types, currency) => {
        if (currency == null || types == null) return;
        this.setState({
          loading: false,
          account: {
            number: '',
            type: this.props.type != null && this.props.type > 0 ? this.props.type : types[0].value,
            name: '',
            startingBalance: 0,
            currency: currency,
          }
        });
      })
    }
  }

  componentWillUnmount() {
    if (this.sub != null) this.sub.unsubscribe();
  }
}

export const AddAccount = ({ match: { params } }) => {
  let type = params.type != null ?  parseInt(params.type) : null;
  return (
    <div>
      <h2><FormattedMessage id="account-form.add-header"></FormattedMessage></h2>
      <AccountForm  type={type}></AccountForm>
    </div>
  )
}


export class EditAccount extends Component {
  constructor(props) {
    super(props);
    this.state = { account: null, loading: true }
  }

  render() {
    return this.state.loading ? <LoadingScreen></LoadingScreen> :
      <div>
        <h2><FormattedMessage id="account-form.edit-header"></FormattedMessage></h2>
        <AccountForm account={this.state.account}></AccountForm>
      </div>
  }

  componentDidMount() {
    this.accountsSub = AccountsStore.accounts.subscribe(acs => {
      this.setState({ account: acs == null ? null : acs.find(x => x.id == routeParamId(this.props)), loading: acs == null });
    });
  }

  componentWillUnmount() {
    this.accountsSub.unsubscribe();
  }

}