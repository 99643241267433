import Service from './service';
import predef from '../../predef';

class CategoriesService extends Service {
    changeCategory(model) {
        return this.post(
            predef.endpoints.category.change
            , model
            , predef.events.category.categoriesChanged
            , null
            , 'categories-service.change-category-success'
            , 'categories-service.change-category-error');
    }

    deleteCategory(model) {
        return this.post(
            predef.endpoints.category.delete
            , model
            , predef.events.category.categoriesChanged
            , null
            , 'categories-service.delete-category-success'
            , 'categories-service.delete-category-error');
    }
}


let instance = new CategoriesService();
export default instance;
