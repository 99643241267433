import Service from './service';
import predef from '../../predef';
import Api from '../../api';

class UserService extends Service {
    changePassword(model) {
        return Api.postFormUrlEncoded(predef.endpoints.user.changePassword
            , `CurrentPassword=${model.currentPassword}&NewPassword=${model.newPassword}&NewPasswordConfirmation=${model.newPasswordConfirmation}`
            , 'user-service.password-changed'
            , 'user.password-changing-error'
        );
    }

    changeLogin(model) {
        var promise = Api.postFormUrlEncoded(predef.endpoints.user.changeLogin
            , `CurrentPassword=${model.currentPassword}&NewLogin=${model.newLogin}`
            , 'user-service.login-changed'
            , 'user.password-login-error'
        );

        promise.then(() => Api.setUserLogin(model.newLogin.toLowerCase()));

        return promise;
    }
}


let instance = new UserService();
export default instance;
