import moment, { calendarFormat } from 'moment';


export class CalendarService {
    static get isoFormat() {
        return 'YYYY-MM-DD'
    }



    toIsoDate(date) {
        let mdate = moment(date);
        if (mdate.isValid()) {
            return mdate.format(CalendarService.isoFormat);
        }

        return null;
    }

    isAfterIso(date1, date2) {
        let m1 = this.momentFromIsoDate(date1);
        let m2 = this.momentFromIsoDate(date2);

        return m1.isAfter(m2);
    }

    isoToMM_YYYY(isoDate) {
        return this.momentFromIsoDate(isoDate).format('MMM `YY');
    }

    momentFromIsoDate(date) {
        return moment(moment(date, CalendarService.isoFormat).toDate());
    }

    momentToIsoDate(mdate) {
        return mdate.format(CalendarService.isoFormat);
    }

    fromIsoDate(date) {
        return this.momentFromIsoDate(date).toDate();
    }

    get today() {
        return moment().startOf('day');
    }

    get todayIso() {
        return this.today.format(CalendarService.isoFormat);
    }

    getMonthFromIso(date) {
        return this.momentFromIsoDate(date).month() + 1;
    }

    getYearFromIso(date) {
        return this.momentFromIsoDate(date).year();
    }


    getDayFromIso(date) {
        return this.momentFromIsoDate(date).date();
    }

    getLastDayOfMonthFromIso(date) {
        return this.momentFromIsoDate(date).daysInMonth();
    }

    get currentMonth() {
        return moment().month() + 1;
    }

    get currentYear() {
        return moment().year();
    }

    get firstOfCurrentMonth() {
        return moment().startOf('month').format(CalendarService.isoFormat);
    }

    monthDateRange(isoDate) {
        var mdate = moment(isoDate, CalendarService.isoFormat);

        return {
            dateFrom: mdate.startOf('month').format(CalendarService.isoFormat),
            dateTo: mdate.endOf('month').format(CalendarService.isoFormat)
        };
    }

    yearDateRange(year) {
        var start = `${year}-01-01`;
        var mdate = moment(start, CalendarService.isoFormat);

        return {
            dateFrom: start,
            dateTo: mdate.endOf('year').format(CalendarService.isoFormat)
        };
    }

    get last12MonthsRange() {
        return {
            dateFrom: this.momentToIsoDate(moment().subtract(12, 'months')),
            dateTo: this.todayIso
        };
    }

}

const calendar = new CalendarService();

export class IsoCalendar {
    static startOfMonth(isoDate) {
        var mdate = calendar.momentFromIsoDate(isoDate);

        return calendar.momentToIsoDate(mdate.startOf('month'));
    }

    static endOfMonth(isoDate) {
        var mdate = calendar.momentFromIsoDate(isoDate);

        return calendar.momentToIsoDate(mdate.endOf('month'));
    }

    static get startOfCurrentMonth() {
        return calendar.momentToIsoDate(moment().startOf('month'));
    }

    static get endOfCurrentMonth() {

        return calendar.momentToIsoDate(moment().endOf('month'));
    }

    static getYear(date) {
        let m = moment(date, CalendarService.isoFormat);
        return m.year();
    }

    static getMonth(date) {
        let m = moment(date, CalendarService.isoFormat);
        return m.month() + 1;
    }
}

export default calendar;
