import Service from './service';
import api from '../../api';
import predef from '../../predef';

class FlagsService extends Service {
    hasFlag(flag) {
        let flags = api.getFlags();
        let foundFlag = flags.find(x => x === flag);
        return foundFlag != null;
    }

    get hasRealEstateFeatureAccessFlag() {
        return this.hasFlag(predef.flags.realEstateFeatureAccess);
    } 

    
    get hasAuditFlag() {
        return this.hasFlag(predef.flags.audit);
    } 
}


let instance = new FlagsService();
export default instance;
